import React from "react";
import PropTypes from "prop-types";
import options from "../options"
import Select from "react-select";
// import "./style.css";
const InspectionAndExamination = ({ handleSingleSelect, handleMultipleSelect, medicalInput, changeInput, editMode }) => {

	const headingStyle = {
		margin: "30px 0 ",
		marginLeft: "20px",
		fontSize: "2.6em",
		color: "#A3CB38",
		fontFamily: "Manrope, sans-serif",
		letterSpacing: "1.3px"

	}

	const headingContainer = {

		width: "100%",
		textAlign: "center"

	}
	const firstPanaelbody = {
		height: "250px"
	}
	const secondPanelBody = {

		height: "200px"
	}
	return (
		<div className="main-container">
			<div style={headingContainer}>


				<h3 className="mt-10" style={headingStyle}>Inspection And Examination</h3>
			</div>
			<div className="panel-body">
				<legend className="legend-text">Listening and Smelling</legend>
				<div className="form-group">
					<div className="row">
						<div className="col-12 col-md-3">
							<label scope="row"><b>Respiration</b> </label>
						</div>
						<div className="col-12 col-md-4">
							<Select
								isDisabled={editMode}
								isMulti
								options={options.respiration}
								placeholder={`Select Respiration...`}
								value={options.respiration.filter((opt) => medicalInput["respiration"]?.includes(opt.value))}
								onChange={(e) => handleMultipleSelect("respiration", e)}
							/>
						</div>
						<div className="col-5">&nbsp;</div>
					</div>
				</div>



				<div className="form-group">
					<div className="row">
						<div className="col-12 col-md-3">
							<label scope="row"> <b>Speech</b></label>
						</div>
						<div className="col-12 col-md-4">
							<Select
								isDisabled={editMode}
								isMulti
								options={options.speech}
								placeholder={`Select speech...`}
								value={options.speech.filter((opt) => medicalInput["speech"]?.includes(opt.value))}
								onChange={(e) => handleMultipleSelect("speech", e)}
							/>
						</div>
						<div className="col-5">&nbsp;</div>
					</div>
				</div>

				<div className="form-group">
					<div className="row">
						<div className="col-12 col-md-3">
							<label scope="row"> <b>Cough</b> </label>
						</div>
						<div className="col-12 col-md-4">
							<Select
								isDisabled={editMode}
								isMulti
								options={options.cough}
								placeholder={`Select speech...`}
								value={options.cough.filter((opt) => medicalInput["cough"]?.includes(opt.value))}
								onChange={(e) => handleMultipleSelect("cough", e)}

							/>
						</div>
						<div className="col-5">&nbsp;</div>
					</div>
				</div>
				<div className="form-group">
					<div className="row">
						<div className="col-12 col-md-3">
							<label scope="row"> <b>Odor</b> </label>
						</div>
						<div className="col-12 col-md-4">
							<Select
								isDisabled={editMode}
								isMulti
								options={options.odor}
								placeholder={`Select odor...`}
								value={options.odor.filter((opt) => medicalInput["odor"]?.includes(opt.value))}
								onChange={(e) => handleMultipleSelect("odor", e)}
							/>
						</div>
						<div className="col-5">&nbsp;</div>
					</div>
				</div>


			</div>

			<div className="panel-body" >
				<legend className="legend-text">Observation</legend>
				<div className="form-group">
					<div className="row">
						<div className="col-12 col-md-3">
							<label scope="row"> <b> Vitality</b> </label>
						</div>
						<div className="col-12 col-md-4">
							<Select
								isDisabled={editMode}
								isMulti
								options={options.vitality}
								placeholder={`Select vitality...`}
								value={options.vitality.filter((opt) => medicalInput["vitality"]?.includes(opt.value))}
								onChange={(e) => handleMultipleSelect("vitality", e)}
							/>
						</div>
						<div className="col-5">&nbsp;</div>
					</div>
				</div>
				<div className="form-group">
					<div className="row">
						<div className="col-12 col-md-3">
							<label scope="row"> <b>Color and Lustre of Face </b> </label>
						</div>
						<div className="col-12 col-md-4">
							<Select


								isDisabled={editMode}
								isMulti
								options={options.colorFace}
								placeholder={`Select "Color and Lustre of Face...`}
								value={options.colorFace.filter((opt) => medicalInput["colorFace"]?.includes(opt.value))}
								onChange={(e) => handleMultipleSelect("colorFace", e)}
							/>
						</div>
						<div className="col-5">&nbsp;</div>
					</div>
				</div>


			</div>

			<div className="panel-body">
				<legend className="legend-text">Physical Examination</legend>
				<div className="form-group">
					<div className="row">
						<div className="col-12 col-md-3">
							<label>
								<b>Physical Appearance&nbsp;:</b>
							</label>
							<Select
								isDisabled={editMode}
								isMulti
								name="physicalAppearance"
								options={options.physicalAppearance}
								placeholder={`Select physicalAppearance...`}
								value={options.physicalAppearance.filter((opt) =>
									medicalInput.physicalAppearance?.includes(opt.value)
								)}
								onChange={(e) => handleMultipleSelect("physicalAppearance", e)}
							/>

						</div>
						<div className="col-12 col-md-9">
							<label>
								<b>Note for Physical Appearance&nbsp;:</b>
							</label>
							<textarea
								className="form-control"
								rows={3}
								type="text"
								name="physicalAppearanceNote"
								value={medicalInput.physicalAppearanceNote}
								onChange={changeInput}
								placeholder="Write physical Appearance Note..."
								readOnly={editMode}
							/>
						</div>
						<div className="col-5">&nbsp;</div>
					</div>
				</div>
				<div className="form-group">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>Tongue-Shape</b>
									</label>
									<Select
										isDisabled={editMode}
										isMulti
										
										options={options.tongueShape}
										placeholder={"Select Tongue-Shape"}
										value={options.tongueShape.filter((opt) =>
											medicalInput["tongueShape"]?.includes(opt.value)
										)}
										onChange={(e) => handleMultipleSelect("tongueShape", e)}
									/>
								</div>
								<div className="col-12 col-md-3">
									<label>
										<b>Tongue-Color</b>
									</label>
									<Select
										isDisabled={editMode}
										isMulti
										
										options={options.tongueColor}
										placeholder={"Select Tongue-color"}
										value={options.tongueColor.filter((opt) =>
											medicalInput["tongueColor"]?.includes(opt.value)
										)}
										onChange={(e) => handleMultipleSelect("tongueColor", e)}
									/>
								</div>
								<div className="col-12 col-md-3">
									<label>
										<b>Tongue-Quality</b>
									</label>
									<Select
										isDisabled={editMode}
										isMulti
										
										options={options.tongueQuality}
										placeholder={"Select Tongue-Quality"}
										value={options.tongueQuality.filter((opt) =>
											medicalInput["tongueQuality"]?.includes(opt.value)
										)}
										onChange={(e) => handleMultipleSelect("tongueQuality", e)}
									/>
								</div>
								
								<div className="col-12 col-md-9">
									<label>
										<b>Note for Tongue:</b>
									</label>
									<textarea
										disabled={editMode}
										rows="3" type="text" className="form-control" name="tongueNote"
										placeholder="Type additional information for Tongue..." 
										value={medicalInput["tongueNote"]}
										onChange={changeInput}
									/>
								</div>
								<div className="col-5">&nbsp;</div>
							</div>
						</div>

						<div className="form-group">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>Pulse-Speed</b>
									</label>
									<Select
										isDisabled={editMode}
										isMulti
									
										options={options.pulseSpeed}
										placeholder={"Select Pulse-Speed"}
										value={options.pulseSpeed.filter((opt) =>
											medicalInput["pulseSpeed"]?.includes(opt.value)
										)}
										onChange={(e) => handleMultipleSelect("pulseSpeed", e)}
									/>
								</div>
								<div className="col-12 col-md-3">
									<label>
										<b>Pulse-Depth</b>
									</label>
									<Select
										isDisabled={editMode}
										isMulti
									
										options={options.pulseDepth}
										placeholder={"Select Pulse-Depth"}
										value={options.pulseDepth.filter((opt) =>
											medicalInput["pulseDepth"]?.includes(opt.value)
										)}
										onChange={(e) => handleMultipleSelect("pulseDepth", e)}
									/>
								</div>
								<div className="col-12 col-md-3">
									<label>
										<b>Pulse-Strength</b>
									</label>
									<Select
										isDisabled={editMode}
										isMulti
									
										options={options.pulseStrength}
										placeholder={"Select Pulse-Strength"}
										value={options.pulseStrength.filter((opt) =>
											medicalInput["pulseStrength"]?.includes(opt.value)
										)}
										onChange={(e) => handleMultipleSelect("pulseStrength", e)}
									/>
								</div>
									
								<div className="col-12 col-md-3">
									<label>
										<b>Pulse-Tension</b>
									</label>
									<Select
										isDisabled={editMode}
										isMulti
									
										options={options.pulseTension}
										placeholder={"Select Pulse-Tension"}
										value={options.pulseTension.filter((opt) =>
											medicalInput["pulseTension"]?.includes(opt.value)
										)}
										onChange={(e) => handleMultipleSelect("pulseTension", e)}
									/>
								</div> 
								{/* <div className="col-12 col-md-3">
									<label>
										<b>Pulse-Rhythm</b>
									</label>
									<Select
										isDisabled={editMode}
										isMulti
										
										options={options.pulseRhythm}
										placeholder={"Select Pulse-Rhythm"}
										value={medicalInput["pulseRhythm"]}
										// value={options.pulseRhythm.filter((opt) =>
										// 	medicalInput["pulseRhythm"].includes(opt.value)
										// )}
										onChange={(e) => handleMultipleSelect("pulseRhythm", e)}
									/>
								</div>    */}
								
							</div>
						</div>

						<div className="form-group">
							<div className="row">
								{/* <div className="col-12 col-md-3">
									<label>
										<b>Pulse-Quality</b>
									</label>
									<Select
										isDisabled={editMode}
										isMulti
										styles={customStyles}
										options={options.pulseQuality}
										placeholder={"Select Pulse-Quality"}
										value={options.pulseQuality.filter((opt) =>
											options?.pulseQuality?.includes(opt.value)
										)}
										onChange={(e) => handleMultipleSelect("pulseQuality", e)}
									/>
								</div> */}
							  
								
							</div>
						</div>

				<div className="form-group">
					<div className="row">
						<div className="col-12 col-md-3">
							<label>
								<b>Palpation of the Epigastrium&nbsp;:</b>
							</label>
							{/* <Select
								isDisabled={editMode}
								isMulti
								name="palpationEpigastrium"
								options={options.palpationEpigastrium}
								placeholder={`Select palpation of the Epigastrium...`}
								value={options.palpationEpigastrium.filter((opt) =>
									medicalInput.palpationEpigastrium?.includes(opt.value)
								)}
								onChange={(e) => handleMultipleSelect("palpationEpigastrium", e)}
							/> */}

						</div>
						<div className="col-12 col-md-9">
							<label>
								<b>Note for Palpation of the Epigastrium&nbsp;:</b>
							</label>
							<textarea
								className="form-control"
								rows={3}
								type="text"
								name="epigastriumPalpationNote"
								defaultValue={medicalInput.epigastriumPalpationNote}
								onChange={changeInput}
								placeholder="Write Palpation of the Epigastrium Note..."
								readOnly={editMode}
							/>
						</div>
						<div className="col-5">&nbsp;</div>
					</div>
				</div>


				

				<div className="form-group">
					<div className="row my-4"  >
						<div className="col-12 col-md-3">
							<label>
								<b>Range of motion&nbsp;:</b>
							</label>
						</div>
						<div className="col-12 col-md-9">
							<Select
								isDisabled={editMode}
								options={options.rangeMotion}
								placeholder={`Select motionRange...`}
								defaultValue={options.rangeMotion && {
									value:medicalInput.rangeMotion,
									label:medicalInput.rangeMotion
								}}
								onChange={(e) => handleSingleSelect("rangeMotion", e.value)}
							/>
						</div>
						{/* <div className="col-5">&nbsp;</div> */}
					</div>
				</div>
				<div className="form-group">
					<div className="row my-4"  >
						<div className="col-12 col-md-3">
							<label>
								<b>Pain gradations&nbsp;:</b>
							</label>
						</div>
						<div className="col-12 col-md-9">
							<Select
								isDisabled={editMode}
								options={options.painLevel}
								placeholder={`Select Pain gradations...`}
								value={
									medicalInput.painLevel && {
										value:medicalInput.painLevel,
										label:medicalInput.painLevel
									}}
								
								onChange={(e) => handleSingleSelect("painLevel", e.value)}
							/>
						</div>
						{/* <div className="col-5">&nbsp;</div> */}
					</div>
				</div>

				<div className="form-group">
					<div className="row">
						<div className="col-12 col-sm-3">
							<label>
								<b>Note for Physical Examination :</b>
							</label>
						</div>
						<div className="col-12 col-sm-9">
							<textarea
								className="form-control"
								rows={6}
								type="text"
								name="physicalExaminationNote"
								defaultValue={medicalInput.physicalExaminationNote}
								onChange={changeInput}
								placeholder="Write Physical Examination Note..."
								readOnly={editMode}
							/>
						</div>
					</div>
				</div>


			</div>
		</div>
	);
}


export default InspectionAndExamination;

import React from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TabContent } from "reactstrap";
import { Panel, PanelHeader, PanelBody,} from "../../components/panel/panel.jsx";
import { getUsers } from "../../services/users";
import { apiUrl } from "./../../config/config.json";
import http from "./../../services/httpService";
import { getKaizen } from "./../../services/kaizens";
//import { getWastes } from "./../../services/wastes";
import { deleteAction, getAction, getActions } from "./../../services/actions";
import { getRootCauses } from "./../../services/rootcauses";
import BasicInfo from "./BasicInfoFields/BasicInfo";
import Fishbone from "./Fishbone/Fishbone";
import "../../index";
import ReusableTab from "../../common/ReusableTab";
// import SpreadSheet from "./SpreadSheet";
import ReusableTabNavs from "../../common/ReusableTabNavs";
import Spreadsheet from "./Spreadsheet/SpreadSheet";
import Tabsharing from "../../common/Tabsharing";
import Notes from "../../common/actions.js";

//import Actions from "./../../../src/components/kaizen/Action";
//import Filter from "./../../../src/components/kaizen/Filters";
//import Category from "./../../../src/components/kaizen/Category";
//import "./../../../src/components/kanban/style.css";

class Kaizenprofile extends React.Component {
  constructor(props) {
    super(props);
	
    this.state = {
      dropdownOpen: false,
      readOnly: true,
      activeTab: 1,
      read: true,
      users: [],
      kanbanName:"",
      data: {
        username: "",
        wasteType: "",
        name:"",
        businessName: "",
        department: "",
        subDepartment: "",
        locations: "",
        plannedDate: new Date(),		
        deadline: new Date(),
        field: "",
        tags: "",
        reference: "",
        //award:"",		
        //assigned to one only
        participants: [],
        sponsor: [],
        falicitator: [],		
        status: "",		
        users: [
          {
            userid: "",
            email: "",
            username: "",
            sharedtilldate: "",
            view: true,
            comment: false,
            edit: false,
          },
        ],
        sharedTo: [],
        sharedTill: [],
        permissions: [],
        sharingLink: "",
        sharedUsers: [],
        narrative:"",
        kaizenId:""
      },
      isLoading: true,
      selectedFile: null,
      loaded: 0,
    };

    this.wasteTypeOptions = [
      { value: "environment", label: "environment" },
      { value: "methods", label: "methods" },
      { value: "people", label: "people" },
      { value: "equipment", label: "equipment" },
      { value: "proces", label: "proces" },
      { value: "materials", label: "materials" },
      { value: "client", label: "client" },
      { value: "supplier", label: "supplier" },	  
    ];

    this.statusOptions = [
      { value: "in-planning", label: "In-Planning" },
      { value: "doing", label: "Doing" },	  
      { value: "checking", label: "Checking" },	  	  
      { value: "acting", label: "Acting" },	  	  
      { value: "active", label: "Active" },
      { value: "onhold", label: "Onhold" },
      { value: "closing", label: "Closing" },
    ];

    this.setReadOnly = this.setReadOnly.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.toggleRead = this.toggleRead.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  removeFile = (files) => {
    this.setState({ selectedFile: files });
  };

  getParent = async(c,comment,id)=>{
    if(comment.parentId===id){
      return c
    }
    else{
    if(!comment.parentId){
    
      return 0
    }
    else{
      const p = await getAction(comment.parentId)
      return this.getParent(c,p.data,id)
    }
  }
  }
   getAllKids = async (id) => {
    try {
      let table=[]
      const comments = await getActions()
      console.log(comments.data)
      for (let j = 0; j <comments.data.length ; j++) {
        const promise1 = Promise.resolve(this.getParent(comments.data[j],comments.data[j],id));
        promise1.then(async(value) => {
          if(value) {
            table.push(value)
          }
        });
        if(j==comments.data.length-1) {
         return table ;
        }
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  async populateKaizen() {
    try {
      const id = this.props.match.params.id;
      const { data: kaizen } = await getKaizen(id);

      let sharedusers = [];
      /*kaizen.share.sharedTo.map((shareduser, index) => {
        let sharedobject = {
          userid: shareduser._id,
          email: shareduser.email,
          username: shareduser.username,
          sharedtilldate: kaizen.share.sharedTill[index],
          view: kaizen.share.permissions[index].view,
          comment: kaizen.share.permissions[index].comment,
          edit: kaizen.share.permissions[index].edit,
        };
        sharedusers.push(sharedobject);
      });*/
      this.setState({ data: this.mapToViewModel(kaizen, sharedusers) });
    } catch (ex) {
      console.error(ex)
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/error");
    }
  }

  formatSelectUsers = (users) => (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: "10px" }}>
          <img width={15} src={users.imageSrc} alt="user img" />
        </div>
        <div>{users.contactName.first + " " + users.contactName.last}</div>
      </div>
    </div>
  );

  async populateUsers() {
    const { data: users } = await getUsers();
    this.setState({ users });
  }

  handleInputChange = (name, value, userIndex) => {
    const data = { ...this.state.data };

    if (value !== "") {
      this.setState({ registeredUser: false });
    } else {
      if (data.users.length === 1) this.setState({ registeredUser: true });
    }

    let username, useremail;
    this.state.users.map((user) => {
      if (user._id === value) {
        username = user.username;
        useremail = user.email;
      }
    });
    data["users"] = this.state.data.users.map((item, index) =>
      index === userIndex
        ? {
            ...item,
            [name]: value,
            nonRegisterEmail: value,
            username: username,
            email: useremail,
          }
        : item
    );
    this.setState({ data });
  };

  handleChange = (name, value, userIndex) => {
    this.setState({ nonRegisteredUser: false });
    const data = { ...this.state.data };
    let username, useremail;
    this.state.users.map((user) => {
      if (user._id === value._id) {
        username = user.username;
        useremail = user.email;
      }
    });
    data["users"] = this.state.data.users.map((item, index) =>
      index === userIndex
        ? {
            ...item,
            [name]: value._id,
            registerId: value._id,
            username: username,
            email: useremail,
          }
        : item
    );
    this.setState({ data });
  };

  handleDateChange = (name, value, userIndex) => {
    const data = { ...this.state.data };
    data["users"] = this.state.data.users.map((item, index) =>
      index === userIndex ? { ...item, [name]: value } : item
    );
    this.setState({ data });
  };

  handleCheckboxChange = (name, userIndex) => {
    const data = { ...this.state.data };
    data["users"] = this.state.data.users.map((item, index) =>
      index === userIndex ? { ...item, [name]: !item[name] } : item
    );
    this.setState({ data });
  };

  addUser = () =>
    this.setState({
      data: {
        ...this.state.data,
        users: [
          ...this.state.data.users,
          {
            username: "",
            email: "",
            registerId: "",
            nonRegisterEmail: "",
            sharedtilldate: "",
            view: false,
            comment: false,
            edit: false,
          },
        ],
      },
    });

  removeUser = (index) => {
    this.setState({
      data: {
        ...this.state.data,
        users: this.state.data.users.filter((mem, i) => index !== i),
      },
    });
  };

  handleSubmit = async () => {
    console.log("kaizenprofile handlesubmit")
    this.setState({ submitted: true });
    const { data } = this.state;
    const id = this.props.match.params.id;
    const sharedRegistrationUsers = [];
    const sharedNonRegistrationUsers = [];
    const sharedTill = [];
    const permissions = [];

    data.users.map((i) => {
      if (i.registerId && i.registerId !== "") {
        sharedRegistrationUsers.push(i.registerId);
      } else if (i.nonRegisterEmail && i.nonRegisterEmail !== "") {
        sharedNonRegistrationUsers.push(i.nonRegisterEmail);
      }

      i.sharedtilldate !== "" && sharedTill.push(i.sharedtilldate);

      if (i.registerId !== "" || i.nonRegisterEmail !== "") {
        permissions.push({ view: i.view, comment: i.comment, edit: i.edit });
      }
    });

    try {
      if (sharedRegistrationUsers.length > 0) {
        const share = {
          _id: id,
          sharingLink: data.sharingLink,
          sharedTo: sharedRegistrationUsers,
          sharedTill,
          permissions,
        };
        //await sharingKaizen(share);
        this.props.history.push("/kaizen/kaizens");
      } else if (sharedNonRegistrationUsers.length > 0) {
        const shareNoregistredUsers = {
          _id: id,
          sharingLink: data.sharingLink,
          sharedTo: sharedNonRegistrationUsers,
          sharedTill,
          permissions,
        };
        //await sharingKaizen({ shareNoregistredUsers });
        this.props.history.push("/kaizen/kaizens");
      }
      this.setState({ submitted: false });
    } catch (ex) {
      if (ex.response) {
        const errors = { ...this.state.errors };
        errors.status = ex.response.data;
        this.setState({ errors });
      }
      this.setState({ submitted: false });
    }
  };

  async componentDidMount() {
    await this.populateKaizen();
    //await this.populateWastes();	
    //await this.populaterootCauses();	
    //await this.populateactions();		
    await this.populateUsers();
    this.setState({ isLoading: false });
  }

  mapToViewModel(kaizen, sharedusers) {
    return {
      _id: kaizen._id,
      username: kaizen?.userID?.username,
      name: kaizen?.name,
      wasteType: kaizen?.wasteType,
      businessName: kaizen?.businessName,
      department: kaizen?.department,
      subDepartment: kaizen?.subDepartment,
      locations: kaizen?.locations,
      plannedDate: new Date(kaizen?.plannedDate),	  
      deadline: new Date(kaizen?.deadline),
      field: kaizen?.field,
      tags: kaizen?.tags,
      reference: kaizen?.reference,
      //award: kaizen?.award,	  
      //assigned to one only
      participants: kaizen?.participants,
	  sponsor: kaizen?.sponsor,
	  falicitator: kaizen?.falicitator,
      status: kaizen?.status,
      participants: kaizen?.userID,
      users: this.state.data.users,
      sharedTo: this.state.data.sharedTo,
      sharedTill: this.state.data.sharedTill,
      permissions: this.state.data.permissions,
      sharingLink: window.location.pathname,
      sharedUsers: sharedusers,
      narrative:kaizen?.narrative,
      kaizenId: kaizen?.kaizenNo
    };
  }

  setActiveTab = (n) => this.setState({ activeTab: n });
  actions = [
    { label: "Save", icon: "fa-save", trigger: () => {} },
    { label: "Edit", icon: "fa-edit", trigger: () => this.setReadOnly() },
    { label: "Print", icon: "fa-print", trigger: () => {} },
    { label: "Share", icon: "fa-share", trigger: () => {} },
    {
      label: "Archive",
      icon: "fa-archive",
      trigger: () => {},
    },

    {
      label: "Save as PDF",
      icon: "fas-fa-file-pdf",
      trigger: () => {},
    },
    {
      label: "Save as XLS",
      icon: "fas-fa-file-excel",
      trigger: () => {},
    },
    {
      label: "Save as CSV",
      icon: "fa-csv",
      trigger: () => {},
    },
  ];

  toggleRead = () => this.setState({ read: !this.state.read });

  setReadOnly = () => this.setState({ readOnly: !this.state.readOnly });

  ///

  ///
  onChangeHandler = (event) => {
    const files = event.target.files;

    // if return true allow to setState
    this.setState({
      selectedFile: files,
      loaded: 0,
    });
  };

  onClickHandler = async (e) => {
    e.preventDefault();
    console.log(this.state.selectedFile, this.state.data);
    try {
      const { data, selectedFile } = this.state;
      const apiEndpoint = apiUrl + "/kaizens";
      const formData = new FormData();
      const body = { ...data };
      delete body._id;
      for (let key in body) {
        formData.append(key, body[key]);
      }
      for (let x = 0; x < selectedFile.length; x++) {
        formData.append("attachments", selectedFile[x]);
      }
      http.put(apiEndpoint + "/" + data._id, formData, {
        onUploadProgress: (ProgressEvent) => {
          this.setState({
            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
          });
        },
      });

      //this.props.history.push("/clinic/kaizens");
    } catch (ex) {
      if (ex.response) {
        console.log(ex.response.data);
      }
    }
  };

  render() {
    if (this.state.isLoading === true)
      return (
        <Spinner
          animation="border"
          style={{
            width: "6rem",
            height: "6rem",
            border: "1px solid",
            position: "fixed",
            top: "50%",
            left: "50%",
          }}
        />
      );

      console.log("state inside profile :  " , this.state.data)

    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/kaizens">Kaizens</Link>
          </li>
        </ol>
        <h1 className="page-header">Kaizen-profile</h1>
        <div className="row">
          <div className="col-12">
            <Panel>
              <PanelHeader noButton>Kaizens</PanelHeader>
              <PanelBody>
                <h1>{this.state.data.name}</h1>

                <ReusableTabNavs
                  actions={this.actions}
                  setActiveTab={(n) => this.setActiveTab(n)}
                  activeTab={this.state.activeTab}
                  navprops={[
                    { label: "Basic information", background: "#FFC69F" },
                    { label: "Data Spreadsheet", background: "#DED99F" },
                    { label: "Actions", background: "#FFC6FF" },
                    { label: "Fishbone", background: "#F4FF2B" },					
                    { label: "Piechart", background: "#B09EFF" },					
                    { label: "Reviews", background: "#FFF5AD" },
                    { label: "Sharing", background: "#A2F5AD" },
                    { label: "Notes", background: "#FFFFC9" },
                  ]}
                />
                <TabContent activeTab={this.state.activeTab}>
                  <ReusableTab id={1}>
                    <BasicInfo
                      readOnly={this.state.readOnly}
                      setReadOnly={() => this.setReadOnly()}
                      statusOptions={this.statusOptions}
                      data={this.state.data}
                      onChangeHandler={this.onChangeHandler}
                      onClickHandler={this.onClickHandler}
                      loaded={this.state.loaded}
                      selectedFile={this.state.selectedFile}
                      removeFile={this.removeFile}
                />
                  </ReusableTab>
                  <ReusableTab id={2} height={"100%"} width={"100%"}>
                    <Spreadsheet
                      readOnly={this.state.readOnly}
                      setReadOnly={() => this.setReadOnly()}
                    />
                  </ReusableTab>
                  <ReusableTab id={3}>
                    <>
                    <Notes commentlists={this.state.tops} />                  
                    </>
                  </ReusableTab>
                  <ReusableTab id={4}>
                    <Fishbone />				  
                  </ReusableTab>
                  <ReusableTab id={5}>
                  </ReusableTab>
                  <ReusableTab id={6}>
                    <>
                      <h4>Reviews</h4>
                      <p>
                        task nr 11
                        http://cameronroe.github.io/react-star-rating/
                        https://github.com/SahajR/react-star-review
                      </p>
                    </>
                  </ReusableTab>
                  <ReusableTab id={7}>
                    <Tabsharing
                      registeredUser={this.state.registeredUser}
                      nonRegisteredUser={this.state.nonRegisteredUser}
                      removeUser={this.removeUser}
                      handleChange={this.handleChange}
                      handleCheckboxChange={this.handleCheckboxChange}
                      handleDateChange={this.handleDateChange}
                      handleSubmit={this.handleSubmit}
                      addUser={this.addUser}
                      data={this.state.data}
                      selectUsers={this.state.users}
                      formatSelectUsers={this.formatSelectUsers}
                      handleInputChange={this.handleInputChange}
                      submitted={this.state.submitted}
                    />
                  </ReusableTab>
                  <ReusableTab id={8}>
                    <>
                    <h4>Notes</h4>
                      <p>
                        Nullam ac sapien justo. Nam augue mauris, malesuada non
                        magna sed, feugiat blandit ligula. In tristique
                        tincidunt purus id iaculis. Pellentesque volutpat tortor
                        a mauris convallis, sit amet scelerisque lectus
                        adipiscing.
                      </p>
                    </>
                  </ReusableTab>
                </TabContent>
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}

export default Kaizenprofile;

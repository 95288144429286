import { MedicalHistory } from "./MedicalHistory";
import Interview from "./Interview";
import InspectionAndExamination from "./InspectionAndExamination";
import { TCMTreatment } from "./TCMTreatment";
import { saveTCMsession } from "../../../../services/tcmsessions";
import { Personal } from "./Personal"
import React, { useState, useEffect } from 'react';
import { Box } from "@mui/material"
import 'react-tabs/style/react-tabs.css';
import "../style.css"
import {
	Panel,
	PanelHeader,
	PanelBody,
} from "../../../../components/panel/panel.jsx"
import ReusableTabNavs from "../../../../common/ReusableTabNavs";
import ReusableTab from "../../../../common/ReusableTab";
import { TabContent } from "reactstrap";



const PreviousSessionsContainer = React.memo(props => {
	const { editMode, setEditMode, sessionData, setfetchAgain, getPrevSessions,fetchAgain, acuPoint, materiaMedic, materiaMedics, herbal } = props;
	const [medicalInput, setmedicalInput] = useState({});


	useEffect(async () => {
		const medicalData = mapToViewModel(sessionData);
		console.log(medicalData)
		await setmedicalInput(medicalData)
		console.log(medicalInput, "tcmsession map view")
		// mapToViewModel(sessionData)
	}, [sessionData])

	const materiaMedicRemedy = [
		{
		  value: "Abies Canadensis",
		  label: "Abies Canadensis",
		},
		{
		  value: "Abies Nigra",
		  label: "Abies Nigra",
		},
		{
		  value: "Acalypha Indica",
		  label: "Acalypha Indica",
		},
		{
		  value: "Acetic Acid",
		  label: "Acetic Acid",
		},
		{
		  value: "Aconitum Napellus",
		  label: "Aconitum Napellus",
		},
	  ];

	function mapToViewModel(tcmsession) {
		console.log("tcmsession map view", tcmsession)
		return {
			_id: tcmsession._id,
			selectedRegisteredPatient: tcmsession.patientNo,
			clinicNo: tcmsession.clinicNo,
			doctorNo: tcmsession.doctorNo,
			chiefComplaint: tcmsession.chiefComplaint,
			symptoms: tcmsession.symptoms,
			westernDisease: tcmsession.WesternDisease,
			currentTreatment: tcmsession.currentTreatment.map((item) => item),
			diseases: tcmsession.diseasesIllnesses,
			surgeries: tcmsession.surgeries,
			medicaments: tcmsession.medicamentsSupplements,
			allergies: tcmsession.allergies,
			pregnancies: tcmsession.pregnancies,
			familyMembers: tcmsession.familyHistory.map((item) => item),
			noteMedicalHistory: tcmsession.medicalHistoryNote,
			socialRelationship: tcmsession.socialRelationship,
			habits: tcmsession.habits.map((item) => item),
			profession: tcmsession.occupation.name,
			employmentStatus: tcmsession.occupation.state.map((item) => item),
			sport: tcmsession.sport.name.map((item) => item),
			sportFrequency: tcmsession.sport.frequency.map((item) => item),
			hobby: tcmsession.hobbies,
			// height: tcmsession.height,
			// heightUnit: tcmsession.heightUnit,
			// weight: tcmsession.weight,
			// weightUnit: tcmsession.weightUnit,
			// bmi: tcmsession.bmi,
			// bmiClassification: tcmsession.bmiClassification,
			// temperature: tcmsession.temperature,
			// temperatureUnit: tcmsession.temperatureUnit,
			thermalFeeling: tcmsession.thermalFeeling.map((item) => item),
			perspiration: tcmsession.perspiration.map((item) => item),
			appetite: tcmsession.appetite.map((item) => item),
			appetiteNote: tcmsession.appetiteNote,
			vomiting: tcmsession.vomiting.map((item) => item),
			vomitingNote: tcmsession.vomitingNote,
			diet: tcmsession.diet.map((item) => item),
			dietNote: tcmsession.dietNote,
			taste: tcmsession.taste.map((item) => item),
			thirst: tcmsession.thirst.map((item) => item),
			defecation: tcmsession.defecation.map((item) => item),
			urination: tcmsession.urination.map((item) => item),
			urinationColor: tcmsession.urineColor,
			sleep: tcmsession.sleeping.map((item) => item),
			head: tcmsession.head.map((item) => item),
			eyes: tcmsession.eyes.map((item) => item),
			ear: tcmsession.ear.map((item) => item),
			nose: tcmsession.nose.map((item) => item),
			throat: tcmsession.throat.map((item) => item),
			menstruation: tcmsession.menstruationHistory.map((item) => item),
			leukorrhea: tcmsession.leukorrhea.map((item) => item),
			localityOfPain: tcmsession.painLocation,
			natureOfPain: tcmsession.painNature,
			emotionState: tcmsession.emotionalStatus,
			emotionNote: tcmsession.emotionalNote,
			mind: tcmsession.mind,
			interviewNote: tcmsession.interviewNote,
			pulseSpeed: tcmsession.pulse.speed.map((item) => item),
			pulseDepth: tcmsession.pulse.depth.map((item) => item),
			pulseStrength: tcmsession.pulse.strength.map((item) => item),
			pulseShape: tcmsession.pulse.shape.map((item) => item),
			pulseTension: tcmsession.pulse.tension.map((item) => item),
			// pulse:tcmsession.pulse.map((item) => item),
			pulseRhythm: tcmsession.pulse.rythm,
			pulseNote: tcmsession.pulse.note,
			tongueShape: tcmsession.tongue.shape.map((item) => item),
			tongueColor: tcmsession.tongue.color.map((item) => item),
			tongueQuality: tcmsession.tongue.quality.map((item) => item),
			tongueNote: tcmsession.tongue.note,
			respiration: tcmsession.respiration.map((item) => item),
			speech: tcmsession.speech.map((item) => item),
			cough: tcmsession.cough.map((item) => item),
			odor: tcmsession.odor.map((item) => item),
			vitality: tcmsession.vitality.map((item) => item),
			colorFace: tcmsession.faceColorLustre.map((item) => item),
			physicalAppearance: tcmsession.physical.appearance.map((item) => item),
			physicalAppearanceNote: tcmsession.physical.appearance.map((item) => item),
			epigastriumPalpation: tcmsession.physical.palpationEpigastrium.map((item) => item),
			epigastriumPalpationNote: tcmsession.physical.palpationEpigastriumNote,
			abdomenPalpation: tcmsession.physical.palpationAbdomen.map((item) => item),
			rangeMotion: tcmsession.rangeMotion,
			painLevel: tcmsession.painLevel,
			physicalExaminationNote: tcmsession.physical.examinationNote,
			tcmDiagnosis: tcmsession.tcmDiagnosis,
			principleTreatment: tcmsession.principleTreatment.map((item) => item),
			acuPoints: tcmsession.acuCombination?.acuPoints,
			acuCombination:tcmsession.acuCombination,
			moxibustion: tcmsession.moxibustion,
			stimulationDuration: tcmsession.acuCombination?.stimulationDuration,
			stimulationMethod: tcmsession.acuCombination?.stimulationMethod,
			needleManipulation: tcmsession.acuCombination?.needleManipulation,
			acuTreatmentNote: tcmsession.acuTreatmentNote,
			herbalFormula1: tcmsession.herbalFormula1,
			materiaMedica1: tcmsession.materiaMedica1.map((item) => item),
			herbalFormula2: tcmsession.herbalFormula2,
			materiaMedica2: tcmsession.materiaMedica2?.map((item) => item),
			TDP: tcmsession.TDP,
			TDPNote: tcmsession.TDPNote,
			tuina: tcmsession.tuina,
			auricularAcupuncture: tcmsession.auricularAcupuncture,
			dietTherapy: tcmsession.dietTherapy,
			recommendation: tcmsession.recommendation,

		};
	}
	// handles the input datas 
	const changeInput = (e) => {
		setmedicalInput((prev) => {
			return {
				...prev, [e.target.name]: e.target.value
			}
		})
	};

	// handle the single data selection 
	const handleSingleSelect = (name, value) => {

		setmedicalInput((prev) => {
			return {
				...prev, [name]: value
			}
		})
	}


	// handle the multiple selection and store in arr
	const handleMultipleSelect = (name, value) => {
		let newValue = value && value.map((item) => item.value)

		setmedicalInput((prev) => {
			return {
				...prev, [name]: newValue ?? []
			}
		})
	}



	// change the family info  inputs 
	const handleFamilyMemberChange = (name, value, indexFamily) => {

		let medicalInputs = { ...medicalInput };
		let changeItem = medicalInputs.familyMembers.find((item, index) => index == indexFamily)
		changeItem[name] = value;
		medicalInputs.familyMembers[indexFamily] = changeItem;

		setmedicalInput(medicalInputs)
	}


	// // add new family memeber
	const addFamilyMember = () => {

		setmedicalInput((prev) => {
			return {
				...prev, familyMembers: [...prev.familyMembers, { familyMember: "", disease: "", year: "", state: "" }]
			}
		})
	}


	// remove a family memeber
	const removeFamilyMember = (familyIndex) => {
		let medicalinput = { ...medicalInput }
		let newmedicalInput = medicalinput.familyMembers.filter((val, index) => index !== familyIndex)
		medicalinput.familyMembers = newmedicalInput
		setmedicalInput(medicalinput)

	};

	// // add new acupoint
	const addAcuPoint = () => {
		let newAcu = medicalInput.acuCombination;
		if(newAcu != undefined || null && newAcu.length > 0){
			newAcu = [...newAcu,{ acuPoint: "", Moxibustion:"", stimulationDuration: "", stimulationMethod: "", needleManipulation: "" }]
		}else{
			newAcu = [{ acuPoint: "", Moxibustion:"",stimulationDuration: "", stimulationMethod: "", needleManipulation: "" }]
		}
		
		console.log(newAcu)
		
		setmedicalInput((prev) => {
			return {
				...prev, acuCombination:newAcu
			}
		})
	}

	const handleAcuCombinationChange = (name, value, Index) => {
		console.log(name,value)
		const data = { ...medicalInput };
		data["acuCombination"] = medicalInput.acuCombination.map((item, index) =>
			index === Index ? { ...item, [name]: value } : item
		);
		setmedicalInput( data );
	};

	// remove a acupoint

	const removeAcuPoint = (familyIndex) => {
		let medicalinput = { ...medicalInput }
		let newmedicalInput = medicalinput.acuCombination.filter((val, index) => index !== familyIndex)
		medicalinput.acuCombination = newmedicalInput
		setmedicalInput(medicalinput)

	};


	// handle remedy changes 

	const handleRemedyChange = (name, value, remedyIndex) => {

		console.log(name,value,remedyIndex);
		let medicalInputCopy = { ...medicalInput };
		let getRemedy = medicalInputCopy.materiaMedica1.find((item, index) => index === remedyIndex)
		getRemedy[name] = value;
		medicalInputCopy.materiaMedica1[remedyIndex] = getRemedy;
		setmedicalInput(medicalInputCopy)
	}

	const handleRemedyChange2 = (name, value, remedyIndex) => {
		console.log(name,value,remedyIndex);
		let medicalInputCopy = { ...medicalInput };
		let getRemedy = medicalInputCopy.materiaMedica2.find((item, index) => index === remedyIndex)
		getRemedy[name] = value;
		medicalInputCopy.materiaMedica2[remedyIndex] = getRemedy;
		setmedicalInput(medicalInputCopy)
	}


	//remove the remedy

	const removeRemedy = (remedyIndex) => {
		let medicalinput = { ...medicalInput }
		let newmedicalInput = medicalinput.materiaMedica1.filter((val, index) => index !== remedyIndex)
		medicalinput.materiaMedica1 = newmedicalInput
		setmedicalInput(medicalinput)
	}

	// addRemedy 
	const addRemedy = () => {

		setmedicalInput((prev) => {
			return { ...prev, materiaMedica1: [...prev.materiaMedica1, { materiamedica: "", dosage: "", unit: "" }] }
		})

	}

	const removeRemedy2 = (remedyIndex) => {
		let medicalinput = { ...medicalInput }
		let newmedicalInput = medicalinput.materiaMedica2.filter((val, index) => index !== remedyIndex)
		medicalinput.materiaMedica2 = newmedicalInput
		setmedicalInput(medicalinput)
	}

	// addRemedy 
	const addRemedy2 = () => {

		setmedicalInput((prev) => {
			return { ...prev, materiaMedica2: [...prev.materiaMedica2, { materiamedica: "", dosage: "", unit: "" }] }
		})

	}



	// switch to the prev tab
	const prevTab = () => {

		if (activeTab === 1) return
		setActiveTab(prev => prev - 1)

	}

	//switch to the next tab
	const nextTab = () => {

		if (activeTab === 5) return
		setActiveTab(prev => prev + 1)

	}

	const updateMedicalSession = async () => {

		

		try {
			console.log(medicalInput)
			const newDate = {...medicalInput,noteEmotion:medicalInput.emotionNote,emotion:medicalInput.emotionState}
			delete newDate.emotionNote;
			delete newDate.emotionState;
			const res = await saveTCMsession(newDate);
			console.log(res)

			setEditMode(!editMode)
			setfetchAgain(!fetchAgain)
			getPrevSessions();
		} catch (err) {
			console.log(err)

		}
	}



	const RenderTabButtons = () => {
		const tabButtonsStyleContainer = {
			marginTop: "20px"
		}
		return (
			<div className="form-group float-right " style={tabButtonsStyleContainer}>
				{
					!editMode && <button type="button" class="btn btn-red btn-md mr-2" onClick={() => setEditMode(true)} >
						Cancel
					</button>
				}
				<button disabled={(5 - activeTab >= 4) && "true"} style={{ cursor: (5 - activeTab >= 4) && "not-allowed", backgroundColor: (5 - activeTab >= 4) && "black" }} type="button" className="btn btn-green btn-md mr-2" onClick={prevTab}>
					Previous
				</button>
				<button style={{ cursor: (5 - activeTab < 1) && "not-allowed" }} type="button" class="btn btn-green btn-md  mr-2" onClick={nextTab}>
					Next
				</button>
				{
					!editMode && <button type="button" class="btn btn-blue btn-md mr-2" onClick={updateMedicalSession}>
						Update
					</button>
				}

			</div>
		);
	};
	const medicalFileStyle = {
		letterSpacing: "1.3px",
		fontSize: "1.2rem"

	}

	const TabBox = {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	}
	const [activeTab, setActiveTab] = useState(1)
	const changesetActiveTab = (n) => setActiveTab(n);

	return (
		<>

			<Box sx={{ width: '100%' }}>



				{
					medicalInput._id && <>
						<Panel>
							<PanelHeader style={medicalFileStyle} noButton>View & Edit MedicalFile</PanelHeader>
							<PanelBody>
								<div style={TabBox}>

									<ReusableTabNavs
										setActiveTab={changesetActiveTab}
										activeTab={activeTab}
										navprops={[
											{ label: "Medical History", background: "#DED99F" },
											{ label: "Interview", background: "#FFC6FF" },
											{ label: "Inspection And Examination", background: "#FFF5AD" },
											{ label: "TCM Treatment", background: "#A2F5AD" },
										]}
									/>
								</div>
								<TabContent activeTab={activeTab}>

									<ReusableTab id={1} height={"100%"} width={"100%"}>
										<>
											<MedicalHistory handleSingleSelect={handleSingleSelect} handleMultipleSelect={handleMultipleSelect} addFamilyMember={addFamilyMember} removeFamilyMember={removeFamilyMember} handleFamilyMemberChange={handleFamilyMemberChange} changeInput={changeInput} medicalInput={medicalInput} editMode={editMode} />
											<RenderTabButtons />
										</>
									</ReusableTab>
									<ReusableTab id={2}>
										<>
											<Interview handleMultipleSelect={handleMultipleSelect} handleSingleSelect={handleSingleSelect} changeInput={changeInput} medicalInput={medicalInput} editMode={editMode} />
											<RenderTabButtons />

										</>
									</ReusableTab>
									<ReusableTab id={3}>
										<>
											<InspectionAndExamination handleSingleSelect={handleSingleSelect} handleMultipleSelect={handleMultipleSelect} changeInput={changeInput} medicalInput={medicalInput} editMode={editMode} />
											<RenderTabButtons />
										</>
									</ReusableTab>
									<ReusableTab id={4}>
										<>
											<TCMTreatment
												addRemedy={addRemedy}
												removeRemedy={removeRemedy}
												addRemedy2={addRemedy2}
												removeRemedy2={removeRemedy2}
												handleRemedyChange={handleRemedyChange}
												handleRemedyChange2={handleRemedyChange2}
												handleMultipleSelect={handleMultipleSelect}
												handleSingleSelect={handleSingleSelect}
												handleAcuCombinationChange={handleAcuCombinationChange}
												changeInput={changeInput}
												medicalInput={medicalInput}
												editMode={editMode}
												addacuPoint={addAcuPoint}
												removeacuPoint={removeAcuPoint}
												acuPoint={acuPoint}
												materiaMedic={materiaMedicRemedy}
												materiaMedics={materiaMedics}
												herbal={herbal}
											/>
											<RenderTabButtons />
										</>
									</ReusableTab>

								</TabContent>
							</PanelBody>
						</Panel>


					</>
				}

			</Box>
		</>)
});

export default PreviousSessionsContainer



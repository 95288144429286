import http from './httpService'; 
import {apiUrl} from './../config/config.json';
const apiEndpoint = apiUrl+'/wastes';


  function wastetypeUrl(id) {
    return `${apiEndpoint}/${id}`;
  }
  
  export function getWasteTypes() {
    return http.get(apiEndpoint);
  }
  
  export function getWasteType(Id) {
    return http.get(wastetypeUrl(Id));
  }
  
  export function saveWasteType(wastetype) {
    //clone
    const body = { ...wastetype };
    console.log(body);
   //update
   if (wastetype._id) {
     //delete _id
     delete body._id;
     return http.put(wastetypeUrl(wastetype._id),body);
   }
 
   //add a new wastetype
   return http.post(apiEndpoint, wastetype);
 }
  
  //delete wastetypes
  export function deleteWasteType(Id) {
    return http.delete(wastetypeUrl(Id));
  }  
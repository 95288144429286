import React, { Fragment } from "react";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel.jsx";
import ReusableTabNavs from "../../../common/ReusableTabNavs";
import ReusableTab from "../../../common/ReusableTab";
import { TabContent } from "reactstrap";
import MedicalHistory from "./MedicalHistory";
import Interview from "./Interview";
import InspectionAndExamination from "./InspectionAndExamination";
import TCMTreatment from "./TCMTreatment";
import PreviousSessions from "./PreviousSessions";
import Joi from "joi";
import { getTCMsession, saveTCMsession } from "../../../services/tcmsessions";

import { Link } from "react-router-dom";
import { apiUrl } from "../../../config/config.json";
import http from "../../../services/httpService";
import avatar from "../../../assets/images/user-12.jpg";
import "./style.css";
import { getAcupunctures } from "../../../services/diseasesconditions.js";

class AddTMCSession extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			patients: [],
			clinics: [],
			doctors: [],
			materiaMedicas: [],
			materiaMedic: [{ value: "", label: "" }],
			pathologies: [],
			principleTreatment: [{ value: "", label: "" }],
			differentiation: [{ value: "", label: "" }],

			diseasesConditions: [],
			diseasesCondition: [{ value: "", label: "" }],

			herbal: [],
			herbal1: [{ value: "", label: "" }],
			acuPoint: [],
			acu: [{ value: "", label: "" }],
			patient: [],
			previousSessions: [],
			errors: {},
			dropdownOpen: false,
			readOnly: true,
			activeTab: 1,
			read: true,
			data: {
				selectedRegisteredPatient: "",
				clinicNo: "",
				doctorNo: "",
				chiefComplaint: "",
				symptoms: "",
				westernDisease: "",
				currentTreatment: [],
				diseases: "",
				surgeries: "",
				medicaments: "",
				allergies: "",
				pregnancies: "",
				familyMembers: [{ familyMember: "", disease: "", year: "", state: "" }],
				noteMedicalHistory: "",
				socialRelationship: "",
				habits: [],
				profession: "",
				employmentStatus: [],
				sport: "",
				sportFrequency: [],
				hobby: "",
				// height: "",
				// heightUnit: "cm",
				// weight: "",
				// weightUnit: "kg",
				// bmi: "",
				// bmiClassification: "",
				// temperature: "",
				// temperatureUnit: "celsius",
				thermalFeeling: [],
				perspiration: [],
				appetite: [],
				appetiteNote: "",
				vomiting: [],
				vomitingNote: "",
				diet: [],
				dietNote: "",
				taste: [],
				thirst: [],
				defecation: [],
				urination: [],
				urinationColor: [],
				sleep: [],
				head: [],
				eyes: [],
				ear: [],
				nose: [],
				throat: [],
				menstruation: [],
				leukorrhea: [],
				localityOfPain: "",
				natureOfPain: "",
				emotionState: "",
				emotionNote: "",
				mind: "",
				interviewNote: "",
				pulseSpeed: [],
				pulseDepth: [],
				pulseStrength: [],
				pulseShape: [],
				pulseTension: [],
				pulseRhythm: [],
				pulseNote: "",
				tongueShape: [],
				tongueColor: [],
				tongueQuality: [],
				respiration: [],
				speech: [],
				cough: [],
				odor: [],
				vitality: [],
				colorFace: [],
				physicalAppearance: [],
				physicalAppearanceNote: "",
				epigastriumPalpation: [],
				epigastriumPalpationNote: "",
				abdomenPalpation: [],
				rangeMotion: "",
				painLevel: "",
				physicalExaminationNote: "",
				tcmDiagnosis: "",
				principleTreatment: "",
				acuCombination: [{ acuPoint: "", stimulationDuration: "", stimulationMethod: "", needleManipulation: "" }],
				acuTreatmentNote: "",
				herbalFormula1: "",
				materiaMedica1: [{ materiamedica: "", dosage: "", unit: "" }],
				herbalFormula2: "",
				materiaMedica2: [{ materiamedica: "", dosage: "", unit: "" }],
				TDP: "",
				TDPNote: "",
				tuina: "",
				auricularAcupuncture: "",
				dietTherapy: "",
				recommendation: "",
			},
		};

		this.setReadOnly = this.setReadOnly.bind(this);
		this.setActiveTab = this.setActiveTab.bind(this);
		this.toggleRead = this.toggleRead.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.renderTabButtons = this.renderTabButtons.bind(this);
		this.addFamilyMember = this.addFamilyMember.bind(this);
		this.removeFamilyMember = this.removeFamilyMember.bind(this);
		this.handleWesternDiseasesChange = this.handleWesternDiseasesChange.bind(this);
		// this.addacuCombination = this.addacuCombination.bind(this);
		// this.removeacuCombination = this.removeacuCombination.bind(this);
		// this.addmateriaMedica1 = this.addmateriaMedica1.bind(this);
		// this.removemateriaMedica1 = this.removemateriaMedica1.bind(this);
		// this.addmateriaMedica2 = this.addmateriaMedica2.bind(this);
		// this.removemateriaMedica2 = this.removemateriaMedica2.bind(this);

	}

	setActiveTab = (n) => this.setState({ activeTab: n });

	toggleRead = () => this.setState({ read: !this.state.read });

	setReadOnly = () => this.setState({ readOnly: !this.state.readOnly });

	// this will handle both text and single-select change
	handleChange = (name, value) => {
		const data = { ...this.state.data };
		data[name] = value;
		this.setState({ data });
	};

	handleMultiSelectChange = (name, value) => {
		const newValue = value && value.map((item) => item.value);
		const data = { ...this.state.data };
		data[name] = newValue;
		this.setState({ data });
	};

	handleMultiChange = (name, options) => {
		const data = { ...this.state.data };
		console.log("value", options);
		data[name] = options.map((o) => o.value);
		console.log(
			"options",
			options.map((o) => o.value)
		);
		this.setState({ data });
	};

	handleFamilyMemberChange = (name, value, familyMemberIndex) => {
		const data = { ...this.state.data };
		data["familyMembers"] = this.state.data.familyMembers.map((item, index) =>
			index === familyMemberIndex ? { ...item, [name]: value } : item
		);
		this.setState({ data });
	};
	handlePrincipleTreatmentChange = (value) => {
		const data = { ...this.state.data };
		data["principleTreatment"] = value;
		this.setState({ data });
	};
	handleDifferentiationChange = (value) => {
		const data = { ...this.state.data };
		data["tcmDiagnosis"] = value;
		this.setState({ data });
	};
	handleAcuCombinationChange = (name, value, Index) => {
		const data = { ...this.state.data };
		data["acuCombination"] = this.state.data.acuCombination.map((item, index) =>
			index === Index ? { ...item, [name]: value } : item
		);
		this.setState({ data });
	};

	handleRemedyChange = (name, value, remedyIndex) => {
		const data = { ...this.state.data };
		data["materiaMedica1"] = this.state.data.materiaMedica1.map((item, index) =>
			index === remedyIndex ? { ...item, [name]: value } : item
		);
		this.setState({ data });
	};
	handleRemedy2Change = (name, value, remedyIndex) => {
		const data = { ...this.state.data };
		data["materiaMedica2"] = this.state.data.materiaMedica2.map((item, index) =>
			index === remedyIndex ? { ...item, [name]: value } : item
		);
		this.setState({ data });
	};
	handleHerbalChange = (name, value, remedyIndex) => {
		const data = { ...this.state.data };
		data["herbalFormula1"] = this.state.data.herbalFormula1.map((item, index) =>
			index === remedyIndex ? { ...item, [name]: value } : item
		);
		this.setState({ data });
	};

	// handleChange = async (name, value, type, familyMemberIndex) => {
	// 	if (familyMemberIndex >= 0) {
	// 		this.setState({
	// 			data: {
	// 				...this.state.data,
	// 				familyMembers: this.state.data.familyMembers.map((el, index) =>
	// 					index === familyMemberIndex ? { ...el, [name]: type === "single-select" ? value.value : value } : el
	// 				),
	// 			},
	// 		});
	// 		return;
	// 	}
	// 	if (type === "multi-select") {
	// 		const newValue = value && value.map((b) => b.value);
	// 		value
	// 			? this.setState({ data: { ...this.state.data, [name]: newValue } })
	// 			: this.setState({ data: { ...this.state.data, [name]: [] } });
	// 		return;
	// 	}
	// 	if (type === "single-select") {
	// 		value
	// 			? this.setState({ data: { ...this.state.data, [name]: value.value } })
	// 			: this.setState({ data: { ...this.state.data, [name]: [] } });
	// 		return;
	// 	}
	// 	this.setState({
	// 		data: {
	// 			...this.state.data,
	// 			[name]: value,
	// 		},
	// 	});
	// };

	schema = Joi.object({
		selectedRegisteredPatient: Joi.any().optional(),
		clinicNo: Joi.any().optional(),
		doctorNo: Joi.any().optional(),
		chiefComplaint: Joi.any().optional(),
		symptoms: Joi.any().optional(),
		westernDisease: Joi.any().optional(),
		currentTreatment: Joi.any().optional(),
		diseases: Joi.any().optional(),
		surgeries: Joi.any().optional(),
		medicaments: Joi.any().optional(),
		allergies: Joi.any().optional(),
		pregnancies: Joi.any().optional(),
		familyMembers: Joi.any().optional(),
		noteMedicalHistory: Joi.any().optional(),
		socialRelationship: Joi.any().optional(),
		habits: Joi.any().optional(),
		profession: Joi.any().optional(),
		employmentStatus: Joi.any().optional(),
		sport: Joi.any().optional(),
		sportFrequency: Joi.any().optional(),
		hobby: Joi.any().optional(),
		// height: Joi.any().optional(),
		// heightUnit: Joi.any().optional(),
		// weight: Joi.any().optional(),
		// weightUnit: Joi.any().optional(),
		// bmi: Joi.any().optional(),
		// bmiClassification: Joi.any().optional(),
		// temperature: Joi.any().optional(),
		// temperatureUnit: Joi.any().optional(),
		thermalFeeling: Joi.any().optional(),
		perspiration: Joi.any().optional(),
		appetite: Joi.any().optional(),
		appetiteNote: Joi.any().optional(),
		vomiting: Joi.any().optional(),
		vomitingNote: Joi.any().optional(),
		diet: Joi.any().optional(),
		dietNote: Joi.any().optional(),
		taste: Joi.any().optional(),
		thirst: Joi.any().optional(),
		defecation: Joi.any().optional(),
		urination: Joi.any().optional(),
		urinationColor: Joi.any().optional(),
		sleep: Joi.any().optional(),
		head: Joi.any().optional(),
		eyes: Joi.any().optional(),
		ear: Joi.any().optional(),
		nose: Joi.any().optional(),
		throat: Joi.any().optional(),
		menstruation: Joi.any().optional(),
		leukorrhea: Joi.any().optional(),
		localityOfPain: Joi.any().optional(),
		natureOfPain: Joi.any().optional(),
		// emotionState: Joi.any().optional(),
		// emotionNote: Joi.any().optional(),
		mind: Joi.any().optional(),
		interviewNote: Joi.any().optional(),
		pulseSpeed: Joi.string().optional(),
		pulseDepth: Joi.string().optional(),
		pulseStrength: Joi.string().optional(),
		pulseShape: Joi.string().optional(),
		pulseTension: Joi.string().optional(),
		pulseRhythm: Joi.string().optional(),
		pulseShape: Joi.string().optional(),
		pulseNote: Joi.string().optional(),
		tongueShape: Joi.string().optional(),
		tongueColor: Joi.string().optional(),
		tongueQuality: Joi.string().optional(),
		respiration: Joi.any().optional(),
		speech: Joi.any().optional(),
		cough: Joi.any().optional(),
		odor: Joi.any().optional(),
		vitality: Joi.any().optional(),
		colorFace: Joi.any().optional(),
		physicalAppearance: Joi.any().optional(),
		physicalAppearanceNote: Joi.any().optional(),
		epigastriumPalpation: Joi.any().optional(),
		epigastriumPalpationNote: Joi.any().optional(),
		abdomenPalpation: Joi.any().optional(),
		rangeMotion: Joi.any().optional(),
		painLevel: Joi.any().optional(),
		physicalExaminationNote: Joi.any().optional(),
		tcmDiagnosis: Joi.any().optional(),
		principleTreatment: Joi.any().optional(),
		acuPoints: Joi.any().optional(),
		stimulationDuration: Joi.any().optional(),
		stimulationMethod: Joi.any().optional(),
		needleManipulation: Joi.any().optional(),
		acuTreatmentNote: Joi.any().optional(),
		herbalFormula1: Joi.any().optional(),
		materiaMedica1: Joi.any().optional(),
		mmDosage1: Joi.any().optional(),
		mmUnit1: Joi.any().optional(),
		herbalFormula2: Joi.any().optional(),
		materiaMedica2: Joi.any().optional(),
		mmDosage2: Joi.any().optional(),
		mmUnit2: Joi.any().optional(),
		TDP: Joi.any().optional(),
		TDPNote: Joi.any().optional(),
		tuina: Joi.any().optional(),
		auricularAcupuncture: Joi.any().optional(),
		dietTherapy: Joi.any().optional(),
		recommendation: Joi.any().optional(),
	});

	async populateTMCSession() {
		try {
			const Id = this.props.match.params.id;
			if (Id === "new") return;
			const { data: tcmsession } = await getTCMsession(Id);
			this.setState({ data: this.mapToViewModel(tcmsession) });
		} catch (ex) {
			if (ex.response && ex.response.status === 404) this.props.history.replace("/error");
		}
	}

	mapToViewModel(tcmsession) {
		return {
			_id: tcmsession._id,
			selectedRegisteredPatient: tcmsession.patientNo,
			clinicNo: tcmsession.clinicNo,
			doctorNo: tcmsession.doctorNo,
			chiefComplaint: tcmsession.chiefComplaint,
			symptoms: tcmsession.symptoms,
			westernDisease: tcmsession.WesternDisease,
			currentTreatment: tcmsession.currentTreatment.map((item) => item),
			diseases: tcmsession.diseases,
			surgeries: tcmsession.surgeries,
			medicaments: tcmsession.medicaments,
			allergies: tcmsession.allergies,
			pregnancies: tcmsession.pregnancies,
			familyMembers: tcmsession.familyHistory.map((item) => item),
			noteMedicalHistory: tcmsession.medicalHistoryNote,
			socialRelationship: tcmsession.socialRelationship,
			habits: tcmsession.habits.map((item) => item),
			profession: tcmsession.occupation.name,
			employmentStatus: tcmsession.occupation.state.map((item) => item),
			sport: tcmsession.sport.name.map((item) => item),
			sportFrequency: tcmsession.sport.frequency.map((item) => item),
			hobby: tcmsession.hobbies[0],
			// height: tcmsession.height,
			// heightUnit: tcmsession.heightUnit,
			// weight: tcmsession.weight,
			// weightUnit: tcmsession.weightUnit,
			// bmi: tcmsession.bmi,
			// bmiClassification: tcmsession.bmiClassification,
			// temperature: tcmsession.temperature,
			// temperatureUnit: tcmsession.temperatureUnit,
			thermalFeeling: tcmsession.thermalFeeling.map((item) => item),
			perspiration: tcmsession.perspiration.map((item) => item),
			appetite: tcmsession.appetite.map((item) => item),
			appetiteNote: tcmsession.appetiteNote,
			vomiting: tcmsession.vomiting.map((item) => item),
			vomitingNote: tcmsession.vomitingNote,
			diet: tcmsession.diet.map((item) => item),
			dietNote: tcmsession.dietNote,
			taste: tcmsession.taste.map((item) => item),
			thirst: tcmsession.thirst.map((item) => item),
			defecation: tcmsession.defecation.map((item) => item),
			urination: tcmsession.urination.map((item) => item),
			urinationColor: tcmsession.urineColor,
			sleep: tcmsession.sleeping.map((item) => item),
			head: tcmsession.head.map((item) => item),
			eyes: tcmsession.eyes.map((item) => item),
			ear: tcmsession.ear.map((item) => item),
			nose: tcmsession.nose.map((item) => item),
			throat: tcmsession.throat.map((item) => item),
			menstruation: tcmsession.menstruationHistory.map((item) => item),
			leukorrhea: tcmsession.leukorrhea.map((item) => item),
			localityOfPain: tcmsession.painLocation.map((item) => item),
			natureOfPain: tcmsession.painNature.map((item) => item),
			// emotionState: tcmsession.emotionState.map((item) => item),
			// emotionNote: tcmsession.emotionNote,
			mind: tcmsession.mind,
			interviewNote: tcmsession.interviewNote.map((item) => item),
			pulseSpeed: tcmsession.pulseSpeed.map((item) => item),
			pulseDepth: tcmsession.pulseDepth.map((item) => item),
			pulseStrength: tcmsession.pulseStrength.map((item) => item),
			pulseShape: tcmsession.pulseShape.map((item) => item),
			pulseTension: tcmsession.pulseTension.map((item) => item),
			pulseRhythm: tcmsession.pulseRhythm.map((item) => item),
			pulseNote: tcmsession.pulseNote.map((item) => item),
			tongueShape: tcmsession.tongueShape.map((item) => item),
			tongueColor: tcmsession.tongueColor.map((item) => item),
			tongueQuality: tcmsession.tongueQuality.map((item) => item),
			respiration: tcmsession.respiration.map((item) => item),
			speech: tcmsession.speech.map((item) => item),
			cough: tcmsession.cough.map((item) => item),
			odor: tcmsession.odor.map((item) => item),
			vitality: tcmsession.vitality.map((item) => item),
			colorFace: tcmsession.faceColorLustre.map((item) => item),
			physicalAppearance: tcmsession.physical.appearance.map((item) => item),
			physicalAppearanceNote: tcmsession.physicalExaminationNote.map((item) => item),
			epigastriumPalpation: tcmsession.physical.palpationEpigastrium.map((item) => item),
			epigastriumPalpationNote: tcmsession.physical.palpationEpigastriumNote.map((item) => item),
			abdomenPalpation: tcmsession.physical.palpationAbdomen.map((item) => item),
			rangeMotion: tcmsession.rangeMotion.map((item) => item),
			painLevel: tcmsession.painLevel.map((item) => item),
			physicalExaminationNote: tcmsession.physicalExaminationNote.map((item) => item),
			tcmDiagnosis: tcmsession.tcmDiagnosis.map((item) => item),
			principleTreatment: tcmsession.principleTreatment.map((item) => item),
			acuPoints: tcmsession.acuPoints.map((item) => item),
			moxibustion: tcmsession.moxibustion.map((item) => item),
			stimulationDuration: tcmsession.stimulationDuration.map((item) => item),
			stimulationMethod: tcmsession.stimulationMethod.map((item) => item),
			needleManipulation: tcmsession.needleManipulation.map((item) => item),
			acuTreatmentNote: tcmsession.acuTreatmentNote.map((item) => item),
			herbalFormula1: tcmsession.herbalFormula1.map((item) => item),
			materiaMedica1: tcmsession.materiaMedica1.map((item) => item),
			mmDosage1: tcmsession.mmDosage1.map((item) => item),
			mmmmUnit1: tcmsession.mmUnit1.map((item) => item),
			herbalFormula2: tcmsession.herbalFormula2.map((item) => item),
			materiaMedica2: tcmsession.materiaMedica2.map((item) => item),
			mmDosage2: tcmsession.mmDosage2.map((item) => item),
			mmmmUnit2: tcmsession.mmUnit2.map((item) => item),
			TDP: tcmsession.TDP.map((item) => item),
			TDPNote: tcmsession.TDPNote.map((item) => item),
			tuina: tcmsession.tuina.map((item) => item),
			auricularAcupuncture: tcmsession.auricularAcupuncture.map((item) => item),
			dietTherapy: tcmsession.dietTherapy.map((item) => item),
			recommendation: tcmsession.recommendation.map((item) => item),

		};
	}

	handleSubmit = async () => {
		console.log("state tcmsessions: ", this.state.data);
		try {
			await saveTCMsession(this.state.data);
			this.props.history.push("/clinic/medicalfiles");
		} catch (ex) {
			if (ex.response) {
				const errors = { ...this.state.errors };
				errors.status = ex.response.data;
				this.setState({ errors });
			}
		}
	};


	async populatePatients() {
		const { data: patients } = await http.get(apiUrl + "/patients");
		this.setState({ patients });


		this.selectUsers = this.state.patients.map((option) => (
			<option key={option._id} value={option._id}>
				{option.patients.contactName.first} {option.patients.contactName.last}
			</option>
		));
	}

	async populatePreviousSessions() {
		const { data: previousSessions } = await http.get(apiUrl + "/tcmsessions");
		this.setState({ previousSessions });
	}

	async populatePatient() {
		const { data: patient } = await http.get(`${apiUrl}/patients/${this.state.data.selectedRegisteredPatient}`);
		this.setState({
			patient,
		});
	}

	async populateDoctors() {
		const { data: doctors } = await http.get(apiUrl + "/doctors");
		// console.log("Doctors: ", doctors);
		this.setState({ doctors });


		this.selectDoctors = this.state.doctors.map((option) => (
			<option key={option._id} value={option.user}>
				{option.doctors.contactName.last}
			</option>
		));
	}
	async populateAcupoint() {
		const { data: acuPoint } = await http.get(apiUrl + "/acupunctures");

		this.setState({ acuPoint });
		console.log("acuPointacuPoint: ", this.state.acuPoint);
		let acuPointArray = this.state.acuPoint.map((item) => {
			return {
				value: item.name,
				label: item.name,
			}
		});
		this.setState({
			acu: acuPointArray
		});
		// this.state.acuPoint.map((option) =>this.setState({acu:[...this.state.acu,{value: option.name,label: option.name}]}));

	}
	async populateMateriaMedica() {
		const { data: materiaMedicas } = await http.get(apiUrl + "/materiamedicas");

		this.setState({ materiaMedicas });
		console.log("materiaMedic: ", this.state.materiaMedicas);
		let materiaMedicaArray = this.state.materiaMedicas.map((item) => {
			return {
				value: item.name,
				label: item.name,
			}
		});
		this.setState({
			materiaMedic: materiaMedicaArray
		});
		// this.state.materiaMedicas.map((option) =>this.setState({materiaMedic:[...this.state.materiaMedic,{value: option.name,label: option.name}]}));

	}
	async populateHerbal() {
		const { data: herbal } = await http.get(apiUrl + "/formulas");

		this.setState({ herbal });
		console.log("herbal: ", this.state.herbal);
		let herbalArray = this.state.herbal.map((item) => {
			return {
				value: item.name,
				label: item.name,
			}
		});
		this.setState({
			herbal1: herbalArray
		});
		// this.state.herbal.map((option) =>this.setState({herbal1:[...this.state.herbal1,{value: option.name,label: option.name}]}));

	}

	async populateClinics() {
		const { data: clinics } = await http.get(apiUrl + "/clinicsolo");
		// console.log("Clinics: ", clinics);
		this.setState({ clinics });
		this.selectClinics = this.state.clinics.map((option) => (
			<option key={option._id} value={option.user}>
				{option.companyInfo.businessName}
			</option>
		));
	}

	async componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			await this.populatePatients();
			await this.populatePreviousSessions();
			await this.populateDoctors();
			await this.populateClinics();
			await this.populateAcupoint();
			await this.populateMateriaMedica();
			await this.populateHerbal();
			await this.populatePathologies();
			await this.populateDiseasesconditions();
		}
	}

	async populatePathologies() {
		const { data: pathologies } = await http.get(apiUrl + "/pathologies");
		console.log("Patholgies");
		console.log(pathologies)
		this.setState({ pathologies });
		let principleTreatmentArray = this.state.pathologies.map((item) => {
			return {
				value: item.principleTreatment,
				label: item.principleTreatment,
			}
		});
		let differentiationArray = this.state.pathologies.map((item) => {
			return {
				value: item.differentiation,
				label: item.differentiation,
			}
		});
		this.setState({
			principleTreatment: principleTreatmentArray,
			differentiation: differentiationArray
		});
	}

	async populateDiseasesconditions() {
		const { data: diseasesConditions } = await getAcupunctures();
		console.log("Diseases Conditions")
		console.log(diseasesConditions)
		this.setState({ diseasesConditions });
		let diseaseConditionsArray = this.state.diseasesConditions.map((item) => {
			return {
				value: item.diseasesconditions,
				label: item.diseasesconditions,
			}
		});
		this.setState({
			diseasesCondition: diseaseConditionsArray
		});
	}

	handleWesternDiseasesChange(value) {
		const data = { ...this.state.data };
		data["westernDisease"] = value;
		this.setState({ data });
	}

	componentWillUnmount() {
		this.setState({ data: {} });
		this._isMounted = false;
	}

	nextTab = () => {
		this.setState((prevState) => ({ activeTab: prevState.activeTab + 1 }));
	};

	previousTab = () => {
		this.setState((prevState) => ({ activeTab: prevState.activeTab - 1 }));
	};

	saveData = () => {
		this.handleSubmit()
		this.setState((prevState) => ({ activeTab: prevState.activeTab + 1 }));

	}

	addFamilyMember = () =>
		this.setState({
			data: {
				...this.state.data,
				familyMembers: [...this.state.data.familyMembers, { familyMember: "", disease: "", year: "", state: "" }],
			},
		});


	removeFamilyMember = (index) => {
		this.setState({
			data: {
				...this.state.data,
				familyMembers: this.state.data.familyMembers.filter((mem, i) => index !== i),
			},
		});
	};

	addRemedy = () =>
		this.setState({
			data: {
				...this.state.data,
				materiaMedica1: [...this.state.data.materiaMedica1, { materiamedica: "", dosage: "", unit: "" }],
			},
		});
	addRemedy2 = () =>
		this.setState({
			data: {
				...this.state.data,
				materiaMedica2: [...this.state.data.materiaMedica2, { materiamedica: "", dosage: "", unit: "" }],
			},
		});
	removeRemedy = (index) => {
		this.setState({
			data: {
				...this.state.data,
				materiaMedica1: this.state.data.materiaMedica1.filter((mem, i) => index !== i),
			},
		});
	}; removeRemedy2 = (index) => {
		this.setState({
			data: {
				...this.state.data,
				materiaMedica2: this.state.data.materiaMedica2.filter((mem, i) => index !== i),
			},
		});
	};

	addherbalFormula1 = () =>
		this.setState({
			data: {
				...this.state.data,
				herbalFormula1: { ...this.state.data.herbalFormula1 },
			},
		});
	removeherbalFormula1 = (index) => {
		this.setState({
			data: {
				...this.state.data,
				herbalFormula1: this.state.data.herbalFormula1.filter((mem, i) => index !== i),
			},
		});
	};

	addacuPoint = () => {
		const data = { ...this.state.data };
		data.acuCombination = [...data.acuCombination, { moxibustion: "", needleManipulation: "", stimulationMethod: "", stimulationDuration: "" }];
		this.setState({ data });
	};

	removeacuPoint = (index) => {
		this.setState({
			data: {
				...this.state.data,
				acuCombination: this.state.data.acuCombination.filter((mem, i) => index !== i),
			},
		});
	};

	handlePatientChange = async (e) => {
		let previousSessions = this.state.previousSessions;
		let selectedPatientPreviousSessions = previousSessions.filter((session) => session.patientNo._id === e.target.value).reverse()
		let latestPreviousSession = selectedPatientPreviousSessions.length > 0 ? selectedPatientPreviousSessions[0] : {}
		console.log("latestPreviousSession", latestPreviousSession)
		if (Object.keys(latestPreviousSession).length > 0) {
			this.setState(
				{
					data: {
						...this.state.data,
						selectedRegisteredPatient: e.target.value,
						currentTreatment: latestPreviousSession.currentTreatment.map((item) => item),
						diseases: latestPreviousSession.diseasesIllnesses,
						surgeries: latestPreviousSession.surgeries,
						medicaments: latestPreviousSession.medicamentsSupplements,
						allergies: latestPreviousSession.allergies,
						pregnancies: latestPreviousSession.pregnancies,
						familyMembers: latestPreviousSession.familyHistory.map((item) => item),
						noteMedicalHistory: latestPreviousSession.medicalHistoryNote,
						socialRelationship: latestPreviousSession.socialRelationship,
						habits: latestPreviousSession.habits.map((item) => item),
						profession: latestPreviousSession.occupation.name,
						employmentStatus: latestPreviousSession.occupation.state.map((item) => item),
						sport: latestPreviousSession.sport.name.map((item) => item),
						sportFrequency: latestPreviousSession.sport.frequency.map((item) => item),
						hobby: latestPreviousSession.hobbies,
					},
				},
				async () => {
					await this.populatePatient();
				}
			);
		} else {
			this.setState(
				{
					data: {
						...this.state.data,
						selectedRegisteredPatient: e.target.value,
						currentTreatment: [],
						diseases: "",
						surgeries: "",
						medicaments: "",
						allergies: "",
						pregnancies: "",
						familyMembers: [{ familyMember: "", disease: "", year: "", state: "" }],
						noteMedicalHistory: "",
						socialRelationship: "",
						habits: [],
						profession: "",
						employmentStatus: [],
						sport: "",
						sportFrequency: [],
						hobby: "",
					},
				},
				async () => {
					await this.populatePatient();
				}
			);
		}
	};


	renderTabButtons = () => {
		return (
			<div className="form-group float-right">
				<button type="button" className="btn btn-green btn-sm mr-2" onClick={this.previousTab}>Previous
				</button>
				<button type="button" className="btn btn-green btn-sm" onClick={this.nextTab}>Next
				</button>
				{this.props.match.params.id !== "new" && (
					<>
						<button type="button" className="btn btn-indigo btn-sm mr-2" style={{ marginLeft: "5px" }} onClick={this.handleSubmit}>Save
						</button>
						<Link to="/clinic/tcmsession/medicalfiles">
							<button type="button" className="btn btn-red btn-sm mr-2">Cancel
							</button>
						</Link>
					</>
				)}
			</div>
		);
	};

	render() {
		const tabMenus = [
			{ label: "Medical History", background: "#FFC69F" },
			{ label: "Interview", background: "#DED99F" },
			{ label: "Inspection & Examination", background: "#FFC6FF", },
			{ label: "TCM Treatment", background: "#FFF5AD" },
			{ label: "File Attachments", background: "#A2F5AD" },
			{ label: "Previous Sessions", background: "#FFFFC9", },
		];

		return (
			<Fragment>
				{this.props.match.params.id !== "new" && (
					<>
						<ol className="breadcrumb float-xl-right">
							<li className="breadcrumb-item">
								<Link to="/">Home</Link>
							</li>
							<li className="breadcrumb-item">
								<Link to="/clinic">Clinic</Link>
							</li>
							<li className="breadcrumb-item">
								<Link to="/clinic/tcmsession/medicalfiles">Medical Files</Link>
							</li>
						</ol>
						<h1 className="page-header">TCMpathy Sessions </h1>
					</>
				)}

				<Panel>
					<PanelHeader noButton>TCM Session</PanelHeader>
					<PanelBody>
						<h1>Add TCM session</h1>
						{this.props.match.params.id === "new" && (
							<div className="row form-group">
								<div className="col-12 col-sm-4">
									<label>
										<b>Select registered patient from administration *</b>
									</label>
								</div>
								<div className="col-12 col-sm-6">
									<select className="selectPatient" placeholder={"Select a patient"} name="selectedRegisteredPatient" onChange={this.handlePatientChange}
										value={this.state.data.selectedRegisteredPatient}>
										<option>Select Patient</option>
										{this.selectUsers}
									</select>
								</div>
							</div>
						)}
						<div className="row form-group">
							<div className="col-12 col-sm-4">
								<label>
									<b>Select Doctor</b>
								</label>
							</div>
							<div className="col-12 col-sm-6">
								<select className="selectDoctor" placeholder={"Select a doctor"} name="doctorNo"
									onChange={(e) => this.handleChange("doctorNo", e.target.value)}
									value={this.state.data.doctorNo}>
									<option>Select Doctor</option>
									{this.selectDoctors}
								</select>
							</div>
						</div>
						<div className="row form-group">
							<div className="col-12 col-sm-4">
								<label>
									<b>Select Clinic</b>
								</label>
							</div>
							<div className="col-12 col-sm-6">
								<select className="selectClinic" placeholder={"Select a clinic"} name="clinicNo"
									onChange={(e) => this.handleChange("clinicNo", e.target.value)}
									value={this.state.data.clinicNo}>
									<option>Select Clinic</option>
									{this.selectClinics}
								</select>
							</div>
						</div>
						<div className="row mt-4 row-no-margin">
							<div className="col-12 col-sm-2">
								<img
									src={this.state.data.selectedRegisteredPatient ? this.state.patient?.user?.imageSrc : avatar}
									style={{ marginBottom: "1.5rem", height: "140px", width: "140px" }}
								/>
							</div>
							<div className="col-12 col-sm-10">
								<div className="row">
									<div className="col-12 col-sm-4">
										<div className="form-group">
											<label>
												<b>Prefix</b>
											</label>
											<input type="text" disabled className="form-control" placeholder="Prefix" name="prefix"
												value={this.state.data.selectedRegisteredPatient ? this.state.patient?.user?.prefix : ""}
											/>
										</div>
									</div>
									<div className="col-12 col-sm-4">
										<div className="form-group">
											<label>
												<b>First Name</b>
											</label>
											<input type="text" disabled className="form-control" name="firstName" placeholder="First Name"
												value={
													this.state.data.selectedRegisteredPatient
														? this.state.patient?.user?.contactName?.first
														: ""
												}
											/>
										</div>
									</div>
									<div className="col-12 col-sm-4">
										<div className="form-group">
											<label>
												<b>Last Name</b>
											</label>
											<input disabled type="text" className="form-control" name="lastName" placeholder="Last Name"
												value={
													this.state.data.selectedRegisteredPatient
														? this.state.patient?.user?.contactName?.last
														: ""
												}
											/>
										</div>
									</div>
									<div className="col-12 col-sm-4">
										<div className="form-group">
											<div className="d-flex flex-column">
												<label>
													<b>Date of Birth *</b>
												</label>
												<input disabled type="text" className="form-control" name="lastName" placeholder="Date of birth"
													value={
														this.state.data.selectedRegisteredPatient
															? this.state.patient?.user?.dateBirth?.toLocaleString("en-GB")
															: ""
													}
												/>
											</div>
										</div>
									</div>
									<div className="col-12 col-sm-4">
										<div className="form-group">
											<label>
												<b>Mobile Phone</b>
											</label>
											<input disabled type="text" className="form-control" name="mobilePhone" placeholder="Mobile Phone"
												value={
													this.state.data.selectedRegisteredPatient ? this.state.patient?.mobilePhone : ""
												}
											/>
										</div>
									</div>
									<div className="col-12 col-sm-4">
										<div className="form-group">
											<label>
												<b>Gender</b>
											</label>
											<input disabled type="text" className="form-control" name="gender" placeholder="Gender"
												value={this.state.data.selectedRegisteredPatient ? this.state.patient?.gender : ""}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<ReusableTabNavs
							setActiveTab={(n) => this.setActiveTab(n)}
							activeTab={this.state.activeTab}
							navprops={tabMenus}
						/>
						<TabContent activeTab={this.state.activeTab}>
							<ReusableTab id={1}>
								<div className="mt-5">
									<MedicalHistory
										handleChange={this.handleChange}
										handleMultiSelectChange={this.handleMultiSelectChange}
										handleMultiChange={this.handleMultiChange}
										handleFamilyMemberChange={this.handleFamilyMemberChange}
										infoState={this.state.data}
										addFamilyMember={this.addFamilyMember}
										removeFamilyMember={this.removeFamilyMember}
										isEditable={this.props.isEditable}
										users={this.selectUsers}
										diseasesConditions={this.state.diseasesConditions}
										diseasesCondition={this.state.diseasesCondition}
										handleWesternDiseasesChange={this.handleWesternDiseasesChange}
									/>
									<div className="form-group float-right">

										<button type="button" className="btn btn-green btn-sm" onClick={this.nextTab}>
											Next
										</button>
										{this.props.match.params.id !== "new" && (
											<>
												<button type="button" className="btn btn-indigo btn-sm mr-2" style={{ marginLeft: "5px" }} onClick={this.handleSubmit}>
													Save
												</button>
												<Link to="/clinic/medicalfiles">
													<button type="button" className="btn btn-red btn-sm mr-2">Cancel
													</button>
												</Link>
											</>
										)}
									</div>
								</div>
							</ReusableTab>
							<ReusableTab id={2} height={"100%"} width={"100%"}>
								<div className="mt-5">
									<Interview
										handleChange={this.handleChange}
										infoState={this.state.data}
										isEditable={this.props.isEditable}
										handleTextChange={this.handleTextChange}
										handleMultiSelectChange={this.handleMultiSelectChange}
										handleMultiChange={this.handleMultiChange}
									/>
									{this.renderTabButtons()}
								</div>
							</ReusableTab>
							<ReusableTab id={3}>
								<div className="mt-5">
									<InspectionAndExamination
										handleChange={this.handleChange}
										handleMultiSelectChange={this.handleMultiSelectChange}
										handleMultiChange={this.handleMultiChange}
										infoState={this.state.data}
										isEditable={this.props.isEditable}
									/>
									{this.renderTabButtons()}
								</div>
							</ReusableTab>
							<ReusableTab id={4}>
								<div className="mt-5">
									<TCMTreatment
										handleChange={this.handleChange}
										infoState={this.state.data}
										isEditable={this.props.isEditable}
										addRemedy={this.addRemedy}
										addRemedy2={this.addRemedy2}
										removeRemedy={this.removeRemedy}
										removeRemedy2={this.removeRemedy2}
										handleRemedyChange={this.handleRemedyChange}
										handleRemedy2Change={this.handleRemedy2Change}
										removeacuPoint={this.removeacuPoint}
										handleAcuCombinationChange={this.handleAcuCombinationChange}
										addacuPoint={this.addacuPoint}
										previousTab={this.previousTab}
										acuPoint={this.state.acu}
										materiaMedic={this.state.materiaMedic}
										materiaMedics={this.state.materiaMedicas}
										herbal={this.state.herbal1}
										addherbalFormula1={this.addherbalFormula1}
										removeherbalFormula1={this.removeherbalFormula1}
										handleHerbalChange={this.handleHerbalChange}
										pathologies={this.state.pathologies}
										principleTreatment={this.state.principleTreatment}
										differentiation={this.state.differentiation}
										handlePrincipleTreatmentChange={this.handlePrincipleTreatmentChange}
										handleDifferentiationChange={this.handleDifferentiationChange}
									/>
									<div className="form-group float-right">
										<button type="button" className="btn btn-green btn-sm mr-2" onClick={this.previousTab} > Previous </button>
										<button type="button" className="btn btn-indigo btn-sm mr-2" onClick={() => this.saveData()}> Save</button>
										<button type="button" className="btn btn-green btn-sm" onClick={this.nextTab} > Go to attach file</button>
									</div>
								</div>
							</ReusableTab>
							<ReusableTab id={5}>
								<Fragment>
									<h4>Sharing</h4>
									<p>
										Nullam ac sapien justo. Nam augue mauris, malesuada non magna sed, feugiat blandit ligula.
										In tristique tincidunt purus id iaculis. Pellentesque volutpat tortor a mauris convallis,
										sit amet scelerisque lectus adipiscing.
									</p>
								</Fragment>
							</ReusableTab>
							<ReusableTab id={6}>
								<PreviousSessions
									previoussessions={this.state.previousSessions}
									selectedPatient={this.state.data.selectedRegisteredPatient}
									acuPoint={this.state.acu}
									materiaMedic={this.state.materiaMedic}
									materiaMedics={this.state.materiaMedicas}
									herbal={this.state.herbal1}
								/>
							</ReusableTab>
						</TabContent>
					</PanelBody>
				</Panel>
			</Fragment>
		);
	}
}
AddTMCSession.defaultProps = {
	isEditable: true,
};

export default AddTMCSession;

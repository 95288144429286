import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Select from "react-select";
import options from "./options";
import "./style.css";

class InspectionAndExamination extends React.Component {
	render() {
		const targetHeight = 34;

		const customStyles = {
			control: (styles) => ({
				...styles,
				minHeight: "initial",
			}),

			option: (provided) => ({
				...provided,
			}),
			valueContainer: (base) => ({
				...base,
				padding: "0 8px",
			}),
			clearIndicator: (base) => ({
				...base,
				padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
			}),
			dropdownIndicator: (base) => ({
				...base,
				padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
			}),
		};

		const { handleChange, handleMultiSelectChange, handleMultiChange, infoState, isEditable } = this.props;
		// console.log("infoState", infoState);
		// console.log("options", options);

		return (
			<div className="main-container">
				<h3 className="mt-10">Inspection & Examination</h3>
				<div className="panel-body">
					<fieldset>
						<legend className="legend-text">Listening and Smelling</legend>
						{[
							{
								label: "Respiration",
								options: options.respiration,
								variable: "respiration",
								num: "1",
							},
							{
								label: "Speech",
								options: options.speech,
								variable: "speech",
								num: "2",
							},
							{
								label: "Cough",
								options: options.cough,
								variable: "cough",
								num: "3",
							},
							{
								label: "Odor",
								options: options.odor,
								variable: "odor",
								num: "4",
							},
						].map((el, index) => (
							<div className="form-group" key={index}>
								<div className="row">
									<div className="col-12 col-md-3">
										<label>
											<b>{el.label}&nbsp;:</b>
										</label>
									</div>
									<div className="col-12 col-md-4">
										<Select
											isDisabled={!isEditable}
											isMulti
											styles={customStyles}
											options={el.options}
											placeholder={`Select ${el.label.toLowerCase()}...`}
											value={el.options.filter((opt) => infoState[el.variable].includes(opt.value))}
											//onChange={(e) => handleMultiSelectChange(el.variable, e)}
											onChange={(e) => handleMultiChange(el.variable, e)}
										/>
									</div>
									<div className="col-5">&nbsp;</div>
								</div>
							</div>
						))}
					</fieldset>
				</div>

				<div className="panel-body">
					<fieldset>
						<legend className="legend-text">Observation</legend>
						{[
							{
								label: "Vitality",
								options: options.vitality,
								variable: "vitality",
								num: "1",
							},
							{
								label: "Color and Lustre of Face",
								options: options.colorFace,
								variable: "colorFace",
								num: "3",
							},
						].map((el, index) => (
							<div className="form-group" key={index}>
								<div className="row">
									<div className="col-12 col-md-3">
										<label>
											<b>{el.label}&nbsp;:</b>
										</label>
									</div>
									<div className="col-12 col-md-4">
										<Select
											isDisabled={!isEditable}
											isMulti
											styles={customStyles}
											options={el.options}
											placeholder={`Select ${el.label.toLowerCase()}...`}
											value={el.options.filter((opt) => infoState[el.variable].includes(opt.value))}
											//onChange={(e) => handleMultiSelectChange(el.variable, e)}
											onChange={(e) => handleMultiChange(el.variable, e)}
										/>
									</div>
									<div className="col-5">&nbsp;</div>
								</div>
							</div>
						))}
					</fieldset>
				</div>

				<div className="panel-body">
					<fieldset>
						<legend className="legend-text">Physical Examination</legend>
						<div className="form-group">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>Physical Appearance&nbsp;:</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.physicalAppearance}
										placeholder={"Select Appearance..."}
										value={options.physicalAppearance.filter((opt) =>
											infoState.physicalAppearance.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("physicalAppearance", e)}
									/>
								</div>
								<div className="col-12 col-md-9">
									<label>
										<b>Note for Physical Appearance :</b>
									</label>
									<textarea
										disabled={!isEditable}
										rows="3"
										type="text"
										className="form-control"
										name="physicalAppearanceNote"
										placeholder="Type additional information for physical Appearance..."
										value={infoState.physicalAppearanceNote}
										onChange={(e) => handleChange(e.target.name, e.target.value)}
									/>
								</div>
								<div className="col-5">&nbsp;</div>
							</div>
						</div>
						<div className="form-group">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>Tongue-Shape</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.tongueShape}
										placeholder={"Select Tongue-Shape"}
										value={options.tongueShape.filter((opt) =>
											infoState?.tongueShape?.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("tongueShape", e)}
									/>
								</div>
								<div className="col-12 col-md-3">
									<label>
										<b>Tongue-Color</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.tongueColor}
										placeholder={"Select Tongue-color"}
										value={options.tongueColor.filter((opt) =>
											infoState?.tongueColor?.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("tongueColor", e)}
									/>
								</div>
								<div className="col-12 col-md-3">
									<label>
										<b>Tongue-Quality</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.tongueQuality}
										placeholder={"Select Tongue-Quality"}
										value={options.tongueQuality.filter((opt) =>
											infoState?.tongueQuality?.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("tongueQuality", e)}
									/>
								</div>
								
								<div className="col-12 col-md-9">
									<label>
										<b>Note for Tongue:</b>
									</label>
									<textarea
										disabled={!isEditable}
										rows="3" type="text" className="form-control" name="tongueNote"
										placeholder="Type additional information for Tongue..." value={infoState.tongueNote}
										onChange={(e) => handleChange(e.target.name, e.target.value)}
									/>
								</div>
								<div className="col-5">&nbsp;</div>
							</div>
						</div>

						<div className="form-group">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>Pulse-Speed</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.pulseSpeed}
										placeholder={"Select Pulse-Speed"}
										value={options.pulseSpeed.filter((opt) =>
											infoState?.pulseSpeed?.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("pulseSpeed", e)}
									/>
								</div>
								<div className="col-12 col-md-3">
									<label>
										<b>Pulse-Depth</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.pulseDepth}
										placeholder={"Select Pulse-Depth"}
										value={options.pulseDepth.filter((opt) =>
											infoState?.pulseDepth?.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("pulseDepth", e)}
									/>
								</div>
								<div className="col-12 col-md-3">
									<label>
										<b>Pulse-Rhythm</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.pulseRhythm}
										placeholder={"Select Pulse-Rhythm"}
										value={options.pulseRhythm.filter((opt) =>
											infoState.pulseRhythm.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("pulseRhythm", e)}
									/>
								</div>   
								
							</div>
						</div>

						<div className="form-group">
							<div className="row">
								{/* <div className="col-12 col-md-3">
									<label>
										<b>Pulse-Quality</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.pulseQuality}
										placeholder={"Select Pulse-Quality"}
										value={options.pulseQuality.filter((opt) =>
											infoState?.pulseQuality?.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("pulseQuality", e)}
									/>
								</div> */}
								<div className="col-12 col-md-3">
									<label>
										<b>Pulse-Strength</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.pulseStrength}
										placeholder={"Select Pulse-Strength"}
										value={options.pulseStrength.filter((opt) =>
											infoState?.pulseStrength?.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("pulseStrength", e)}
									/>
								</div>
								<div className="col-12 col-md-3">
									<label>
										<b>Pulse-Tension</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.pulseTension}
										placeholder={"Select Pulse-Tension"}
										value={options.pulseTension.filter((opt) =>
											infoState.pulseTension.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("pulseTension", e)}
									/>
								</div>   
								
							</div>
						</div>
						<div className="form-group">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>Palpation of the Epigastrium&nbsp;:</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.epigastriumPalpation}
										placeholder={"Select epigastrium palpation..."}
										value={options.epigastriumPalpation.filter((opt) =>
											infoState.epigastriumPalpation.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("epigastriumPalpation", e)}
									/>
								</div>
								<div className="col-12 col-md-9">
									<label>
										<b>Note for Palpation of the Epigastrium&nbsp;:</b>
									</label>
									<textarea disabled={!isEditable} rows="3" type="text" className="form-control" name="epigastriumPalpationNote"
										placeholder="Type additional information for Palpation of the Epigastrium..."
										value={infoState.epigastriumPalpationNote}
										onChange={(e) => handleChange(e.target.name, e.target.value)}
									/>
								</div>
								<div className="col-5">&nbsp;</div>
							</div>
						</div>

						{[
							{label: "Range of motion", options: options.rangeMotion, variable: "rangeMotion", num: "1",},
							{label: "Pain gradations", options: options.painLevel, variable: "painLevel", num: "2",},
						].map((el, index) => (
							<div className="form-group" key={index}>
								<div className="row">
									<div className="col-12 col-md-3">
										<label>
											<b>{el.label}&nbsp;:</b>
										</label>
									</div>
									<div className="col-12 col-md-4">
										<Select
											isDisabled={!isEditable}
											styles={customStyles}
											options={el.options}
											placeholder={`Select ${el.label.toLowerCase()}...`}
											value={
												infoState[el.variable] && {
													value: infoState[el.variable],
													label: infoState[el.variable],
												}
											}
											onChange={(e) => handleChange(el.variable, e.value)}
										/>
									</div>
									<div className="col-5">&nbsp;</div>
								</div>
							</div>
						))}
						<div className="form-group">
							<div className="row">
								<div className="col-12 col-sm-3">
									<label>
										<b>Note for Physical Examination :</b>
									</label>
								</div>
								<div className="col-12 col-sm-9">
									<textarea disabled={!isEditable} rows="6" className="form-control" name="physicalExaminationNote"
										placeholder="Type in additional information for Physical Examination..."
										value={infoState.physicalExaminationNote}
										onChange={(e) => handleChange(e.target.name, e.target.value)}
									/>
								</div>
							</div>
						</div>
					</fieldset>
				</div>
			</div>
		);
	}
}

InspectionAndExamination.propTypes = {
	handleChange: PropTypes.func.isRequired,
	infoState: PropTypes.object.isRequired,
};

export default InspectionAndExamination;

import React, { useEffect, useRef, useState } from 'react';

import ReplyIcon from '@material-ui/icons/Reply';
import { useHistory, useParams } from "react-router-dom";

import moment from "moment";
import { Box } from '@material-ui/core';

import { Panel, PanelHeader, PanelBody } from '../components/panel/panel.jsx';

import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import AttachmentIcon from "@material-ui/icons/Attachment";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PanToolIcon from "@material-ui/icons/PanTool";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import LockIcon from "@material-ui/icons/Lock";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deleteAction, getAction, getActions } from '../services/actions.js';
import Action from '../pages/kaizen/action.js';
import ActionReply from '../pages/kaizen/action.js';
import { selectSortActionId } from 'chonky';
//import { element } from 'prop-types';


function ListingDetailsNotes() {
  const [commentlists,setCommentLists]= useState([])
  const taskId = useParams().id;
  const [allPosts, setAllPosts] = useState([])
  const [nestedComments, setNestedComments] = useState([])
  const stateRef = useRef();
  stateRef.current = nestedComments;
  const [add, setAdd] = useState(false)
  const [topicReply, setTopicReply] = useState(false)
  const [quote, setQuote] = useState(false)
  const [edit, setEdit] = useState(false)
  const [action, setAction] = useState({})

  const getParent = async(comment,id)=>{
    if(comment.parentId===id){
      return 1
    }
    else{
    if(!comment.parentId || comment.parentId===null){
  return 0
    }
    else{
      const p = await getAction(comment.parentId)
      return getParent(p.data,id)
    }
  }
  }
   const getAllKids = async (id) => {
    try {
      let table=[]
      const actions = await getActions()
      const filteredActions = actions.data.filter((e)=>e.kaizenNo._id === taskId)
      for (let j = 0; j <filteredActions.length ; j++) {
        const promise1 = Promise.resolve(await getParent(filteredActions[j],id));
        promise1.then(async(value) => {
          if(value) {
            table.push(filteredActions[j])
          }
        });
        if(j==filteredActions.length-1) {return table ; }}  }
    catch (err) {console.log(err)}
  }
  const deleteReply = async (id) => {
    try {
        const promise1 = Promise.resolve(await getAllKids(id));
        promise1.then(async(value) => {
          let size=value.length
          
        size>0 ? value.map(async(e,index)=>{
           await deleteAction(e._id)
           toast.success("action deleted")
           size-1==index && await deleteAction(id) && toast.success("action deleted") && await populateKaizen()
          }) : await deleteAction(id) && toast.success("action deleted") && await populateKaizen()
        })}
    catch (err) { console.log(err) }
  }

    const populateKaizen =  async () => {
    try {

      let filteredActions = []
      const actions = await getActions()
      //actions.data.map(async(e)=>await deleteAction(e._id) )
      let kaizenActions = actions.data.filter((e) => e.kaizenNo._id === taskId)
      setAllPosts(kaizenActions)
       filteredActions =  actions?.data?.filter(post => post.kaizenNo._id===taskId && !post.parentId);
      for (let j = 0; j <filteredActions.length ; j++) {
        const promise = Promise.resolve(await getAllKids(filteredActions[j]._id));
        promise.then(async(value) => {
            filteredActions[j].comments=value});
          j==filteredActions.length-1 && setCommentLists(filteredActions) 
      }
    }
    catch(ex){
      console.error(ex)
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/error");
    }
  }





  //reply to action
  const handleReply = (e, post) => {
    e.preventDefault();
    window.scrollTo({ top: 100, left: 0, behavior: "auto" });
    setAction(post)
    setTopicReply(true)
    setEdit(false)
    setQuote(false)
    setAdd(false)
  };

  const handleQuote = (e, post) => {
    e.preventDefault();
    window.scrollTo({ top: 100, left: 0, behavior: "auto" });
    setAction(post)
    setQuote(true)
    setEdit(false)
    setTopicReply(false)
    setAdd(false)
  };
  const handleEdit = (e, post) => {
    e.preventDefault();
    window.scrollTo({ top: 100, left: 0, behavior: "auto" });
    setAction(post)
    setEdit(true)
    setTopicReply(false)
    setQuote(false)
    setAdd(false)
  };
  const handleAddAction = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 100, left: 0, behavior: "auto" });
    setAction(taskId)
    setAdd(true)
    setEdit(false)
    setQuote(false)
    setTopicReply(false)
    
  };
  //SubmitForm for the topic reply
  const submitReplyForm = () => {
    setTopicReply(false);

  };
  //SubmitForm for the topic reply
  const submitQuoteForm = () => {
    setQuote(false);

  };
  //SubmitForm for the topic reply
  const submitEditForm = () => {
    setEdit(false);
  };
  const submitAddForm = () => {
    setAdd(false);
  };
  useEffect(() => {
    return populateKaizen()
  }, [])



  return (
    <>
      <button onClick={(e) => { handleAddAction(e) }} style={{ padding: "5px 10px ", color: "black", fontWeight: "bold", border: "none", marginBottom: "15px" }}>
        post action
      </button>
      <div className="col-xl-12">
        <ToastContainer />
        <Panel>
          <PanelHeader>
            {allPosts?.length}  Comments
          </PanelHeader>
          <PanelBody>
            {add &&
              <div className="mb-3">
                <ActionReply
                  new
                  submitReplyForm={submitAddForm}
                  action={action}
                populateKaizen={populateKaizen}
                />

              </div>
            }
            {topicReply &&
              <div className="mb-3">
                <ActionReply
                reply
                  submitReplyForm={submitReplyForm}
                  action={action}
                  populateKaizen={populateKaizen}
                />

              </div>
            }

            {quote &&
              <div className="mb-3">
                <ActionReply
                  quote
                  submitReplyForm={submitQuoteForm}
                  populateKaizen={populateKaizen}
                  action={action}
                />

              </div>
            }
            {edit &&
              <div className="mb-3">
                <ActionReply
                  edit
                  submitReplyForm={submitEditForm}
                  populateKaizen={populateKaizen}
                  action={action}
                />

              </div>
            }

            {commentlists?.length > 0 &&
              commentlists.map((item) =>

                <div className="media media-sm">
                  <Box to="/ui/media-object" className="media-left" display="flex" flexDirection="column" alignItems="center">
                    <img src={item?.userID?.imageSrc} alt="" className="avatar__image" />
                    <div className="comment__userName">{item?.userID?.contactName?.first + ' ' + item?.userID?.contactName?.last}</div>
                  </Box>
                  <div className="media-body">
                    <h4 className="media-heading"></h4>
                    {moment(item?.createdOn).format("LLLL").split(' ')[0] + ' ' + moment(item?.createdOn).format("LLLL").split(' ')[1] + ' ' + moment(item?.createdOn).format("LLLL").split(' ')[2] + ' ' + moment(item?.createdOn).format("LLLL").split(' ')[3]}<br />

                    {item?.rootcause?.name}<br />
                    <div style={{ width: "40%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                      <div>
                        <strong>implementation </strong>{item?.implementation}
                      </div>
                      <div>
                        <strong>efficiency </strong>{item?.efficiency}
                      </div>
                    </div>


                    <div
                      className="comment-reply d-flex justify-content-end align-items-center">
                      <Box>
                        <span style={{ marginRight: ".5rem" }}>
                          <FormatQuoteIcon
                            onClick={(e) => { handleQuote(e, item) }}
                          // onClick={() => opencomment1(taskId, item._id,true)}
                          />
                        </span>
                        <span style={{ marginRight: ".5rem" }}>
                          <FavoriteBorderIcon />
                        </span>
                        <span style={{ marginRight: ".5rem" }}>
                          <AttachmentIcon />
                        </span>
                        <span style={{ marginRight: ".5rem" }}>
                          <BookmarkBorderIcon />
                        </span>
                        <span style={{ marginRight: ".5rem" }}>
                          <ReplyIcon
                            onClick={(e) => { handleReply(e, item) }}
                          // onClick={() => opencomment1(taskId, item._id)} 
                          />
                          Reply
                        </span>
                        <span style={{ marginRight: ".5rem" }}>
                          <EditIcon
                            onClick={(e) => { handleEdit(e, item) }}
                          //onClick={() => editComment(item._id)}
                          />
                        </span>

                        <span style={{ marginRight: ".5rem" }}>
                          <PanToolIcon />
                        </span>
                        <span style={{ marginRight: ".5rem" }}>
                          <DeleteIcon onClick={async () => await deleteReply(item._id)} />

                        </span>
                        <span style={{ marginRight: ".5rem" }}>
                          <LockIcon />
                        </span>
                      </Box>
                    </div>
                    {item.comments?.length>0 ? (
                      item.comments.map((item2, index2) => {
                        return (
                          <ul className="comments-reply" key={index2}>
                            <li>
                              <div className="media media-sm comment">
                                <Box display="flex" flexDirection="column" alignItems="center">
                                  <Box backgroundColor="red" margin=".75rem">
                                    <img className="avatar__image" src={item2?.userID?.imageSrc} />
                                  </Box>
                                  <Box>
                                    <div className="comment__userName">{item2?.userID?.contactName?.first + ' ' + item2?.userID?.contactName?.last}</div>
                                  </Box>
                                </Box>
                                <div className="comment-body">


                                  <h4 className="media-heading"></h4>
                                  {moment(item?.createdOn).format("LLLL").split(' ')[0] + ' ' + moment(item2?.createdOn).format("LLLL").split(' ')[1] + ' ' + moment(item2?.createdOn).format("LLLL").split(' ')[2] + ' ' + moment(item2?.createdOn).format("LLLL").split(' ')[3]}<br />
                                  {item2?.rootcause?.name}<br />
                                  <div style={{ width: "40%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <>
                                      <strong>implementation </strong>{item2?.implementation}
                                    </>
                                    <>
                                      <strong>efficiency </strong>{item2?.efficiency}
                                    </>
                                  </div>


                                  <div
                                    className="comment-reply d-flex justify-content-end align-item2s-center">
                                    <Box>
                                      <span style={{ marginRight: ".5rem" }}>
                                        <FormatQuoteIcon
                                          onClick={(e) => { handleQuote(e, item2) }}
                                        // onClick={() => opencomment1(taskId, item2._id,true)}
                                        />
                                      </span>
                                      <span style={{ marginRight: ".5rem" }}>
                                        <FavoriteBorderIcon />
                                      </span>
                                      <span style={{ marginRight: ".5rem" }}>
                                        <AttachmentIcon />
                                      </span>
                                      <span style={{ marginRight: ".5rem" }}>
                                        <BookmarkBorderIcon />
                                      </span>
                                      <span style={{ marginRight: ".5rem" }}>
                                        <ReplyIcon
                                          onClick={(e) => { handleReply(e, item2) }}
                                        //onClick={() => opencomment1(taskId, item2._id)}
                                        />
                                        Reply
                                      </span>
                                      <span style={{ marginRight: ".5rem" }}>
                                        <EditIcon
                                          onClick={(e) => { handleEdit(e, item2) }}

                                        //onClick={() => editComment(item2._id)} 
                                        />
                                      </span>

                                      <span style={{ marginRight: ".5rem" }}>
                                        <PanToolIcon />
                                      </span>
                                      <span style={{ marginRight: ".5rem" }}>
                                        <DeleteIcon onClick={() => deleteReply(item2._id)} />
                                      </span>
                                      <span style={{ marginRight: ".5rem" }}>
                                        <LockIcon />
                                      </span>
                                    </Box>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        )
                      })
                    ) : ''}


                  </div>





                </div>








              )
            }


          </PanelBody>
        </Panel>





      </div>
    </>
  );
}

export default ListingDetailsNotes;
const Menu = [
  {
    path: "aivoluon.com",
    icon: "fas fa-globe text-green",
    title: "wappsolute.com",
  },
	{
		path: "/Calendar & Planning",
		icon: "far fa-calendar-alt",
		title: "Calendar & Planning",
		badge: "10",
		children: [
			{ path: "/calendar", icon: "fa fa-calendar", title: "Calendar" },
			{ path: "/clinic/appointments", title: "Appointments" },
			{ path: "/clinic/reqforappointments", title: "Requests for Appointments" },
			{ path: "/clinic/appointments/new", title: "Add Appointment" },
			{ path: "/calendar/scheduler", title: "Team-scheduler" },
			{ path: "/scheduler", title: "scheduler Dev" },
		],
	},

	{
		path: "/files",
		icon: "fa fa-hdd",
		title: "Your Drive",
		badge: "10",
		children: [
			{ path: "/clinic/yourdrive", title: "your drive" },
			{ path: "/drive/files", title: "files" },
			{ path: "/drive/search_drive", title: "Search in Your drive" },
			{ path: "/page-option/with-wide-sidebar", title: "My Drive" },
			{ path: "/gallery", icon: "fa fa-image", title: "Gallery" },
		],
	},

	{
		path: "/Users",
		icon: "fa fa-address-book text-lime",
		title: "Users",
		badge: "10",
		children: [
			{ path: "/clinic/doctors", title: "Doctors" },
			{ path: "/clinic/doctors/new", title: "Add doctor" },
			{ path: "/clinic/search_doctor", title: "Search in Doctors" },
			{ path: "/clinic/receptions", title: "receptions" },
			{ path: "/clinic/receptions/new", title: "Add reception" },
			{ path: "/clinic/search_reception", title: "Search in Receptions" },
			{ path: "/clinic/accountants", title: "accountants" },
			{ path: "/clinic/accountants/new", title: "Add accountant" },
			{ path: "/clinic/search_accountant", title: "Search in Accountants" },
			{ path: "/clinic/patients", title: "patients" },
			{ path: "/clinic/patients/new", title: "Add patient" },
			{ path: "/clinic/search_patient", title: "Search in Patients" },
			{ path: "/clinic/users", title: "users" },
			{ path: "/clinic/user/new", title: "Add user" },
			{ path: "/clinic/search_user", title: "Search in Users" },
			{ path: "/user/userroles/", title: "User-role" },
			{ path: "/user/permissions", title: "Rights & Permissions" },
			{ path: "/user/Tab.jsx", title: "Rights & Permissions Tabs" },
		],
	},

  {
    path: "/Databases", icon: "fa fa-briefcase text-orange", title: "Databases",
    children: [
      { path: "/databases/titles", title: "Titles" },
      { path: "/databases/organizations", title: "Organizations" },
      { path: "/databases/banks", title: "Banks" },
      { path: "/databases/insurances", title: "Insurances" },
      { path: "/databases/reservedusernames", title: "Reserved Usernames" },
      { path: "/databases/termofuses/", title: "Term of USe" },
      { path: "/databases/privacypolicies/", title: "Privacy Policy" },
      { path: "/databases/meta", title: "Meta information" },	  
    ],
  },

	{
		path: "/Clinicsolos",
		icon: "fa fa-hospital",
		title: "ClinicSolos",
		badge: "10",
		children: [
			{ path: "/clinic/clinicsolos", title: "clinicsolos" },
			{ path: "/clinic/clinicsolos/new", title: "Add clinicsolo" },
			{ path: "/clinic/workinghours", title: "Working Hours" },
			{ path: "/clinic/search_clinicsolo", title: "Search in clinicsolos" },
		],
	},
  {
    path: "/Medicalfiles", icon: "fa fa-medkit text-pink", title: "Medical Files",  badge: "10",
    children: [
      { path: "/clinic/medicalfiles", title: "Medical Files" },
      { path: "/clinic/tcmsessions/new", title: "Add TCM session" },
      { path: "/clinic/ayurvedasessions/new", title: "Add Ayurveda session" },	  
      { path: "/clinic/homeopathysessions/new", title: "Add homeopathic session" },
      { path: "/clinic/physicalconditions", title: "Physical conditions" },
      { path: "/clinic/physicalconditions/new", title: "Add Physical condition"},
      { path: "/clinic/search_medicalfile", title: "Search in Medical Files" },
    ],
  },
	{
		path: "/treatments",
		icon: "fa fa-medkit",
		title: "Treatments for TCM",
		badge: "10",
		children: [
			{ path: "/clinic/tcmtreatments", title: "TCMtreatments" },
			{ path: "/clinic/tcmtreatments/new", title: "Add TCM treatment" },
			{ path: "/clinic/search_tcmtreatment", title: "Search in TCMtreatments" },
		],
	},

	{
		path: "/Physical Conditions",
		icon: "fa fa-child",
		title: "Physical Conditions",
		badge: "10",
		children: [
			{ path: "/clinic/physicalconditions", title: "Physical Conditions" },
			{ path: "/clinic/physicalconditions/new", title: "Add Physical Condtion of patient" },
		],
	},

  {
    path: "/Accounting",
    icon: "fas fa-balance-scale text-cyan",
    title: "Accounting",
    badge: "10",
    children: [
      { path: "/accounting/accountingsettings", title: "Settings" },	
      { path: "/accounting/invoices", title: "Invoices" },
      { path: "/accounting/invoices/new", title: "Add Invoice" },
      { path: "/accounting/expenses", title: "Expenses" },
      { path: "/accounting/expenses/new", title: "Add Expense" },
      { path: "/accounting/transactions", title: "Transactions" },
      { path: "/accounting/transactions/new", title: "Add transaction" },
      { path: "/accounting/COAs", title: "COAs" },
	  { path: "/accounting/NCOAs", title: "COAs" },	  
      { path: "/accounting/COAs/new", title: "Add COA" },
      { path: "/accounting/services", title: "Services" },
      { path: "/accounting/services/new", title: "Add Service" },
      { path: "/accounting/products", title: "Products" },
      { path: "/accounting/products/new", title: "Add Product" },
      { path: "/accounting/profitlossstatement",title: "Profit vs Loss Statement"},
      { path: "/accounting/charts", title: "Charts" },
    ],
  },


	{
		path: "/CRM",
		icon: "fa fa-hospital",
		title: "CRM",
		badge: "10",
		children: [
			{ path: "/crm/companies", title: "companies" },
			{ path: "/crm/companies/new", title: "Add company" },
			{ path: "/crm/workinghours", title: "Working Hours" },
			{ path: "/crm/search_company", title: "Search in companies" },
		],
	},

	{
		path: "/accounting",
		icon: "fa fa-balance-scale",
		title: "Accounting",
		children: [
			{ path: "/accounting/invoiceprofile", title: "Invoiceprofile" },
			{ path: "/accounting/products", title: "Product" },
			{ path: "/accounting/services", title: "Services" },
			{ path: "/accounting/coas", title: "COAs" },
			{ path: "/accounting/coas/new", title: "Add COA" },
			{ path: "/accounting/invoices", title: "Invoices" },
			{ path: "/accounting/invoices/new", title: "Add invoice" },
			{ path: "/accounting/expences", title: "Expences" },
			{ path: "/accounting/expenses/new", title: "Add expense" },
			{ path: "/accounting/transactions", title: "Transactions" },
			{ path: "/accounting/transactions/new", title: "Add Transaction" },
			{ path: "/accounting/chart-js", title: "Charts Income vs Expenses" },
			{ path: "/accounting/profitlossstatement", title: "Profit-Loss Statement" },
		],
	},

	{
		path: "/tickets",
		icon: "fa fa-ticket-alt",
		title: "Tickets",
		badge: "10",
		children: [
			{ path: "/clinic/grid-tickets", title: "new kanbanboard" },
			{ path: "/ticket/grid-tickets", title: "Gridview of Tickets" },
			{ path: "/ticket/tickets", title: "Tickets" },
			{ path: "/ticket/ticketprofile", title: "Ticketprofile" },
			{ path: "/clinic/tickets/new", title: "Add ticket" },
			{ path: "/clinic/search_ticket", title: "Search in tickets" },
			{ path: "/ticket/timelinetickets", title: "Timeline of Tickets" },			
		],
	},

	{
		path: "/database",
		icon: "fa fa-database",
		title: "database",
		badge: "10",
		children: [
			{ path: "/clinic/treatments", title: "treatments" },
			{ path: "/clinic/currencies", title: "currencies" },
			{ path: "/clinic/titles", title: "Titles" },
			{ path: "/clinic/reservedNames", title: "Reserved Names" },
			{ path: "/clinic/meridians", title: "Meridians" },
			{ path: "/clinic/acupuncture", title: "Acupuncture" },
			{ path: "/clinic/materiamedica", title: "Materia Medica" },
			{ path: "/clinic/formulas", title: "Formulas" },
		],
	},

	{
		path: "/database",
		icon: "fa fa-book",
		title: "Homeopathy-books",
		badge: "10",
		children: [
			{ path: "/clinic/allen", title: "Allen" },
			{ path: "/clinic/boericke", title: "Boericke" },
			{ path: "/clinic/boger", title: "Boger" },
			{ path: "/clinic/clarke", title: "Clarke" },
			{ path: "/clinic/dunham", title: "Dunham" },
			{ path: "/clinic/farrington", title: "Farrington" },
			{ path: "/clinic/guernsey", title: "Guernsey" },
			{ path: "/clinic/hahnemann", title: "Hahnemann" },
			{ path: "/clinic/hering", title: "Hahnemann" },
			{ path: "/clinic/kent", title: "Kent" },
			{ path: "/clinic/lippe", title: "Lippe" },
			{ path: "/clinic/mure", title: "Mure" },
			{ path: "/clinic/nash", title: "Nash" },
		],
	},

	{
		path: "/Salons",
		icon: "fa fa-building",
		title: "Salons",
		children: [
			{ path: "/salon/salons", title: "Salons" },
			{ path: "/salon/salons/new", title: "Add salon" },
			{ path: "/salon/search_reception", title: "Search in Salons" },
		],
	},

	{
		path: "/Treatments",
		icon: "fa fa-heart",
		title: "Salons Treatments",
		badge: "10",
		children: [
			{ path: "/salon/nailtreatments", title: "Treatments for Salon" },
			{ path: "/salon/nailtreatments/new", title: "Add Treatment" },
			{ path: "/salon/search_nailtreatment", title: "Search in Treatments for Salon" },
		],
	},

	{
		path: "/Garages",
		icon: "fa fa-car",
		title: "Garages",
		children: [
			{ path: "/garage/garages", title: "Garages" },
			{ path: "/garage/garages/new", title: "Add garage" },
			{ path: "/garage/search_garage", title: "Search in Garages" },
		],
	},

	{
		path: "/APIs",
		icon: "fa fa-key",
		title: "APIs",
		children: [
			{ path: "/api/apis", title: "APIs" },
			{ path: "/api/apis/new", title: "Add API" },
			{ path: "/api/search_api", title: "Search in APIs" },
		],
	},

	{
		path: "/Garage-Treatments",
		icon: "fa fa-wrench",
		title: "Garages Treatments",
		children: [
			{ path: "/garage/garagetreatments", title: "Treatments for Garage" },
			{ path: "/garage/garagetreatments/new", title: "Add Treatment" },
			{ path: "/garage/search_garagetreatment", title: "Search in Treatments for Garage" },
			{ path: "/garage/vehicles", title: "Vehicle" },
			{ path: "/garage/vehicles/new", title: "Add vehicle" },
			{ path: "/garage/search_vehicle", title: "Search in Vehicle" },
		],
	},

	{
		path: "/labels",
		icon: "fa fa-barcode",
		title: "Labels",
		children: [
			{ path: "/label/labels-grid", title: "Gridview of Labels" },
			{ path: "/label/labels", title: "Labels" },
			{ path: "/label/labels/new", title: "New Label" },
		],
	},

	{
		path: "/forum-front",
		icon: "fa fa-truck",
		title: "Transport",
		children: [
			{ path: "/tlerp/transports", title: "Transports" },
			{ path: "/tlerp/transports/new", title: "New Transport" },
			{ path: "/tlerp/search_transport/", title: "Search in Transports" },
		],
	},

	{
		path: "/forum-front",
		icon: "fa fa-gift",
		title: "Cargos",
		children: [
			{ path: "/tlerp/cargos", title: "Cargos" },
			{ path: "/tlerp/cargos/new", title: "New Cargo" },
			{ path: "/tlerp/search_cargo/", title: "Search in Cargos" },
		],
	},

	{
		path: "/forum-front",
		icon: "fa fa-plane",
		title: "OBCs",
		children: [
			{ path: "/tlerp/obcs", title: "OBCs" },
			{ path: "/tlerp/obcs/new", title: "New OBC" },
			{ path: "/tlerp/search_obc/", title: "Search in OBCs" },
		],
	},

	{
		path: "/forum-front",
		icon: "fab fa-docker",
		title: "Shipments",
		children: [
			{ path: "/tlerp/shipments", title: "Shipments" },
			{ path: "/tlerp/shipments/new", title: "New Shipment" },
			{ path: "/tlerp/search_hipment/", title: "Search in Shipment" },
		],
	},

	{
		path: "/forum-front",
		icon: "fa fa-hospital",
		title: "Forum Front",
		children: [
			{ path: "/forum-front/forums", title: "Forums" },
			{ path: "/forum-front/postcompose", title: "Post compose" },
			{ path: "/forum-front/postdetail", title: "Post Detail" },
		],
	},

	{
		path: "/email",
		icon: "fa fa-hdd",
		title: "Email",
		badge: "10",
		children: [
			{ path: "/email/inbox", title: "Inbox" },
			{ path: "/email/compose", title: "Compose" },
			{ path: "/email/detail", title: "Detail" },
		],
	},
	{ 
		path: "/kanban",
		icon: "fa fa-columns",
		title: "Kanban",
		children: [
			{
				path: "/kanban/scrumboard",
				title: "Kanbans-scrumboard",
		
			},

			{ path: "/kanban/kanbans/new", title: "New Kanban" },
			{ path: "/kanban/kanbans", title: "Admin Kanbans" },
			{ path: "/kanban/listkanbans", title: "Admin Lists of Kanbans" },
			{ path: "/kanban/tasks", title: "Admin tasks" },
			{ path: "/kanban/timelinetasks", title: "Timeline of Tasks" },
			{ path: "/kanban/scrumboard", title: "Archived Kanban" },			
		],
	},

	{ 
		path: "/kaizen",
		icon: "fa fa-columns",
		title: "Kaizen",
		children: [
			{
				path: "/kanban/scrumboard",
				title: "Kanbans-scrumboard",
				children: [
					{
						path: "/kanban/scrumboard/menu-2-1",
						title: "Menu 2.1",
						children: [
							{ path: "/kanban/scrumboard/menu-3-1", title: "Menu 3.1" },
							{ path: "/kanban/scrumboard/menu-2-1/menu-3-2", title: "Menu 3.2" },
						],
					},
					{ path: "/menu/menu-1-1/menu-2-2", title: "Menu 2.2" },
					{ path: "/menu/menu-1-1/menu-2-3", title: "Menu 2.3" },
				],
			},

			{ path: "/kaizen/kaibanzen", title: "Kaibanzen" },
			{ path: "/kaizen/kaizens/new", title: "New Kaizen" },
			{ path: "/kaizen/kaizens", title: "Admin Kaizens" },
			{ path: "/kaizen/gridkaizens", title: "Grid of Kaizens" },			
			{ path: "/kaizen/timelinekaizens", title: "timeline of Kaizens" },
			{ path: "/kaizen/wastetypes/new", title: "Add Waste-type" },
			{ path: "/kaizen/wastetypes", title: "AdminWaste-Types" },			
			
		],
	},


	{ path: "/widgets", icon: "fab fa-simplybuilt", title: "Widgets", label: "NEW" },
	{
		path: "/ui",
		icon: "fa fa-gem",
		title: "UI Elements",
		label: "NEW",
		children: [
			{ path: "/ui/general", title: "General", highlight: true },
			{ path: "/ui/typography", title: "Typograhy" },
			{ path: "/ui/tabs-accordion", title: "Tabs & Accordion" },
			{ path: "/ui/modal-notification", title: "Modal & Notification" },
			{ path: "/ui/widget-boxes", title: "Widget Boxes" },
			{ path: "/ui/media-object", title: "Media Object" },
			{ path: "/ui/buttons", title: "Buttons", highlight: true },
			{ path: "/ui/icons", title: "Icons" },
			{ path: "/ui/simple-line-icons", title: "Simple Line Icons" },
			{ path: "/ui/ionicons", title: "Ionicons" },
			{ path: "/ui/language-bar-icon", title: "Language Bar & Icon" },
			{ path: "/ui/social-buttons", title: "Social Buttons" },
		],
	},
	{ path: "/bootstrap-4", img: "/assets/img/logo/logo-bs4.png", title: "Bootstrap 4", label: "NEW" },
	{
		path: "/form",
		icon: "fa fa-list-ol",
		title: "Form Stuff",
		label: "NEW",
		children: [
			{ path: "/form/elements", title: "Form Elements", highlight: true },
			{ path: "/form/add_clinicsolo", title: "Add clinicsolo", highlight: true },
			{ path: "/form/wizards", title: "Form Wizards", highlight: true },
		],
	},
	{
		path: "/email-template",
		icon: "fa fa-envelope",
		title: "Email Template",
		children: [
			{ path: "/email-template/system", title: "System Template" },
			{ path: "/email-template/newsletter", title: "Newsletter Template" },
		],
	},
	{
		path: "/chart",
		icon: "fa fa-chart-pie",
		title: "Chart",
		label: "NEW",
		children: [
			{ path: "/chart/js", title: "Chart JS" },
			{ path: "/chart/d3", title: "d3 Chart" },
			{ path: "/chart/apex", title: "Apex Chart", highlight: true },
		],
	},
	{ path: "/calendar", icon: "fa fa-calendar", title: "Calendar" },
	{ path: "/map", icon: "fa fa-map", title: "Map" },
	{ path: "/gallery", icon: "fa fa-image", title: "Gallery" },
	{
		path: "/page-option",
		icon: "fa fa-cogs",
		title: "Page Options",
		label: "NEW",
		children: [
			{ path: "/page-option/blank", title: "Blank Page" },
			{ path: "/page-option/with-footer", title: "Page with Footer" },
			{ path: "/page-option/without-sidebar", title: "Page without Sidebar" },
			{ path: "/page-option/with-right-sidebar", title: "Page with Right Sidebar" },
			{ path: "/page-option/with-minified-sidebar", title: "Page with Minified Sidebar" },
			{ path: "/page-option/with-two-sidebar", title: "Page with Two Sidebar" },
			{ path: "/page-option/full-height", title: "Full Height Content" },
			{ path: "/page-option/with-wide-sidebar", title: "Page with Wide Sidebar" },
			{ path: "/page-option/with-light-sidebar", title: "Page with Light Sidebar" },
			{ path: "/page-option/with-mega-menu", title: "Page with Mega Menu" },
			{ path: "/page-option/with-top-menu", title: "Page with Top Menu" },
			{ path: "/page-option/with-boxed-layout", title: "Page with Boxed Layout" },
			{ path: "/page-option/with-mixed-menu", title: "Page with Mixed Menu" },
			{ path: "/page-option/boxed-layout-with-mixed-menu", title: "Boxed Layout with Mixed Menu" },
			{ path: "/page-option/with-transparent-sidebar", title: "Page with Transparent Sidebar" },
			{ path: "/page-option/with-search-sidebar", title: "Page with Search Sidebar", highlight: true },
		],
	},
	{
		path: "/extra",
		icon: "fa fa-gift",
		title: "Extra",
		label: "NEW",
		children: [
			{ path: "/extra/timeline", title: "Timeline" },
			{ path: "/extra/coming-soon", title: "Coming Soon Page" },
			{ path: "/extra/search", title: "Search Results" },
			{ path: "/extra/invoice", title: "Invoice" },
			{ path: "/extra/error", title: "404 Error Page" },
			{ path: "/extra/profile", title: "Profile Page" },
			{ path: "/extra/scrum-board", title: "Scrum Board", highlight: true },
			{ path: "/extra/cookie-acceptance-banner", title: "Cookie Acceptance Banner", highlight: true },
		],
	},
];

export default Menu;

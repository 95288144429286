import React, { Component } from "react";
import Table from "./../common/table";
import { Link, withRouter } from "react-router-dom";

class WasteTypesTable extends Component {
	// constructor(props) {
	// 	super(props);
	// 	this.state = {
	// 		values: [],
	// 	};
	// }

	columns = [
		//   {path: '_id', label: 'Id'},
		{
			key: "checkbox",
			label: (
				<input
					type="check"
					style={{
						width: "15px",
						height: "15px",
						marginTop: "0.4rem",
						borderRadius: 0,
					}}
				/>
			),
			content: (kanban) => (
				<span className="icon-img sm-r-5" style={{ marginTop: "15px" }}>
					<input
						type="checkbox"
						style={{
							width: "15px",
							height: "15px",
							marginTop: "0.4rem",
							borderRadius: 0,
						}}
						onChange={this.props.handleCheckboxChange}
						value={kanban._id}
					/>
				</span>
			),
		},
		{
			key: "avatar",
			label: "Owner",
			content: (user) => (
				<span className="icon-img sm-r-5">
					<img
						style={{ width: "30px", height: "30px", borderRadius: "50%" }}
						src={user.user.imageSrc}
						alt=""
					/>
					<span>{user.user.contactName.first}</span>
				</span>
			),
		},
		/*{label: 'Owner',   path: 'user.contactName.first' } ,*/
		{label: 'WasteType',   path: 'name' } ,   
		{
			key : "color" , 
			label: 'Color',
			path: 'color' ,
			content: (user) => (
				<input type="color" style={{width : "100%"}} value={user.color} disabled = {true} />
			)

		} ,   	  
		{label: 'Note',   path: 'note' } ,   	  	  
	];

	render() {
		//console.log(this.columns) ;
		const { wastetypes, onSort, sortColumn } = this.props;
		return (
			<Table
				columns={this.columns}
				sortColumn={sortColumn}
				onSort={onSort}
				data={wastetypes}
			/>
		);
	}
}

export default WasteTypesTable;
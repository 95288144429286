import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Panel, PanelBody } from '../../components/panel/panel.jsx';

import DateTime from 'react-datetime';
import "./c.scss"
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';
import Joi from 'joi';
import Form from '../../common/form.jsx';
import { apiUrl } from '../../config/config.json';
import http from '../../services/httpService';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; // import styles
import { getInternalPost, saveInternalPost } from '../../services/internalposts.js';
import { getAction, saveAction } from '../../services/actions.js';
import { getProfile } from '../../services/authservice.js';
import { getRootcauses } from '../../services/rootcauses.js';
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Handle = Slider.Handle;


class actionReply extends Form {
    constructor(props) {
        super(props);

        var maxYesterday = '';
        var minYesterday = DateTime.moment().subtract(1, 'day');

        this.minDateRange = (current) => {
            return current.isAfter(minYesterday);
        };
        this.maxDateRange = (current) => {
            return current.isAfter(maxYesterday);
        };
        this.minDateChange = (value) => {
            this.setState({
                maxDateDisabled: false
            });
            maxYesterday = value;
        };

        this.state = {
            maxDateDisabled: true,
            profiles: [],
            rootcauses: [],
            action:"",

            data: {
                message: '',
               // parentId: '',
                rootcause : '',
                kaizenNo : '' ,
                status: '',
            },
            

            selectedFile: null,
            errors: {}
        }

        this.statusOptions = [
			{value: 'active', label: 'Active'},
			{value: 'locked', label: 'Locked'},
		];
        this.numberOptions = [1,2,3,4,5,6,7,8,9,10];
        this.handleSlider = (props) => {
            const { value, dragging, index, ...restProps } = props;
            return (
                <Tooltip
                    prefixCls="rc-slider-tooltip"
                    overlay={value}
                    visible={dragging}
                    placement="top"
                    key={index}
                >
                    <Handle value={value} {...restProps} />
                </Tooltip>
            );
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }
    
    async populateStatus(){
		this.statusoptions = this.statusOptions.map(option => (
			<option key={option.label} value={option.value}>
				{option.value}
			</option>
		));
	}
    async populateEfficiencyImplementation(){
		this.numberoptions = this.numberOptions.map(option => (
			<option key={option} value={option}>
				{option}
			</option>
		));
	}
	async populateRootCauses(){
        const { data: rootcauses } = await getRootcauses();
 console.log(rootcauses)
		
		this.setState({ rootcauses });
		this.selectedRootOptions = rootcauses.map(option => (
			<option key={option._id} value={option._id}>
				{option.name}
			</option>
		));
	}

    async populateAction() {
		try {
			const skillId = this.props.match.params.idaction;
		console.log(skillId)

			const { data: action } = await getAction(skillId);
console.log(this.mapToViewModel(action))

			this.setState({ data: this.mapToViewModel(action) });

console.log(this.state.data)

		} catch (ex) {
			if (ex.response && ex.response.status === 404)
				this.props.history.replace("/error");
		}
	}
    async componentDidMount() {
        await this.populateStatus()
        await this.populateEfficiencyImplementation()
        await this.populateRootCauses()
      if(this.props.edit){
        let data = {}
        data._id=this.props.action._id

         data.userID = this.props.action.userID._id;
        if(this.props.action?.parentId) data.parentId = this.props.action?.parentId
        data.kaizenNo=this.props.action.kaizenNo._id
        data.efficiency=this.props.action.efficiency
        data.implementation=this.props.action.implementation
        data.status=this.props.action.status
        data.message=this.props.action.message

        this.setState({ data  });

      }
      else{ 
        const user = await getProfile();
        if(this.props.new)
        {
            const data = { ...this.state.data };
            data.userID = user._id;
            data.kaizenNo=this.props.action
            this.setState({ data });
        }
        else
        { 
        const data = { ...this.state.data };
        data.userID = user._id;
        data.parentId = this.props.action._id
        data.kaizenNo=this.props.action.kaizenNo._id
        this.setState({ data });
    }
    }
    }

    schema = Joi.object({
        message: Joi.string(),
      //  parentId: Joi.any().required(),
        //createdAt: Joi.string().optional(),
        status: Joi.any().required(),
    });


    doSubmit = async () => {
        try {
            console.log(this.state.data)
            await saveAction(this.state.data);
    this.props.submitReplyForm()
     this.props.populateKaizen()
        } catch (ex) {
            //if(ex.response && ex.response.status === 404){
            if (ex.response) {
                const errors = { ...this.state.errors };
                errors.Postname = ex.response.data;
                this.setState({ errors });
            }
        }
    };


    mapToViewModel(Post) {
        return {
            _id: Post._id,
            message: Post.message,
            status: Post.status,
            userID:Post.userID._id,
            efficiency: Post.efficiency,
            implementation: Post.implementation,
            rootcause: Post.rootcause._id,
            kaizenNo:Post.kaizenNo,
            parentId:Post.parentId
        };
    }


    render() {
        const { data, errors } = this.state;
        return (
            <React.Fragment>
                <div>
                  
                    <div className="row">
                        <div className="col-xl-10">
                            <div  className="style-panel"  >
                                <PanelBody className="panel-form">
                                    <form className="form-horizontal form-bordered" onSubmit={this.handleSubmit} >
{ this.props.quote && <p style={{fontSize:"16px",marginTop:"10px" , marginBottom:"10px" , marginLeft:"20px" }} >Quoting <span style={{fontWeight:"bold" }} >"{this.props.action.message}"</span></p>}
 {this.props.reply && <p style={{fontSize:"16px",marginTop:"10px" , marginBottom:"10px" , marginLeft:"20px" }} >Replying to <span style={{fontWeight:"bold" }} >{this.props.action.message}</span></p>}
                                        <ReactSummernote
                                            options={{
                                                lang: 'ru-RU',
                                                height: 350,
                                                dialogsInBody: true,
                                                toolbar: [
                                                    ['style', ['style']],
                                                    ['font', ['bold', 'underline', 'clear']],
                                                    ['fontname', ['fontname']],
                                                    ['para', ['ul', 'ol', 'paragraph']],
                                                    ['table', ['table']],
                                                    ['insert', ['link', 'picture', 'video']],
                                                    ['view', ['fullscreen', 'codeview']]
                                                ]
                                            }}

                                             value={this.props.edit? this.state.data.message:""}

                                            onChange={
                                                (e) => {
                                                    console.log(e)
                                                    let a = e.split(">")[1]
                                                    let data = this.state.data
                                                    data.message = a.split("<")[0]
                                                    this.setState({ data: data })
                                                }
                                            }
                                        />
                      {this.renderInput("efficiency","Efficiency","number","Enter efficiency")} 
					  {this.renderInput("implementation","Implementation","number","Enter implementation")}     
                                      
{/*                                           	<div className="form-group row">
												<label className="col-lg-4 col-form-label" htmlFor="category" >Select status</label>
											<div className="col-lg-8">
												<select name="status" id="status" value={data.status} onChange={this.handleChange} className="form-control" >
													<option value="">Select status</option>
													{this.statusoptions}
												</select>
											</div>
											{errors.status && (<div className="alert alert-danger">{errors.status}</div>)}
											</div> */}
                                        	<div className="form-group row">
											<label className="col-lg-4 col-form-label" htmlFor="category" >Select root cause</label>
											<div className="col-lg-8">
												<select name="rootcause" id="rootcause" value={data.rootcause} onChange={this.handleChange} className="form-control" >
													<option value="">Select Root-Cause</option>
													{this.selectedRootOptions}
												</select>
											</div>
											{errors.status && (<div className="alert alert-danger">{errors.status}</div>)}
	</div>
                                        <div className="form-group row">
                                            <div className="col-lg-8">
                                                <button  type="submit" disabled={this.validate} className="btn btn-primary width-65">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </PanelBody>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(actionReply);
import react, { useState, useEffect } from "react";
import options from "../options"
import { getAcupunctures } from "../../../../services/acupunctures";
import { apiUrl } from "../../../../config/config.json";
import http from "../../../../services/httpService";
import Select from "react-select"

export const TCMTreatment = ({ addRemedy, removeRemedy, handleRemedyChange, handleRemedyChange2, medicalInput, editMode, changeInput, addacuPoint, removeacuPoint, removeRemedy2, addRemedy2, handleMultipleSelect, acuPoint, materiaMedic, materiaMedics, herbal, handleSingleSelect, handleAcuCombinationChange }) => {

	const [acuOptions, setAcuOptions] = useState([])
	const [herbalOptions, setherbalOptions] = useState([])

	console.log(medicalInput, 'medicalInput')
	const actionStyle = {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-evenly"
	}

	const headingStyle = {
		margin: "30px 0 ",
		marginLeft: "20px",
		fontFamily: "Manrope, sans-serif",
		fontSize: "2.6em",
		color: "rgb(43, 159, 193)",
		letterSpacing: "1.3px"

	}

	const headingContainer = {

		width: "100%",
		textAlign: "center"

	}
	const disableStyle = {
		cursor: "not-allowed"
	}


	const getAcuPoints = async () => {
		const res = await getAcupunctures();
		let data = res.data;
		data = data.map((item) => {
			if (data.includes(item.name)) {
				return;
			}
			return { value: item.name, label: item.name };
		})
		setAcuOptions(data);
	}

	const getherbalOptions = () => {
		http.get(`${apiUrl}/formulas`).then((res) => {
			console.log("res",res)
			let data = res.data;
			data = data.map((item) => {
				if (data.includes(item.name)) {
					return;
				}
				return { value: item.name, label: item.name };
			})
			setherbalOptions(data);

		})
	}


	useEffect(() => {
		getAcuPoints();
		getherbalOptions();
	}, []);


	console.log(acuOptions)
	console.log(herbalOptions)
	return (
		<div className="main-container">
			<div style={headingContainer}>


				<h3 style={headingStyle} className="mt-10">TCM Treatment</h3>
			</div>
			<div className="panel-body">
				<legend className="legend-text">Differentiation</legend>
				<div className="table-responsive">
					<div className="form-group">
						<label scope="row">TCM Diagnosis</label>

						<textarea
							disabled={editMode}
							rows="1" className="form-control w-75" name="tcmDiagnosis" value={medicalInput.tcmDiagnosis}
							onChange={changeInput} placeholder="Your TCM Diagnosis for the patient..." />

					</div>
					<div className="form-group">
						<label scope="row">Principle Treatment </label>
						<textarea
							disabled={editMode}
							rows="1" className="form-control w-75" name="principleTreatment" placeholder="Your treatment for the patient..."
							value={medicalInput.principleTreatment} onChange={changeInput} readOnly={editMode} />

					</div>
					{/* <div className="form-group">
						<label>
							<b>Note&nbsp;:</b>
						</label>
						<textarea
							disabled={editMode}
							rows="10" className="form-control w-75" name="acutreatmentNote" placeholder="Note for treatment for the patient..." 
							value={medicalInput.acutreatmentNote} onChange={changeInput}/>
					</div> */}

				</div>
			</div>
			{/* <div className="form-group">
					<label scope="row">TDP note</label>

					<textarea
						disabled={editMode}
						rows="4" className="form-control w-75" name="TPDNote" placeholder="Type in notes for TDP..."  value={medicalInput.TPDNote}
						onChange={changeInput}
					/>
				</div> */}

			<div>
				<div className="panel-body">
					<fieldset>
						<legend className="legend-text">Acu-point combination</legend>
						<div className="row">
							<div className="col-12 col-sm-9">
								<div className="d-flex flex-wrap justify-content-between">
									<div className="form-group">
										<button type="button" style={!editMode ? disableStyle : null} class="btn btn-primary btn-sm" disabled={editMode}
											onClick={addacuPoint}
										>
											Add Acu-point
										</button>
									</div>
									{/* <div className="form-group">
										<button type="button" class="btn btn-purple btn-sm" onClick={this.previousTab}>
											Click for suggested remedy from the Site
										</button>
									</div> */}
								</div>
							</div>
						</div>
						{medicalInput.acuCombination?.map((item, index) => (
							<div className="row" key={index}>

								<div className="col-12 col-sm-3">
									<label>
										<b>Acu-point</b>
									</label>
									<div className="form-group">
										<Select
											isDisabled={editMode}

											options={acuOptions}
											placeholder={"Select Acupoint..."}
											value={
												item.acuPoints && {
													value: item.acuPoints,
													label: item.acuPoints,
												}
											}
											onChange={(e) => handleAcuCombinationChange("acuPoints", e.value, index)}
										/>
									</div>
								</div>
								{/*
								<div className="col-12 col-sm-2">
									<label>
										<b>Moxibustion</b>
									</label>
									<div className="form-group w-75" >
										<Select
											isDisabled={editMode}
											
											options={options.moxibustion}
											placeholder={"Yes/No"}
											value={
												item.Moxibustion && {
													value: item.Moxibustion,
													label: item.Moxibustion,
												}
											}
											onChange={(e) => handleAcuCombinationChange("Moxibustion", e.value, index)}
										/>
									</div>
								</div>
										*/}

								<div className="col-12 col-sm-2">
									<label>
										<b>NeedleManipulation</b>
									</label>
									<div className="form-group">
										<Select
											isDisabled={editMode}

											options={options.needleManipulationOptions}
											placeholder={"needleManipulation..."}
											value={
												item.needleManipulation && {
													value: item.needleManipulation,
													label: item.needleManipulation,
												}
											}
											onChange={(e) => handleAcuCombinationChange("needleManipulation", e.value, index)}
										/>
									</div>
								</div>

								<div className="col-12 col-sm-2">
									<label>
										<b>StimulationMethod</b>
									</label>
									<div className="form-group">
										<Select
											isDisabled={editMode}

											options={options.stimulationMethodOptions}
											placeholder={"stimulationMethod..."}
											value={
												item.stimulationMethod && {
													value: item.stimulationMethod,
													label: item.stimulationMethod,
												}
											}
											onChange={(e) => handleAcuCombinationChange("stimulationMethod", e.value, index)}
										/>
									</div>
								</div>

								<div className="col-12 col-sm-2">
									<label>
										<b>StimulationDuration</b>
									</label>
									<div className="form-group">
										<input
											type="number"
											disabled={editMode}
											className="form-control w-75"
											name="stimulationDuration"

											placeholder="stimulationDuration..."
											value={item.stimulationDuration}
											onChange={(e) => handleAcuCombinationChange("stimulationDuration", e.target.value, index)}
										/>
									</div>
								</div>
								{index > 0 && (
									<div className="col-6 col-sm-2">
										<div className="form-group">
											<label style={{ marginRight: "10px" }}>
												<b>Remove</b>
											</label>
											<button
												className="btn btn-danger btn-icon btn-circle btn-lg"
												onClick={() => removeacuPoint(index)}
												disabled={editMode}
											>
												<i className="fa fa-trash"></i>
											</button>
										</div>
									</div>
								)}
							</div>
						))}

						<div className="form-group">
							<label>
								<b>Note for Acu-treatment</b>
							</label>
							<textarea
								disabled={editMode}
								rows="10"
								className="form-control w-75"
								name="acuTreatmentNote"
								placeholder="Note for treatment for the patient..."
								value={medicalInput.acuTreatmentNote}
								onChange={changeInput}
							/>
						</div>
					</fieldset>
				</div>
				{/* <div className="form-group">
					<button type="button" style={!editMode ? disableStyle : null} class="btn btn-primary btn-sm" disabled={editMode} onClick={addRemedy}>
						Add Materia Medica to Herbal Formula
					</button>
				</div> */}

				{medicalInput.remedy?.map((item, index) => (
					< div className="row" key={index} >
						<div className="col-12 col-sm-1">
							<label>
								<b>Materia MEdica</b>
							</label>
						</div>
						<div className="col-12 col-sm-4">
							<div className="form-group">

								<Select
									isDisabled={editMode}
									options={options.remedy}
									placeholder={"Select Remedy"}
									value={
										item.materiaMedica && {
											value: item.materiaMedica,
											label: item.materiaMedica,
										}
									}
									onChange={(e) => handleRemedyChange("materiaMedica", e.value, index)}
								/>
							</div>
						</div>
						<div className="col-12 col-sm-1">
							<label>
								<b>Potency</b>
							</label>
						</div>
						<div className="col-12 col-sm-4">
							<div className="form-group">

								<Select
									isDisabled={editMode}
									options={options.potency}
									placeholder={"Select Potency..."}
									value={
										item.potency && {
											value: item.potency,
											label: item.potency,
										}
									}
									onChange={(e) => handleRemedyChange("potency", e.value, index)}
								/>
							</div>
						</div>

						<div className="col-6 col-sm-2">
							<div style={actionStyle} className="form-group">
								<label>
									<b>Remove</b>
								</label>
								<button disabled={editMode}
									style={!editMode ? disableStyle : null}
									className="btn btn-danger btn-icon btn-circle btn-md cursor-pointer"
									onClick={() => removeRemedy(index)}	>
									<i className="fa fa-trash"></i>
								</button>
							</div>
						</div>
					</div>
				))}
				<div className="panel-body">
					<fieldset>
						<legend className="legend-text">Herbal Treatment</legend>
						<legend className="legend-text">Herbal Formula 1</legend>
						<div className="row">
							<div className="col-12 col-sm-9">
								<div className="d-flex flex-wrap justify-content-between">
									{/* <div className="form-group">
										<button type="button" class="btn btn-primary btn-sm" onClick={addherbalFormula1}>
											Select Herbal Formula
										</button>
									</div> */}
									<div className="form-group">
										{/* <button type="button" class="btn btn-purple btn-sm" onClick={this.previousTab}>
											Click for suggested remedy from the Site
										</button> */}
									</div>
								</div>
							</div>
							<div className="col-12 col-sm-4">
								<div className="form-group">
									<Select
										isDisabled={editMode}

										options={herbalOptions}
										placeholder={"Select Herbal..."}
										value={
											medicalInput.herbalFormula1 && {
												value: medicalInput.herbalFormula1,
												label: medicalInput.herbalFormula1,
											}
										}

									onChange={(e) => handleSingleSelect("herbalFormula1", e.value)}
									/>
								</div>
							</div>


						</div>

						<div className="row">
							<div className="col-12 col-sm-9">
								<div className="d-flex flex-wrap justify-content-between">
									<div className="form-group">
										<button type="button" style={!editMode ? disableStyle : null} class="btn btn-primary btn-sm" disabled={editMode} onClick={addRemedy}>
											Add Materia Medica to Herbal Formula 1
										</button>
									</div>
									<div className="form-group">
										{/* <button type="button" class="btn btn-purple btn-sm" onClick={this.previousTab}>
											Click for suggested remedy from the Site
										</button> */}
									</div>
								</div>
							</div>
						</div>
						{medicalInput.materiaMedica1?.map((item, index) => (
							<div className="row" key={index}>

								<div className="col-9 col-sm-4">
									<div className="form-group">
										<label>
											<b>Materia Medica</b>
										</label>
										<Select
											isDisabled={editMode}

											options={materiaMedic}
											// materiaMedic
											placeholder={"Select Remedy..."}
											value={
												item.materiamedica1 && {
													value: item.materiamedica1,
													label: item.materiamedica1,
												}
											}
											onChange={(e) => handleRemedyChange("materiamedica1", e.value, index)}
										/>
									</div>
								</div>

								<div className="col-12 col-sm-2">
									<label>
										<b>Dosage</b>
									</label>
									<div className="form-group">
										<input
											type="number"
											disabled={editMode}
											className="form-control w-75"
											name="dosage1"

											placeholder="dosage..."
											value={item.dosage1}
											onChange={(e) => handleRemedyChange("dosage1", e.target.value, index)}
										/>
									</div>
								</div>

								<div className="col-4 col-sm-4">
									<div className="form-group">
										<label>
											<b>Unit</b>
										</label>
										<Select
											isDisabled={editMode}

											options={options.unit}
											placeholder={"Select Unit..."}
											value={
												item.unit1 && {
													value: item.unit1,
													label: item.unit1,
												}
											}
											onChange={(e) => handleRemedyChange("unit1", e.value, index)}
										/>
									</div>
								</div>
								{index > 0 && (
									<div className="col-6 col-sm-2">
										<div className="form-group">
											<label>
												<b>Remove</b>
											</label>
											<button
												className="btn btn-danger btn-icon btn-circle btn-lg"
												onClick={() => removeRemedy(index)}
											>
												<i className="fa fa-trash"></i>
											</button>
										</div>
									</div>
								)}
							</div>
						))}

						<div className="col-12 col-sm-4">
							<div className="form-group">
							</div>
						</div>

						<legend className="legend-text">Herbal Formula 2</legend>
						<div className="row">
							<div className="col-12 col-sm-9">
								<div className="d-flex flex-wrap justify-content-between">
									{/* <div className="form-group">
										<button type="button" class="btn btn-primary btn-sm" onClick={addherbalFormula1}>
											Select Herbal Formula
										</button>
									</div> */}
									<div className="form-group">
										{/* <button type="button" class="btn btn-purple btn-sm" onClick={this.previousTab}>
											Click for suggested remedy from the Site
										</button> */}
									</div>
								</div>
							</div>
							<div className="col-12 col-sm-4">
								<div className="form-group">
									<Select
										isDisabled={editMode}
										name="herbalFormula2"
										options={herbalOptions}
										placeholder={"Select Herbal..."}
										value={
											medicalInput.herbalFormula1 && {
												value: medicalInput.herbalFormula2,
												label: medicalInput.herbalFormula2,
											}
										}

										onChange={(e) => handleSingleSelect("herbalFormula2", e.value)}
									/>
								</div>
							</div>


						</div>

						<div className="row">
							<div className="col-12 col-sm-9">
								<div className="d-flex flex-wrap justify-content-between">
									<div className="form-group">
										<button type="button" style={!editMode ? disableStyle : null} class="btn btn-primary btn-sm" disabled={editMode}
											onClick={addRemedy2}
										>
											Add Materia Medica to Herbal Formula 2
										</button>
									</div>
									<div className="form-group">
										{/* <button type="button" class="btn btn-purple btn-sm" onClick={this.previousTab}>
											Click for suggested remedy from the Site
										</button> */}
									</div>
								</div>
							</div>
						</div>
						{medicalInput.materiaMedica2?.map((item, index) => (
							<div className="row" key={index}>

								<div className="col-8 col-sm-4">
									<div className="form-group">
										<label>
											<b>Materia Medica</b>
										</label>

										<Select
											isDisabled={editMode}

											options={materiaMedic}
											placeholder={"Select Remedy..."}
											value={
												item.materiamedica2 && {
													value: item.materiamedica2,
													label: item.materiamedica2,
												}
											}
											onChange={(e) => handleRemedyChange2("materiamedica2", e.value, index)}
										/>
									</div>
								</div>

								<div className="col-12 col-sm-2">
									<label>
										<b>Dosage</b>
									</label>
									<div className="form-group">
										<input
											type="number"
											disabled={editMode}
											className="form-control w-75"
											name="dosage2"

											placeholder="dosage..."
											value={item.dosage2}
											onChange={(e) => handleRemedyChange2("dosage2", e.target.value, index)}
										/>
									</div>
								</div>

								<div className="col-4 col-sm-4">
									<div className="form-group">
										<label>
											<b>Unit</b>
										</label>
										<Select
											isDisabled={editMode}
											name="unit2"
											options={options.unit}
											placeholder={"Select Unit..."}
											value={
												item.unit2 && {
													value: item.unit2,
													label: item.unit2,
												}
											}
											onChange={(e) => handleRemedyChange2("unit2", e.value, index)}
										/>
									</div>
								</div>
								{index > 0 && (
									<div className="col-6 col-sm-2">
										<div className="form-group">
											<label>
												<b>Remove</b>
											</label>
											<button
												className="btn btn-danger btn-icon btn-circle btn-lg"
												onClick={() => removeRemedy2(index)}
											>
												<i className="fa fa-trash"></i>
											</button>
										</div>
									</div>
								)}
							</div>
						))}

						<div className="row">
							<div className="col-12 col-sm-2">
								<div className="form-group">
									<label>
										<b>TDP</b>
									</label>
									<Select
										isDisabled={editMode}

										options={options.tdp}
										placeholder={" select Yes/No"}
										value={
											medicalInput.TDP && {
												value: medicalInput.TDP,
												label: medicalInput.TDP,
											}
										}

										// onChange={(e) => {handleChange("TDP", e.value)}}
										onChange={(e) => handleSingleSelect("TDP", e.value)}
									/>
								</div>
							</div>
							<div className="col-12 col-sm-9">
								<div className="form-group">
									<label>
										<b>TDPNote</b>
									</label>
									<textarea disabled={editMode} rows="4" className="form-control w-75" name="TDPNote" placeholder="Type in Note for TPD..."
										value={medicalInput.TDPNote}
										onChange={changeInput}
									/>
								</div>
							</div>
						</div>

					</fieldset>
				</div>
				<div className="form-group">
					<label scope="row">Auricular Acupuncture</label>

					<textarea
						disabled={editMode}
						rows="4" className="form-control w-75" name="auricularAcupuncture" placeholder="Type in your notes..." value={medicalInput.auricularAcupuncture}
						onChange={changeInput}
					/>
				</div>
				<div className="form-group">
					<label scope="row">Tuina</label>

					<textarea
						disabled={editMode}
						rows="4" className="form-control w-75" name="tuina" placeholder="Type in notes for tuina..." value={medicalInput.tuina}
						onChange={changeInput}
					/>
				</div>


				<div className="form-group">
					<label scope="row">Diet-therapy</label>

					<textarea
						disabled={editMode}
						rows="4" className="form-control w-75" name="dietTherapy" placeholder="Type in diet for the patient..." value={medicalInput.dietTherapy}
						onChange={changeInput}
					/>
				</div>
				<div className="form-group">
					<label scope="row">Recommendation</label>
					<textarea
						disabled={editMode} rows="6" className="form-control w-75" name="recommendation"
						placeholder="Type in recommendation for the patient..." value={medicalInput.recommendation} onChange={changeInput} />
				</div>

			</div>
		</div >
	);
}

import React,{useState , useEffect} from "react";
//import Priority from "./Priority";
import Category from "./Category";
import Status from "./Status";
import "react-datetime/css/react-datetime.css";
// import DateRangePicker from "./DateRangePicker";
import DateTime from "react-datetime";

import { Modal, ModalHeader, ModalBody, ModalFooter,Progress } from "reactstrap";

import { Panel, PanelBody } from "../../../components/panel/panel.jsx";
import FilePreview from "./FilePreview";
import { ToastContainer } from 'react-toastify';
import { getKaizen , saveKaizen } from "../../../services/kaizens";
import { getWasteTypes } from "../../../services/wastetypes";
import { useParams } from "react-router";
import { apiUrl } from "../../../config/config.json";
import http from "../../../services/httpService";
import Select from "react-select";
export default function BasicInfo({ 
  readOnly,
  setReadOnly,
  wasteTypeOptions,
  statusOptions,
  data,
  loaded,
  onChangeHandler,
  onInputChange,
  onClickHandler,
  selectedFile,
  removeFile,
 }) {
  // const [modalCreatedOn, setModalCreatedOn] = useState(false);
  // const [modalDeadline, setModalDeadline] = useState(false);
  // const toggleModal = (name) => {
  //   switch (name) {
  //     case "modalCreatedOn":
  //       setModalCreatedOn((mco) => !mco);
  //       break;
  //     case "modalDeadline":
  //       setModalDeadline((mdl) => !mdl);
  //       break;
  //     default:
  //       break;
  //   }
  // };


  const [addAttachements, setAddAttachements] = useState(false);
  const { id : kaizenId } = useParams();
  let [ wasteTypes , setWasteTypes ] = useState([])
  let [ kaizen ,setKaizen ] = useState(

    { user: "",
      kaizenNo: "",
      name: "",
      motto : "" , 
  
      participants: [],
      businessNo: "",
      department: "",
      subDepartment: "",
      wasteType : "" , 
      sponsor : [] , 
      facilitator: [] ,
      section : "" , 
      documentNo : "" , 
      actions : "" , 
      reference : " ", 
      deadline : new Date() , 
      plannedDate : new Date() ,
      createdOn : new Date() , 
      locations: "",
      narrative: "",
      field: "",
      tags: "",
      note: "",
      status: "active",
    }
    )

  let populateKaizen = async () => {
    console.log("populating kaizen")
    try {

      let { data } = await getKaizen(kaizenId);
      console.log("fetched >>>>> " , data)
      setKaizen( {...data} ) 
      console.log("update kaizen : " , kaizen  )

      const { data : wasteType } = await getWasteTypes()
      console.log("fetched wastetype :" , wasteType)
      setWasteTypes( wasteType.map( waste => {
        return {
          _id: waste._id,
          label: waste.name,
          value: waste._id,
        }
      }) )
      
    } catch (ex) {
      console.error(ex)
      if (ex.response && ex.response.status === 404)
      console.error( ex )
    }
  }
  
  let [users , setUsers] = useState( [] ) 
  
  let populateUsers = async () => {
    const { data: users } = await http.get(apiUrl + "/users")
    
    setUsers (users.map((user) => ({
      _id: user._id,
      label: user.username,
      value: user._id,
      user : user
    })))
  }

  let statusOption = [
    { value: "in-planning", label: "In-Planning" },
    { value: "doing", label: "Doing" },	  
    { value: "checking", label: "checking" },	  	  
    { value: "acting", label: "Acting" },	  	  
    { value: "active", label: "Active" },
    { value: "pending", label: "Pending" },
    { value: "archive", label: "Archive" },
  ];
  
  let handleChange = ({target:input}) => {
    const data = { ...kaizen };
    data[input.name] = input.value;
    setKaizen( data );
  };
  
  let handleMultiChange = (name, options) => {
    const data = { ...kaizen };
    console.log("value", options);
    data[name] = options/*.map((o) => o.value)*/;
    console.log(
      "options",
      options.map((o) => o.value)
      );
      setKaizen( {...data });
    };
    
/*  let handleSelectChange = ( { _id } , field ) => {
    console.log("SELECT ONCHANGE : " , _id)
    setKaizen({  ...kaizen , [field] : _id })
  }*/
  let handleDateChange = (date , field) => {
    setKaizen({ ...kaizen ,  [field] : date })
  }

  let formatSelectOption = ( {user} ) => (
    <div>
      <div style={{ display: "flex" , alignItems:"center"}}>
        <div style={{ marginRight: "10px",}}>
          <img width={15} src={user.imageSrc} alt="img here" />
        </div>
        <div>{user.contactName.first + " " + user.contactName.last } </div>
      </div>
    </div>
  );
  
  let handleSubmit = async () => {
    console.log( "submitting the following : " , kaizen )
    
    try{
      
      let data = {...kaizen}
      //delete data.user
      delete data.award
      delete data.__v
      await saveKaizen(data)
      
    }
    catch( err )
    {
      console.error( err )
    }
    
  }

  useEffect( async () => {
    await populateUsers() 
    await populateKaizen()
  } , [])
  
  console.log( "state :" , kaizen)
  /*let sponsorIDs = kaizen.sponsor?.map( el => el._id)
  console.log("sponsor ids : " , sponsorIDs)*/

  return (
    <>
      <div className="row">
        <div className="col-8">
          <Panel>
            <PanelBody>
              <h3 className="m-t-10">Basic information</h3>
              <div className="form-group row m-b-15">
                <label className="col-sm-3 col-form-label">Owner</label>
                <div className="col-sm-9" style={{"position" : "relative"}}>
                  <img src={kaizen?.user?.imageSrc} width= {18} style={{"position" : "absolute" , "top" : "8px"  , "left" : "20px"}} alt="" />
                  <input
                    className="form-control d-inline" 
                    style={{ "padding-left" : "35px" }}
                    type="text"
                    placeholder="Select owner"
                    name="username"
                    readOnly={readOnly}
                    value={kaizen?.user?.contactName?.first}
                  />
                </div>
              </div>
              <div className="form-group row m-b-15">
                <label className="col-sm-3 col-form-label">Participants</label>
                <div className="col-sm-9">
                <Select
                  isMulti
                  isDisabled = {readOnly}
                  name="participants"
                  options={users}
                  formatOptionLabel={formatSelectOption}
                  placeholder={"Select Participants..."}
                  value={users?.filter((opt) =>
                    {
                      let participantsIDs = kaizen.participants?.map( el => el._id)
                      return participantsIDs?.includes(opt.value)
                    })}
                  onChange={(e) =>
                    handleMultiChange("participants", e)
                  }
                />
                </div>
              </div>
              <div className="form-group row m-b-15">
                <label className="col-sm-3 col-form-label">Waste-Type</label>
                <div className="col-sm-9">
                  {/*<Category
                    options={wasteTypes}
                    name="wasteType"
                    selectedValue={wasteTypes.find(waste => waste.label == kaizen.wasteType)}
                    onChange={(e)=>  setKaizen( { ...kaizen , wasteType : e.label })}
                />*/}
                <Select 
                  isDisabled = {readOnly}
                  id="wasteType" 
                  value={wasteTypes?.filter((opt) => kaizen.wasteType === opt.label)} options = {wasteTypes} 
                  onChange={(e) => setKaizen( { ...kaizen , wasteType : e.label})}  
                />
                </div>
              </div>
              <div className="form-group row m-b-15">
                <label className="col-sm-3 col-form-label">Status</label>
                <div className="col-sm-9">
                  <Status
                    isDisabled = {readOnly}
                    readOnly = {readOnly}
                    value={{ label : kaizen.status , value : kaizen.status}}
                    name="status"
                    options={statusOption}
                    onChange= { (e) => setKaizen( { ...kaizen , status : e.value }) }
                  />
                </div>
              </div>
              <div className="form-group row m-b-15">
                <label className="col-sm-3 col-form-label">Department</label>
                <div className="col-sm-9">
                  <div className="row">
                    <div className="col-sm-5">
                    <input className="form-control" 
                      type="text"
                      readOnly = {readOnly}
                      name="department"
                      value={kaizen.department}input
                      placeholder="Read only input here"
                      onChange = { (e) => handleChange(e) }
                    />
                    </div>
                    <label className="col-sm-3 col-form-label">
                      Sub-department
                     </label>
                     <div className="col-sm-4">
                      <input
                        className="form-control"
                        name="subDepartment"
                        type="text"
                        readOnly = {readOnly}
                        placeholder="Readonly input here…"
                        onChange= { (e) => handleChange(e) }
                        value={kaizen.subDepartment}
                      />
                     </div>
                  </div>
                </div>
              </div>

              <div className="form-group row m-b-15">
                <label className="col-sm-3 col-form-label">Field</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    name="field"
                    type="text"
                    readOnly = {readOnly}
                    placeholder="Readonly input here…"
                    onChange= { (e) => handleChange(e) }
                    value={kaizen.field}
                  />
                </div>
              </div>
              <div className="form-group row m-b-15">
                <label className="col-sm-3 col-form-label">Tags</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    name="tags"
                    type="text"
                    readOnly = {readOnly}
                    placeholder="Readonly input here…"
                    onChange= { (e) => handleChange(e) }
                    value={kaizen.tags}
                  />
                </div>
              </div>
              <div className="form-group row m-b-15">
                <label className="col-sm-3 col-form-label">Locations</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    name="locations"
                    placeholder="Readonly input here…"
                    readOnly = {readOnly}
                    onChange= { (e) => handleChange(e) }
                    value={kaizen.locations}
                  />
                </div>
              </div>
              <div className="form-group row m-b-15">
                <label className="col-sm-3 col-form-label">Sponsor</label>
                <div className="col-sm-9">
                <Select 
                id="sponsor" 
                isDisabled = {readOnly}
                readOnly = {readOnly}
                /*value={users?.filter((opt) =>
                  {
                    let sponsorIDs = kaizen.sponsor?.map( el => el._id)
                    return sponsorIDs?.includes(opt.value)
                  })}*/
                isMulti
                formatOptionLabel={formatSelectOption}  
                options = {users} 
                onChange={(e) => handleMultiChange("sponsor", e)}  
                />
                </div>
              </div>
              <div className="form-group row m-b-15">
                <label className="col-sm-3 col-form-label">Facilitator</label>
                <div className="col-sm-9">
                <Select 
                readOnly = {readOnly}
                isDisabled = {readOnly}
                id="facilitator" 
                //value={users?.filter((opt) => kaizen.facilitator.find( el => el === opt.value))} 
                value={users?.filter((opt) =>
                  {
                    let facilitatorIDs = kaizen.facilitator?.map( el => el._id)
                    return facilitatorIDs?.includes(opt.value)
                  })}
                isMulti
                formatOptionLabel={formatSelectOption} 
                options = {users} 
                onChange={(e) => handleMultiChange("facilitator", e)}  />
                </div>
              </div>
              <div className="form-group row m-b-15">
                <label className="col-sm-3 col-form-label">Planned On</label>
                <div className="col-sm-9">
                  <div className="row">
                    <div className="col-sm-4">
                      <DateTime
                        // className={` datetime`}

                        initialValue={new Date()}
                        name="createdOn"
                        inputProps={{
                          placeholder: "Datepicker",
                          disabled: true,
                        }}
                        closeOnSelect={true}
                      />
                    </div>
                    <div className="col-sm-3">
                    <label className="col-sm-3 col-form-label">Deadline</label>
                    </div>
                    <div className="col-sm-5">
                      <DateTime
                        name="deadline"
                        inputProps={{
                          placeholder: "Datepicker",
                          disabled : readOnly
                        }}
                        id={"deadline"}
                        value={ new Date( kaizen.deadline ) }
                        closeOnSelect={true}
                        onChange= { (date) => handleDateChange(date._d , "deadline") }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group row m-b-15">
                <label className="col-sm-3 col-form-label">Reference</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    name="reference"
                    type="text"
                    placeholder="Readonly input here…"
                    readOnly = {readOnly}
                    onChange= { (e) => handleChange(e) }
                    value={kaizen.reference}
                  />
                </div>
              </div>

            </PanelBody>
          </Panel>
        </div>
        <div className="col-4">
          <Panel>
            <PanelBody>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/1200px-QR_code_for_mobile_English_Wikipedia.svg.png"
                alt="qr-code"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                  minHeight: "100px",
                  minWidth: "100px",
                  maxHeight: "200px",
                  maxWidth: "200px",
                  padding: "10px",
                }}
              />
            </PanelBody>
          </Panel>
        </div>
      </div>
      <div>
        <Panel>
          <PanelBody>
            <h1>Problem:</h1>
                {readOnly ? (
                  <h3>{data?.narrative}</h3>
                ) : (
                  <textarea
                    value={data?.narrative}
                    rows="7"
                    style={{ width: "inherit" }}
                  ></textarea>
                )}
          </PanelBody>
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label">Note</label>
                                <div className="col-sm-9">
                                    <textarea
                                        disabled={readOnly}
                                        className="form-control"
                                        type="text"
                                        placeholder="Readonly input here…"
                                        // readOnly={readOnly}
                                        value={data.note}
                                        onChange={(e) => {
                                            const note = e.target.value
                                            onInputChange({ note })
                                        }}
                                    />
                                </div>
                            </div>
                            <p className="text-right ml-2 mt-4">
                                <>
                                    <button className="btn btn-danger m-r-5" disabled={readOnly}>
                                        Cancel
                                    </button>
                                    <button className="btn btn-primary" onClick={(e) => {

                                        onClickHandler(e, data)
                                    }}
                                        disabled={readOnly}
                                    >
                                        Submit
                                    </button>
                                </>
                            </p>
		
		  
        </Panel>
      </div>
      <div>
        <Panel>
          <PanelBody>
            <h1>Attachments:</h1>
            <button className="btn btn-success" onClick={() => { setAddAttachements(!addAttachements)}}>Add attachments</button>

     
<Modal isOpen={addAttachements} toggle={() => setAddAttachements(!addAttachements)}>

		<ModalHeader toggle={() => {setAddAttachements(!addAttachements)}}>Upload</ModalHeader>
          			<ModalBody>
        
   
                <div class="row">
      	  <div class="offset-md-3 col-md-6">
               <div class="form-group files">
                <label>Upload Your File </label>
                <input type="file" name="attachments" class="form-control" multiple onChange={onChangeHandler}/>
              </div>  
              <div class="form-group">
              <ToastContainer />
              <Progress max="100" color="success" value={loaded} >{Math.round(loaded,2) }%</Progress>
        
              </div> 
              
              {/* <button type="button" class="btn btn-success btn-block" onClick={onClickHandler}>Upload</button> */}

	      </div>
      </div>




								</ModalBody>
								<ModalFooter>
									<button className="btn btn-red" title="Cancel" onClick={() => { setAddAttachements(!addAttachements)}}><i className="ion md-close"></i>Cancel</button>								
									 <button className="btn btn-green" title="Save the changes" type="submit" onClick={onClickHandler}><i className="far fa-save"></i></button> 
								</ModalFooter>
							
</Modal>




{loaded=="100"?<FilePreview selectedFile={selectedFile} removeFile={removeFile}/>:""}
          </PanelBody>
        </Panel>
      </div>
    </>
  );
}
import React, { useState, useEffect } from "react";
import PreviousSessionsContainer from "./previoussessions/PreviousSessionsContainer";
import { apiUrl } from "../../../config/config.json";
import http from "../../../services/httpService";

function PreviousSessions({ selectedPatient, fetchAgain, setfetchAgain }) {

	const [data, setData] = useState([]);
	const [editMode, setEditMode] = useState(true)

	const getPrevSessions = () => {
		http.get(`${apiUrl}/homeopathysessions`).then((res) => {


			let someData = res.data.filter((file, index) => index !== 0)

			setData(
				someData.map((item) => {
					return {
						...item,
						isVisible: true,
					};
				})
			);
		});
	};


	const viewSession = (id) => {
		setData(
			data.map((item) => {
				if (id === item._id) {
					return item;
				} else {
					return { ...item, isVisible: !item.isVisible };
				}
			})
		);
	};

	useEffect(() => {
		getPrevSessions();
	}, []);





	return (
		<div className="table-responsive mt-4"  >
			<table className="table">
				<thead>
					<tr>
						<th>Date/Time</th>
						<th>Complaint</th>
						<th>Session</th>
						<th>Avatar</th>
						<th>Practitioner</th>
						<th>Avatar</th>
						<th>Clinic</th>
						<th>Edit</th>
						<th>View Record</th>
					</tr>
				</thead>
				<tbody>
					{data &&
						data?.map((session, index) => {
							let date = new Date(session.createdOn);
							let d = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

							return (
								session.patientNo?._id === selectedPatient &&
								session.isVisible && (
									<>
										<tr>
											<td>
												{d} {date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()}
											</td>
											<td>{session.chiefComplaint}</td>
											<td>Intake</td>
											<td>
												<img src={session.doctorNo?.user?.imageSrc} style={{ height: "30px", width: "30px", borderRadius: "50%" }} />
											</td>
											<td>{session.doctorNo?.user?.contactName?.last}</td>
											<td>
												<img src={session.clinicNo?.user?.imageSrc} style={{ height: "30px", width: "30px", borderRadius: "50%" }} />
											</td>
											<td>{session.clinicNo?.user?.contactName?.last}</td>
											<td>
												<a onClick={() => setEditMode(editMode)}>
													Edit
												</a>
											</td>
											<td>
												<a
													data-toggle="collapse"
													data-target={`#multiCollapseExample1${index}`}
													role="button"
													aria-expanded="false"
													aria-controls={`multiCollapseExample1${index}`}
													onClick={() => viewSession(session._id)}
													style={{ color: "blue" }}
												>
													View
												</a>
											</td>
										</tr>
										<tr>
											<td colSpan={12}>
												<div className="collapse multi-collapse" id={`multiCollapseExample1${index}`}>
													<PreviousSessionsContainer setfetchAgain={setfetchAgain} fetchAgain={fetchAgain} key={"prevSession"} editMode={editMode} sessionData={session} setEditMode={setEditMode} />
												</div>
											</td>
										</tr>
									</>
								)
							);
						})}
				</tbody>
			</table>
		</div>
	);
}

export default PreviousSessions;

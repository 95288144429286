
import { MedicalHistory } from "./MedicalHistory";
import Interview from "./Interview";
import InspectionAndExamination from "./InspectionAndExamination";
import { HomeoTreatment } from "./HomeoTreatment";
import { saveHomeosession } from "../../../../services/homeopathysessions";
import { Personal } from "./Personal"
import React, { useState, useEffect } from 'react';
import { Box } from "@mui/material"
import 'react-tabs/style/react-tabs.css';
import "../style.css"
import {
	Panel,
	PanelHeader,
	PanelBody,
} from "../../../../components/panel/panel.jsx"
import ReusableTabNavs from "../../../../common/ReusableTabNavs";
import ReusableTab from "../../../../common/ReusableTab";
import { TabContent } from "reactstrap";



const PreviousSessionsContainer = React.memo(props => {
	const { editMode, setEditMode, sessionData, setfetchAgain, fetchAgain } = props;
	const [medicalInput, setmedicalInput] = useState({});


	useEffect(() => {
		setmedicalInput(mapToViewModel(sessionData))
	}, [sessionData])



	function mapToViewModel(homeosession) {
		return {
			_id: homeosession?._id,
			selectedRegisteredPatient: homeosession?.patientNo?._id,
			clinicNo: homeosession?.clinicNo?._id,
			doctorNo: homeosession?.doctorNo?._id,
			chiefComplaint: homeosession?.chiefComplaint,
			symptoms: homeosession?.symptoms,
			westernDisease: homeosession?.WesternDisease,
			currentTreatment: homeosession?.currentTreatment.map((item) => item),
			diseases: homeosession?.diseasesIllnesses,
			surgeries: homeosession?.surgeries,
			medicaments: homeosession?.medicamentsSupplements,
			allergies: homeosession?.allergies,
			pregnancies: homeosession?.pregnancies,
			familyMembers: homeosession?.familyHistory.map((item) => item),
			noteMedicalHistory: homeosession?.medicalHistoryNote,
			socialRelationship: homeosession?.socialRelationship,
			habits: homeosession?.habits.map((item) => item),
			profession: homeosession?.occupation.name,
			employmentStatus: homeosession?.occupation?.state?.map((item) => item),
			sport: homeosession?.sport.name.map((item) => item),
			sportFrequency: homeosession?.sport.frequency.map((item) => item),
			hobby: homeosession?.hobbies,
			height: homeosession?.height,
			heightUnit: homeosession?.heightUnit,
			weight: homeosession?.weight,
			weightUnit: homeosession?.weightUnit,
			bmi: homeosession?.bmi,
			bmiClassification: homeosession?.bmiClassification,
			temperature: homeosession?.temperature,
			temperatureUnit: homeosession?.temperatureUnit,
			thermalFeeling: homeosession?.thermalFeeling.map((item) => item),
			perspiration: homeosession?.perspiration.map((item) => item),
			appetite: homeosession?.appetite.map((item) => item),
			appetiteNote: homeosession?.appetiteNote,
			vomiting: homeosession?.vomiting.map((item) => item),
			vomitingNote: homeosession?.vomitingNote,
			diet: homeosession?.diet.map((item) => item),
			dietNote: homeosession?.dietNote,
			taste: homeosession?.taste.map((item) => item),
			thirst: homeosession?.thirst.map((item) => item),
			defecation: homeosession?.defecation.map((item) => item),
			urination: homeosession?.urination.map((item) => item),
			urinationColor: homeosession?.urineColor,
			sleep: homeosession?.sleeping.map((item) => item),
			head: homeosession?.head.map((item) => item),
			eyes: homeosession?.eyes.map((item) => item),
			ear: homeosession?.ear.map((item) => item),
			nose: homeosession?.nose.map((item) => item),
			throat: homeosession?.throat.map((item) => item),
			menstruation: homeosession?.menstruationHistory.map((item) => item),
			leukorrhea: homeosession?.leukorrhea.map((item) => item),
			localityOfPain: homeosession?.painLocation,
			natureOfPain: homeosession?.painNature,
			emotion: homeosession?.emotional?.status?.map((item) => item),
			noteEmotion: homeosession?.emotional?.note,
			mind: homeosession?.mind,
			interviewNote: homeosession?.interviewNote,
			respiration: homeosession?.respiration.map((item) => item),
			speech: homeosession?.speech.map((item) => item),
			cough: homeosession?.cough.map((item) => item),
			odor: homeosession?.odor.map((item) => item),
			vitality: homeosession?.vitality.map((item) => item),
			colorFace: homeosession?.faceColorLustre.map((item) => item),
			physicalAppearance: homeosession?.physical.appearance.map((item) => item),
			physicalAppearanceNote: homeosession?.physicalExaminationNote,
			epigastriumPalpation: homeosession?.physical.palpationEpigastrium.map((item) => item),
			epigastriumPalpationNote: homeosession?.physical.palpationEpigastriumNote,
			abdomenPalpation: homeosession?.physical.palpationAbdomen,
			motionRange: homeosession?.rangeMotion,
			painGradations: homeosession?.painLevel,
			physicalExaminationNote: homeosession?.physicalExaminationNote,
			homeoDiagnosis: homeosession?.homeoDiagnosis,
			principleTreatment: homeosession?.principleTreatment,
			acutreatmentNote: homeosession?.treatmentNote,
			remedy: homeosession?.remedy.map((item) => item),
			dietTherapy: homeosession?.dietTherapy,
			recommendation: homeosession?.recommendation,
		};
	}
	// handles the input datas 
	const changeInput = (e) => {
		setmedicalInput((prev) => {
			return {
				...prev, [e.target.name]: e.target.value
			}
		})
	};

	// handle the single data selection 
	const handleSingleSelect = (name, value) => {

		setmedicalInput((prev) => {
			return {
				...prev, [name]: value
			}
		})
	}


	// handle the multiple selection and store in arr
	const handleMultipleSelect = (name, value) => {
		let newValue = value && value.map((item) => item.value)

		setmedicalInput((prev) => {
			return {
				...prev, [name]: newValue ?? []
			}
		})
	}



	// change the family info  inputs 
	const handleFamilyMemberChange = (name, value, indexFamily) => {

		let medicalInputs = { ...medicalInput };
		let changeItem = medicalInputs.familyMembers.find((item, index) => index == indexFamily)
		changeItem[name] = value;
		medicalInputs.familyMembers[indexFamily] = changeItem;

		setmedicalInput(medicalInputs)
	}


	// // add new family memeber
	const addFamilyMember = () => {

		setmedicalInput((prev) => {
			return {
				...prev, familyMembers: [...prev.familyMembers, { familyMember: "", disease: "", year: "", state: "" }]
			}
		})
	}


	// remove a family memeber
	const removeFamilyMember = (familyIndex) => {

		let medicalinput = { ...medicalInput }
		let newmedicalInput = medicalinput.familyMembers.filter((val, index) => index !== familyIndex)
		medicalinput.familyMembers = newmedicalInput
		setmedicalInput(medicalinput)

	};


	// handle remedy changes 

	const handleRemedyChange = (name, value, remedyIndex) => {

		let medicalInputCopy = { ...medicalInput };
		let getRemedy = medicalInputCopy.remedy.find((item, index) => index === remedyIndex)
		getRemedy[name] = value;
		medicalInputCopy.remedy[remedyIndex] = getRemedy;
		setmedicalInput(medicalInputCopy)
	}


	//remove the remedy

	const removeRemedy = (remedyIndex) => {
		let medicalinput = { ...medicalInput }
		let newmedicalInput = medicalinput.remedy.filter((val, index) => index !== remedyIndex)
		medicalinput.remedy = newmedicalInput
		setmedicalInput(medicalinput)
	}

	// addRemedy 
	const addRemedy = () => {

		setmedicalInput((prev) => {
			return { ...prev, remedy: [...prev.remedy, { materiaMedica: "", potency: "" }] }
		})

	}



	// switch to the prev tab
	const prevTab = () => {

		if (activeTab === 1) return
		setActiveTab(prev => prev - 1)

	}

	//switch to the next tab
	const nextTab = () => {

		if (activeTab === 5) return
		setActiveTab(prev => prev + 1)

	}

	const updateMedicalSession = async () => {

		try {
			await saveHomeosession(medicalInput);

			setEditMode(!editMode)
			setfetchAgain(!fetchAgain)
		} catch (err) {
			// console.log(err)

		}
	}



	const RenderTabButtons = () => {
		const tabButtonsStyleContainer = {
			marginTop: "20px"
		}
		return (
			<div className="form-group float-right " style={tabButtonsStyleContainer}>
				{
					!editMode && <button type="button" class="btn btn-red btn-md mr-2" onClick={() => setEditMode(true)} >
						Cancel
					</button>
				}
				<button disabled={(5 - activeTab >= 4) && "true"} style={{ cursor: (5 - activeTab >= 4) && "not-allowed", backgroundColor: (5 - activeTab >= 4) && "black" }} type="button" className="btn btn-green btn-md mr-2" onClick={prevTab}>
					Previous
				</button>
				<button style={{ cursor: (5 - activeTab < 1) && "not-allowed" }} type="button" class="btn btn-green btn-md  mr-2" onClick={nextTab}>
					Next
				</button>
				{
					!editMode && <button type="button" class="btn btn-blue btn-md mr-2" onClick={updateMedicalSession}>
						Update
					</button>
				}

			</div>
		);
	};
	const medicalFileStyle = {
		letterSpacing: "1.3px",
		fontSize: "1.2rem"

	}

	const TabBox = {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	}
	const [activeTab, setActiveTab] = useState(1)
	const changesetActiveTab = (n) => setActiveTab(n);

	return (
		<>

			<Box sx={{ width: '100%' }}>



				{
					medicalInput._id && <>
						<Panel>
							<PanelHeader style={medicalFileStyle} noButton>View & Edit MedicalFile</PanelHeader>
							<PanelBody>
								<div style={TabBox}>

									<ReusableTabNavs
										setActiveTab={changesetActiveTab}
										activeTab={activeTab}
										navprops={[
											{ label: "Medical History", background: "#DED99F" },
											{ label: "Interview", background: "#FFC6FF" },
											{ label: "Inspection And Examination", background: "#FFF5AD" },
											{ label: "Homeo Treatment", background: "#A2F5AD" },
										]}
									/>
								</div>
								<TabContent activeTab={activeTab}>

									<ReusableTab id={1} height={"100%"} width={"100%"}>
										<>
											<MedicalHistory handleSingleSelect={handleSingleSelect} handleMultipleSelect={handleMultipleSelect} addFamilyMember={addFamilyMember} removeFamilyMember={removeFamilyMember} handleFamilyMemberChange={handleFamilyMemberChange} changeInput={changeInput} medicalInput={medicalInput} editMode={editMode} />
											<RenderTabButtons />
										</>
									</ReusableTab>
									<ReusableTab id={2}>
										<>
											<Interview handleMultipleSelect={handleMultipleSelect} handleSingleSelect={handleSingleSelect} changeInput={changeInput} medicalInput={medicalInput} editMode={editMode} />
											<RenderTabButtons />

										</>
									</ReusableTab>
									<ReusableTab id={3}>
										<>
											<InspectionAndExamination handleSingleSelect={handleSingleSelect} handleMultipleSelect={handleMultipleSelect} changeInput={changeInput} medicalInput={medicalInput} editMode={editMode} />
											<RenderTabButtons />
										</>
									</ReusableTab>
									<ReusableTab id={4}>
										<>
											<HomeoTreatment addRemedy={addRemedy} removeRemedy={removeRemedy} handleRemedyChange={handleRemedyChange} handleMultipleSelect={handleMultipleSelect} handleSingleSelect={handleSingleSelect} changeInput={changeInput} medicalInput={medicalInput} editMode={editMode} />
											<RenderTabButtons />
										</>
									</ReusableTab>

								</TabContent>
							</PanelBody>
						</Panel>


					</>
				}









			</Box>
		</>)
});

export default PreviousSessionsContainer



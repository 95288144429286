import http from './httpService'; 
import {apiUrl} from './../config/config.json';
const apiEndpoint = apiUrl+'/kaizens';


  function kaizenUrl(id) {
    return `${apiEndpoint}/${id}`;
  }
  
  export function getKaizens() {
    return http.get(apiEndpoint);
  }
  
  export function getKaizen(Id) {
    return http.get(kaizenUrl(Id));
  }
  
  export function saveKaizen(kaizen) {
    //clone
    const body = { ...kaizen };
    console.log( "saving kaizen " , body );
   //update
   if (kaizen._id) {
     //delete _id
     delete body._id;
     return http.put(kaizenUrl(kaizen._id),body);
   }
 
   //add a new kaizen
   return http.post(apiEndpoint, kaizen);
 }
  
  //delete kaizens
  export function deleteKaizen(Id) {
    return http.delete(kaizenUrl(Id));
  }  
import "bootstrap";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import size from "lodash/size";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DateRange from "../../components/kanban/DateRangePicker";
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import Actions from "./../../../src/components/kaizen/Action";
import Card from "./../../../src/components/kaizen/Card";
import Filter from "./../../../src/components/kaizen/Filters";
import "./../../../src/components/kaizen/style.css";

const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;
  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
};

function KanBanBoard(props) {
  const [datePickerClass, setPickerClass] = useState("d-none");

  const [columns, setColumns] = useState(/*Object.entries(columnsFromBackend)*/);
  const [filterColumn, setFilterColumns] = useState(columns);

  const filterTaskHandler = (title, value) => {
    if (title === "priority" && !isEmpty(value)) {
      switch (value) {
        case "urgent": {

          const filteredvalue = map(filterColumn, ([col1, col]) => {
            let { items, ...rest } = col;
            items = filter(items, ({ priority }) => priority === "urgent");
            
            return [col1, { items, ...rest }];
          });
          // setFilterColumns(filteredvalue);
          
          const removeEmptyItems = filter(
            filteredvalue,
            ([col1, col]) => size(col.items) !== 0
          );
          setFilterColumns(removeEmptyItems);

          break;
        }
        case "low": {
          const filteredvalue = map(filterColumn, ([col1, col]) => {
            let { items, ...rest } = col;
            items = filter(items, ({ priority }) => priority === "low");

            return [col1, { items, ...rest }];
          });
          const removeEmptyItems = filter(
            filteredvalue,
            ([col1, col]) => size(col.items) !== 0
          );
          setFilterColumns(removeEmptyItems);

          break;
        }
        case "normal": {
          const filteredvalue = map(filterColumn, ([col1, col]) => {
            let { items, ...rest } = col;
            items = filter(items, ({ priority }) => priority === "normal");
            return [col1, { items, ...rest }];
          });
          const removeEmptyItems = filter(
            filteredvalue,
            ([col1, col]) => size(col.items) !== 0
          );
          setFilterColumns(removeEmptyItems);
          break;
        }
        case "high": {
          const filteredvalue = map(filterColumn, ([col1, col]) => {
            let { items, ...rest } = col;
            items = filter(items, ({ priority }) => priority === "high");

            return [col1, { items, ...rest }];
          });
          const removeEmptyItems = filter(
            filteredvalue,
            ([col1, col]) => size(col.items) !== 0
          );
          setFilterColumns(removeEmptyItems);
          break;
        }
        case "select": {
          setFilterColumns(columns);
        }
        case "showall": {
          setFilterColumns(columns);
        }

        default: {
          setFilterColumns(columns);
        }
      }
      // if (value !== "showall" && value !== "select") {
      //   const filteredvalue = map(filterColumn, ([col1, col]) => {
      //     let { items, ...rest } = col;
      //     items = filter(items, ({ priority }) => priority === value);

      //     return [col1, { items, ...rest }];
      //   });

      //   setFilterColumns(filteredvalue);
      // } else {
      //   setFilterColumns(columns);
      // }
    }
else if (title === "status" && !isEmpty(value)) {
      switch (value) {
        case "open": {
          const filteredvalue = map(filterColumn, ([col1, col]) => {
            let { items, ...rest } = col;
            items = filter(items, ({ category }) => category === "open");

            return [col1, { items, ...rest }];
          });
          const removeEmptyItems = filter(
            filteredvalue,
            ([col1, col]) => size(col.items) !== 0
          );
          setFilterColumns(removeEmptyItems);
          break;
        }
        case "onhold": {
          const filteredvalue = map(filterColumn, ([col1, col]) => {
            let { items, ...rest } = col;
            items = filter(items, ({ category }) => category === "onhold");

            return [col1, { items, ...rest }];
          });
          const removeEmptyItems = filter(
            filteredvalue,
            ([col1, col]) => size(col.items) !== 0
          );
          setFilterColumns(removeEmptyItems);
          break;
        }
        case "reopen": {
          const filteredvalue = map(filterColumn, ([col1, col]) => {
            let { items, ...rest } = col;
            items = filter(items, ({ category }) => category === "reopen");
            return [col1, { items, ...rest }];
          });
          const removeEmptyItems = filter(
            filteredvalue,
            ([col1, col]) => size(col.items) !== 0
          );
          setFilterColumns(removeEmptyItems);
          break;
        }
        case "closed": {
          const filteredvalue = map(filterColumn, ([col1, col]) => {
            let { items, ...rest } = col;
            items = filter(items, ({ category }) => category === "closed");

            return [col1, { items, ...rest }];
          });
          const removeEmptyItems = filter(
            filteredvalue,
            ([col1, col]) => size(col.items) !== 0
          );
          setFilterColumns(removeEmptyItems);
          break;
        }
        case "select": {
          setFilterColumns(columns);
        }
        case "showall": {
          setFilterColumns(columns);
        }

        default: {
          setFilterColumns(columns);
        }
      }
    }
  };

  const [statusOptions, setStatusOptions] = useState([
      { value: "in-planning", label: "In-Planning" },
      { value: "doing", label: "Doing" },	  
      { value: "checking", label: "checking" },	  	  
      { value: "acting", label: "Acting" },	  	  
      { value: "active", label: "Active" },
      { value: "pending", label: "Pending" },
      { value: "archive", label: "Archive" },
  ]);

  const [wasteTypeOptions, setWasteTypeOptions] = useState([
      { value: "environment", label: "environment" },
      { value: "methods", label: "methods" },
      { value: "people", label: "people" },
      { value: "equipment", label: "equipment" },
      { value: "proces", label: "proces" },
      { value: "materials", label: "materials" },
      { value: "client", label: "client" },
      { value: "supplier", label: "supplier" },	  
  ]);
  
  useEffect(() => {
    console.log("object entries ", Object.entries(columns));
  });

  return (
    <div className="m-3">
      <div className="scroll">
        <Panel className="mb-0">
          <PanelHeader>Kaizens</PanelHeader>
          <PanelBody>
            {/* <h1 className="page-header m-b-10">Kanban name</h1> */}

            <Filter
              statusOptions={statusOptions}
              wasteTypeOptions={wasteTypeOptions}
              onChangeDateRange={() => {
                if (datePickerClass !== "") {
                  setPickerClass("");
                } else {
                  setPickerClass("d-none");
                }
              }}
              onfilter={(title, value) => {
                filterTaskHandler(title, value);
              }}
            />
            <DateRange className={datePickerClass} />
          </PanelBody>
        </Panel>
        <div className="KaizenCardsHo">
          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
          >
            {filterColumn.map(([columnId, column], index) => {
              return (
                <div className={`${column.classN}`}>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          <Actions name={column.name} actionN={column.number} />
                          <div className="cards">
                            {column.items.map((item, index) => {
                              return (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <Card
                                          statusOptions={statusOptions}
                                          wasteTypeOptions={wasteTypeOptions}
                                          content={item}
                                        />
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                          </div>
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              );
            })}
          </DragDropContext>
        </div>
      </div>
    </div>
  );
}

export default KanBanBoard;

import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Select from "react-select";
import options from "./options";
import "./style.css";
// import PrevHomeoTreatment from "./previoussessions/HomeoTreatment";

class HomeoTreatment extends React.Component {
	render() {
		const targetHeight = 34;

		const customStyles = {
			control: (styles) => ({
				...styles,
				minHeight: "initial",
			}),

			option: (provided) => ({
				...provided,
			}),
			valueContainer: (base) => ({
				...base,
				padding: "0 8px",
			}),
			clearIndicator: (base) => ({
				...base,
				padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
			}),
			dropdownIndicator: (base) => ({
				...base,
				padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
			}),
		};

		const { handleChange, infoState, isEditable, addRemedy, removeRemedy, handleRemedyChange,
			hMateriaMedicas, hMateriaMedic } = this.props;
		// console.log("infoState", infoState);
		// console.log("options", options);

		return (
			<div className="main-container">
				<h3 className="mt-10">Homeo Treatment</h3>
				<div className="panel-body">
					<fieldset>
						<legend className="legend-text">Differentiation</legend>
						<div className="form-group">
							<label>
								<b>Homeo Diagnosis&nbsp;:</b>
							</label>
							<input
								disabled={!isEditable}
								type="text"
								className="form-control w-75"
								name="homeoDiagnosis"
								placeholder="Your Homeo Diagnosis for the patient..."
								value={infoState.homeoDiagnosis}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
							/>
						</div>
						<div className="form-group">
							<label>
								<b>Principle Treatment&nbsp;:</b>
							</label>
							<input
								disabled={!isEditable}
								type="text"
								className="form-control w-75"
								name="principleTreatment"
								placeholder="Your treatment for the patient..."
								value={infoState.principleTreatment}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
							/>
						</div>
						<div className="form-group">
							<label>
								<b>Note&nbsp;:</b>
							</label>
							<textarea
								disabled={!isEditable}
								rows="10"
								className="form-control w-75"
								name="acutreatmentNote"
								placeholder="Note for treatment for the patient..."
								value={infoState.acutreatmentNote}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
							/>
						</div>
					</fieldset>
				</div>

				<div className="panel-body">
					<fieldset>
						<legend className="legend-text">Remedy Prescription</legend>
						<div className="row">
							<div className="col-12 col-sm-9">
								<div className="d-flex flex-wrap justify-content-between">
									<div className="form-group">
										<button type="button" className="btn btn-primary btn-sm" onClick={addRemedy}>
											Add Remedy to Prescription
										</button>
									</div>
								</div>
							</div>
						</div>
						{infoState.remedy.map((item, index) => (
							<div className="row" key={index}>
								<div className="col-12 col-sm-1">
									<label>
										<b>Remedy</b>
									</label>
								</div>
								<div className="col-12 col-sm-4">
									<div className="form-group">
										<Select
											isDisabled={!isEditable}
											styles={customStyles}
											options={hMateriaMedic}
											placeholder={"Select Remedy..."}
											value={
												item.remedy && {
													value: item.materiaMedica,
													label: item.materiaMedica,
												}
											}
											onChange={(e) => handleRemedyChange("materiaMedica", e.value, index)}
										/>
									</div>
								</div>
								<div className="col-12 col-sm-1">
									<label>
										<b>Potency</b>
									</label>
								</div>
								<div className="col-12 col-sm-2">
									<div className="form-group">
										<Select
											isDisabled={!isEditable}
											styles={customStyles}
											options={options.potency}
											placeholder={"Select Potency..."}
											value={
												item.remedy && {
													value: item.potency,
													label: item.potency,
												}
											}
											onChange={(e) => handleRemedyChange("potency", e.value, index)}
										/>
									</div>
								</div>
								{index > 0 && (
									<div className="col-6 col-sm-2">
										<div className="form-group">
											{/* <label>
												<b>Remove</b>
											</label> */}
											<button
												className="btn btn-danger btn-icon btn-circle btn-lg"
												onClick={() => removeRemedy(index)}
											>
												<i className="fa fa-trash"></i>
											</button>
										</div>
									</div>
								)}
							</div>
						))}

						<div className="form-group">
							<label>
								<b>Diet-therapy&nbsp;:</b>
							</label>
							<textarea disabled={!isEditable}rows="4" className="form-control w-75" name="dietTherapy" placeholder="Type in diet for the patient..."
								value={infoState.dietTherapy}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
							/>
						</div>
						<div className="form-group">
							<label>
								<b>Recommendation&nbsp;:</b>
							</label>
							<textarea disabled={!isEditable} rows="6" className="form-control w-75" name="recommendation" placeholder="Type in recommendation for the patient..."
								value={infoState.recommendation}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
							/>
						</div>
					</fieldset>
				</div>
			</div>
		);
	}
}

HomeoTreatment.propTypes = {
	handleChange: PropTypes.func.isRequired,
	infoState: PropTypes.object.isRequired,
};

export default HomeoTreatment;

import "bootstrap";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DateRange from "../../components/kanban/DateRangePicker";
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import Actions from "./../../../src/components/kanban/Action";
import Task from "./../../../src/components/kanban/Task";
import Filter from "./../../../src/components/kanban/Filters";
import "./../../../src/components/kanban/style.css";
import { getListKanbans } from "../../services/listkanbans.js";
import { saveTask, getTasks } from "../../services/tasks.js";
import { getKanban } from "../../services/kanbans.js";
import { apiUrl } from "./../../config/config.json";
import http from "./../../services/httpService";

function KanbanBoard() {
  const [listkanbans, setListkanbans] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [kanban, setKanban] = useState([]);
  const [columns, setColumns] = useState();
  const [filterColumn, setFilterColumns] = useState([]);
  const [prevFilter, setprevFilter] = useState({ title: "", value: "" });
  const [listIdWithTasks, setlistIdWithTasks] = useState([]);
  const url = window.location.href;
  const kanbanId = (url.substring(url.lastIndexOf("/") + 1)).replaceAll("#", '');

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    let sourceItems;
    let destItems;
    let sourceIndex, destIndex, clonnedArray;
    if (source.droppableId !== destination.droppableId) {
      listIdWithTasks.map((lkWithTasks, i) => {
        if (source.droppableId == lkWithTasks.listkanban) {
          sourceItems = [...lkWithTasks.tasks];
          sourceIndex = i;
        }
        if (destination.droppableId == lkWithTasks.listkanban) {
          destItems = [...lkWithTasks.tasks];
          destIndex = i;
        }
      });
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      clonnedArray = [...listIdWithTasks];
      clonnedArray[sourceIndex].tasks = sourceItems;
      clonnedArray[destIndex].tasks = destItems;
      setlistIdWithTasks(clonnedArray)

      filterColumn.map((task) => {
        let data;
        if (result.draggableId == task?._id) {
          data = {
            _id: task._id,
            userID: task.userID?._id,
            listKanbanNo: destination.droppableId,
            kanbanNo: task.kanbanNo,
            taskNo: task.taskNo,
            taskname: task.name,
            narrative: task.narrative,
            category: task.category,
            priority: task.priority,
            deadline: task.deadline,
            participants: task.participants?.map(part => part?._id),
            documentNo: "",
            field: task.field,
            tags: task.tags,
            reference: task.reference,
            sharingLink: task.share.link,
            sharedTo: task.share.sharedTo,
            sharedTill: task.share.sharedTill,
            note: task.note,
            createdOn: task.createdOn,
            status: task.status,
          };
          saveTask(data);
        }
      })
    } else {
      if (!filterColumn) return;
      listIdWithTasks.map((lkWithTasks, i) => {
        if (source.droppableId == lkWithTasks.listkanban) {
          sourceItems = [...lkWithTasks.tasks];
          sourceIndex = i;
        }
      });
      const [removed] = sourceItems.splice(source.index, 1);
      sourceItems.splice(destination.index, 0, removed);
      clonnedArray = [...listIdWithTasks];
      clonnedArray[sourceIndex].tasks = sourceItems;
      setlistIdWithTasks(clonnedArray)
    }
  };

  function updateFilter1Columns(value, title){
    if (value === "showall" || value === title) return setFilterColumns(filter(filterColumn, (item) => item[title]))
    setFilterColumns(filter(
      filterColumn,
      (item) => item[title] === value
    ));
  }

  function updateFilter2Columns(value){
    if (value === "showall" || value === "priority") return setFilterColumns(filter(columns, (item) => item.priority))
    setFilterColumns(filter(columns, (item) => item.priority === value))
  }

  const filterTaskHandler = (title, value) => {
    if(isEmpty(value)) return;
    if (title === "priority") {
      if (prevFilter.title === "category" || prevFilter.title === "status") {
        updateFilter1Columns(value, title)
      } else {
        updateFilter2Columns(value);
      }
    } else if (title === "status") {
      if (prevFilter.title === "category" || prevFilter.title === "priority") {
        updateFilter1Columns(value, title)
      } else {
        updateFilter2Columns(value);
      }
    } else if (title === "category") {
      if (prevFilter.title === "status" || prevFilter.title === "priority") {
        updateFilter1Columns(value, title)
      } else {
        updateFilter2Columns(value);
      }
    }
    setprevFilter({ title, value });
  };

  const priorityOptions = [
    { value: "urgent", label: "Urgent" },
    { value: "high", label: "High" },
    { value: "normal", label: "Normal" },
    { value: "low", label: "Low" },
  ];

  const statusOptions = [
    { value: "in progress", label: "In Progress" },
    { value: "pending", label: "Pending" },
    { value: "new", label: "New" },
    { value: "active", label: "Active" },
    { value: "archive", label: "Archive" },
  ];

  const categoryOptions = [
    { value: "bug-error", label: "bug-error" },
    { value: "disconnection", label: "disconnection" },
    { value: "feature-request", label: "feature-request" },
    { value: "frontend", label: "frontend" },
    { value: "backend", label: "backend" },
    { value: "AI", label: "AI" },
    { value: "NLP", label: "NLP" },
    { value: "image-recognization", label: "image-recognization" },
    { value: "hosting", label: "hosting" },
    { value: "tablet", label: "tablet" },
    { value: "phone", label: "phone" },
    { value: "web", label: "web" },
  ];

  function adjust(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
  }

  const handleDelete = async (listkanban) => {
    try {
      await http.delete(apiUrl + "/listkanbans/" + listkanban?._id);
      setListkanbans(listkanbans.filter((ListKanban) => ListKanban._id !== listkanban._id));
    } catch (ex) {
      if (ex.response && ex.response === 404) {
        return alert("already deleted");
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      //Get all tasks
      const allTasks = await getTasks();
      setTasks(allTasks?.data);
      setFilterColumns(allTasks?.data);

      //Get Kanban Data
      setKanban(await getKanban(kanbanId));

      //Get All List Kanbans
      const { data: listkanbans } = await getListKanbans();
      setListkanbans(listkanbans);
    }

    fetchData()
  }, [kanbanId]);

  useEffect(async () => {
    const { data: users } = await http.get(apiUrl + "/users");
    filterColumn?.map(task => {
      let taskPart = task?.participants?.map((part) => {
        const user = users?.filter((user) => {
          return user?._id == part
        });
        return user[0] ? user[0] : part;
      })
      taskPart = taskPart ? taskPart : [];
      task.participants = [...taskPart];
      return task
    })
    let list = [];
    listkanbans?.map((lk) => {
      list = [...list, { listkanban: lk?._id, tasks: [] }];
    });
    filterColumn?.map((task) => {
      list = list?.map((lkTasks) => {
        return task?.listKanbanNo?._id == lkTasks.listkanban ? { listkanban: lkTasks.listkanban, tasks: [...lkTasks.tasks, task] } : lkTasks
      })
    })
    setlistIdWithTasks(list)
  }, [listkanbans, filterColumn])
  return (
    <>
      <div className="m-3">
        <div className="scroll">
          <Panel className="mb-0">
            <PanelHeader>{kanban.data?.name}</PanelHeader>
            <PanelBody>
              <Filter
                kanban={kanban}
                statusOptions={statusOptions}
                priorityOptions={priorityOptions}
                categoryOptions={categoryOptions}
                onChangeDateRange={() => {
                }}
                onfilter={(title, value) => {
                  filterTaskHandler(title, value);
                }}
              />
              <DateRange className={"d-none"} />
            </PanelBody>
          </Panel>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
          {listkanbans?.map((column) => {
            return (
              <div>
                {column?.kanbanNo?._id === kanbanId && (
                  <Droppable droppableId={column._id} key={column._id}>
                    {(provided) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            backgroundColor: column.color,
                            padding: 4,
                            width: 350,
                            minHeight: "100%",
                            margin: 10,
                          }}
                        >
                          <div
                            style={{
                              ...headerStyles,
                              backgroundColor: adjust(
                                column.color,
                                50
                              ),
                            }}
                          >
                            <h4 style={headingStyles}>{column.name}</h4>
                            <Actions
                              kanban={kanban}
                              listkanban={column}
                              handleDelete={handleDelete}
                              name={column.name}
                              actionN={column._id}
                              backgroundC={column.color}
                            />
                          </div>
                          {listIdWithTasks.map((lkIdWithTask) => {
                            return (
                              lkIdWithTask?.listkanban === column?._id && (
                                lkIdWithTask?.tasks?.map((task, index) => {
                                  return (
                                    <Draggable
                                      key={task?._id}
                                      draggableId={task?._id}
                                      index={index}
                                    >
                                      {(provided) => {
                                        return (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                              userSelect: "none",
                                              ...provided.draggableProps.style,
                                            }}
                                          >
                                            <Task
                                              setFilterColumns={setFilterColumns}
                                              filterColumn={filterColumn}
                                              tasks={tasks}
                                              setTasks={setTasks}
                                              statusOptions={statusOptions}
                                              priorityOptions={priorityOptions}
                                              categoryOptions={categoryOptions}
                                              content={task}
                                            />
                                          </div>
                                        )
                                      }}
                                    </Draggable>)
                                })
                              ));
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                )}
              </div>
            );
          })}
        </DragDropContext>
      </div>
    </>
  );
}

const headerStyles = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  color: "white",
  paddingLeft: "10px",
};
const headingStyles = {
  paddingTop: "10px",
};

export default KanbanBoard;

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import DatePicker from "react-datepicker";
import options from "./options";
import avatar from "../../../assets/images/user-12.jpg";
import "react-datepicker/dist/react-datepicker.css";
import { apiUrl } from "../../../config/config.json";
import http from "../../../services/httpService";
import "./style.css";

class MedicalHistory extends React.Component {
	render() {
		const targetHeight = 34;

		const customStyles = {
			control: (styles) => ({
				...styles,
				minHeight: "initial",
			}),

			option: (provided) => ({
				...provided,
			}),
			valueContainer: (base) => ({
				...base,
				padding: "0 8px",
			}),
			clearIndicator: (base) => ({
				...base,
				padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
			}),
			dropdownIndicator: (base) => ({
				...base,
				padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
			}),
		};

		const {
			handleChange,
			infoState,
			addFamilyMember,
			removeFamilyMember,
			isEditable,
			handleMultiChange,
			handleMultiSelectChange,
			handleFamilyMemberChange,
			diseasesConditions,
			diseasesCondition,
			handleWesternDiseasesChange
		} = this.props;

		return (
			<Fragment>
				<h3 className="mt-10">Medical History</h3>
				<div className="panel-body">
					<fieldset>
						<legend className="legend-text">Personal Medical History</legend>
						<div className="form-group">
							<label>
								<b>Chief Complaint *</b>
							</label>
							<textarea
								disabled={!isEditable}
								rows="3"
								className="form-control w-75"
								name="chiefComplaint"
								placeholder="Your chief complaint..."
								value={infoState.chiefComplaint}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
							/>
						</div>
						<div className="form-group">
							<label>
								<b>Symptoms:</b>
							</label>
							<textarea
								disabled={!isEditable}
								rows="4"
								className="form-control w-75"
								name="symptoms"
								placeholder="the symptoms..."
								value={infoState.symptoms}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
							/>
						</div>
						<div className="form-group">
							<label>
								<b>Western Disease/syndrome :</b>
							</label>
							<Select
								isDisabled={!isEditable}
								styles={customStyles}
								className="w-75"
								options={diseasesCondition}
								placeholder="The Western Disease / Syndrome..."
								value={infoState.westernDisease && {
									value: infoState.westernDisease,
									label: infoState.westernDisease
								}}
								onChange={(e) => handleWesternDiseasesChange(e.value)}
							/>
						</div>
						<div className="form-group">
							<label>
								<b>Current Treatment :</b>
							</label>
							<div style={{ width: "300px" }}>
								<Select
									isDisabled={!isEditable}
									isMulti
									styles={customStyles}
									options={options.currentTreatment}
									placeholder={"Select current treatment..."}
									value={options.currentTreatment.filter((opt) =>
										infoState.currentTreatment.includes(opt.value)
									)}
									onChange={(e) => handleMultiChange("currentTreatment", e)}
								/>
							</div>
						</div>
						<div className="form-group">
							<label>
								<b>Diseases & Illnesses :</b>
							</label>
							<input
								type="text"
								disabled={!isEditable}
								className="form-control w-75"
								name="diseases"
								placeholder="Type in Western Disease Name in the past..."
								value={infoState.diseases}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
							/>
						</div>
						<div className="form-group">
							<label>
								<b>Surgeries :</b>
							</label>
							<input
								type="text"
								disabled={!isEditable}
								className="form-control w-75"
								name="surgeries"
								placeholder="Surgeries in the past..."
								value={infoState.surgeries}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
							/>
						</div>
						<div className="form-group">
							<label>
								<b>Medicaments & Supplements in use:</b>
							</label>
							<input
								type="text"
								disabled={!isEditable}
								className="form-control w-75"
								name="medicaments"
								placeholder="Medicaments & Supplements in use or used in the past..."
								value={infoState.medicaments}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
							/>
						</div>
						<div className="form-group">
							<label>
								<b>Allergies :</b>
							</label>
							<textarea disabled={!isEditable} rows="4" className="form-control w-75" name="allergies" 
								placeholder="Type in allergies..." value={infoState.allergies}
								onChange={(e) => handleChange(e.target.name, e.target.value)} />
						</div>
						<div className="form-group">
							<label>
								<b>Pregnancies :</b>
							</label>
							<input
								type="text"
								disabled={!isEditable}
								className="form-control w-75"
								name="pregnancies"
								placeholder="Pregnancies, year of pregnancies in the past...preganacy (year)"
								value={infoState.pregnancies}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
							/>
						</div>
					</fieldset>
				</div>

				<div className="panel-body">
					<fieldset>
						<legend className="legend-text">Family Medical History</legend>
						<div className="form-group">
							<button type="button" className="btn btn-primary btn-sm" onClick={addFamilyMember}>
								Add Family Role with Medical History
							</button>
						</div>
						{infoState.familyMembers.map((familyMember, index) => {
							return (
								<div className="row" key={index}>
									<div className="col-9 col-md-3">
										<div className="form-group">
											<label>
												<b>Family Member :</b>
											</label>
											<Select
												isDisabled={!isEditable}
												styles={customStyles}
												options={options.familyMember}
												placeholder={"Select family member..."}
												value={
													familyMember.familyMember && {
														value: familyMember.familyMember,
														label: familyMember.familyMember,
													}
												}
												onChange={(e) => handleFamilyMemberChange("familyMember", e.value, index)}
											/>
										</div>
									</div>
									<div className="col-12 col-md-4">
										<div className="form-group">
											<label>
												<b>Disease :</b>
											</label>
											<Select
												isDisabled={!isEditable}
												styles={customStyles}
												options={diseasesCondition}
												placeholder="Type in Western Disease Name of The Family Member..."
												value={familyMember.disease && {
													value: familyMember.disease,
													label: familyMember.disease
												}}
												onChange={(e) => handleFamilyMemberChange("disease", e.value, index)}
											/>
										</div>
									</div>
									<div className="col-9 col-md-2">
										<div className="form-group">
											<label>
												<b>Year :</b>
											</label>
											<Select
												isDisabled={!isEditable}
												styles={customStyles}
												options={options.year}
												placeholder={"Year"}
												value={
													familyMember.year && {
														value: familyMember.year,
														label: familyMember.year,
													}
												}
												onChange={(e) => handleFamilyMemberChange("year", e.value, index)}
											/>
										</div>
									</div>
									<div className="col-2 col-md-2">
										<div className="form-group">
											<label>
												<b>State :</b>
											</label>
											<Select
												isDisabled={!isEditable}
												styles={customStyles}
												options={options.state}
												placeholder={"Select status..."}
												value={
													familyMember.state && {
														value: familyMember.state,
														label: familyMember.state,
													}
												}
												onChange={(e) => handleFamilyMemberChange("state", e.value, index)}
											/>
										</div>
									</div>
									{index > 0 && (
										<div className="col-6 col-md-1">
											<div className="form-group">
												<label>
													<b>Remove</b>
												</label>
												<button className="btn btn-danger btn-icon btn-circle btn-lg" onClick={() => removeFamilyMember(index)}>
													<i className="fa fa-trash"></i>
												</button>
											</div>
										</div>
									)}
								</div>
							);
						})}
						{/* Family Members Section End*/}
						<div className="form-group">
							<label>
								<b>Note for Medical History :</b>
							</label>
							<textarea
								disabled={!isEditable}
								rows="8"
								className="form-control w-75"
								name="noteMedicalHistory"
								placeholder="Type additional information of Medical History..."
								value={infoState.noteMedicalHistory}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
							/>
						</div>
					</fieldset>
				</div>
			</Fragment>
		);
	}
}

MedicalHistory.propTypes = {
	handleChange: PropTypes.func.isRequired,
	infoState: PropTypes.object.isRequired,
};

export default MedicalHistory;

import React, { Fragment } from "react";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel.jsx";
import ReusableTabNavs from "../../../common/ReusableTabNavs";
import ReusableTab from "../../../common/ReusableTab";
import { TabContent } from "reactstrap";
import MedicalHistory from "./MedicalHistory";
import Interview from "./Interview";
import InspectionAndExamination from "./InspectionAndExamination";
import AyurvedaTreatment from "./AyurvedaTreatment";
import PreviousSessions from "./PreviousSessions";
import Joi from "joi";
import { getAyurvedasession, saveAyurvedasession } from "../../../services/ayurvedasessions";

import { Link } from "react-router-dom";
import { apiUrl } from "../../../config/config.json";
import http from "../../../services/httpService";
import avatar from "../../../assets/images/user-12.jpg";
import "./style.css";
import { getAcupunctures } from "../../../services/diseasesconditions.js";

class AddAyurvedaSession extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			patients: [],
			clinics: [],
			doctors: [],
			aMateriaMedicas: [],
			aMateriaMedic: [{ value: "", label: "" }],
			diseasesConditions: [],
			diseasesCondition: [{ value: "", label: "" }],
			patient: {},
			previousSessions: [],
			errors: {},
			dropdownOpen: false,
			readOnly: true,
			activeTab: 1,
			read: true,
			data: {
				selectedRegisteredPatient: "",
				clinicNo: "",
				doctorNo: "",
				chiefComplaint: "",
				symptoms: "",
				westernDisease: "",
				currentTreatment: [],
				diseases: "",
				surgeries: "",
				medicaments: "",
				allergies: "",
				pregnancies: "",
				familyMembers: [{ familyMember: "", disease: "", year: "", state: "" }],
				noteMedicalHistory: "",
				socialRelationship: "",
				habits: [],
				profession: "",
				employmentStatus: [],
				sport: "",
				sportFrequency: [],
				hobby: "",
				// height: "",
				// heightUnit: "cm",
				// weight: "",
				// weightUnit: "kg",
				// bmi: "",
				// bmiClassification: "",
				// temperature: "",
				// temperatureUnit: "celsius",
				thermalFeeling: [],
				perspiration: [],
				appetite: [],
				appetiteNote: "",
				vomiting: [],
				vomitingNote: "",
				diet: [],
				dietNote: "",
				taste: [],
				thirst: [],
				defecation: [],
				urination: [],
				urinationColor: [],
				sleep: [],
				head: [],
				eyes: [],
				ear: [],
				nose: [],
				throat: [],
				menstruation: [],
				leukorrhea: [],
				localityOfPain: "",
				natureOfPain: "",
				// emotionState: [],
				// emotionNote: "",
				// tongueShape: "",				
				// tongueColor: "",
				// tongueNote: "",				
				// mindState: "",
				// bodyConstitution: "",				
				interviewNote: "",
				respiration: [],
				speech: [],
				cough: [],
				odor: [],
				vitality: [],
				colorFace: [],
				physicalAppearance: [],
				// physicalAppearanceNote: "",
				epigastriumPalpation: [],
				epigastriumPalpationNote: "",
				abdomenPalpation: [],
				// rangeMotion: "",
				// painLevel: "",
				physicalExaminationNote: "",
				ayurvedaDiagnosis: "",
				principleTreatment: "",
				treatmentNote: "",
				remedy: [{ materiaMedica: "", potency: "", unit: "" }],
				// potency: "",
				dietTherapy: "",
				recommendation: "",
			},
		};
		this.setReadOnly = this.setReadOnly.bind(this);
		this.setActiveTab = this.setActiveTab.bind(this);
		this.toggleRead = this.toggleRead.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.renderTabButtons = this.renderTabButtons.bind(this);
		this.addFamilyMember = this.addFamilyMember.bind(this);
		this.removeFamilyMember = this.removeFamilyMember.bind(this);
		this.handleWesternDiseasesChange = this.handleWesternDiseasesChange.bind(this);
	}

	setActiveTab = (n) => this.setState({ activeTab: n });

	toggleRead = () => this.setState({ read: !this.state.read });

	setReadOnly = () => this.setState({ readOnly: !this.state.readOnly });

	// this will handle both text and single-select change
	handleChange = (name, value) => {
		const data = { ...this.state.data };
		data[name] = value;
		this.setState({ data });
	};

	handleMultiSelectChange = (name, value) => {
		const newValue = value && value.map((item) => item.value);
		const data = { ...this.state.data };
		data[name] = newValue;
		this.setState({ data });
	};

	saveData = () => {
		this.handleSubmit();
		this.setState((prevState) => ({ activeTab: prevState.activeTab + 1 }));

	}

	handleMultiChange = (name, options) => {
		const data = { ...this.state.data };
		console.log("value", options);
		data[name] = options.map((o) => o.value);
		console.log(
			"options",
			options.map((o) => o.value)
		);
		this.setState({ data });
	};

	handleFamilyMemberChange = (name, value, familyMemberIndex) => {
		const data = { ...this.state.data };
		data["familyMembers"] = this.state.data.familyMembers.map((item, index) =>
			index === familyMemberIndex ? { ...item, [name]: value } : item
		);
		this.setState({ data });
	};

	handleRemedyChange = (name, value, remedyIndex) => {
		const data = { ...this.state.data };
		data["remedy"] = this.state.data.remedy.map((item, index) =>
			index === remedyIndex ? { ...item, [name]: value } : item
		);
		this.setState({ data });
	};

	// handleChange = async (name, value, type, familyMemberIndex) => {
	// 	if (familyMemberIndex >= 0) {
	// 		this.setState({
	// 			data: {
	// 				...this.state.data,
	// 				familyMembers: this.state.data.familyMembers.map((el, index) =>
	// 					index === familyMemberIndex ? { ...el, [name]: type === "single-select" ? value.value : value } : el
	// 				),
	// 			},
	// 		});
	// 		return;
	// 	}
	// 	if (type === "multi-select") {
	// 		const newValue = value && value.map((b) => b.value);
	// 		value
	// 			? this.setState({ data: { ...this.state.data, [name]: newValue } })
	// 			: this.setState({ data: { ...this.state.data, [name]: [] } });
	// 		return;
	// 	}
	// 	if (type === "single-select") {
	// 		value
	// 			? this.setState({ data: { ...this.state.data, [name]: value.value } })
	// 			: this.setState({ data: { ...this.state.data, [name]: [] } });
	// 		return;
	// 	}
	// 	this.setState({
	// 		data: {
	// 			...this.state.data,
	// 			[name]: value,
	// 		},
	// 	});
	// };

	schema = Joi.object({
		selectedRegisteredPatient: Joi.any().optional(),
		chiefComplaint: Joi.any().optional(),
		symptoms: Joi.any().optional(),
		westernDisease: Joi.any().optional(),
		currentTreatment: Joi.any().optional(),
		diseases: Joi.any().optional(),
		surgeries: Joi.any().optional(),
		medicaments: Joi.any().optional(),
		allergies: Joi.any().optional(),
		pregnancies: Joi.any().optional(),
		familyMembers: Joi.any().optional(),
		noteMedicalHistory: Joi.any().optional(),
		socialRelationship: Joi.any().optional(),
		habits: Joi.any().optional(),
		profession: Joi.any().optional(),
		employmentStatus: Joi.any().optional(),
		sport: Joi.any().optional(),
		sportFrequency: Joi.any().optional(),
		hobby: Joi.any().optional(),
		thermalFeeling: Joi.any().optional(),
		perspiration: Joi.any().optional(),
		appetite: Joi.any().optional(),
		appetiteNote: Joi.any().optional(),
		vomiting: Joi.any().optional(),
		vomitingNote: Joi.any().optional(),
		diet: Joi.any().optional(),
		dietNote: Joi.any().optional(),
		taste: Joi.any().optional(),
		thirst: Joi.any().optional(),
		defecation: Joi.any().optional(),
		urination: Joi.any().optional(),
		urinationColor: Joi.any().optional(),
		sleep: Joi.any().optional(),
		head: Joi.any().optional(),
		eyes: Joi.any().optional(),
		ear: Joi.any().optional(),
		nose: Joi.any().optional(),
		throat: Joi.any().optional(),
		menstruation: Joi.any().optional(),
		leukorrhea: Joi.any().optional(),
		localityOfPain: Joi.any().optional(),
		natureOfPain: Joi.any().optional(),
		emotion: Joi.any().optional(),
		noteEmotion: Joi.any().optional(),
		mind: Joi.any().optional(),
		interviewNote: Joi.any().optional(),
		respiration: Joi.any().optional(),
		speech: Joi.any().optional(),
		cough: Joi.any().optional(),
		odor: Joi.any().optional(),
		vitality: Joi.any().optional(),
		appearance: Joi.any().optional(),
		colorFace: Joi.any().optional(),
		physicalAppearance: Joi.any().optional(),
		//		physicalAppearanceNote: Joi.any().optional(),
		epigastriumPalpation: Joi.any().optional(),
		epigastriumPalpationNote: Joi.any().optional(),
		abdomenPalpation: Joi.any().optional(),
		motionRange: Joi.any().optional(),
		painGradations: Joi.any().optional(),
		physicalExaminationNote: Joi.any().optional(),
		ayurvedaDiagnosis: Joi.any().optional(),
		principleTreatment: Joi.any().optional(),
		treatmentNote: Joi.any().optional(),
		remedy: Joi.any().optional(),
		//potency: Joi.any().optional(),
		dietTherapy: Joi.any().optional(),
		recommendation: Joi.any().optional(),
		clinicNo: Joi.any().optional(),
		doctorNo: Joi.any().optional(),
		appointmentType: Joi.any().optional(),
		sessionType: Joi.any().optional(),
		diseasesIllnesses: Joi.any().optional(),
		medicamentsSupplements: Joi.any().optional(),
		medicalHistoryNote: Joi.any().optional(),
		occupationState: Joi.any().optional(),
		pulseType: Joi.any().optional(),
		pulseRate: Joi.any().optional(),
		pulseRhythm: Joi.any().optional(),
		pulseIntensity: Joi.any().optional(),
		pulseVolume: Joi.any().optional(),
		pulseQuality: Joi.any().optional(),
		pulseNote: Joi.any().optional(),
		mindConstitution: Joi.any().optional(),
		aBodyConstitution: Joi.any().optional(),
		diseaseFormation: Joi.any().optional(),
		ayurvedaDiagnosis: Joi.any().optional(),
		principleTreatment: Joi.any().optional(),
		treatmentNote: Joi.any().optional(),
		dietTherapy: Joi.any().optional(),
		recommendation: Joi.any().optional(),
	});

	async populateAyurvedaSession() {
		try {
			const Id = this.props.match.params.id;
			if (Id === "new") return;
			const { data: ayurvedasession } = await getAyurvedasession(Id);
			this.setState({ data: this.mapToViewModel(ayurvedasession) });
		} catch (ex) {
			if (ex.response && ex.response.status === 404) this.props.history.replace("/error");
		}
	}

	mapToViewModel(ayurvedasession) {
		return {
			_id: ayurvedasession._id,
			selectedRegisteredPatient: ayurvedasession.patientNo,
			clinicNo: ayurvedasession.clinicNo,
			doctorNo: ayurvedasession.doctorNo,
			chiefComplaint: ayurvedasession.chiefComplaint,
			symptoms: ayurvedasession.symptoms,
			westernDisease: ayurvedasession.WesternDisease,
			currentTreatment: ayurvedasession.currentTreatment.map((item) => item),
			diseases: ayurvedasession.diseasesIllnesses,
			surgeries: ayurvedasession.surgeries,
			medicaments: ayurvedasession.medicamentsSupplements,
			allergies: ayurvedasession.allergies,
			pregnancies: ayurvedasession.pregnancies,
			familyMembers: ayurvedasession.familyHistory.map((item) => item),
			noteMedicalHistory: ayurvedasession.medicalHistoryNote,
			socialRelationship: ayurvedasession.socialRelationship,
			habits: ayurvedasession.habits.map((item) => item),
			profession: ayurvedasession.occupation.name,
			employmentStatus: ayurvedasession.occupation.state.map((item) => item),
			sport: ayurvedasession.sport.name.map((item) => item),
			sportFrequency: ayurvedasession.sport.frequency.map((item) => item),
			hobby: ayurvedasession.hobbies[0],
			// height: ayurvedasession.height,
			// heightUnit: ayurvedasession.heightUnit,
			// weight: ayurvedasession.weight,
			// weightUnit: ayurvedasession.weightUnit,
			// bmi: ayurvedasession.bmi,
			// bmiClassification: ayurvedasession.bmiClassification,
			// temperature: ayurvedasession.temperature,
			// temperatureUnit: ayurvedasession.temperatureUnit,
			thermalFeeling: ayurvedasession.thermalFeeling.map((item) => item),
			perspiration: ayurvedasession.perspiration.map((item) => item),
			appetite: ayurvedasession.appetite.map((item) => item),
			appetiteNote: ayurvedasession.appetiteNote,
			vomiting: ayurvedasession.vomiting.map((item) => item),
			vomitingNote: ayurvedasession.vomitingNote,
			diet: ayurvedasession.diet.map((item) => item),
			dietNote: ayurvedasession.dietNote,
			taste: ayurvedasession.taste.map((item) => item),
			thirst: ayurvedasession.thirst.map((item) => item),
			defecation: ayurvedasession.defecation.map((item) => item),
			urination: ayurvedasession.urination.map((item) => item),
			urinationColor: ayurvedasession.urineColor,
			sleep: ayurvedasession.sleeping.map((item) => item),
			head: ayurvedasession.head.map((item) => item),
			eyes: ayurvedasession.eyes.map((item) => item),
			ear: ayurvedasession.ear.map((item) => item),
			nose: ayurvedasession.nose.map((item) => item),
			throat: ayurvedasession.throat.map((item) => item),
			menstruation: ayurvedasession.menstruationHistory.map((item) => item),
			leukorrhea: ayurvedasession.leukorrhea.map((item) => item),
			localityOfPain: ayurvedasession.painLocation,
			natureOfPain: ayurvedasession.painNature,
			// emotionState: ayurvedasession.emotion.emotionState.map((item) => item),
			// emotionNote: ayurvedasession.emotion.emotionNote,
			mind: ayurvedasession.mind,
			interviewNote: ayurvedasession.interviewNote,
			respiration: ayurvedasession.respiration.map((item) => item),
			speech: ayurvedasession.speech.map((item) => item),
			cough: ayurvedasession.cough.map((item) => item),
			odor: ayurvedasession.odor.map((item) => item),
			vitality: ayurvedasession.vitality.map((item) => item),
			colorFace: ayurvedasession.faceColorLustre.map((item) => item),
			physicalAppearance: ayurvedasession.physical.appearance.map((item) => item),
			physicalAppearanceNote: ayurvedasession.physicalExaminationNote,
			epigastriumPalpation: ayurvedasession.physical.palpationEpigastrium.map((item) => item),
			epigastriumPalpationNote: ayurvedasession.physical.palpationEpigastriumNote,
			abdomenPalpation: ayurvedasession.physical.palpationAbdomen,
			// rangeMotion: ayurvedasession.rangeMotion,
			// painLevel: ayurvedasession.painLevel,
			physicalExaminationNote: ayurvedasession.physicalExaminationNote,
			ayurvedaDiagnosis: ayurvedasession.ayurvedaDiagnosis,
			principleTreatment: ayurvedasession.principleTreatment,
			treatmentNote: ayurvedasession.treatmentNote,
			remedy: ayurvedasession.remedy.map((item) => item),
			dietTherapy: ayurvedasession.dietTherapy,
			recommendation: ayurvedasession.recommendation,
		};
	}

	handleSubmit = async () => {
		console.log("state ayurvedasessions: ", this.state.data);
		try {
			await saveAyurvedasession(this.state.data);
			this.props.history.push("/clinic/medicalfiles");
		} catch (ex) {
			if (ex.response) {
				const errors = { ...this.state.errors };
				errors.status = ex.response.data;
				this.setState({ errors });
			}
		}
	};


	async populatePatients() {
		const { data: patients } = await http.get(apiUrl + "/patients");
		this.setState({ patients });


		this.selectUsers = this.state.patients.map((option) => (
			<option key={option._id} value={option._id}>
				{option.patients.contactName.first} {option.patients.contactName.last}
			</option>
		));
	}

	async populatePreviousSessions() {
		const { data: previousSessions } = await http.get(apiUrl + "/ayurvedasessions");
		this.setState({ previousSessions });
	}

	async populatePatient() {
		const { data: patient } = await http.get(`${apiUrl}/patients/${this.state.data.selectedRegisteredPatient}`);
		const Patient = patient[0];
		this.setState({
			patient:Patient,
		});
	}

	async populateDoctors() {
		const { data: doctors } = await http.get(apiUrl + "/doctors");
		// console.log("Doctors: ", doctors);
		this.setState({ doctors });


		this.selectDoctors = this.state.doctors.map((option) => (
			<option key={option._id} value={option._id}>
				{option.doctors.contactName.last}
			</option>
		));
	}

	async populateClinics() {
		const { data: clinics } = await http.get(apiUrl + "/clinicsolo");
		// console.log("Clinics: ", clinics);
		this.setState({ clinics });
		this.selectClinics = this.state.clinics.map((option) => (
			<option key={option._id} value={option._id}>
				{option.companyInfo.businessName}
			</option>
		));
	}

	async componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			await this.populatePatients();
			await this.populatePreviousSessions();
			await this.populateClinics();
			await this.populateDoctors();
			await this.populateAyurvedaSession();
			await this.populatePatient();
			await this.populateAMateriaMedica();
			await this.populateDiseasesconditions();
		}
	}

	async populateAMateriaMedica() {
		const { data: aMateriaMedicas } = await http.get(apiUrl + "/amateriamedicas");
		this.setState({ aMateriaMedicas });
		console.log("aMateriaMedic: ", this.state.aMateriaMedicas);
		let aMateriaMedicaArray = this.state.aMateriaMedicas.map((item) => {
			return {
				value: item.name,
				label: item.name,
			}
		});
		this.setState({
			aMateriaMedic: aMateriaMedicaArray
		});

	}

	async populateDiseasesconditions() {
		const { data: diseasesConditions } = await getAcupunctures();
		console.log("Diseases Conditions")
		console.log(diseasesConditions)
		this.setState({ diseasesConditions });
		let diseaseConditionsArray = this.state.diseasesConditions.map((item) => {
			return {
				value: item.diseasesconditions,
				label: item.diseasesconditions,
			}
		});
		this.setState({
			diseasesCondition: diseaseConditionsArray
		});
	}

	handleWesternDiseasesChange(value) {
		const data = { ...this.state.data };
		data["westernDisease"] = value;
		this.setState({ data });
	}

	componentWillUnmount() {
		this.setState({ data: {} })
		this._isMounted = false;
	}

	nextTab = () => {
		this.setState((prevState) => ({ activeTab: prevState.activeTab + 1 }));
	};

	previousTab = () => {
		this.setState((prevState) => ({ activeTab: prevState.activeTab - 1 }));
	};

	addFamilyMember = () =>
		this.setState({
			data: {
				...this.state.data,
				familyMembers: [...this.state.data.familyMembers, { familyMember: "", disease: "", year: "", state: "" }],
			},
		});

	removeFamilyMember = (index) => {
		this.setState({
			data: {
				...this.state.data,
				familyMembers: this.state.data.familyMembers.filter((mem, i) => index !== i),
			},
		});
	};

	addRemedy = () => {


		const data = { ...this.state.data };
		data.remedy = [...data.remedy, { materiaMedica: "", potency: "", unit:"" }];
		this.setState({ data });
	};

	removeRemedy = (index) => {
		this.setState({
			data: {
				...this.state.data,
				remedy: this.state.data.remedy.filter((mem, i) => index !== i),
			},
		});
	};

	handlePatientChange = async (e) => {
		let previousSessions = this.state.previousSessions;
		let selectedPatientPreviousSessions = previousSessions.filter((session)=>session.patientNo._id === e.target.value).reverse()
		let latestPreviousSession = selectedPatientPreviousSessions.length > 0 ? selectedPatientPreviousSessions[0] : {}
		console.log("latestPreviousSession",latestPreviousSession)
		if(Object.keys(latestPreviousSession).length > 0){
			this.setState(
				{
					data: {
						...this.state.data,
						selectedRegisteredPatient: e.target.value,
						currentTreatment: latestPreviousSession.currentTreatment.map((item) => item),
						diseases: latestPreviousSession.diseasesIllnesses,
						surgeries: latestPreviousSession.surgeries,
						medicaments: latestPreviousSession.medicamentsSupplements,
						allergies: latestPreviousSession.allergies,
						pregnancies: latestPreviousSession.pregnancies,
						familyMembers: latestPreviousSession.familyHistory.map((item) => item),
						noteMedicalHistory: latestPreviousSession.medicalHistoryNote,
						socialRelationship: latestPreviousSession.socialRelationship,
						habits: latestPreviousSession.habits.map((item) => item),
						profession: latestPreviousSession.occupation.name,
						employmentStatus: latestPreviousSession.occupation.state.map((item) => item),
						sport: latestPreviousSession.sport.name.map((item) => item),
						sportFrequency: latestPreviousSession.sport.frequency.map((item) => item),
						hobby: latestPreviousSession.hobbies,
					},
				},
				async () => {
					await this.populatePatient();
				}
			);
		}else{
			this.setState(
				{
					data: {
						...this.state.data,
						selectedRegisteredPatient: e.target.value,
						currentTreatment: [],
						diseases: "",
						surgeries: "",
						medicaments: "",
						allergies: "",
						pregnancies: "",
						familyMembers: [{ familyMember: "", disease: "", year: "", state: "" }],
						noteMedicalHistory: "",
						socialRelationship: "",
						habits: [],
						profession: "",
						employmentStatus: [],
						sport: "",
						sportFrequency: [],
						hobby: "",
					},
				},
				async () => {
					await this.populatePatient();
				}
			);
		}

		console.log('selected patient',this.state.patient);
	};


	renderTabButtons = () => {
		return (
			<div className="form-group float-right">
				<button type="button" className="btn btn-green btn-sm mr-2" onClick={this.previousTab}>Previous
				</button>
				<button type="button" className="btn btn-green btn-sm" onClick={this.nextTab}>Next
				</button>
				{this.props.match.params.id !== "new" && (
					<>
						<button type="button" className="btn btn-indigo btn-sm mr-2" style={{ marginLeft: "5px" }} onClick={this.handleSubmit}>Save
						</button>
						<Link to="/clinic/medicalfiles">
							<button type="button" className="btn btn-red btn-sm mr-2">Cancel
							</button>
						</Link>
					</>
				)}
			</div>
		);
	};

	render() {
		const tabMenus = [
			{ label: "Medical History", background: "#FFC69F" },
			{ label: "Interview", background: "#DED99F" },
			{ label: "Inspection & Examination", background: "#FFC6FF", },
			{ label: "Ayurveda Treatment", background: "#FFF5AD" },
			{ label: "File Attachments", background: "#A2F5AD" },
			{ label: "Previous Sessions", background: "#FFFFC9", },
		];

		return (
			<Fragment>
				{this.props.match.params.id !== "new" && (
					<>
						<ol className="breadcrumb float-xl-right">
							<li className="breadcrumb-item">
								<Link to="/">Home</Link>
							</li>
							<li className="breadcrumb-item">
								<Link to="/clinic">Clinic</Link>
							</li>
							<li className="breadcrumb-item">
								<Link to="/clinic/medicalfiles">Medical Files</Link>
							</li>
						</ol>
						<h1 className="page-header">Ayurveda Sessions </h1>
					</>
				)}

				<Panel>
					<PanelHeader noButton>Ayurveda Session</PanelHeader>
					<PanelBody>
						<h1>Add ayurveda session</h1>
						{this.props.match.params.id === "new" && (
							<div className="row form-group">
								<div className="col-12 col-sm-4">
									<label>
										<b>Select registered patient from administration *</b>
									</label>
								</div>
								<div className="col-12 col-sm-6">
									<select className="selectPatient" placeholder={"Select a patient"} name="selectedRegisteredPatient" onChange={this.handlePatientChange}
										value={this.state.data.selectedRegisteredPatient}>
										<option>Select Patient</option>
										{this.selectUsers}
									</select>
								</div>
							</div>
						)}
						<div className="row form-group">
							<div className="col-12 col-sm-4">
								<label>
									<b>Select Doctor</b>
								</label>
							</div>
							<div className="col-12 col-sm-6">
								<select className="selectDoctor" placeholder={"Select a doctor"} name="doctorNo"
									onChange={(e) => this.handleChange("doctorNo", e.target.value)}
									value={this.state.data.doctorNo}>
									<option>Select Doctor</option>
									{this.selectDoctors}
								</select>
							</div>
						</div>
						<div className="row form-group">
							<div className="col-12 col-sm-4">
								<label>
									<b>Select Clinic</b>
								</label>
							</div>
							<div className="col-12 col-sm-6">
								<select className="selectClinic" placeholder={"Select a clinic"} name="clinicNo"
									onChange={(e) => this.handleChange("clinicNo", e.target.value)}
									value={this.state.data.clinicNo}>
									<option>Select Clinic</option>
									{this.selectClinics}
								</select>
							</div>
						</div>
						<div className="row mt-4 row-no-margin">
							<div className="col-12 col-sm-2">
								<img
									src={this.state.data.selectedRegisteredPatient ? this.state.patient?.imageSrc : avatar}
									style={{ marginBottom: "1.5rem", height: "140px", width: "140px" }}
								/>
							</div>
							<div className="col-12 col-sm-10">
								<div className="row">
									<div className="col-12 col-sm-4">
										<div className="form-group">
											<label>
												<b>Prefix</b>
											</label>
											<input type="text" disabled className="form-control" placeholder="Prefix" name="prefix"
												value={this.state.data.selectedRegisteredPatient ? this.state.patient?.prefix : ""}
											/>
										</div>
									</div>
									<div className="col-12 col-sm-4">
										<div className="form-group">
											<label>
												<b>First Name</b>
											</label>
											<input type="text" disabled className="form-control" name="firstName" placeholder="First Name"
												value={
													this.state.data.selectedRegisteredPatient
														? this.state.patient?.contactName?.first
														: ""
												}
											/>
										</div>
									</div>
									<div className="col-12 col-sm-4">
										<div className="form-group">
											<label>
												<b>Last Name</b>
											</label>
											<input disabled type="text" className="form-control" name="lastName" placeholder="Last Name"
												value={
													this.state.data.selectedRegisteredPatient
														? this.state.patient?.contactName?.last
														: ""
												}
											/>
										</div>
									</div>
									<div className="col-12 col-sm-4">
										<div className="form-group">
											<div className="d-flex flex-column">
												<label>
													<b>Date of Birth *</b>
												</label>
												<input disabled type="text" className="form-control" name="dateBirth" placeholder="Date of birth"
													value={
														this.state.data.selectedRegisteredPatient
															? this.state.patient?.dateBirth?.toLocaleString("en-GB")
															: ""
													}
												/>
											</div>
										</div>
									</div>
									<div className="col-12 col-sm-4">
										<div className="form-group">
											<label>
												<b>Mobile Phone</b>
											</label>
											<input disabled type="text" className="form-control" name="mobilePhone" placeholder="Mobile Phone"
												value={
													this.state.data.selectedRegisteredPatient ? this.state.patient?.mobilePhone : ""
												}
											/>
										</div>
									</div>
									<div className="col-12 col-sm-4">
										<div className="form-group">
											<label>
												<b>Gender</b>
											</label>
											<input disabled type="text" className="form-control" name="gender" placeholder="Gender"
												value={this.state.data.selectedRegisteredPatient ? this.state.patient?.gender : ""}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<ReusableTabNavs
							setActiveTab={(n) => this.setActiveTab(n)}
							activeTab={this.state.activeTab}
							navprops={tabMenus}
						/>
						<TabContent activeTab={this.state.activeTab}>
							<ReusableTab id={1}>
								<div className="mt-5">
									<MedicalHistory
										handleChange={this.handleChange}
										handleMultiSelectChange={this.handleMultiSelectChange}
										handleMultiChange={this.handleMultiChange}
										handleFamilyMemberChange={this.handleFamilyMemberChange}
										infoState={this.state.data}
										addFamilyMember={this.addFamilyMember}
										removeFamilyMember={this.removeFamilyMember}
										isEditable={this.props.isEditable}
										users={this.selectUsers}
										diseasesConditions={this.state.diseasesConditions}
										diseasesCondition={this.state.diseasesCondition}
										handleWesternDiseasesChange={this.handleWesternDiseasesChange}
									/>
									<div className="form-group float-right">

										<button type="button" className="btn btn-green btn-sm" onClick={this.nextTab}>
											Next
										</button>
										{this.props.match.params.id !== "new" && (
											<>
												<button type="button" className="btn btn-indigo btn-sm mr-2" style={{ marginLeft: "5px" }} onClick={this.handleSubmit}>
													Save
												</button>
												<Link to="/clinic/medicalfiles">
													<button type="button" className="btn btn-red btn-sm mr-2">Cancel
													</button>
												</Link>
											</>
										)}
									</div>
								</div>
							</ReusableTab>
							<ReusableTab id={2} height={"100%"} width={"100%"}>
								<div className="mt-5">
									<Interview
										handleChange={this.handleChange}
										infoState={this.state.data}
										isEditable={this.props.isEditable}
										handleTextChange={this.handleTextChange}
										handleMultiSelectChange={this.handleMultiSelectChange}
										handleMultiChange={this.handleMultiChange}
									/>
									{this.renderTabButtons()}
								</div>
							</ReusableTab>
							<ReusableTab id={3}>
								<div className="mt-5">
									<InspectionAndExamination
										handleChange={this.handleChange}
										handleMultiSelectChange={this.handleMultiSelectChange}
										handleMultiChange={this.handleMultiChange}
										infoState={this.state.data}
										isEditable={this.props.isEditable}
									/>
									{this.renderTabButtons()}
								</div>
							</ReusableTab>
							<ReusableTab id={4}>
								<div className="mt-5">
									<AyurvedaTreatment
										handleChange={this.handleChange}
										infoState={this.state.data}
										isEditable={this.props.isEditable}
										addRemedy={this.addRemedy}
										aMateriaMedicas={this.state.aMateriaMedicas}
										aMateriaMedic={this.state.aMateriaMedic}
										removeRemedy={this.removeRemedy}
										handleRemedyChange={this.handleRemedyChange}
										handleMultiChange={this.handleMultiChange}
									/>
									<div className="form-group float-right">
										<button type="button" className="btn btn-green btn-sm mr-2" onClick={this.previousTab} > Previous </button>
										<button type="button" className="btn btn-indigo btn-sm mr-2" onClick={() => this.saveData()} > Save</button>
										<button type="button" className="btn btn-green btn-sm" onClick={this.nextTab} > Go to attach file</button>
									</div>
								</div>
							</ReusableTab>
							<ReusableTab id={5}>
								<Fragment>
									<h4>Sharing</h4>
									<p>
										Nullam ac sapien justo. Nam augue mauris, malesuada non magna sed, feugiat blandit ligula.
										In tristique tincidunt purus id iaculis. Pellentesque volutpat tortor a mauris convallis,
										sit amet scelerisque lectus adipiscing.
									</p>
								</Fragment>
							</ReusableTab>
							<ReusableTab id={6}>
								<PreviousSessions
									previoussessions={this.state.previousSessions}
									selectedPatient={this.state.data.selectedRegisteredPatient}
								/>
							</ReusableTab>
						</TabContent>
					</PanelBody>
				</Panel>
			</Fragment>
		);
	}
}
AddAyurvedaSession.defaultProps = {
	isEditable: true,
};

export default AddAyurvedaSession;

import React, { useEffect, useState } from "react"
import { getInternalPost, getInternalPosts } from "../services/internalposts";
import { setSeconds } from "date-fns";
import ListingDetailsNotes from "./actionsList";
import { getRootCause, getRootcauses } from "../services/rootcauses";
import Action from "../pages/kaizen/action";

const Notes = ({commentlists,reload}) =>{
const [count , setCount] = useState(0)
useEffect(()=>{
const data = async ()=> { const posts = await getInternalPosts()
  console.log(posts)
  setCount(posts.length)
  const r = await getRootcauses()
  console.log(r.data)
}
return data
},[] )
    return(
      <div className="">
        <div style={{ marginTop: "4em", color: "black"}}>
            <div className="comments-wrap">
<ListingDetailsNotes
reload={reload}
                    commentlists={commentlists} />
            </div>
        </div>
        </div>
                

    )
}

export default Notes
import React, { useEffect, useState } from 'react'
import option from "../options"
import Select from "react-select"
import { apiUrl } from "../../../../config/config.json"
import http from "../../../../services/httpService"
export const Personal = ({ changeInput, medicalInput, editMode }) => {

    const [patient, setPatient] = useState([])
    const [clinics, setclinics] = useState([])
    const [doctors, setdoctors] = useState([])
    const [aPatient, setaPatient] = useState([])


    // fetch all the patients

    const fetchPatients = async () => {
        try {
            const { data } = await http.get(apiUrl + "/patients");
            if (!data) return;
            setPatient(data)
        } catch (err) {
            console.log(err)

        }

    }
    // .. fetch all the doctors 
    const fetchDoctors = async () => {
        try {
            const { data } = await http.get(apiUrl + "/doctors");
            if (!data) return;
            setdoctors(data)
        } catch (err) {
            console.log(err)

        }

    }


    // fetch all the clinics
    const populateClinics = async () => {
        try {
            const { data } = await http.get(apiUrl + "/clinicsolo");
            if (!data) return;
            setclinics(data)
        } catch (err) {
            // console.log(err)

        }

    }


    async function fetchAPatient() {

        if (!medicalInput?.selectedRegisteredPatient) return;

        const { data: patient } = await http.get(`${apiUrl}/users/${medicalInput?.selectedRegisteredPatient}`);
        setaPatient(patient)

    }


    useEffect(() => {
        fetchPatients()
        fetchDoctors()
        populateClinics()
        fetchAPatient()
    }, [])


    const headingStyle = {
        margin: "30px 0 ",
        marginLeft: "20px",
        fontSize: "2.6em",
        color: " #6c5ce7",
        letterSpacing: "1.3px"

    }

    const headingContainer = {

        width: "100%",
        textAlign: "center"

    }
    const listheadingStyle = {
        fontSize: "1rem",
    }
    const disableStyle = {
        cursor: "not-allowed"
    }
    return (
        <div>
            <div className="row mt-4 row-no-margin">
                <div className="col-12 col-sm-2">
                    <img
                        src={aPatient?.imageSrc}
                        style={{ borderRadius: "50%", marginBottom: "1.5rem", height: "120px", width: "120px" }}
                    />
                </div>
                <div className="col-12 col-sm-10">
                    <div className="row">
                        <div className="col-12 col-sm-4">
                            <div className="form-group">
                                <label>
                                    <b>Prefix</b>
                                </label>
                                <input type="text" disabled className="form-control" placeholder="Prefix" name="prefix" value={aPatient?.prefix}/>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4">
                            <div className="form-group">
                                <label>
                                    <b>First Name</b>
                                </label>
                                <input type="text" disabled className="form-control" name="firstName" placeholder="First Name" 
                                    value={
                                        aPatient?.contactName?.first ?? " "
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-4">
                            <div className="form-group">
                                <label>
                                    <b>Last Name</b>
                                </label>
                                <input disabled type="text" className="form-control" name="lastName" placeholder="Last Name" 
                                    value={
                                        aPatient?.contactName?.last
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-4">
                            <div className="form-group">
                                <div className="d-flex flex-column">
                                    <label>
                                        <b>Date of Birth *</b>
                                    </label>
                                    <input disabled type="text" className="form-control" name="lastName" placeholder="Date of birth"
                                        value={
                                            aPatient?.dateBirth?.toLocaleString("en-GB") ?? "Empty "
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4">
                            <div className="form-group">
                                <label>
                                    <b>Mobile Phone</b>
                                </label>
                                <input disabled type="text" className="form-control" name="mobilePhone" placeholder="Mobile Phone" value={aPatient?.mobilePhone}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-4">
                            <div className="form-group">
                                <label>
                                    <b>Gender</b>
                                </label>

                                <input disabled type="text" className="form-control" name="gender" placeholder="gender" value={aPatient?.gender} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>

    )
}
import React from "react";
import PropTypes from "prop-types";
import { Input } from "@mui/material";
import options from "../options"
import Select from "react-select";
// import "./style.css";

const Interview = ({ handleMultipleSelect, handleSingleSelect, medicalInput, editMode, changeInput }) => {


	const rowStyle = {
		margin: "8px 0 "
	}



	const headingStyle = {
		margin: "30px 0 ",
		marginLeft: "20px",
		fontSize: "2.6em",
		color: "#D980FA",
		fontFamily: "Manrope, sans-serif",
		letterSpacing: "1.3px"

	}

	const headingContainer = {

		width: "100%",
		textAlign: "center"

	}

	return (
		<div className="main-container">

			<div style={headingContainer}>
				<h3 style={headingStyle} className="mt-10">Interview</h3>

			</div>
			<div className="panel-body" style={{ marginBottom: "40px" }}>
				<legend className="legend-text">Lifestyle</legend>


				<div className="form-group">
					<div className="row">
						<div className="col-12 col-md-3">
							<label>
								<b>Social Relationship:</b>
							</label>
						</div>
						<div className="col-12 col-md-4">
							<Select
								isDisabled={editMode}
								name="socialRelationship"
								options={options.socialRelationship}
								placeholder={"Select social relationship..."}
								value={
									medicalInput.socialRelationship && {
										value: medicalInput.socialRelationship,
										label: medicalInput.socialRelationship,
									}
								}
								onChange={(e) => handleSingleSelect("socialRelationship", e.value)}
							/>
						</div>
						<div className="col-5">&nbsp;</div>
					</div>
				</div>
				<div className="form-group">
					<div className="row">
						<div className="col-12 col-md-3">
							<label>
								<b>Habits:</b>
							</label>
						</div>
						<div className="col-12 col-md-4">
							<Select
								isDisabled={editMode}
								isMulti

								options={options.habits}
								placeholder={"Select habits..."}
								value={options.habits.filter((opt) => medicalInput.habits?.includes(opt.value))}
								onChange={(e) => handleMultipleSelect("habits", e)}
							/>
						</div>
						<div className="col-5">&nbsp;</div>
					</div>
				</div>

				<div className="form-group">
					<div className="row">
						<div className="col-12 col-sm-3">
							<label>
								<b>Profession:</b>
							</label>
						</div>
						<div className="col-12 col-sm-9">
							<input
								disabled={editMode}
								type="text"
								className="form-control"
								name="profession"
								placeholder="Your profession..."
								value={medicalInput.profession}
								onChange={(e) => changeInput(e)}
							/>
						</div>
					</div>
				</div>

				<div className="form-group">
					<div className="row">
						<div className="col-12 col-md-3">
							<label>
								<b>Employment Status:</b>
							</label>
						</div>
						<div className="col-12 col-md-4">
							<Select
								isDisabled={editMode}
								isMulti
								options={options.employmentStatus}
								placeholder={"Select employment status..."}
								value={options.employmentStatus.filter((opt) =>
									medicalInput.employmentStatus?.includes(opt.value)
								)}
								onChange={(e) => handleMultipleSelect("employmentStatus", e)}
							/>
						</div>
						<div className="col-5">&nbsp;</div>
					</div>
				</div>

				<div className="form-group">
					<div className="row">
						<div className="col-12 col-sm-3">
							<label>
								<b>Sport:</b>
							</label>
						</div>
						<div className="col-12 col-sm-9">
							<input
								disabled={editMode}
								className="form-control"
								type="text"
								name="sport"
								defaultValue={medicalInput.sport}
								onChange={(e) => changeInput(e)}
								placeholder="Readonly input here…"
								readOnly={editMode}
							/>
						</div>
					</div>
				</div>


				<div className="form-group">
					<div className="row">
						<div className="col-12 col-md-3">
							<label>
								<b>Sport Frequency:</b>
							</label>
						</div>
						<div className="col-12 col-md-4">
							<Select
								isDisabled={editMode}
								isMulti
								options={options.sportFrequency}
								placeholder={"Select sportFrequency status..."}
								value={options.sportFrequency.filter((opt) =>
									medicalInput.sportFrequency?.includes(opt.value)
								)}
								onChange={(e) => handleMultipleSelect("sportFrequency", e)}
							/>
						</div>
						<div className="col-5">&nbsp;</div>
					</div>
				</div>


				<div className="form-group">
					<div className="row">
						<div className="col-12 col-sm-3">
							<label>
								<b>Hobby:</b>
							</label>
						</div>
						<div className="col-12 col-sm-9">

							<textarea
								className="form-control"
								rows="3"
								type="text"
								name="hobby"
								defaultValue={medicalInput.hobby}
								onChange={changeInput}
								placeholder="Readonly input here…"
								readOnly={editMode}
							/>
						</div>
					</div>
				</div>


			</div>
			<div className="panel-body">
				<legend className="legend-text">Inquiring for physical constitution</legend>

				<div className="row">
					<div className="col-12 col-sm-4">
						<div className="row">
							<div className="col-12 col-sm-3">
								<div className="form-group">
									<label>
										<b>Height :</b>
									</label>
								</div>
							</div>
							<div className="col-8 col-sm-4">
								<input
									disabled={editMode}
									type="number"
									className="form-control"
									name="height"
									placeholder="height"
									defaultValue={medicalInput.height}
									onChange={(e) => changeInput(e)}
								/>
							</div>
							<div className="col-4 col-sm-4">
								<Select
									isDisabled={editMode}
									options={[
										{ label: "cm", value: "cm" },
										{ label: "inch", value: "inch" },
									]}
									placeholder={"Select unit..."}
									value={
										medicalInput.heightUnit && {
											label: medicalInput.heightUnit,
											value: medicalInput.heightUnit,
										}}
									onChange={(e) => handleSingleSelect("heightUnit", e.value)}
								/>

							</div>
						</div>
					</div>
					<div className="col-12 col-sm-4">
						<div className="row">
							<div className="col-12 col-sm-3">
								<div className="form-group">
									<label>
										<b>Weight:</b>
									</label>
								</div>
							</div>
							<div className="col-8 col-sm-4">
								<input

									disabled={editMode}
									type="number"
									className="form-control"
									name="weight"
									placeholder="weight"
									value={medicalInput.weight}
									onChange={(e) => changeInput(e)}
								/>
							</div>
							<div className="col-4 col-sm-4">
								<Select
									isDisabled={editMode}
									options={[
										{ label: "kg", value: "kg" },
										{ label: "lbs", value: "lbs" },
									]}
									placeholder={"Select unit..."}
									value={
										medicalInput.weightUnit && {
											label: medicalInput.weightUnit,
											value: medicalInput.weightUnit,
										}}
									onChange={(e) => handleSingleSelect("weightUnit", e.value)}
								/>
							</div>
						</div>
					</div>
					<div className="col-12 col-sm-4">
						<div className="row">
							<div className="col-12 col-sm-3">
								<div className="form-group">
									<label>
										<b style={{ whiteSpace: "nowrap" }}>Temperature:</b>
									</label>
								</div>
							</div>
							<div className="col-8 col-sm-4">
								<input

									disabled={editMode}
									type="number"
									className="form-control"
									name="temperature"
									placeholder="temperature"
									value={medicalInput.temperature}
									onChange={(e) => changeInput(e)}
								/>
							</div>
							<div className="col-4 col-sm-5">
								<Select
									isDisabled={editMode}
									options={[
										{ label: "Celcius (°C)", value: "celsius" },
										{ label: "Fahrenheit (°F)", value: "fahrenheit" },
									]}
									placeholder={"Select unit..."}
									value={[
										{ label: "Celcius(°C)", value: "celsius" },
										{ label: "Fahrenheit(°F)", value: "fahrenheit" },
									].find((unit) => unit.value === medicalInput.temperatureUnit)}
									onChange={(e) => handleSingleSelect("temperatureUnit", e.value)}
								/>

							</div>
						</div>
					</div>
				</div>


				<div className="table-responsive">

					<div className="form-group mt-2">
						<div className="row">
							<div className="col-12 col-md-1">
								<label>
									<b>BMI :</b>
								</label>
							</div>
							<div className="col-12 col-md-3">
								<input

									disabled={editMode}
									type="number"
									className="form-control"
									name="bmi"
									placeholder="temperature"
									value={medicalInput.bmi}
									onChange={(e) => changeInput(e)}
								/>

							</div>
							<div className="col-12 col-md-2">
								<label>
									<b>Classification :</b>
								</label>
							</div>
							<div className="col-12 col-md-3">
								<input
									disabled={editMode}
									type="text"
									className="form-control"
									name="bmiClassification"
									placeholder="BMI classification..."
									value={medicalInput.bmiClassification}
									onChange={(e) => changeInput(e)}
								/>
							</div>
						</div>
					</div>





























					<div className="form-group mt-4">
						<div className="row">
							<div className="col-12 col-md-3">
								<label>
									<b>1.&nbsp;Thermal Feeling :</b>
								</label>
							</div>
							<div className="col-12 col-md-4">
								<Select
									isDisabled={editMode}
									isMulti
									options={options.thermalFeeling}
									placeholder={`Select thermalFeeling...`}
									onChange={(e) => handleMultipleSelect("thermalFeeling", e)}
									value={options.thermalFeeling.filter((opt) => medicalInput.thermalFeeling?.includes(opt.value))}
								/>
							</div>
							<div className="col-5">&nbsp;</div>
						</div>
					</div>


					<div className="form-group">
						<div className="row">
							<div className="col-12 col-md-3">
								<label>
									<b>2.&nbsp;Perspiration :</b>
								</label>
							</div>
							<div className="col-12 col-md-4">
								<Select
									isDisabled={editMode}
									isMulti
									options={options.perspiration}
									placeholder={`Select perspiration...`}
									onChange={(e) => handleMultipleSelect("perspiration", e)}
									value={options.perspiration.filter((opt) => medicalInput.perspiration?.includes(opt.value))}
								/>
							</div>
							<div className="col-5">&nbsp;</div>
						</div>
					</div>




					<div className="form-group">
						<div className="row">
							<div className="col-12 col-md-3">
								<label>
									<b>3.&nbsp;Appetite :</b>
								</label>
								<Select
									isDisabled={editMode}
									isMulti
									options={options.appetite}
									placeholder={`Select appetite...`}
									onChange={(e) => handleMultipleSelect("appetite", e)}
									value={options.appetite?.filter((opt) => medicalInput.appetite?.includes(opt.value))}
								/>
							</div>
							<div className="col-12 col-md-9">
								<label>
									<b>Note for appetite :</b>
								</label>
								<textarea
									disabled={editMode}
									rows="3"
									type="text"
									className="form-control"
									name="appetiteNote"
									placeholder="Your additional information for appetite..."
									value={medicalInput.appetiteNote}
									onChange={changeInput}
								/>
							</div>
							<div className="col-5">&nbsp;</div>
						</div>
					</div>





					<div className="form-group">
						<div className="row">
							<div className="col-12 col-md-3">
								<label>
									<b>4.&nbsp;Vomiting :</b>
								</label>
								<Select
									isDisabled={editMode}
									isMulti
									options={options.vomiting}
									placeholder={`Select vomiting...`}
									onChange={(e) => handleMultipleSelect("vomiting", e)}
									value={options.vomiting.filter((opt) => medicalInput.vomiting?.includes(opt.value))}
								/>
							</div>
							<div className="col-12 col-md-9">
								<label>
									<b>Note for vomiting :</b>
								</label>
								<textarea
									rows="3"
									className="form-control"
									disabled={editMode}
									type="text"
									name="appetiteNote"
									value={medicalInput.appetiteNote}
									onChange={changeInput}
									placeholder="Write a appetite notes.."

								/>
							</div>
							<div className="col-5">&nbsp;</div>
						</div>
					</div>



					<div className="form-group">
						<div className="row">
							<div className="col-12 col-md-3">
								<label>
									<b>5.&nbsp;Diet :</b>
								</label>
								<Select
									isDisabled={editMode}
									isMulti
									options={options.diet}
									placeholder={`Select diet...`}
									onChange={(e) => handleMultipleSelect("diet", e)}
									value={options.diet.filter((opt) => medicalInput.diet?.includes(opt.value))}

								/>

							</div>
							<div className="col-12 col-md-9">
								<label>
									<b>Note for diet :</b>
								</label>
								<textarea
									className="form-control"
									disabled={editMode}
									type="text"
									name="dietNote"
									value={medicalInput.dietNote}
									onChange={changeInput}
									placeholder="dietNote"
									readOnly={editMode}
									rows={3}
								/>
							</div>
							<div className="col-5">&nbsp;</div>
						</div>
					</div>


					<div className="form-group mt-4" >
						<div className="row">
							<div className="col-12 col-md-3">
								<label>
									<b>
										6. Taster:
									</b>
								</label>
							</div>
							<div className="col-12 col-md-4">
								<Select
									isDisabled={editMode}
									isMulti
									options={options.taste}
									placeholder={`Select taste...`}
									onChange={(e) => handleMultipleSelect("taste", e)}
									value={options.taste.filter((opt) => medicalInput.taste?.includes(opt.value))}
								/>
							</div>
							<div className="col-5">&nbsp;</div>
						</div>
					</div>

					<div className="form-group mt-4" >
						<div className="row">
							<div className="col-12 col-md-3">
								<label>
									<b>
										7. Thirst:
									</b>
								</label>
							</div>
							<div className="col-12 col-md-4">
								<Select
									isDisabled={editMode}
									isMulti
									options={options.thirst}
									placeholder={`Select thirst...`}
									onChange={(e) => handleMultipleSelect("thirst", e)}
									value={options.thirst.filter((opt) => medicalInput.thirst?.includes(opt.value))}
								/>
							</div>
							<div className="col-5">&nbsp;</div>
						</div>
					</div>


					<div className="form-group mt-4" >
						<div className="row">
							<div className="col-12 col-md-3">
								<label>
									<b>
										8. Defecation/Stool:
									</b>
								</label>
							</div>
							<div className="col-12 col-md-4">
								<Select
									isDisabled={editMode}
									isMulti
									options={options.defecation}
									placeholder={`Select defecation/stool...`}
									onChange={(e) => handleMultipleSelect("defecation", e)}
									value={options.defecation.filter((opt) => medicalInput.defecation?.includes(opt.value))}
								/>
							</div>
							<div className="col-5">&nbsp;</div>
						</div>
					</div>
					<div className="form-group mt-4" >
						<div className="row">
							<div className="col-12 col-md-3">
								<label>
									<b>
										9. Urination:
									</b>
								</label>
							</div>
							<div className="col-12 col-md-4">
								<Select
									isDisabled={editMode}
									isMulti
									options={options.urination}
									placeholder={`Select urination...`}
									onChange={(e) => handleMultipleSelect("urination", e)}
									value={options.urination.filter((opt) => medicalInput.urination?.includes(opt.value))}
								/>
							</div>
							<div className="col-5">&nbsp;</div>
						</div>
					</div>
					<div className="form-group mt-4" >
						<div className="row">
							<div className="col-12 col-md-3">
								<label>
									<b>
										10. Urination-color:
									</b>
								</label>
							</div>
							<div className="col-12 col-md-4">
								<Select
									isDisabled={editMode}
									isMulti
									options={options.urinationColor}
									placeholder={`Select sleep...`}
									onChange={(e) => handleMultipleSelect("urinationColor", e)}
									value={options.urinationColor.filter((opt) => medicalInput.urinationColor?.includes(opt.value))}
								/>
							</div>
							<div className="col-5">&nbsp;</div>
						</div>
					</div>
					<div className="form-group mt-4" >
						<div className="row">
							<div className="col-12 col-md-3">
								<label>
									<b>
										11. Sleep:
									</b>
								</label>
							</div>
							<div className="col-12 col-md-4">
								<Select
									isDisabled={editMode}
									isMulti
									options={options.sleep}
									placeholder={`Select sleep...`}
									onChange={(e) => handleMultipleSelect("sleep", e)}
									value={options.sleep.filter((opt) => medicalInput.sleep?.includes(opt.value))}
								/>
							</div>
							<div className="col-5">&nbsp;</div>
						</div>
					</div>




					<div className="form-group mt-4" >
						<div className="row">
							<div className="col-12 col-md-3">
								<label>
									<b>
										12. Head:
									</b>
								</label>
							</div>
							<div className="col-12 col-md-4">
								<Select
									isDisabled={editMode}
									isMulti
									options={options.head}
									placeholder={`Select head...`}
									onChange={(e) => handleMultipleSelect("head", e)}
									value={options.head.filter((opt) => medicalInput.head?.includes(opt.value))}
								/>
							</div>
							<div className="col-5">&nbsp;</div>
						</div>
					</div>







					<div className="form-group mt-4" >
						<div className="row">
							<div className="col-12 col-md-3">
								<label>
									<b>
										13. Eyes:
									</b>
								</label>
							</div>
							<div className="col-12 col-md-4">
								<Select
									isDisabled={editMode}
									isMulti
									options={options.eyes}
									placeholder={`Select ear...`}
									onChange={(e) => handleMultipleSelect("eyes", e)}
									value={options.eyes.filter((opt) => medicalInput.eyes?.includes(opt.value))}
								/>
							</div>
							<div className="col-5">&nbsp;</div>
						</div>
					</div>




					<div className="form-group mt-4" >
						<div className="row">
							<div className="col-12 col-md-3">
								<label>
									<b>
										14. Ear:
									</b>
								</label>
							</div>
							<div className="col-12 col-md-4">
								<Select
									isDisabled={editMode}
									isMulti
									options={options.ear}
									placeholder={`Select ear...`}
									onChange={(e) => handleMultipleSelect("ear", e)}
									value={options.ear.filter((opt) => medicalInput.ear?.includes(opt.value))}
								/>
							</div>
							<div className="col-5">&nbsp;</div>
						</div>
					</div>



					<div className="form-group mt-4" >
						<div className="row">
							<div className="col-12 col-md-3">
								<label>
									<b>
										15. Nose:
									</b>
								</label>
							</div>
							<div className="col-12 col-md-4">
								<Select
									isDisabled={editMode}
									isMulti
									options={options.nose}
									placeholder={`Select nose...`}
									onChange={(e) => handleMultipleSelect("nose", e)}
									value={options.nose.filter((opt) => medicalInput.nose?.includes(opt.value))}
								/>
							</div>
							<div className="col-5">&nbsp;</div>
						</div>
					</div>










					<div className="form-group mt-4" >
						<div className="row">
							<div className="col-12 col-md-3">
								<label>
									<b>
										16. Throat:
									</b>
								</label>
							</div>
							<div className="col-12 col-md-4">
								<Select
									isDisabled={editMode}
									isMulti
									options={options.throat}
									placeholder={`Select throat...`}
									onChange={(e) => handleMultipleSelect("throat", e)}
									value={options.throat?.filter((opt) => medicalInput.throat?.includes(opt.value))}
								/>
							</div>
							<div className="col-5">&nbsp;</div>
						</div>
					</div>




					<div className="form-group mt-4" >
						<div className="row">
							<div className="col-12 col-md-3">
								<label>
									<b>
										17. Menstruation History:
									</b>
								</label>
							</div>
							<div className="col-12 col-md-4">
								<Select
									isDisabled={editMode}
									isMulti
									options={options.menstruation}
									placeholder={`Select menstruation...`}
									onChange={(e) => handleMultipleSelect("menstruation", e)}
									value={options.menstruation.filter((opt) => medicalInput.menstruation?.includes(opt.value))}
								/>
							</div>
							<div className="col-5">&nbsp;</div>
						</div>
					</div>






					<div className="form-group mt-4" >
						<div className="row">
							<div className="col-12 col-md-3">
								<label>
									<b>
										18. Leukorrhea:
									</b>
								</label>
							</div>
							<div className="col-12 col-md-4">
								<Select
									isDisabled={editMode}
									isMulti
									options={options.leukorrea}
									placeholder={`Select Leukorrhea...`}
									onChange={(e) => handleMultipleSelect("leukorrhea", e)}
									value={options.leukorrea.filter((opt) => medicalInput.leukorrhea?.includes(opt.value))}
								/>
							</div>
							<div className="col-5">&nbsp;</div>
						</div>
					</div>




					<div className="form-group mt-4" >
						<div className="row">
							<div className="col-12 col-md-3">
								<label>
									<b>
										19. Nature of Pain:
									</b>
								</label>
							</div>
							<div className="col-12 col-md-4">
								<input
									disabled={editMode}
									className="form-control"
									type="text"
									name="natureOfPain"
									value={medicalInput.natureOfPain}
									onChange={(e) => changeInput(e)}
									placeholder="Note for natureOfPain"
								/>

							</div>
							<div className="col-5">&nbsp;</div>
						</div>
					</div>




					<div className="form-group mt-4" >
						<div className="row">
							<div className="col-12 col-md-3">
								<label>
									<b>
										20. Locality of Pain:
									</b>
								</label>
							</div>
							<div className="col-5 col-md-4">
								<input

									disabled={editMode}
									type="text"
									className="form-control"
									name="localityOfPain"
									placeholder="Type in location of your pain..."
									value={medicalInput.localityOfPain}
									onChange={(e) => changeInput(e)}
								/>
							</div>
							<div className="col-5">&nbsp;</div>
						</div>
					</div>


					<div className="form-group">
						<div className="row">
							<div className="col-12 col-md-3">
								<label>
									<b>21&#46;&nbsp;Emotional Status&nbsp;:</b>
								</label>
								<Select
									isDisabled={editMode}
									isMulti
									options={options.emotion}
									placeholder={"Select emotional status..."}
									value={options?.emotion?.filter((opt) => medicalInput?.emotion?.includes(opt.value))}
									onChange={(e) => handleMultipleSelect("emotion", e)}
								/>
							</div>
							<div className="col-12 col-md-9">
								<label>
									<b>Note for Emotion :</b>
								</label>
								<textarea
									disabled={editMode}
									rows={3}
									className="form-control"
									type="text"
									name="noteEmotion"
									value={medicalInput.noteEmotion}
									onChange={(e) => changeInput(e)}
									placeholder="Note for emotion"
								/>
							</div>
							<div className="col-5">&nbsp;</div>
						</div>
					</div>














					<div className="form-group">
						<div className="row">
							<div className="col-12 col-sm-3">
								<label>
									<b>22&#46;&nbsp;Mind&nbsp;:</b>
								</label>
							</div>
							<div className="col-12 col-sm-9">
								<textarea
									className="form-control"
									disabled={editMode}
									type="text"
									rows={6}
									name="mind"
									value={medicalInput.mind}
									onChange={changeInput}
									placeholder="State of mind of patient..."
									readOnly={editMode}
								/>
							</div>
						</div>
					</div>





					<div className="form-group">
						<div className="row">
							<div className="col-12 col-sm-3">
								<label>
									<b>Note for interview :</b>
								</label>
							</div>
							<div className="col-12 col-sm-9">
								<textarea
									disabled={editMode}

									rows="6"
									className="form-control"
									name="interviewNote"
									placeholder="Type additional information of interviewer..."
									value={medicalInput.interviewNote}
									onChange={changeInput}
								/>
							</div>
						</div>
					</div>






				</div>
			</div>
		</div>
	);
}

// emotinal note 
// sport name 


export default Interview;


import { MedicalHistory } from "./MedicalHistory";
import Interview from "./Interview";
import InspectionAndExamination from "./InspectionAndExamination";
import { AyurvedaTreatment } from "./AyurvedaTreatment";
//import { Personal } from "./Personal"
import React, { useState, useEffect } from 'react';
import { Box } from "@mui/material"
import 'react-tabs/style/react-tabs.css';
import "../style.css"
import {
	Panel,
	PanelHeader,
	PanelBody,
} from "../../../../components/panel/panel.jsx"
import ReusableTabNavs from "../../../../common/ReusableTabNavs";
import ReusableTab from "../../../../common/ReusableTab";
import { TabContent } from "reactstrap";
import { saveAyurvedasession } from "../../../../services/ayurvedasessions";



const PreviousSessionsContainer = React.memo(props => {
	const { editMode, setEditMode, sessionData, setfetchAgain, fetchAgain } = props;
	const [medicalInput, setmedicalInput] = useState({});


	useEffect(() => {
		setmedicalInput(mapToViewModel(sessionData))
	}, [sessionData])



	function mapToViewModel(ayurvedasession) {
		return {
			_id:ayurvedasession?._id,
			selectedRegisteredPatient:ayurvedasession?.patientNo?._id,
			clinicNo:ayurvedasession?.clinicNo?._id,
			doctorNo:ayurvedasession?.doctorNo?._id,
			chiefComplaint:ayurvedasession?.chiefComplaint,
			symptoms:ayurvedasession?.symptoms,
			westernDisease:ayurvedasession?.WesternDisease,
			currentTreatment:ayurvedasession?.currentTreatment.map((item) => item),
			diseases:ayurvedasession?.diseasesIllnesses,
			surgeries:ayurvedasession?.surgeries,
			medicaments:ayurvedasession?.medicamentsSupplements,
			allergies:ayurvedasession?.allergies,
			pregnancies:ayurvedasession?.pregnancies,
			familyMembers:ayurvedasession?.familyHistory.map((item) => item),
			noteMedicalHistory:ayurvedasession?.medicalHistoryNote,
			socialRelationship:ayurvedasession?.socialRelationship,
			habits:ayurvedasession?.habits.map((item) => item),
			occupation:ayurvedasession?.occupation.name,
			employmentStatus:ayurvedasession?.occupation?.state?.map((item) => item),
			sport:ayurvedasession?.sport.name.map((item) => item),
			sportFrequency:ayurvedasession?.sport.frequency.map((item) => item),
			hobby:ayurvedasession?.hobbies,
			height:ayurvedasession?.height,
			heightUnit:ayurvedasession?.heightUnit,
			weight:ayurvedasession?.weight,
			weightUnit:ayurvedasession?.weightUnit,
			bmi:ayurvedasession?.bmi,
			bmiClassification:ayurvedasession?.bmiClassification,
			temperature:ayurvedasession?.temperature,
			temperatureUnit:ayurvedasession?.temperatureUnit,
			thermalFeeling:ayurvedasession?.thermalFeeling.map((item) => item),
			perspiration:ayurvedasession?.perspiration.map((item) => item),
			appetite:ayurvedasession?.appetite.map((item) => item),
			appetiteNote:ayurvedasession?.appetiteNote,
			vomiting:ayurvedasession?.vomiting.map((item) => item),
			vomitingNote:ayurvedasession?.vomitingNote,
			diet:ayurvedasession?.diet.map((item) => item),
			dietNote:ayurvedasession?.dietNote,
			taste:ayurvedasession?.taste.map((item) => item),
			thirst:ayurvedasession?.thirst.map((item) => item),
			defecation:ayurvedasession?.defecation.map((item) => item),
			urination:ayurvedasession?.urination.map((item) => item),
			urineColor:ayurvedasession?.urineColor,
			sleeping:ayurvedasession?.sleeping.map((item) => item),
			head:ayurvedasession?.head.map((item) => item),
			eyes:ayurvedasession?.eyes.map((item) => item),
			ear:ayurvedasession?.ear.map((item) => item),
			nose:ayurvedasession?.nose.map((item) => item),
			throat:ayurvedasession?.throat.map((item) => item),
			menstruation:ayurvedasession?.menstruationHistory.map((item) => item),
			leukorrhea:ayurvedasession?.leukorrhea.map((item) => item),
			painLocation: ayurvedasession?.painLocation,
			painNature: ayurvedasession?.painNature,
			emotion:ayurvedasession?.emotional?.status?.map((item) => item),
			noteEmotion:ayurvedasession?.emotional?.note,
			mind:ayurvedasession?.mind,
			interviewNote:ayurvedasession?.interviewNote,
			respiration:ayurvedasession?.respiration.map((item) => item),
			speech:ayurvedasession?.speech.map((item) => item),
			cough:ayurvedasession?.cough.map((item) => item),
			odor:ayurvedasession?.odor.map((item) => item),
			vitality:ayurvedasession?.vitality.map((item) => item),
			colorFace:ayurvedasession?.faceColorLustre.map((item) => item),
			physicalAppearance:ayurvedasession?.physical.appearance.map((item) => item),
			physicalAppearanceNote:ayurvedasession?.physicalExaminationNote,
			palpationEpigastrium:ayurvedasession?.physical.palpationEpigastrium.map((item) => item),
			palpationEpigastriumNote:ayurvedasession?.physical.palpationEpigastriumNote,
			palpationAbdomen:ayurvedasession?.physical.palpationAbdomen,
			rangeMotion:ayurvedasession?.rangeMotion,
			painLevel:ayurvedasession?.painLevel,
			physicalExaminationNote:ayurvedasession?.physicalExaminationNote,
			ayurvedaDiagnosis:ayurvedasession?.ayurvedaDiagnosis,
			principleTreatment:ayurvedasession?.principleTreatment,
			treatmentNote:ayurvedasession?.treatmentNote,
			// remedy:ayurvedasession?.remedy?.map((item) => item),
			dietTherapy:ayurvedasession?.dietTherapy,
			recommendation:ayurvedasession?.recommendation,
		};
	}
	// handles the input datas 
	const changeInput = (e) => {
		setmedicalInput((prev) => {
			return {
				...prev, [e.target.name]: e.target.value
			}
		})
	};

	// handle the single data selection 
	const handleSingleSelect = (name, value) => {

		setmedicalInput((prev) => {
			return {
				...prev, [name]: value
			}
		})
	}


	// handle the multiple selection and store in arr
	const handleMultipleSelect = (name, value) => {
		let newValue = value && value.map((item) => item.value)

		setmedicalInput((prev) => {
			return {
				...prev, [name]: newValue ?? []
			}
		})
	}



	// change the family info  inputs 
	const handleFamilyMemberChange = (name, value, indexFamily) => {

		let medicalInputs = { ...medicalInput };
		let changeItem = medicalInputs.familyMembers.find((item, index) => index == indexFamily)
		changeItem[name] = value;
		medicalInputs.familyMembers[indexFamily] = changeItem;

		setmedicalInput(medicalInputs)
	}


	// // add new family memeber
	const addFamilyMember = () => {

		setmedicalInput((prev) => {
			return {
				...prev, familyMembers: [...prev.familyMembers, { familyMember: "", disease: "", year: "", state: "" }]
			}
		})
	}


	// remove a family memeber
	const removeFamilyMember = (familyIndex) => {

		let medicalinput = { ...medicalInput }
		let newmedicalInput = medicalinput.familyMembers.filter((val, index) => index !== familyIndex)
		medicalinput.familyMembers = newmedicalInput
		setmedicalInput(medicalinput)

	};


	// handle remedy changes 

	const handleRemedyChange = (name, value, remedyIndex) => {

		let medicalInputCopy = { ...medicalInput };
		let getRemedy = medicalInputCopy.remedy.find((item, index) => index === remedyIndex)
		getRemedy[name] = value;
		medicalInputCopy.remedy[remedyIndex] = getRemedy;
		setmedicalInput(medicalInputCopy)
	}


	//remove the remedy

	const removeRemedy = (remedyIndex) => {
		let medicalinput = { ...medicalInput }
		let newmedicalInput = medicalinput.remedy.filter((val, index) => index !== remedyIndex)
		medicalinput.remedy = newmedicalInput
		setmedicalInput(medicalinput)
	}

	// addRemedy 
	const addRemedy = () => {

		setmedicalInput((prev) => {
			return { ...prev, remedy: [...prev.remedy, { materiaMedica: "", potency: "" }] }
		})

	}



	// switch to the prev tab
	const prevTab = () => {

		if (activeTab === 1) return
		setActiveTab(prev => prev - 1)

	}

	//switch to the next tab
	const nextTab = () => {

		if (activeTab === 5) return
		setActiveTab(prev => prev + 1)

	}

	const updateMedicalSession = async () => {
		try {
			await saveAyurvedasession(medicalInput);
			setEditMode(!editMode)
			setfetchAgain(!fetchAgain)
		} catch (err) {
			// console.log(err)

		}
	}



	const RenderTabButtons = () => {
		const tabButtonsStyleContainer = {
			marginTop: "20px"
		}
		return (
			<div className="form-group float-right " style={tabButtonsStyleContainer}>
				{
					!editMode && <button type="button" class="btn btn-red btn-md mr-2" onClick={() => setEditMode(true)} >
						Cancel
					</button>
				}
				<button disabled={(5 - activeTab >= 4) && "true"} style={{ cursor: (5 - activeTab >= 4) && "not-allowed", backgroundColor: (5 - activeTab >= 4) && "black" }} type="button" className="btn btn-green btn-md mr-2" onClick={prevTab}>
					Previous
				</button>
				<button style={{ cursor: (5 - activeTab < 1) && "not-allowed" }} type="button" class="btn btn-green btn-md  mr-2" onClick={nextTab}>
					Next
				</button>
				{
					!editMode && <button type="button" class="btn btn-blue btn-md mr-2" onClick={updateMedicalSession}>
						Update
					</button>
				}

			</div>
		);
	};
	const medicalFileStyle = {
		letterSpacing: "1.3px",
		fontSize: "1.2rem"

	}

	const TabBox = {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	}
	const [activeTab, setActiveTab] = useState(1)
	const changesetActiveTab = (n) => setActiveTab(n);

	return (
		<>

			<Box sx={{ width: '100%' }}>



				{
					medicalInput._id && <>
						<Panel>
							<PanelHeader style={medicalFileStyle} noButton>View & Edit MedicalFile</PanelHeader>
							<PanelBody>
								<div style={TabBox}>

									<ReusableTabNavs
										setActiveTab={changesetActiveTab}
										activeTab={activeTab}
										navprops={[
											{ label: "Medical History", background: "#DED99F" },
											{ label: "Interview", background: "#FFC6FF" },
											{ label: "Inspection And Examination", background: "#FFF5AD" },
											{ label: "Ayurveda Treatment", background: "#A2F5AD" },
										]}
									/>
								</div>
								<TabContent activeTab={activeTab}>

									<ReusableTab id={1} height={"100%"} width={"100%"}>
										<>
											<MedicalHistory handleSingleSelect={handleSingleSelect} handleMultipleSelect={handleMultipleSelect} addFamilyMember={addFamilyMember} removeFamilyMember={removeFamilyMember} handleFamilyMemberChange={handleFamilyMemberChange} changeInput={changeInput} medicalInput={medicalInput} editMode={editMode} />
											<RenderTabButtons />
										</>
									</ReusableTab>
									<ReusableTab id={2}>
										<>
											<Interview handleMultipleSelect={handleMultipleSelect} handleSingleSelect={handleSingleSelect} changeInput={changeInput} medicalInput={medicalInput} editMode={editMode} />
											<RenderTabButtons />

										</>
									</ReusableTab>
									<ReusableTab id={3}>
										<>
											<InspectionAndExamination handleSingleSelect={handleSingleSelect} handleMultipleSelect={handleMultipleSelect} changeInput={changeInput} medicalInput={medicalInput} editMode={editMode} />
											<RenderTabButtons />
										</>
									</ReusableTab>
									<ReusableTab id={4}>
										<>
											<AyurvedaTreatment addRemedy={addRemedy} removeRemedy={removeRemedy} handleRemedyChange={handleRemedyChange} handleMultipleSelect={handleMultipleSelect} handleSingleSelect={handleSingleSelect} changeInput={changeInput} medicalInput={medicalInput} editMode={editMode} />
											<RenderTabButtons />
										</>
									</ReusableTab>

								</TabContent>
							</PanelBody>
						</Panel>


					</>
				}

			</Box>
		</>)
});

export default PreviousSessionsContainer



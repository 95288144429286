import React, { Component, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel.jsx";
import { getMedicalFiles, deleteMedicalFile, saveMedicalFile } from "../../../services/medicalfiles";
import "bootstrap/dist/css/bootstrap.min.css";
import Pagination from "../../../common/pagination";
import { paginate } from "../../../utils/paginate";
import MedicalFilesTable from "../../../components/medicalfilesTable.jsx";
import SearchBox from "./../../../common/searchBox";
import _ from "lodash";
import http from "../../../services/httpService";
import { apiUrl } from "../../../config/config.json";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { Col, Button, Form, FormGroup, Input, Modal, Label, ModalHeader, ModalBody, Row } from "reactstrap";
import ReusableTabNavs from "../../../common/ReusableTabNavs";
import ReusableTab from "../../../common/ReusableTab";
import { TabContent } from "reactstrap";
//not needed
//import PreviousSessionsContainer from "./previoussessions/PreviousSessionsContainer";
// Icons imports
import newIcon from "../../../assets/Icons/new.svg";
import trash from "../../../assets/Icons/trash.svg";
import csvIcon from "../../../assets/Icons/csv.svg";
import xlsIcon from "../../../assets/Icons/xls.svg";
import pdfIcon from "../../../assets/Icons/pdf.svg";
import archiveIcon from "../../../assets/Icons/archive.svg";
import printerIcon from "../../../assets/Icons/printer-xxl.svg";
import clipboardIcon from "../../../assets/Icons/copy2clipboard.svg";
import importIcon from "../../../assets/Icons/import.svg";
import { useTable } from "react-table";
import Icon from "./../../../common/icon";
import PreviousSessions from "./PreviousSessions.js";
import { getTCMsessions, saveTCMsession } from "../../../services/tcmsessions";

const tabMenus = [
	{ label: "Active", background: "#2BD62E" },
	{ label: "Archived", background: "#939993" },
];

function MedicalFilesTableData() {
	const [medicalFiles, setMedicalFiles] = useState([]);
	const [medicalFilesData, setMedicalFilesData] = useState([]);
	const [checkedFields, setCheckedFields] = useState([]);
	const [fetchAgain, setfetchAgain] = useState(false)




	const getAllMedicalFiles = async () => {
		const { data } = await getTCMsessions();
		setMedicalFiles(data);

        console.log(JSON.stringify(data))

		let files = data.map((item) => {
			return {
				avatar: item.patientNo?.imageSrc,
				name: item?.name,
				patient: item.patientNo?.contactName?.last,
				complaint: item?.chiefComplaint,
				date: item.createdOn,
				session: item.sessionType,
				practitioner: "practitioner",
				clinic: "clinic",
				viewSession: item,
				visibleFile: true,
			};
		});
		setMedicalFilesData(files);
	};

	const viewFile = (id) => {
		setMedicalFilesData(
			medicalFilesData.map((item) => {
				if (id === item.viewSession._id) {

					return item;
				} else {

					return { ...item, visibleFile: !item.visibleFile };
				}
			})
		);
	};


	const handleCheckboxChange = (e) => {
		if (e.target.checked) {
			setCheckedFields([...checkedFields, e.target.value]);
		} else {
			setCheckedFields(checkedFields.filter((field) => field !== e.target.value));
		}
	};

	const selectItem = async (e) => {
		const { data } = await getMedicalFiles();

		const { checked } = e.target;
		const collection = [];

		if (checked) {
			data.map((item) => {
				collection.push(item._id);
			});
		}

		setCheckedFields(collection);
	};

	// delete medical files
	const handleDelete = (CheckedMedicalFiles) => {
		const originalMedicalFiles = medicalFilesData;
		CheckedMedicalFiles.map(async (file) => {
			setMedicalFilesData(medicalFilesData.filter((mf) => mf._d !== file));
			try {
				await http.delete(apiUrl + "/tcmsessions/" + file);
			} catch (ex) {
				if (ex.response && ex.response === 404) {
					alert("already deleted");
				}
				setMedicalFilesData(originalMedicalFiles);
			}
		});
	};

	useEffect(() => {
		getAllMedicalFiles();
	}, []);

	const columns = React.useMemo(
		() => [
			{
				Header: <input type="checkbox" style={{ marginLeft: "12px" }} onClick={(e) => selectItem(e)} />,
				accessor: "col1",
				sortable: false,
			},
			{
				Header: "Patient",
				accessor: "col3", // accessor is the "key" in the data
				sortable: true,
			},
			{
				Header: "Complaint",
				accessor: "col4",
				sortable: true,
			},
			{
				Header: "Date/Time",
				accessor: "col5", // accessor is the "key" in the data
				sortable: true,
			},
			{
				Header: "Session",
				accessor: "col6",
				sortable: true,
			},
			{
				Header: "Practitioner",
				accessor: "col8", // accessor is the "key" in the data
				sortable: true,
			},
			{
				Header: "Clinic",
				accessor: "col10", // accessor is the "key" in the data
				sortable: true,
			},
			{
				Header: "Previous Sessions",
				accessor: "col11",
				sortable: false,
			},
		],
		[]
	);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
		columns,
		data: medicalFilesData,
	});

	const DisplayMedicalFiles = () => {
		return <></>;
	};

	const [activeTab, setActiveTab] = React.useState(1);
	const [entries, setEntries] = React.useState(10);
	const [searchQuery, setSearchQuery] = React.useState("");

	const setActiveView = (n) => setActiveTab(n);

	const targetHeight = 34;

	const customStyles = {
		control: (styles) => ({
			...styles,
			minHeight: "initial",
		}),

		option: (provided) => ({
			...provided,
		}),
		valueContainer: (base) => ({
			...base,
			padding: "0 8px",
		}),
		clearIndicator: (base) => ({
			...base,
			padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
		}),
		dropdownIndicator: (base) => ({
			...base,
			padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
		}),
	};

	return (
		<div >
			<div className="d-flex justify-content-between align-items-center">
				<h1 className="page-header text-center">Medical Files of Patients </h1>
			</div>
			{/* {console.log("Checked : ", checkedFields)} */}
			<Panel>
				<PanelHeader>Management for Medical Files</PanelHeader>
				<React.Fragment>
					<ToastContainer />
					<div className="toolbar" style={toolbarStyles}>
						<Icon
							to="/clinic/tcmsession/new"
							title="add tcmsession"
							btnStyle={btnStyles}
							iconStyle={iconStyles}
							icon={newIcon}
						/>
						<Icon
							title="Delete medical file"
							btnStyle={btnStyles}
							iconStyle={iconStyles}
							icon={trash}
							onClick={() => handleDelete(checkedFields)}
						/>
						<Icon
							to="/clinic/medicalfiles"
							title="csv"
							btnStyle={btnStyles}
							iconStyle={iconStyles}
							icon={csvIcon}
						/>
						<Icon
							to="/clinic/medicalfiles"
							title="pdf"
							btnStyle={btnStyles}
							iconStyle={iconStyles}
							icon={pdfIcon}
						/>
						<Icon
							to="/clinic/medicalfiles"
							title="xls"
							btnStyle={btnStyles}
							iconStyle={iconStyles}
							icon={xlsIcon}
						/>
						<Icon
							to="/clinic/medicalfiles"
							title="printer"
							btnStyle={btnStyles}
							iconStyle={iconStyles}
							icon={printerIcon}
						/>
						<Icon
							to="/clinic/medicalfiles"
							title="clipboard"
							btnStyle={btnStyles}
							iconStyle={iconStyles}
							icon={clipboardIcon}
						/>
						<Icon
							to="/clinic/medicalfiles"
							title="archive"
							btnStyle={btnStyles}
							iconStyle={iconStyles}
							icon={archiveIcon}
						/>
						<Icon
							to="/clinic/medicalfiles"
							title="import"
							btnStyle={btnStyles}
							iconStyle={iconStyles}
							icon={importIcon}
						/>
					</div>
				</React.Fragment>

				<hr className="m-0" />
				<PanelBody>
					<ReusableTabNavs setActiveTab={(n) => setActiveView(n)} activeTab={activeTab} navprops={tabMenus} />
					<TabContent activeTab={activeTab}>
						<ReusableTab id={1}>
							<div className="d-flex justify-content-between align-items-center mt-4">
								<div className="d-flex align-items-center">
									Show&nbsp;
									<div style={{ width: "70px" }}>
										<Select
											styles={customStyles}
											options={[
												{
													value: 10,
													label: 10,
												},
											]}
											placeholder={"Entries..."}
											value={{
												value: entries,
												label: entries,
											}}
											onChange={(e) => setEntries(e.value)}
										/>
									</div>
									&nbsp;entries
								</div>
								<div className="d-flex align-items-center">
									<span>Search:&nbsp;</span>
									<input className="form-control" type="text" />
								</div>
							</div>

							<div className="table-responsive mt-3">
								<table className="table table-bordered" {...getTableProps()}>
									<thead>
										{headerGroups.map((headerGroup) => (
											<tr {...headerGroup.getHeaderGroupProps()}>
												{headerGroup.headers.map((column) => (
													<th {...column.getHeaderProps()}>
														<div class="d-flex">
															<span>{column.render("Header")}</span>
															<span class="ml-auto">
																{column.sortable ? (
																	column.isSorted ? (
																		column.isSortedDesc ? (
																			<i className="fa fa-sort-down fa-fw f-s-14 text-blue"></i>
																		) : (
																			<i className="fa fa-sort-up fa-fw f-s-14 text-blue"></i>
																		)
																	) : (
																		<i className="fa fa-sort fa-fw f-s-14 opacity-3"></i>
																	)
																) : (
																	""
																)}
															</span>
														</div>
													</th>
												))}
											</tr>
										))}
									</thead>
									<tbody {...getTableBodyProps()}>
										{rows.map((row, index) => {
											// console.log("ROw: ", row);
											//  let date =  new Date().toLocaleDateString(); 
											let date = new Date(row.original?.viewSession?.createdOn);
											let d = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
                                            console.log("row",row)
											// this are the single medical file INFORMATION 
											return (
												row.original.visibleFile && (
													<>
														<tr>
															<input
																type="checkbox"
																style={{
																	width: "15px",
																	height: "15px",
																	marginTop: "15px",
																	marginLeft: "20px",
																	borderRadius: "2px",
																}}
																value={row.original.viewSession._id}
																onChange={(e) => handleCheckboxChange(e)}
																checked={checkedFields.includes(row.original.viewSession._id)}
															/>
															
															<td>
															<img
																	src={row.original.avatar}
																	style={{ height: "30px", width: "30px", borderRadius: "50%" }}
																/>
																{row.original.patient}</td>
															<td>{row.original.complaint}</td>
															<td>
																{d}{" "}
																{/* {row.original?.viewSession?.createdOn} */}
															</td>
															<td>{row.original.session !== "" ? row.original.session : "intake"}</td>
														
															<td>
															<img
																	src={row.original.viewSession?.doctorNo?.imageSrc}
																	style={{ height: "30px", width: "30px", borderRadius: "50%" }}
																/>
																{row.original.viewSession?.doctorNo?.contactName?.last}</td>
															
															<td>
															<img
																	src={row.original?.viewSession?.clinicNo?.imageSrc}
																	style={{ height: "30px", width: "30px", borderRadius: "50%" }}
																/>
																
																{row.original.viewSession?.clinicNo?.contactName?.last}</td>
															<td>
																<a
																	style={{ color: "blue" }}
																	data-toggle="collapse"
																	data-target={`#multiCollapseExample1${index}`}
																	role="button"
																	aria-expanded="false"
																	aria-controls={`multiCollapseExample1${index}`}
																	onClick={() => viewFile(row.original?.viewSession?._id)}
																>
																	View /Edit sessions
																</a>
															</td>
														</tr>
														<tr>
															<td colSpan={12}>
																<div
																	class="collapse multi-collapse"
																	id={`multiCollapseExample1${index}`}
																>
																	<PreviousSessions fetchAgain={fetchAgain} setfetchAgain={setfetchAgain} key={"prev1"}
																		selectedPatient={row.original?.viewSession?.patientNo?._id}
																	/>
																</div>
															</td>
														</tr>
													</>
												));
										})}
									</tbody>
								</table>
							</div>
							{/* <div className="d-flex justify-content-between align-items-center">
								<div className="text-muted">Showing 0 to 0 of 0 entries</div>
								<div className="d-flex">
									<button className="btn btn-green btn-sm mr-2">Previous</button>
									<button className="btn btn-green btn-sm">Next</button>
								</div>
							</div> */}
						</ReusableTab>
						<ReusableTab id={2} height={"100%"} width={"100%"}>
							<DisplayMedicalFiles />
						</ReusableTab>
					</TabContent>
				</PanelBody>
			</Panel>
		</div>
	);
}

const toolbarStyles = {
	background: "#c8e9f3",
	padding: "10px",
};

const btnStyles = { background: "#348fe2", margin: "0rem" };
const iconStyles = {
	width: "25px",
	height: "25px",
	marginRight: "0rem",
};

export default MedicalFilesTableData;

import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Select from "react-select";
import options from "./options";
import "./style.css";

class InspectionAndExamination extends React.Component {
	render() {
		const targetHeight = 34;

		const customStyles = {
			control: (styles) => ({
				...styles,
				minHeight: "initial",
			}),

			option: (provided) => ({
				...provided,
			}),
			valueContainer: (base) => ({
				...base,
				padding: "0 8px",
			}),
			clearIndicator: (base) => ({
				...base,
				padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
			}),
			dropdownIndicator: (base) => ({
				...base,
				padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
			}),
		};

		const { handleChange, handleMultiSelectChange, handleMultiChange, infoState, isEditable } = this.props;
		// console.log("infoState", infoState);
		// console.log("options", options.respiration);

		return (
			<div className="main-container">
				<h3 className="mt-10">Inspection & Examination</h3>
				<div className="panel-body">
					<fieldset>
						<legend className="legend-text">Listening and Smelling</legend>
						{[
							{
								label: "Respiration", options: options.respiration, variable: "respiration", num: "1",
							},
							{
								label: "Speech", options: options.speech, variable: "speech", num: "2",
							},
							{
								label: "Cough", options: options.cough, variable: "cough", num: "3",
							},
							{
								label: "Odor", options: options.odor, variable: "odor", num: "4",
							},
						].map((el, index) => (
							<div className="form-group" key={index}>
								<div className="row">
									<div className="col-12 col-md-3">
										<label>
											<b>{el.label}&nbsp;:</b>
										</label>
									</div>
									<div className="col-12 col-md-4">
										<Select
											isDisabled={!isEditable}
											isMulti
											styles={customStyles}
											options={el.options}
											placeholder={`Select ${el.label.toLowerCase()}...`}
											value={el.options.filter((opt) => infoState[el.variable]?.includes(opt.value))}
											//onChange={(e) => handleMultiSelectChange(el.variable, e)}
											onChange={(e) => handleMultiChange(el.variable, e)}
										/>
									</div>
									<div className="col-5">&nbsp;</div>
								</div>
							</div>
						))}
					</fieldset>
				</div>

				<div className="panel-body">
					<fieldset>
						<legend className="legend-text">Observation</legend>
						{[
							{
								label: "Vitality", options: options.vitality, variable: "vitality", num: "1",
							},
							{
								label: "Color and Lustre of Face", options: options.colorFace, variable: "colorFace", num: "3",
							},
						].map((el, index) => (
							<div className="form-group" key={index}>
								<div className="row">
									<div className="col-12 col-md-3">
										<label>
											<b>{el.label}&nbsp;:</b>
										</label>
									</div>
									<div className="col-12 col-md-4">
										<Select
											isDisabled={!isEditable}
											isMulti
											styles={customStyles}
											options={el.options}
											placeholder={`Select ${el.label.toLowerCase()}...`}
											value={el.options.filter((opt) => infoState[el.variable]?.includes(opt.value))}
											//onChange={(e) => handleMultiSelectChange(el.variable, e)}
											onChange={(e) => handleMultiChange(el.variable, e)}
										/>
									</div>
									<div className="col-5">&nbsp;</div>
								</div>
							</div>
						))}
					</fieldset>
				</div>

				<div className="panel-body">
					<fieldset>
						<legend className="legend-text">Physical Examination</legend>
						<div className="form-group">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>Physical Appearance&nbsp;: (Aakruti)</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.physicalAppearance}
										placeholder={"Select Appearance..."}
										value={options.physicalAppearance.filter((opt) =>
											infoState?.physicalAppearance?.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("physicalAppearance", e)}
									/>
								</div>
								<div className="col-12 col-md-9">
									<label>
										<b>Note for Physical Appearance :</b>
									</label>
									<textarea
										disabled={!isEditable}
										rows="3" type="text" className="form-control" name="physicalAppearanceNote"
										placeholder="Type additional information for physical Appearance..." value={infoState.physicalAppearanceNote}
										onChange={(e) => handleChange(e.target.name, e.target.value)}
									/>
								</div>
								<div className="col-5">&nbsp;</div>
							</div>
						</div>

						<div className="form-group">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>Palpation of the Epigastrium&nbsp;: (Sparsha)</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.epigastriumPalpation}
										placeholder={"Select epigastrium palpation..."}
										value={options.epigastriumPalpation.filter((opt) =>
											infoState?.epigastriumPalpation?.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("epigastriumPalpation", e)}
									/>
								</div>
								<div className="col-12 col-md-9">
									<label>
										<b>Note for Palpation of the Epigastrium&nbsp;:</b>
									</label>
									<textarea disabled={!isEditable} rows="3" type="text" className="form-control" name="epigastriumPalpationNote"
										placeholder="Type additional information for Palpation of the Epigastrium..."
										value={infoState.epigastriumPalpationNote}
										onChange={(e) => handleChange(e.target.name, e.target.value)}
									/>
								</div>
								<div className="col-5">&nbsp;</div>
							</div>
						</div>

						{[
							{label: "Range of motion", options: options.rangeMotion, variable: "rangeMotion", num: "1",},
							{label: "Pain gradations", options: options.painLevel, variable: "painLevel", num: "2",},
						].map((el, index) => (
							<div className="form-group" key={index}>
								<div className="row">
									<div className="col-12 col-md-3">
										<label>
											<b>{el.label}&nbsp;:</b>
										</label>
									</div>
									<div className="col-12 col-md-4">
										<Select
											isDisabled={!isEditable}
											styles={customStyles}
											options={el.options}
											placeholder={`Select ${el.label.toLowerCase()}...`}
											value={
												infoState[el.variable] && {
													value: infoState[el.variable],
													label: infoState[el.variable],
												}
											}
											onChange={(e) => handleChange(el.variable, e.value)}
										/>
									</div>
									<div className="col-5">&nbsp;</div>
								</div>
							</div>
						))}
						<div className="form-group">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>Tongue-Shape</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.tongueShape}
										placeholder={"Select Tongue-Shape"}
										value={options.tongueShape.filter((opt) =>
											infoState?.tongueShape?.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("tongueShape", e)}
									/>
								</div>
								<div className="col-12 col-md-3">
									<label>
										<b>Tongue-Color</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.tongueColor}
										placeholder={"Select Tongue-color"}
										value={options.tongueColor.filter((opt) =>
											infoState?.tongueColor?.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("tongueColor", e)}
									/>
								</div>
								
								<div className="col-12 col-md-9">
									<label>
										<b>Note for Tongue:</b>
									</label>
									<textarea
										disabled={!isEditable}
										rows="3" type="text" className="form-control" name="tongueNote"
										placeholder="Type additional information for Tongue..." value={infoState.tongueNote}
										onChange={(e) => handleChange(e.target.name, e.target.value)}
									/>
								</div>
								<div className="col-5">&nbsp;</div>
							</div>
						</div>

						<div className="form-group">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>Pulse-Rate (Vega)</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.pulseRate}
										placeholder={"Select Pulse-Rate"}
										value={options.pulseRate.filter((opt) =>
											infoState?.pulseRate?.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("pulseRate", e)}
									/>
								</div>
								<div className="col-12 col-md-3">
									<label>
										<b>Pulse-Type</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.pulseType}
										placeholder={"Select Pulse-Type"}
										value={options.pulseType.filter((opt) =>
											infoState?.pulseType?.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("pulseType", e)}
									/>
								</div>
								<div className="col-12 col-md-3">
									<label>
										<b>Pulse-Rhythm</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.pulseRhythm}
										placeholder={"Select Pulse-Rhythm"}
										value={options.pulseRhythm.filter((opt) =>
											infoState?.pulseRhythm?.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("pulseRhythm", e)}
									/>
								</div>   
								
							</div>
						</div>

						<div className="form-group">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>Pulse-Intensity (Vega)</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.pulseIntensity}
										placeholder={"Select Pulse-Intensity"}
										value={options.pulseIntensity.filter((opt) =>
											infoState?.pulseIntensity?.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("pulseIntensity", e)}
									/>
								</div>
								<div className="col-12 col-md-3">
									<label>
										<b>Pulse-Volume</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.pulseVolume}
										placeholder={"Select Pulse-Volume"}
										value={options.pulseVolume.filter((opt) =>
											infoState?.pulseVolume?.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("pulseVolume", e)}
									/>
								</div>
								<div className="col-12 col-md-3">
									<label>
										<b>Pulse-Quality</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.pulseQuality}
										placeholder={"Select Pulse-Quality"}
										value={options.pulseQuality.filter((opt) =>
											infoState?.pulseQuality?.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("pulseQuality", e)}
									/>
								</div>   
								
							</div>
						</div>

						<div className="form-group">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>Mind-Constitution</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.mindConstitution}
										placeholder={"Select Mind-Constitution"}
										value={options.mindConstitution.filter((opt) =>
											infoState?.mindConstitution?.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("mindConstitution", e)}
									/>
								</div>
								<div className="col-12 col-md-3">
									<label>
										<b>Body-Constitution</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.aBodyConstitution}
										placeholder={"Select Body-Constitution"}
										value={options.aBodyConstitution.filter((opt) =>
											infoState?.aBodyConstitution?.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("aBodyConstitution", e)}
									/>
								</div>
								<div className="col-12 col-md-3">
									<label>
										<b>Disease-Formation</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.diseaseFormation}
										placeholder={"Select Disease-Formation"}
										value={options.diseaseFormation.filter((opt) =>
											infoState?.diseaseFormation?.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("diseaseFormation", e)}
									/>
								</div>
								
							</div>
						</div>
						

						<div className="form-group">
							<div className="row">
								<div className="col-12 col-sm-3">
									<label>
										<b>Note for Physical Examination :</b>
									</label>
								</div>
								<div className="col-12 col-sm-9">
									<textarea disabled={!isEditable} rows="6" className="form-control" name="physicalExaminationNote"
										placeholder="Type in additional information for Physical Examination..."
										value={infoState.physicalExaminationNote}
										onChange={(e) => handleChange(e.target.name, e.target.value)}
									/>
								</div>
							</div>
						</div>
						
					</fieldset>
				</div>
			</div>
		);
	}
}

InspectionAndExamination.propTypes = {
	handleChange: PropTypes.func.isRequired,
	infoState: PropTypes.object.isRequired,
};

export default InspectionAndExamination;
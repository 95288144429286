import http from './httpService'; 
import {apiUrl} from './../config/config.json';
const apiEndpoint = apiUrl+'/actions';


  function actionUrl(id) {
    return `${apiEndpoint}/${id}`;
  }
  
  export function getActions() {
    return http.get(apiEndpoint);
  }
  
  export function getAction(Id) {
    return http.get(actionUrl(Id));
  }
  
  export function saveAction(action) {
    //clone
    const body = { ...action };
   //update
   if (body._id) {
     //delete _id
     let id = actionUrl(body._id)

     delete body._id;
     console.log(body);


     return http.put(id,body);
   }
 
   //add a new action
   return http.post(apiEndpoint, action);
 }
  
  //delete actions
  export function deleteAction(Id) {
    return http.delete(actionUrl(Id));
  }  
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {Panel,PanelHeader,PanelBody,} from "./../../components/panel/panel.jsx";
// import {
//   UncontrolledButtonDropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
// } from "reactstrap";
// import axios from "axios";
import { getKaizens } from "./../../services/kaizens";
//import { getWastes } from "./../../services/wastes";
import { getActions } from "./../../services/actions";
import { getRootCauses } from "./../../services/rootcauses";
import "bootstrap/dist/css/bootstrap.min.css";
//import FloatSubMenu from './../../components/float-sub-menu/float-sub-menu';
import Pagination from "../../common/pagination";
import { paginate } from "../../utils/paginate";
import KaizensTable from "../../components/kaizensTable.jsx";
import SearchBox from "./../../common/searchBox";
import _ from "lodash";
import http from "./../../services/httpService";
import { apiUrl } from "./../../config/config.json";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import {
//   Col,
//   Button,
//   Form,
//   FormGroup,
//   Input,
//   Modal,
//   Label,
//   ModalHeader,
//   ModalBody,
//   Row,
// } from "reactstrap";

// Icons imports
import newIcon from "../../assets/Icons/new.svg";
import eyeIcon from "../../assets/Icons/eye.svg";
import editIcon from "../../assets/Icons/edit.svg";
import trashIcon from "../../assets/Icons/trash.svg";
import csvIcon from "../../assets/Icons/csv.svg";
import xlsIcon from "../../assets/Icons/xls.svg";
import pdfIcon from "../../assets/Icons/pdf.svg";
import sharingIcon from "../../assets/Icons/sharing.svg";

import Icon from "./../../common/icon";

class KaizensDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kaizens: [],
      checkedFields: [],
      pageSize: 10,
      currentPage: 1,
      sortColumn: { path: "title", order: "asc" },
      searchQuery: "",
      errors: {},
    };
  }

  async componentDidMount() {
    const data = await getKaizens();
    console.log("kaizens : " , data.data)
    /*const kaizensWithPartNames=data.data ?.map((kaizen) => {
      kaizen.participants = kaizen?.participants?.map((part) => {
            return `${part?.username} `;
      });
      return kaizen;
    });
    this.setState({kaizens:kaizensWithPartNames})*/
    this.setState({kaizens : data.data })
  }

  handleDelete = (user) => {
    console.log(user);
    const kaizens = this.state.kaizens.filter((el) => el._id !== user._id);
    this.setState({ kaizens: kaizens });
  };
  //sorting columns
  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };
  handlePageChange = (page) => {
    console.log(page);
    this.setState({ currentPage: page });
  };

  handleSearch = (query) => {
    console.log(query);
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleCheckboxChange = ({ target: { checked, value } }) => {
    console.log( "target : " , checked , value)
    
    let {checkedFields} = this.state

    if (checked)
    this.setState( { checkedFields : [ ...checkedFields , value ] } )
    else

    this.setState( { checkedFields : checkedFields.filter( e => e !== value )})
    console.log('state after selecting : ' , this.state.checkedFields)

  }

  handleMassDelete = (CheckedKaizens) => {
    const originalKaizens = this.state.kaizens;
    CheckedKaizens.map(async (kaizen) => {
      try {
        await http.delete(apiUrl + "/kaizens/" + kaizen);
        const kaizens = this.state.kaizens.filter(
          (Kaizen) => Kaizen._id !== kaizen
        );
        this.setState({ kaizens });
      } catch (ex) {
        if (ex.response && ex.response === 404) {
          alert("already deleted");
        }

        this.setState({ kaizens: originalKaizens });
      }
      console.log("Kaizens: ", this.state.kaizens);
    });
  };

  getDataPgnation = () => {
    const {
      pageSize,
      currentPage,
      kaizens: Kaizens,
      sortColumn,
      searchQuery,
    } = this.state;
    //
    //filter maybe next time
    let filtered = Kaizens;
    if (searchQuery) {
      console.log(searchQuery);
      filtered = Kaizens.filter(
        (el) =>
          el.email.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
          el.userID.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    }

    //
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const kaizens = paginate(sorted, currentPage, pageSize);
    return { data: kaizens };
  };

  render() {
    const { length: count } = this.state.kaizens;

    const { pageSize, currentPage, sortColumn, searchQuery , checkedFields } = this.state;

    // if(count === 0)  return "<p>No data available</p>";

    const { data: kaizens } = this.getDataPgnation();


    return (
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/kaizen/kaizens">Kaizens</Link>
          </li>
        </ol>
        <h1 className="page-header">Kaizens </h1>
        <Panel>
          <PanelHeader>Kaizens Management</PanelHeader>

          <React.Fragment>
            <ToastContainer />
            <div className="toolbar" style={toolbarStyles}>
              <Icon
                to="/kaizen/kaizens/new"
                title="Add kaizen"
                btnStyle={btnStyles}
                iconStyle={iconStyles}
                icon={newIcon}
              />
              <Icon
				        to={this.state.checkedFields ? `/kaizen/kaizenprofile/${checkedFields[0]}`: "/kaizen/kaizens/"}
				        title="view kaizen" 
                btnStyle={btnStyles} 
                iconStyle={iconStyles} 
                icon={eyeIcon} 
			        />
			  
              <Icon
                to={
                  checkedFields
                    ? `/kaizen/kaizens/${checkedFields[0]}`
                    : "/kaizen/kaizens/"
                }
                title="Edit kaizen"
                btnStyle={btnStyles}
                iconStyle={iconStyles}
                icon={editIcon}
              />
              <Icon
                to="/kaizen/kaizens/"
                handleClick={() =>
                  this.handleMassDelete(this.state.checkedFields)
                }
                title="Delete kaizen"
                btnStyle={btnStyles}
                iconStyle={iconStyles}
                icon={trashIcon}
              />
              <Icon
                to="/kaizen/kaizens/"
                title="Xlsx kaizen"
                btnStyle={btnStyles}
                iconStyle={iconStyles}
                icon={xlsIcon}
              />
              <Icon
                to="/kaizen/kaizens/"
                title="CSV kaizen"
                btnStyle={btnStyles}
                iconStyle={iconStyles}
                icon={csvIcon}
              />
              <Icon
                to="/kaizen/kaizens/"
                title="pdf kaizen"
                btnStyle={btnStyles}
                iconStyle={iconStyles}
                icon={pdfIcon}
              />
              <Icon
                to="/kaizen/kaizens/"
                title="share kaizen"
                btnStyle={btnStyles}
                iconStyle={iconStyles}
                icon={sharingIcon}
              />
            </div>
            <div className="table-responsive">
              <SearchBox value={searchQuery} onChange={this.handleSearch} />
              <p
                className="page-header float-xl-left"
                style={
                  ({ marginBottom: 5 }, { marginLeft: 20 }, { marginTop: 5 })
                }
              >
                {count} entries
              </p>

              <KaizensTable
                kaizens={this.state.kaizens}
                onDelete={this.handleDelete}
                onSort={this.handleSort}
                sortColumn={sortColumn}
                handleCheckboxChange={this.handleCheckboxChange}
              />
            </div>
          </React.Fragment>

          <hr className="m-0" />
          <PanelBody>
            <div className="d-flex align-items-center justify-content-center">
              <Pagination
                itemsCount={count}
                pageSize={pageSize}
                onPageChange={this.handlePageChange}
                currentPage={currentPage}
              />
            </div>
          </PanelBody>
        </Panel>
      </div>
    );
  }
}
const toolbarStyles = {
  background: "#c8e9f3",
  padding: "10px",
};

const btnStyles = { background: "#348fe2", margin: "0rem" };

const iconStyles = {
  width: "25px",
  height: "25px",
  marginRight: "0rem",
};

export default KaizensDataTable;

import React, { Component } from "react";
import Table from "./../common/table";
import { Link, withRouter } from "react-router-dom";
import moment from "moment"

class KaizensTable extends Component {
	columns = [
		{
			key: "checkbox",
			label: <input type="check" style={checkboxStyles} />,
			content: (listKaizen) => (
				<span className="icon-img sm-r-5" style={{ marginTop: "15px" }}>
					<input
						type="checkbox"
						style={checkboxStyles}
						onChange={this.props.handleCheckboxChange}
						value={listKaizen._id}
					/>
				</span>
			),
		},
		{label: "KaizenNo", path: "kaizenNo" },
		{label: "Name", path: "name" },
		{
			label: "Owner",
			key: "owner" ,
			content : (kaizen) => (
				<span>
					<img src={kaizen.user?.imageSrc} alt="" width={15} />
					<span>{kaizen.user?.contactName.first}</span>
				</span>
			)
		},

		{ label: "participants",  key: "participants",
			content : (kaizen) => (
		
				<span>
					{kaizen.participants.map(el => 
					<div>
						<img src={el.imageSrc} width={16} alt="" />
						<span> {el.contactName.first}  </span>
						<br />
					</div>
					)}
				</span>
			)
		},
		{ label: "Sponsor",  key: "sponsor",
			content : (kaizen) => (
		
				<span>
					{kaizen.sponsor.map(el => 
					<div>
						<img src={el.imageSrc} width={16} alt="" />
						<span> {el.contactName.first} </span>
						<br />
					</div>
					)}
				</span>
			)
		},
		{ label: "Facilitator", key: "facilitator" ,
			content : (el) => (
				<span>
					{el.facilitator.map(el => 
					<div>
						<img src={el.imageSrc} width={16} alt="" />
						<span> {el.contactName.first} </span>
						<br />
					</div>
					)}
				</span>
			)

		},		
		{label: "Business", path: "businessNo.companyInfo.businessName" },		
		{label: 'Department',   path: "department" } ,   	  
		{label: 'SubDepartment',   path: "subDepartment" } ,   	  		
		{label: 'StartDate',   path: "plannedDate" , content:(kaizen) =>(moment(kaizen.plannedDate).format("L"))} ,
		{label: 'Deadline',   path: "deadline" , content:(kaizen) =>(moment(kaizen.deadline).format("L"))} ,
		{label: 'Field',   path: "field" } ,   	  
		{label: 'Tags',   path: "tags" } ,   	  
		{label: 'Description',   path: "description" } ,   	  
		{label: 'Award',   path: "award" } ,   	  	  				
		{label: 'Note',   path: "note" } ,   	  	  		
//		{label: "CreatedOn", path: "createdOn" },
		{label: "Status", path: "status" },		
	];

	render() {
		//console.log(this.columns) ;
		const { kaizens, onSort, sortColumn } = this.props;
		return (
			<Table columns={this.columns} sortColumn={sortColumn} onSort={onSort} data={kaizens} />
		);
	}
}

const checkboxStyles = {
	width: "15px",
	height: "15px",
	marginTop: "0.4rem",
	borderRadius: 0,
};

export default KaizensTable;

import options from "../options"
import Select from "react-select"
import httpService from "../../../../services/httpService"
import { apiUrl } from "../../../../config/config.json"
import { useEffect, useState } from "react"


export const MedicalHistory = ({ handleSingleSelect, removeFamilyMember, addFamilyMember, handleMultipleSelect, handleFamilyMemberChange, medicalInput, editMode, changeInput }) => {

	const [aPatient, setaPatient] = useState([])

	// fetch all the patients

	// const fetchPatients = async () => {
	// 	try {
	// 		const { data } = await http.get(apiUrl + "/patients");
	// 		if (!data) return;
	// 		setPatient(data)
	// 	} catch (err) {
	// 		console.log(err)

	// 	}

	// }
	// // .. fetch all the doctors 
	// const fetchDoctors = async () => {
	// 	try {
	// 		const { data } = await http.get(apiUrl + "/doctors");
	// 		if (!data) return;
	// 		setdoctors(data)
	// 	} catch (err) {
	// 		console.log(err)

	// 	}

	// }


	// // fetch all the clinics
	// const populateClinics = async () => {
	// 	try {
	// 		const { data } = await http.get(apiUrl + "/clinicsolo");
	// 		if (!data) return;
	// 		setclinics(data)
	// 	} catch (err) {
	// 		// console.log(err)

	// 	}

	// }


	async function fetchAPatient() {

		if (!medicalInput?.selectedRegisteredPatient) return;

		const { data: patient } = await httpService.get(`${apiUrl}/users/${medicalInput?.selectedRegisteredPatient._id}`);
		setaPatient(patient)

	}


	useEffect(() => {
		fetchAPatient()
	}, [])


	const selectStyle = {
		fontSize: "13px",
		minHeight: "initial",
		width: "75%"
	}
	const actionStyle = {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-evenly"
	}
	const headingStyle = {
		margin: "30px 0 ",
		marginLeft: "20px",
		fontFamily: "Manrope, sans-serif",
		fontSize: "2.6em",
		color: "rgb(241, 196, 15)",
		letterSpacing: "1.3px"

	}

	const headingContainer = {

		widlabel: "100%",
		textAlign: "center"

	}


	const disableStyle = {
		cursor: "not-allowed"
	}
	const labels = {
		fontWeight: "600",
		letterSpacing: "1.1px",
		fontSize: "12px",
		margin: "3px 0"
	}


	return (

		<div className="main-container text-align-center" >

			<div className="panel-body">

				<legend className="legend-text">Personal Medical</legend>


				{/* <Interview handleMultipleSelect={handleMultipleSelect} handleSingleSelect={handleSingleSelect} changeInput={changeInput} medicalInput={medicalInput} editMode={editMode} /> */}


				<div className="row mt-4 mb-5 row-no-margin">
					<div className="col-12 col-sm-2">
						<img
							src={aPatient?.imageSrc}
							style={{ borderRadius: "50%", marginBottom: "1.5rem", height: "120px", width: "120px" }}
						/>
					</div>
					<div className="col-12 col-sm-10">
						<div className="row">
							<div className="col-12 col-sm-4">
								<div className="form-group">
									<label>
										<b>Prefix</b>
									</label>
									<input type="text" disabled className="form-control" placeholder="Prefix" name="prefix" value={aPatient?.prefix} />
								</div>
							</div>
							<div className="col-12 col-sm-4">
								<div className="form-group">
									<label>
										<b>First Name</b>
									</label>
									<input type="text" disabled className="form-control" name="firstName" placeholder="First Name"
										value={
											aPatient?.contactName?.first ?? " "
										}
									/>
								</div>
							</div>
							<div className="col-12 col-sm-4">
								<div className="form-group">
									<label>
										<b>Last Name</b>
									</label>
									<input disabled type="text" className="form-control" name="lastName" placeholder="Last Name"
										value={
											aPatient?.contactName?.last
										}
									/>
								</div>
							</div>
							<div className="col-12 col-sm-4">
								<div className="form-group">
									<div className="d-flex flex-column">
										<label>
											<b>Date of Birth *</b>
										</label>
										<input disabled type="text" className="form-control" name="lastName" placeholder="Date of birth"
											value={
												aPatient?.dateBirth?.toLocaleString("en-GB") ?? "Empty "
											}
										/>
									</div>
								</div>
							</div>
							<div className="col-12 col-sm-4">
								<div className="form-group">
									<label>
										<b>Mobile Phone</b>
									</label>
									<input disabled type="text" className="form-control" name="mobilePhone" placeholder="Mobile Phone" value={aPatient?.mobilePhone}
									/>
								</div>
							</div>
							<div className="col-12 col-sm-4">
								<div className="form-group">
									<label>
										<b>Gender</b>
									</label>

									<input disabled type="text" className="form-control" name="gender" placeholder="gender" value={aPatient?.gender} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="mx-auto w-full" style={headingContainer}>
					<h2 style={headingStyle} className="mt-10 mx-auto">Medical History</h2>
				</div>

				<div className="inputBox" >
					<label style={labels}> Chief complaint</label>
					<textarea rows={5} type="text" name="chiefComplaint" value={medicalInput.chiefComplaint} onChange={changeInput}
						placeholder="Readonly input here…" readOnly={editMode} className=" form-control w-75"/>
				</div>

				<div className="inputBox" >
					<label style={labels} >Symptoms</label>
					<textarea className=" form-control w-75" type="text" name="symptoms" value={medicalInput.symptoms} onChange={changeInput}
						rows={4} placeholder="Symtoms" readOnly={editMode}/>
				</div>
				<div className="inputBox"  >
					<label style={labels} >Western Disease/syndrome</label>
					<textarea className=" form-control w-75" type="text" row={1} name="westernDisease" value={medicalInput.westernDisease} onChange={changeInput}
						placeholder="Readonly input here…" readOnly={editMode}/>

				</div>
				<div className="inputBox" >
					<label style={labels}>Current currentTreatment</label>
					<div className="w-75">

						<Select

							isDisabled={editMode}
							isMulti
							style={selectStyle}
							options={options.currentTreatment}
							placeholder={"Select current currentTreatment..."}
							defaultValue={
								medicalInput.currentTreatment?.length >= 1 && options.currentTreatment.filter((opt) =>
									medicalInput.currentTreatment?.includes(opt.value)
								)

							}

							onChange={(e) => handleMultipleSelect("currentTreatment", e)}
						/>
					</div>
				</div>
				<div className="inputBox" >
					<label style={labels}>Diseases & Illnesses</label>
					<textarea className="form-control w-75" rows={1} type="text" name="diseases" 
						value={medicalInput.diseases}
						onChange={changeInput} placeholder="Readonly input here…" readOnly={editMode}/>
				</div>
				<div className="inputBox" >
					<label style={labels}>Surgeries</label>
					<textarea
						className="form-control w-75" 
						type="text" name="surgeries" value={medicalInput.surgeries} rows={1} onChange={changeInput}
						placeholder="Readonly input here…" readOnly={editMode} />
				</div>
				<div className="inputBox" >
					<label style={labels}>Medicaments & Supplements in use</label>
					<textarea className="form-control w-75" rows={1} type="text" name="medicaments" value={medicalInput.medicaments} 
						onChange={changeInput} placeholder="Readonly input here…" readOnly={editMode}/>
				</div>
				<div className="inputBox" >
					<label style={labels} >Allergies</label>
					<textarea className="form-control w-75" type="text" rows={2} name="allergies" value={medicalInput.allergies} 
						onChange={changeInput} placeholder="allergies" readOnly={editMode}/>
				</div>
				<div className="inputBox" >
					<label style={labels}>Pregnancies</label>
					<textarea
						className="form-control w-75" type="text" name="pregnancies" rows={1} value={medicalInput.pregnancies}
						onChange={changeInput} placeholder="pregnancies" readOnly={editMode} />
				</div>
			</div >
			<div className="panel-body">
				<legend className="legend-text">Family Medical History</legend>
				<div className="form-group">
					<button style={!editMode ? disableStyle : null} disabled={editMode} type="button" class="btn btn-primary btn-sm" onClick={addFamilyMember}>
						Add Family Role wilabel Medical History
					</button>
				</div>
				<table class="table" >
					<thead>

						<th>Family Member</th>
						<th>Disease</th>
						<th>Year</th>
						<th>State</th>
						<th>Action</th>
					</thead>
					<tbody className="familyHistoryTable" >

						{
							medicalInput.familyMembers
							?.map((member, index) => (
								<>

									<tr>
										<td>
										<Select
											style={selectStyle}
											isDisabled={editMode}
											options={options.familyMember}
											placeholder={"Family member"}
											value={
												member.familyMember && {
													value: member.familyMember,
													label: member.familyMember,
												}
											}
											onChange={(e) => handleFamilyMemberChange("familyMember", e.value, index)}
										/>
										</td>
										<td>
											<textarea
												disabled={editMode}
												rows="1.4"
												className="form-control"
												name="disease"
												placeholder="Disease"
												value={member.disease}
												onChange={(e) => handleFamilyMemberChange("disease", e.target.value, index)}
											/>
										</td>
										<td>
											<Select
												style={selectStyle}
												isDisabled={editMode}
												options={options.year}
												placeholder={"Year..."}
												value={
													member.year && {
														value: member.year,
														label: member.year,
													}
												}
												onChange={(e) => handleFamilyMemberChange("year", e.value, index)}
											/>
										</td>
										<td>
											<Select
												style={selectStyle}
												isDisabled={editMode}
												options={options.state}
												placeholder={"Status"}
												value={
													member.state && {
														value: member.state,
														label: member.state,
													}
												}
												onChange={(e) => handleFamilyMemberChange("state", e.value, index)}
											/>


										</td>
										<td>
											<div style={actionStyle} >
												<th >
													<b>Remove</b>
												</th>
												<button
													style={!editMode ? disableStyle : null}
													disabled={editMode}
													className="btn btn-danger btn-icon btn-circle btn-md cursor-pointer"
													onClick={() => removeFamilyMember(index)}
												>
													<i className="fa fa-trash"></i>
												</button>
											</div>
										</td>
									</tr>
								</>

							))
						}

					</tbody>
				</table>
			</div>
		</div >
	);
}


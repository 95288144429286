import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Select from "react-select";
import options from "./options";
import "./style.css";
import UIModalNotification from "./suggestingtreatment";
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
// import PrevTCMTreatment from "./previoussessions/TCMTreatment";

class TCMTreatment extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			modalDialog: false
		};
		this.toggleModal = this.toggleModal.bind(this);
	}

	toggleModal = () => {
		this.setState({ modalDialog: !this.state.modalDialog });
	};

	render() {

		const targetHeight = 34;

		const customStyles = {
			control: (styles) => ({
				...styles,
				minHeight: "initial",
			}),

			option: (provided) => ({
				...provided,
			}),
			valueContainer: (base) => ({
				...base,
				padding: "0 8px",
			}),
			clearIndicator: (base) => ({
				...base,
				padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
			}),
			dropdownIndicator: (base) => ({
				...base,
				padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
			}),
		};

		const { handleChange, infoState, isEditable, addRemedy, addRemedy2, removeRemedy2, removeRemedy, handleRemedyChange, handleRemedy2Change
			, removeacuPoint, addacuPoint, handleAcuCombinationChange, acuPoint, addherbalFormula1, handleHerbalChange, herbal
			, handlestimulationMethodChange, handlestimulationDurationChange, previousTab, materiaMedic, materiaMedics
			, pathologies, principleTreatment, differentiation, handlePrincipleTreatmentChange, handleDifferentiationChange } = this.props;
		// console.log("Hello is Editable", isEditable);
		// console.log("infoStatesss", infoState.herbalFormula1);
		// console.log("options acu", acuPoint);
		// console.log("herbal1", herbal);
		// console.log("materiaMedic", materiaMedic,"materiaMedics",materiaMedics);

		return (
			<div className="main-container">
				<h3 className="mt-10">TCM Treatment</h3>
				<div className="panel-body">
					<fieldset>
						<legend className="legend-text">Differentiation</legend>
						<div className="form-group">
							<label>
								<b>TCM Diagnosis&nbsp;:</b>
							</label>
							<Select
								isDisabled={!isEditable}
								styles={customStyles}
								name="tcmDiagnosis"
								placeholder="Your TCM Diagnosis for the patient..."
								options={differentiation}
								value={infoState.tcmDiagnosis && {
									value: infoState.tcmDiagnosis,
									label: infoState.tcmDiagnosis
								}}
								onChange={(e) => handleDifferentiationChange(e.value)}
							/>
						</div>
						<div className="form-group">
							<label>
								<b>Principle Treatment&nbsp;:</b>
							</label>
							<Select
								isDisabled={!isEditable}
								styles={customStyles}
								options={principleTreatment}
								name="principleTreatment"
								placeholder="Your treatment for the patient..."
								value={infoState.principleTreatment && {
									value: infoState.principleTreatment,
									label: infoState.principleTreatment
								}}
								onChange={(e) => handlePrincipleTreatmentChange(e.value)}
							/>
						</div>
						<div className="form-group">
							<button type="button" className="btn btn-purple btn-sm" onClick={this.toggleModal}>
								Click for suggested treatment from the Site
							</button>

						</div>
						<Modal isOpen={this.state.modalDialog} toggle={this.toggleModal}>
							<ModalHeader toggle={this.toggleModal} close={<button className="btn-close" onClick={this.toggleModal}></button>}>Suggesting Treatment from Site</ModalHeader>
							<ModalBody>
								<p>

									<b>Primary Acu Treatment&nbsp;:</b><br />

									<ul>
										{pathologies && pathologies.map((pathology, index) => {
											if (pathology.principleTreatment === infoState.principleTreatment) {
												return (
													<li key={index}>{pathology.primacuTreatment}</li>
												)
											}
										})}
									</ul>
								</p>
								<p>

									<b>Secondary Acu Treatment&nbsp;:</b><br />

									<ul>
										{pathologies && pathologies.map((pathology, index) => {
											if (pathology.principleTreatment === infoState.principleTreatment) {
												return (
													<li key={index}>{pathology.secacuTreatment}</li>
												)
											}
										})}
									</ul>
								</p>
								<p>
									<b>Herbal Formulas&nbsp;:</b><br />
									<ul>
										{pathologies && pathologies.map((pathology, index) => {
											if (pathology.principleTreatment === infoState.principleTreatment) {
												return (
													<li key={index}>{pathology.herbalPrescription}</li>
												)
											}
										})}
									</ul>
								</p>
							</ModalBody>
							<ModalFooter>
								<button className="btn btn-sucess" onClick={this.toggleModal}>Close</button>
							</ModalFooter>
						</Modal>
					</fieldset>
				</div>
				<div className="panel-body">
					<fieldset>
						<legend className="legend-text">Acu-point combination</legend>
						<div className="row">
							<div className="col-12 col-sm-9">
								<div className="d-flex flex-wrap justify-content-between">
									<div className="form-group">
										<button type="button" className="btn btn-primary btn-sm" onClick={addacuPoint}>
											Add Acu-point
										</button>
									</div>
									{/* <div className="form-group">
										<button type="button" className="btn btn-purple btn-sm" onClick={this.previousTab}>
											Click for suggested remedy from the Site
										</button>
									</div> */}
								</div>
							</div>
						</div>
						{infoState.acuCombination?.map((item, index) => (
							<div className="row" key={index}>

								<div className="col-12 col-sm-3">
									<label>
										<b>Acu-point</b>
									</label>
									<div className="form-group">
										<Select
											isDisabled={!isEditable}
											styles={customStyles}
											options={acuPoint}
											placeholder={"Select Acupoint..."}
											value={
												item.acuPoint && {
													value: item.acuPoint,
													label: item.acuPoint,
												}
											}
											onChange={(e) => handleAcuCombinationChange("acuPoint", e.value, index)}
										/>
									</div>
								</div>

								<div className="col-12 col-sm-2">
									<label>
										<b>Moxibustion</b>
									</label>
									<div className="form-group w-75" >
										<Select
											isDisabled={!isEditable}
											styles={customStyles}
											options={options.moxibustion}
											placeholder={"Yes/No"}
											value={
												item.acuPoint && {
													value: item.Moxibustion,
													label: item.Moxibustion,
												}
											}
											onChange={(e) => handleAcuCombinationChange("Moxibustion", e.value, index)}
										/>
									</div>
								</div>


								<div className="col-12 col-sm-2">
									<label>
										<b>NeedleManipulation</b>
									</label>
									<div className="form-group">
										<Select
											isDisabled={!isEditable}
											styles={customStyles}
											options={options.needleManipulationOptions}
											placeholder={"needleManipulation..."}
											value={
												item.needleManipulation && {
													value: item.needleManipulation,
													label: item.needleManipulation,
												}
											}
											onChange={(e) => handleAcuCombinationChange("needleManipulation", e.value, index)}
										/>
									</div>
								</div>

								<div className="col-12 col-sm-2">
									<label>
										<b>StimulationMethod</b>
									</label>
									<div className="form-group">
										<Select
											isDisabled={!isEditable}
											styles={customStyles}
											options={options.stimulationMethodOptions}
											placeholder={"stimulationMethod..."}
											value={
												item.stimulationMethod && {
													value: item.stimulationMethod,
													label: item.stimulationMethod,
												}
											}
											onChange={(e) => handleAcuCombinationChange("stimulationMethod", e.value, index)}
										/>
									</div>
								</div>

								<div className="col-12 col-sm-2">
									<label>
										<b>StimulationDuration</b>
									</label>
									<div className="form-group">
										<input
											type="number"
											disabled={!isEditable}
											className="form-control w-75"
											name="stimulationDuration"

											placeholder="stimulationDuration..."
											value={item.stimulationDuration}
											onChange={(e) => handleAcuCombinationChange("stimulationDuration", e.value, index)}
										/>
									</div>
								</div>
								{index > 0 && (
									<div className="col-6 col-sm-2">
										<div className="form-group">
											<label style={{ marginRight: "10px" }}>
												<b>Remove</b>
											</label>
											<button
												className="btn btn-danger btn-icon btn-circle btn-lg"
												onClick={() => removeacuPoint(index)}
											>
												<i className="fa fa-trash"></i>
											</button>
										</div>
									</div>
								)}
							</div>
						))}

						<div className="form-group">
							<label>
								<b>Note for Acu-treatment</b>
							</label>
							<textarea
								disabled={!isEditable}
								rows="10"
								className="form-control w-75"
								name="acuTreatmentNote"
								placeholder="Note for treatment for the patient..."
								value={infoState.acuTreatmentNote}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
							/>
						</div>
					</fieldset>
				</div>

				<div className="panel-body">
					<fieldset>
						<legend className="legend-text">Herbal Treatment</legend>
						<legend className="legend-text">Herbal Formula 1</legend>
						<div className="row">
							<div className="col-12 col-sm-9">
								<div className="d-flex flex-wrap justify-content-between">
									{/* <div className="form-group">
										<button type="button" className="btn btn-primary btn-sm" onClick={addherbalFormula1}>
											Select Herbal Formula
										</button>
									</div> */}
									<div className="form-group">
										{/* <button type="button" className="btn btn-purple btn-sm" onClick={this.previousTab}>
											Click for suggested remedy from the Site
										</button> */}
									</div>
								</div>
							</div>
							<div className="col-12 col-sm-4">
								<div className="form-group">
									<Select
										isDisabled={!isEditable}
										styles={customStyles}
										options={herbal}
										placeholder={"Select Herbal..."}
										value={
											infoState.herbalFormula1 && {
												value: infoState.herbalFormula1,
												label: infoState.herbalFormula1,
											}
										}

										onChange={(e) => { handleChange("herbalFormula1", e.value) }}
									/>
								</div>
							</div>


						</div>

						<div className="row">
							<div className="col-12 col-sm-9">
								<div className="d-flex flex-wrap justify-content-between">
									<div className="form-group">
										<button type="button" className="btn btn-primary btn-sm" onClick={addRemedy}>
											Add Materia Medica to Herbal Formula 1
										</button>
									</div>
									<div className="form-group">
										{/* <button type="button" className="btn btn-purple btn-sm" onClick={this.previousTab}>
											Click for suggested remedy from the Site
										</button> */}
									</div>
								</div>
							</div>
						</div>
						{infoState.materiaMedica1?.map((item, index) => (
							<div className="row" key={index}>


								<div className="col-9 col-sm-4">

									<div className="form-group">
										<label>
											<b>Materia Medica</b>
										</label>
										<Select
											isDisabled={!isEditable}
											styles={customStyles}
											options={materiaMedic}
											// materiaMedic
											placeholder={"Select Remedy..."}
											value={
												item.materiamedica && {
													value: item.materiamedica,
													label: item.materiamedica,
												}
											}
											onChange={(e) => handleRemedyChange("materiamedica", e.value, index)}
										/>
									</div>
								</div>

								<div className="col-12 col-sm-2">
									<label>
										<b>Dosage</b>
									</label>
									<div className="form-group">
										<input
											type="number"
											disabled={!isEditable}
											className="form-control w-75"
											name="dosage"

											placeholder="dosage..."
											value={item.dosage}
											onChange={(e) => handleRemedyChange("dosage", e.value, index)}
										/>
									</div>
								</div>


								<div className="col-4 col-sm-4">

									<div className="form-group">
										<label>
											<b>Unit</b>
										</label>
										<Select
											isDisabled={!isEditable}
											styles={customStyles}
											options={options.unit}
											placeholder={"Select Unit..."}
											value={
												item.remedy && {
													value: item.unit,
													label: item.unit,
												}
											}
											onChange={(e) => handleRemedyChange("unit", e.value, index)}
										/>
									</div>
								</div>
								{index > 0 && (
									<div className="col-6 col-sm-2">
										<div className="form-group">
											<label>
												<b>Remove</b>
											</label>
											<button
												className="btn btn-danger btn-icon btn-circle btn-lg"
												onClick={() => removeRemedy(index)}
											>
												<i className="fa fa-trash"></i>
											</button>
										</div>
									</div>
								)}
							</div>
						))}

						<div className="col-12 col-sm-4">
							<div className="form-group">
							</div>
						</div>

						<legend className="legend-text">Herbal Formula 2</legend>
						<div className="row">
							<div className="col-12 col-sm-9">
								<div className="d-flex flex-wrap justify-content-between">
									{/* <div className="form-group">
										<button type="button" className="btn btn-primary btn-sm" onClick={addherbalFormula1}>
											Select Herbal Formula
										</button>
									</div> */}
									<div className="form-group">
										{/* <button type="button" className="btn btn-purple btn-sm" onClick={this.previousTab}>
											Click for suggested remedy from the Site
										</button> */}
									</div>
								</div>
							</div>
							<div className="col-12 col-sm-4">
								<div className="form-group">
									<Select
										isDisabled={!isEditable}
										styles={customStyles}
										options={herbal}
										placeholder={"Select Herbal..."}
										value={
											infoState.herbalFormula1 && {
												value: infoState.herbalFormula2,
												label: infoState.herbalFormula2,
											}
										}

										onChange={(e) => { handleChange("herbalFormula2", e.value) }}
									/>
								</div>
							</div>


						</div>

						<div className="row">
							<div className="col-12 col-sm-9">
								<div className="d-flex flex-wrap justify-content-between">
									<div className="form-group">
										<button type="button" className="btn btn-primary btn-sm" onClick={addRemedy2}>
											Add Materia Medica to Herbal Formula 2
										</button>
									</div>
									<div className="form-group">
										{/* <button type="button" className="btn btn-purple btn-sm" onClick={this.previousTab}>
											Click for suggested remedy from the Site
										</button> */}
									</div>
								</div>
							</div>
						</div>
						{infoState.materiaMedica2?.map((item, index) => (
							<div className="row" key={index}>

								<div className="col-8 col-sm-4">
									<div className="form-group">
										<label>
											<b>Materia Medica</b>
										</label>

										<Select
											isDisabled={!isEditable}
											styles={customStyles}
											options={materiaMedic}
											placeholder={"Select Remedy..."}
											value={
												item.materiamedica && {
													value: item.materiamedica,
													label: item.materiamedica,
												}
											}
											onChange={(e) => handleRemedy2Change("materiamedica", e.value, index)}
										/>
									</div>
								</div>

								<div className="col-12 col-sm-2">
									<label>
										<b>Dosage</b>
									</label>
									<div className="form-group">
										<input
											type="number"
											disabled={!isEditable}
											className="form-control w-75"
											name="dosage"

											placeholder="dosage..."
											value={item.dosage}
											onChange={(e) => handleRemedy2Change("dosage", e.value, index)}
										/>
									</div>
								</div>

								<div className="col-4 col-sm-4">
									<div className="form-group">
										<label>
											<b>Unit</b>
										</label>
										<Select
											isDisabled={!isEditable}
											styles={customStyles}
											options={options.unit}
											placeholder={"Select Unit..."}
											value={
												item.remedy && {
													value: item.unit,
													label: item.unit,
												}
											}
											onChange={(e) => handleRemedy2Change("unit", e.value, index)}
										/>
									</div>
								</div>
								{index > 0 && (
									<div className="col-6 col-sm-2">
										<div className="form-group">
											<label>
												<b>Remove</b>
											</label>
											<button
												className="btn btn-danger btn-icon btn-circle btn-lg"
												onClick={() => removeRemedy2(index)}
											>
												<i className="fa fa-trash"></i>
											</button>
										</div>
									</div>
								)}
							</div>
						))}

						<div className="row">
							<div className="col-12 col-sm-2">
								<div className="form-group">
									<label>
										<b>TDP</b>
									</label>
									<Select
										isDisabled={!isEditable}
										styles={customStyles}
										options={options.tdp}
										placeholder={" select Yes/No"}
										value={
											infoState.TDP && {
												value: infoState.TDP,
												label: infoState.TDP,
											}
										}

										onChange={(e) => { handleChange("TDP", e.value) }}
									/>
								</div>
							</div>
							<div className="col-12 col-sm-9">
								<div className="form-group">
									<label>
										<b>TDPNote</b>
									</label>
									<textarea disabled={!isEditable} rows="4" className="form-control w-75" name="TDPNote" placeholder="Type in Note for TPD..."
										value={infoState.TDPNote}
										onChange={(e) => handleChange(e.target.name, e.target.value)}
									/>
								</div>
							</div>
						</div>




						<div className="form-group">
							<label>
								<b>Auricular-Acupuncture</b>
							</label>
							<textarea disabled={!isEditable} rows="4" className="form-control w-75" name="auricularAcupuncture" placeholder="Type in Auricular Acupuncture-points..."
								value={infoState.auricularAcupuncture}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
							/>
						</div>

						<div className="form-group">
							<label>
								<b>Tuina</b>
							</label>
							<textarea disabled={!isEditable} rows="4" className="form-control w-75" name="tuina" placeholder="Type in notes for Tuina..."
								value={infoState.tuina}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
							/>
						</div>

						<div className="form-group">
							<label>
								<b>Diet-therapy&nbsp;:</b>
							</label>
							<textarea disabled={!isEditable} rows="4" className="form-control w-75" name="dietTherapy" placeholder="Type in diet for the patient..."
								value={infoState.dietTherapy}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
							/>
						</div>
						<div className="form-group">
							<label>
								<b>Recommendation&nbsp;:</b>
							</label>
							<textarea disabled={!isEditable} rows="6" className="form-control w-75" name="recommendation" placeholder="Type in recommendation for the patient..."
								value={infoState.recommendation}
								onChange={(e) => handleChange(e.target.name, e.target.value)}
							/>
						</div>
					</fieldset>
				</div>

			</div>
		);
	}
}

TCMTreatment.propTypes = {
	handleChange: PropTypes.func.isRequired,
	infoState: PropTypes.object.isRequired,
};

export default TCMTreatment;

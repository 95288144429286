import options from "../options"
import Select from "react-select"
export const AyurvedaTreatment = ({ addRemedy, removeRemedy, handleRemedyChange, medicalInput, editMode, changeInput }) => {
	const actionStyle = {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-evenly"
	}

	const headingStyle = {
		margin: "30px 0 ",
		marginLeft: "20px",
		fontFamily: "Manrope, sans-serif",
		fontSize: "2.6em",
		color: "rgb(43, 159, 193)",
		letterSpacing: "1.3px"

	}

	const headingContainer = {

		width: "100%",
		textAlign: "center"

	}
	const disableStyle = {
		cursor: "not-allowed"
	}
	return (
		<div className="main-container">
			<div style={headingContainer}>


				<h3 style={headingStyle} className="mt-10">Homeo Treatment</h3>
			</div>
			<div className="panel-body">
				<legend className="legend-text">Differentiation</legend>
				<div className="table-responsive">
					<div className="form-group">
						<label scope="row">Homeo Diagnosis</label>

						<textarea
							disabled={editMode}
							rows="1" className="form-control w-75" name="homeoDiagnosis" value={medicalInput.homeoDiagnosis}
							onChange={changeInput} placeholder="Your Homeo Diagnosis for the patient..." />

					</div>
					<div className="form-group">
						<label scope="row">Principle Treatment </label>
						<textarea
							disabled={editMode}
							rows="1" className="form-control w-75" name="principleTreatment" placeholder="Your treatment for the patient..." 
							value={medicalInput.principleTreatment} onChange={changeInput} readOnly={editMode} />

					</div>
					<div className="form-group">
						<label>
							<b>Note&nbsp;:</b>
						</label>
						<textarea
							disabled={editMode}
							rows="10" className="form-control w-75" name="acutreatmentNote" placeholder="Note for treatment for the patient..." 
							value={medicalInput.acutreatmentNote} onChange={changeInput}/>
					</div>

				</div>
			</div>
			<div>

				<div className="form-group">
					<button type="button" style={!editMode ? disableStyle : null} class="btn btn-primary btn-sm" disabled={editMode} onClick={addRemedy}>
						Add Remedy to Prescription
					</button>
				</div>

				{medicalInput.remedy?.map((item, index) => (
					< div className="row" key={index} >
						<div className="col-12 col-sm-1">
							<label>
								<b>Remedy</b>
							</label>
						</div>
						<div className="col-12 col-sm-4">
							<div className="form-group">

								<Select
									isDisabled={editMode}
									options={options.remedy}
									placeholder={"Select Remedy"}
									value={
										item.materiaMedica && {
											value: item.materiaMedica,
											label: item.materiaMedica,
										}
									}
									onChange={(e) => handleRemedyChange("materiaMedica", e.value, index)}
								/>
							</div>
						</div>
						<div className="col-12 col-sm-1">
							<label>
								<b>Potency</b>
							</label>
						</div>
						<div className="col-12 col-sm-4">
							<div className="form-group">

								<Select
									isDisabled={editMode}
									options={options.potency}
									placeholder={"Select Potency..."}
									value={
										item.potency && {
											value: item.potency,
											label: item.potency,
										}
									}
									onChange={(e) => handleRemedyChange("potency", e.value, index)}
								/>
							</div>
						</div>

						<div className="col-6 col-sm-2">
							<div style={actionStyle} className="form-group">
								<label>
									<b>Remove</b>
								</label>
								<button disabled={editMode}
									style={!editMode ? disableStyle : null} 
									className="btn btn-danger btn-icon btn-circle btn-md cursor-pointer"
									onClick={() => removeRemedy(index)}	>
									<i className="fa fa-trash"></i>
								</button>
							</div>
						</div>
					</div>
				))}
				<div className="form-group">
					<label scope="row">Diet-therapy</label>

					<textarea
						disabled={editMode}
						rows="4" className="form-control w-75" name="dietTherapy" placeholder="Type in diet for the patient..."  value={medicalInput.dietTherapy}
						onChange={changeInput}
					/>
				</div>
				<div className="form-group">
					<label scope="row">Recommendation</label>
					<textarea
						disabled={editMode} rows="6" className="form-control w-75" name="recommendation"
						placeholder="Type in recommendation for the patient..." value={medicalInput.recommendation} onChange={changeInput} />
				</div>

			</div>
		</div >
	);
}

import React from "react";
import { Route } from "react-router-dom";
import { PageSettings } from "./../../config/page-settings.js";
import SidebarNavList from "./sidebar-nav-list.jsx";
import menus from "./menu.jsx";
import { getKanbans } from "../../services/kanbans.js";
import { apiUrl } from "../../config/config.json";
import http from "../../services/httpService";

class SidebarNav extends React.Component {
  static contextType = PageSettings;

  constructor(props) {
    super(props);
    this.state = {
      active: -1,
      clicked: -1,
      menus: menus,
      kanbansData: [],
      allkanbans: [],
      kanbans: [],
      permissions: [],
    };

    this.handleSidebarSearch = this.handleSidebarSearch.bind(this);
  }

  async getAllKanbans() {
    const kanbansData = await getKanbans();
    this.setState({
      kanbansData: kanbansData.data,
    });
    this.state.kanbansData.map((kanban) => {
      this.setState({
        allkanbans: [
          ...this.state.allkanbans,
          //{ path: `/kanban/allkanbans/${kanban._id.split(" ").join("-").toLowerCase()}`, title: kanban.name },
          { path: `/kanban/allkanbans/${kanban._id}`, title: kanban.name },
        ],
      });
    });
    this.setState({
      kanbans: [
        {
          path: "/kanban/allkanbans",
          title: "Kanbans",
          children: this.state.allkanbans,
        },
      ],
    });
    // console.log("All kanbans menu ", this.state.allkanbans);
    // console.log("All kanbans menu ", this.state.kanbans);
    //this.state.menus[24].children.splice(0, 0, this.state.kanbans[0]);
    this.state.menus.map((item) => {
      if (item.path === "/kanban")
        item.children.splice(0, 0, this.state.kanbans[0]);
    });
  }

  async getRoles() {
    const { data: profiles } = await http.get(apiUrl + "/profiles");
    this.setState({
      permissions: [
        {
          path: "/user/permissions",
          title: "UserRoles Permissions",
          children: profiles.map((profile) => ({
            path: `/user/permissions/${profile._id}`,
            title: profile.profileName,
          })),
        },
      ],
    });
    this.state.menus.map((item) => {
      if (item.path === "/Users")
        item.children.splice(0, 0, this.state.permissions[0]);
    });
  }

  async componentDidMount() {
    await this.getAllKanbans();
    await this.getRoles();
    console.log(this.state.menus);
  }

  handleExpand(e, i, match) {
    e.preventDefault();

    if (this.state.clicked === -1 && match) {
      this.setState((state) => ({
        active: -1,
        clicked: 1,
      }));
    } else {
      this.setState((state) => ({
        active: this.state.active === i ? -1 : i,
        clicked: 1,
      }));
    }
  }

  handleSidebarSearch(e) {
    let searchValue = e.target.value;
    searchValue = searchValue.toLowerCase();

    this.setState((state) => {
      let newMenus = [];
      if (searchValue !== "") {
        newMenus = menus.filter((item) => {
          let title = item.title;
          title = title.toLowerCase();
          if (title.search(searchValue) > -1) {
            item.search = true;
            return true;
          } else {
            if (item.children) {
              for (var i = 0; i < item.children.length; i++) {
                let title2 = item.children[i]["title"];
                title2 = title2.toLowerCase();

                if (title2.search(searchValue) > -1) {
                  item.search = true;
                  return true;
                }
              }
            }
            return false;
          }
        });
      } else {
        newMenus = menus.filter((item) => {
          item.search = false;
          return true;
        });
      }
      return {
        menus: newMenus,
      };
    });
  }

  render() {
    return (
      <ul className="nav">
        {this.context.pageSidebarSearch && (
          <li className="nav-search">
            <input
              type="text"
              className="form-control"
              placeholder="Sidebar menu filter..."
              onKeyUp={this.handleSidebarSearch}
            />
          </li>
        )}
        <li className="nav-header">Navigation</li>
        {this.state.menus.map((menu, i) => (
          <Route
            path={menu.path}
            exact={menu.exact}
            key={i}
            children={({ match }) => (
              <SidebarNavList
                data={menu}
                key={i}
                expand={(e) => this.handleExpand(e, i, match)}
                active={i === this.state.active}
                clicked={this.state.clicked}
              />
            )}
          />
        ))}
      </ul>
    );
  }
}

export default SidebarNav;

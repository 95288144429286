import React, { useEffect, useRef, useState } from 'react';
import { FiThumbsUp, FiRefreshCw } from 'react-icons/fi'
import { FaRegSmile } from 'react-icons/fa'
import Button from "./Button";
import SectionDivider from "./SectionDivider";
import { Link } from "react-router-dom";
import "../../../mm.css"
import ReplyIcon from '@material-ui/icons/Reply';
import { useHistory } from "react-router-dom";

import moment from "moment";
import { Avatar, Box, Typography } from '@material-ui/core';

import { Panel, PanelHeader, PanelBody } from './../../../components/panel/panel.jsx';

import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import AttachmentIcon from "@material-ui/icons/Attachment";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PanToolIcon from "@material-ui/icons/PanTool";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import LockIcon from "@material-ui/icons/Lock";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import { deletePost } from '../../../services/posts';
import { deleteInternalPost, getInternalPost, getInternalPosts, getInternalPostTopic } from '../../../services/internalposts';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



function ListingDetailsComments1({ commentlists }) {
  const history = useHistory()
  const taskId = history.location.pathname.split("/")[4];
  const [allPosts, setAllPosts] = useState([])
  const [nestedComments , setNestedComments] = useState([]);
  const stateRef = useRef();

stateRef.current = nestedComments;

  useEffect(() => {
    const getCommentsNumber = async () => {
      const comments = await getInternalPostTopic(taskId)
      setAllPosts(comments.data);
    }
    return getCommentsNumber();
  }, [])
  const opencomment1 = (topicId, parentId) => {

    history.push(`/kanban/reply/${topicId}/${parentId}`);

  };

  const editComment = (id) => {
    history.push(`/kanban/internalPost/${id}`);
  };
  const deleteReply = async (id) => {
    await getNestedComments(id);
    stateRef.current.map(async(e)=>await deleteInternalPost(e._id));
    await deleteInternalPost(id);
    toast.success("Comment Deleted!")

  }

  const getNestedComments = async (id) => {

    try {
      const comments = await getInternalPostTopic(taskId)
      for (let j = 0; j <comments.data.length ; j++) {
        await getAllReplies(comments.data[j],id)
      }
    }
    catch (err) {
      console.log(err)
    }
  }


  const getKids = async (id) => {

    try {
      const comments = await getInternalPostTopic(taskId)
      for (let j = 0; j <comments.data.length ; j++) {
        await getAllReplies(comments.data[j],id);
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const getAllReplies = async (comment,id)=>{
    let reply = {}
    if (comment.parentId) {
      reply = comment
      while (reply.parentId) {
        reply = await getInternalPost(reply.parentId)
        reply = reply.data
        if (reply._id === id) {
         setNestedComments(prev=>[...prev,comment]);
        }
      }
    }
  }

  return (
    <>
      <div className="col-xl-12">
        <ToastContainer />

        <Panel>
          <PanelHeader>
            {allPosts?.length}  Comments
          </PanelHeader>
          <PanelBody>
            {
              commentlists.map((item) =>

                <div className="media media-sm">
                  <Box to="/ui/media-object" className="media-left" display="flex" flexDirection="column" alignItems="center">
                    <img src={item?.user?.imageSrc} alt="" className="avatar__image" />
                    <div className="comment__userName">{item?.user?.contactName?.first + ' ' + item?.user?.contactName?.last}</div>
                  </Box>
                  <div className="media-body">
                    <h4 className="media-heading">{item.title}</h4>
                    {moment(item?.createdOn).format("LLLL").split(' ')[0] + ' ' + moment(item?.createdOn).format("LLLL").split(' ')[1] + ' ' + moment(item?.createdOn).format("LLLL").split(' ')[2] + ' ' + moment(item?.createdOn).format("LLLL").split(' ')[3]}

                    <p>{item?.narrative}</p>

                    <div
                      className="comment-reply d-flex justify-content-end align-items-center">
                      <Box>
                        <span style={{ marginRight: ".5rem" }}>
                          <FormatQuoteIcon />
                        </span>
                        <span style={{ marginRight: ".5rem" }}>
                          <FavoriteBorderIcon />
                        </span>
                        <span style={{ marginRight: ".5rem" }}>
                          <AttachmentIcon />
                        </span>
                        <span style={{ marginRight: ".5rem" }}>
                          <BookmarkBorderIcon />
                        </span>
                        <span style={{ marginRight: ".5rem" }}>
                          <ReplyIcon onClick={() => opencomment1(taskId, item._id)} />
                          Reply
                        </span>
                        <span style={{ marginRight: ".5rem" }}>
                          <EditIcon onClick={() => editComment(item._id)} />
                        </span>

                        <span style={{ marginRight: ".5rem" }}>
                          <PanToolIcon />
                        </span>
                        <span style={{ marginRight: ".5rem" }}>
                          <DeleteIcon onClick={async () =>await  deleteReply(item._id)} />

                        </span>
                        <span style={{ marginRight: ".5rem" }}>
                          <LockIcon />
                        </span>
                      </Box>
                    </div>
                    {item.comments ? (
                      item.comments.map((item2, index2) => {
                        return (
                          <ul className="comments-reply" key={index2}>
                            <li>
                              <div className="media media-sm comment">
                                <Box display="flex" flexDirection="column" alignItems="center">
                                  <Box backgroundColor="red" margin=".75rem">
                                    <img className="avatar__image" src={item2?.user?.imageSrc}/>
                                  </Box>
                                  <Box>
                                    <div className="comment__userName">{item2?.user?.contactName?.first + ' ' + item2?.user?.contactName?.last}</div>
                                  </Box>
                                </Box> 
                                                           <div className="comment-body">




                                  <p className="comment-content">
                                    {item2.narrative}
                                  </p>
                                  <div
                                    className="comment-reply d-flex justify-content-end align-item2s-center">
                                    <Box>
                                      <span style={{ marginRight: ".5rem" }}>
                                        <FormatQuoteIcon />
                                      </span>
                                      <span style={{ marginRight: ".5rem" }}>
                                        <FavoriteBorderIcon />
                                      </span>
                                      <span style={{ marginRight: ".5rem" }}>
                                        <AttachmentIcon />
                                      </span>
                                      <span style={{ marginRight: ".5rem" }}>
                                        <BookmarkBorderIcon />
                                      </span>
                                      <span style={{ marginRight: ".5rem" }}>
                                        <ReplyIcon onClick={() => opencomment1(taskId, item2._id)} />
                                        Reply
                                      </span>
                                      <span style={{ marginRight: ".5rem" }}>
                                        <EditIcon onClick={() => editComment(item2._id)} />
                                      </span>

                                      <span style={{ marginRight: ".5rem" }}>
                                        <PanToolIcon />
                                      </span>
                                      <span style={{ marginRight: ".5rem" }}>
                                        <DeleteIcon onClick={() => deleteReply(item2._id)} />
                                      </span>
                                      <span style={{ marginRight: ".5rem" }}>
                                        <LockIcon />
                                      </span>
                                    </Box>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        )
                      })
                    ) : ''}


                  </div>





                </div>








              )
            }


          </PanelBody>
        </Panel>





      </div>
    </>
  );
}

export default ListingDetailsComments1;
import React from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
// import { UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
// import ReactTags from "react-tag-autocomplete";
// import DatePicker from "react-datepicker";
import DateTime from "react-datetime";
// import moment from "moment";
//import Select from 'react-select';
//import Select from "../../common/select";

// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from "@material-ui/core/FormControl";
// import FormLabel from "@material-ui/core/FormLabel";

import Tooltip from "rc-tooltip";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "react-datetime/css/react-datetime.css";
import "react-datepicker/dist/react-datepicker.css";
import Joi from "joi";
import Select from "react-select";
import Form from "../../common/form.jsx";
import { apiUrl } from "../../config/config.json";
import http from "../../services/httpService";
import auth from "../../services/authservice";

import { saveKaizen, getKaizen } from "./../../services/kaizens";
import { getClinics } from "./../../services/clinics";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Handle = Slider.Handle;

class Kaizen extends Form {
  constructor(props) {
    super(props);
    const user = auth.getProfile();
    var maxYesterday = "";
    var minYesterday = DateTime.moment().subtract(1, "day");

    this.minDateRange = (current) => {
      return current.isAfter(minYesterday);
    };
    this.maxDateRange = (current) => {
      return current.isAfter(maxYesterday);
    };
    this.minDateChange = (value) => {
      this.setState({
        maxDateDisabled: false,
      });
      maxYesterday = value;
    };

    this.state = {
      maxDateDisabled: true,
      isEditable: true,
      profiles: [],
      users: [],
      imageSrc: "",
      clinics:[],
      data: {
        user: user._id,
        kaizenNo: this.makeKaizenNo(),
        name: "",
        motto : "" , 

        participants: [],
        businessNo: user._id,
        department: "",
        subDepartment: "",
        //wasteType : "" , 
        sponsor : [] , 
        facilitator: [] ,
        section : "" , 
        documentNo : "" , 
        //actions : "" , 
        reference : " ", 
        deadline : new Date(new Date().getTime()+21*24*60*60*1000) , 
        plannedDate : new Date() ,
        createdOn : new Date() , 
        locations: "",
        narrative: "",
        field: "",
        tags: "",
        note: "",
        status: "active",
      },
      selectedFile: null,
      errors: {},
    };

    this.statusOptions = [
      { value: "in-planning", label: "In-Planning" },
      { value: "doing", label: "Doing" },	  
      { value: "checking", label: "checking" },	  	  
      { value: "acting", label: "Acting" },	  	  
      { value: "active", label: "Active" },
      { value: "pending", label: "Pending" },
      { value: "archive", label: "Archive" },
    ];

    this.handleSlider = (props) => {
      const { value, dragging, index, ...restProps } = props;
      return (
        <Tooltip prefixCls="rc-slider-tooltip" overlay={value} visible={dragging} placement="top" key={index} >
          <Handle value={value} {...restProps} />
        </Tooltip>
      );
    };

    this.handleDateChange = (date , field) => {
			this.setState({
				data: {...this.state.data ,  [field] : date }
			})
			console.log(this.state.data)
		}

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeImgHandler = this.onChangeImgHandler.bind(this);

    this.formatSelectOption = ( {user} ) => (
      <div>
        <div style={{ display: "flex" , alignItems:"center"}}>
          <div style={{ marginRight: "10px",}}>
            <img width={15} src={user.imageSrc} alt="img here" />
          </div>
          <div>{user.contactName.first + " " + user.contactName.last } </div>
        </div>
      </div>
    );

    this.handleSelectChange = ( { _id } , field ) => {
      console.log("SELECT ONCHANGE : " , _id)
      this.setState({ data: {...this.state.data , [field] : _id }})
    }
  }

  async populateStatus() {
    this.statusoptions = this.statusOptions.map((option) => (
      <option key={option.label} value={option.value}>
        {option.value}
      </option>
    ));
  }

  async populateParticipants() {
    const { data: users } = await http.get(apiUrl + "/users");
    console.log("users : " , users)
    this.setState({ users : users });
    this.selectParticipants = this.state.users.map((user) => ({
      _id: user._id,
      label: user.username,
      value: user._id,
      user : user
    }));

    this.selectSponsor = users.map((user) => ({
      _id: user._id,
      label: user.username,
      value: user._id,
      user : user
    }));

    this.selectFalicitators = users.map((user) => ({
      _id: user._id,
      label: user.username,
      value: user._id,
      user : user
    }));


  }

  async populateSponsor() {
    const { data: users } = await http.get(apiUrl + "/users");
    this.selectSponsor = users.map((user) => ({
      _id: user._id,
      label: user.username,
      value: user._id,
      user : user
    }));

  }

  async populateFalicitator() {
    const { data: users } = await http.get(apiUrl + "/users");
    console.log("populating facilitators : " , users )
    this.selectFalicitators = users.map((user) => ({
      _id: user._id,
      label: user.username,
      value: user._id,
      user : user
    }));

  }

  async populateKaizen() {
    console.log("populating kaizen")
    try {
      const kaizenId = this.props.match.params.id;
      console.log("id : " , kaizenId)
      if (kaizenId === "new") return;
      const { data: kaizen } = await getKaizen(kaizenId);
      this.setState({ data: this.mapToViewModel(kaizen) });


      console.log(this.state.data);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/error");
    }
  }

  async componentDidMount() {
    await this.populateKaizen();
    await this.populateParticipants();
    await this.populateStatus();
    await this.populateClinics();
    //await this.populateFalicitator();
    //await this.populateSponsor();
  }

  schema = Joi.object({
    user: Joi.any().optional(),
    kaizenNo: Joi.any().optional(),
    name: Joi.any().optional(),
    participants: Joi.any().optional(),
    sponsor: Joi.any().optional(),
    facilitator: Joi.any().optional(),	
    businessNo: Joi.any().optional(),
    department: Joi.any().optional(),
    subDepartment: Joi.any().optional(),
    locations: Joi.any().optional(),
    narrative: Joi.any().optional(),
    field: Joi.any().optional(),
    tags: Joi.any().optional(),
    note: Joi.any().optional(),
    status: Joi.any().optional(),
  });

  handlecreatedOnChange = (e) => {
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };
    data["createdOn"] = e;
    this.setState({ data });
    console.log(this.state.data);
  };

  handledeadlineChange = (e) => {
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };
    data["deadline"] = e;
    this.setState({ data });
    console.log(this.state.data);
  };

  onChangeImgHandler = (event) => {
    this.setState({ imageSrc: event.target.files[0] });
    console.log(event.target.files[0]);
  };

  handleMultiChange = (name, options) => {
    const data = { ...this.state.data };
    console.log("options", options);
    //data[name] = options.map((o) => o.value);
    data[name] = options

    this.setState({ data });
  };

  doSubmit = async (kaizen) => {
    try {
      console.log(this.state.data);
      await saveKaizen(this.state.data);
      this.props.history.push("/kaizen/kaizens");
    } catch (ex) {
      //if(ex.response && ex.response.status === 404){
      if (ex.response) {
        const errors = { ...this.state.errors };
        errors.kaizenname = ex.response.data;
        this.setState({ errors });
        //console.log(this.state.errors);
      }
    }
  };

  makeKaizenNo() {
    let kaizenNumber = "KZ-";
    const possible = "ABCDEFGHIJKLMNPQRSTUVWXYZ2356789";
    for (let i = 0; i <= 5; i++)
      kaizenNumber += possible.charAt(
        Math.floor(Math.random() * possible.length)
      );
    return kaizenNumber;
  }

  mapToViewModel(kaizen) {
    console.log("mapping this nigga")
    console.log("mapping : " , kaizen._id)
    return {
      _id: kaizen._id,
      user: kaizen.user,
      kaizenNo: kaizen.kaizenNo,
      name: kaizen.name,
      participants: kaizen.participants,
      businessNo: kaizen.businessNo,
      department: kaizen.department,
      subDepartment: kaizen.subDepartment,
      locations: kaizen.locations,
      narrative: kaizen.narrative,
      field: kaizen.field,
      tags: kaizen.tags,
      note: kaizen.note,
      status: kaizen.status,
      motto : kaizen.motto ,
      //wasteType : kaizen.wasteType ,
      sponsor : kaizen.sponsor ,
      facilitator : kaizen.facilitator ,
      section : kaizen.section ,
      //actions : kaizen.actions ,
      documentNo  : kaizen.documentNo,
      reference : kaizen.reference
    };
  }

  async populateClinics() {
    const { data: clinics } = await getClinics();
    this.setState({ clinics });
    this.selectClinics = this.state.clinics.map(option => (
    <option key={option._id} value={option._id}>
      {option.companyInfo.businessName}
    </option>
    ));
    }

  render() {
    const { data, errors, imageSrc } = this.state;
    console.log("state : " , data) 
    console.log( "participant default value : " ,  this.selectParticipants?.filter((opt) =>
    {
      let participantIDs = this.state.data.participants?.map( el => el._id)
      return participantIDs?.includes(opt.value)
    }
  ))
    return (
      <React.Fragment>
        <div>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/kaizen/kaizens">Kaizens</Link>
            </li>
            <li className="breadcrumb-item active">Add Kaizen</li>
          </ol>
          <h1 className="page-header">
            Add Kaizen<small>Kaizen-registration-form</small>
          </h1>

          <div className="row">
            <div className="col-xl-10">
              <Panel>
                <PanelHeader>Add Kaizen</PanelHeader>
                <PanelBody className="panel-form">
                  <form
                    className="form-horizontal form-bordered"
                    onSubmit={this.handleSubmit}
                  >
                    {this.renderInput("name","Name of kaizen","text","Enter Name/Title/subject for kaizen")}
                    {/* {this.renderInput(
                      "participants",
                      "Participants",
                      "text",
                      "Participants"
                    )} */}
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label" htmlFor="participants" >
                        Participants
                      </label>
                      <div className="col-lg-8">
                        <Select
                          isDisabled={!this.state.isEditable}
                          isMulti
                          name="participants"
                          //styles={customStyles}
                          options={this.selectParticipants}
                          formatOptionLabel={this.formatSelectOption}
                          placeholder={"Select Participants..."}
                          value={this.selectParticipants?.filter((opt) =>
                            {
                              let participantIDs = this.state.data.participants?.map( el => el._id)
                              return participantIDs?.includes(opt.value)
                            }
                          ) }
                          onChange={(e) =>
                            this.handleMultiChange("participants", e)
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-4 col-form-label" htmlFor="sponsor" >
                          Sponsor
                        </label>
                        <div className="col-lg-8">
                        <Select
                        isDisabled={!this.state.isEditable}
                        isMulti
                        name="sponsor"
                        //styles={customStyles}
                        options={this.selectSponsor}
                        formatOptionLabel={this.formatSelectOption}
                        placeholder={"Select sponsor..."}
                        value={this.selectSponsor?.filter((opt) =>
                          {
                            let sponsorIDs = this.state.data.sponsor?.map( el => el._id)
                            return sponsorIDs?.includes(opt.value)
                          }
                        )}
                        onChange={(options) =>this.handleMultiChange("sponsor" , options)}
                      />

                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-4 col-form-label" htmlFor="facilitator" >facilitator
                        </label>
                        <div className="col-lg-8">
                        <Select
                        isDisabled={!this.state.isEditable}
                        isMulti
                        name="facilitator"
                        //styles={customStyles}
                        options={this.selectFalicitators}
                        formatOptionLabel={this.formatSelectOption}
                        placeholder={"Select facilitator..."}
                        //value={this.selectFalicitators?.filter((opt) => this.state.data.facilitator?.includes(opt.value))}
                        value={this.selectFalicitators?.filter((opt) =>
                          {
                            let facilitatorIDs = this.state.data.facilitator?.map( el => el._id)
                            return facilitatorIDs?.includes(opt.value)
                          })}
                        
                        onChange={(options) => this.handleMultiChange("facilitator" , options)}
                      />

                        </div>
                    </div>
                    
                    {/* <div className="form-group row">
										<label className="col-lg-4 col-form-label">Subscription Type</label>
										<div className="btn-group col-lg-8">
											<div className="btn btn-secondary active">
												<input type="radio" name="subscription" onChange={this.handleChange} value="Kaizen"  checked={data.subscription === "Kaizen" } />
												<label>Kaizen</label>
											</div>
											<div className="btn btn-secondary">
												<input type="radio" name="subscription" onChange={this.handleChange} value="Solo" checked={data.subscription === "Solo" } />
												<label>SoloPractice</label>
											</div>
										</div>
										{errors.subscription && (<div className="alert alert-danger">{errors.subscription}</div>)}
									</div>  */}

                    {/* {this.renderInput("businessNo", "BusinessNo", "text", "Business Number")} */}
                    <div className="form-group row m-b-15">
                      <label className="col-lg-4 col-form-label">Department</label>
                      <div className="col-lg-8">
                        <div className="row">
                          <div className="col-sm-5  ">
                          <input className="form-control m-0" type="text"
                            name="department"
                            value={data.department}
                            placeholder="Enter department"
                            onChange = {this.handleChange }
                          />
                          </div>
                          <label className="col-sm-3 col-form-label py-0 ps-5 ">
                            Sub-department
                          </label>
                          <div className="col-sm-4">
                            <input
                              className="form-control m-0"
                              name="subDepartment"
                              type="text"
                              placeholder="Enter sub department"
                              onChange = {this.handleChange}
                              value={data.subDepartment}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.renderInput("motto","Motto","text","Enter motto")}
                    {/*this.renderInput("wasteType","wasteType","text","Enter waste type")*/}
                    {this.renderInput("section","Section","text","Enter section")}
                    {this.renderInput("documentNo","DocumentNo","text","Enter documentNo")}
                    {/*this.renderInput("actions","actions","text","Enter actions")*/}
                    {this.renderInput("reference","Reference","text","Enter reference")}


                    <div className="form-group row m-b-15">
                      <label className="col-lg-4 col-form-label" htmlFor="deadline">Deadline</label>
                      <div className="col-lg-8">
                        <div className="row">
                          <div className="col-sm-5  ">
                            <DateTime id="deadline" value = {data.deadline}  onChange={ (date) => this.handleDateChange(date._d , "deadline") }/>
                          </div>
                          <label className="col-sm-3 col-form-label py-0 ps-5 ">
                           Planned date
                          </label>
                          <div className="col-sm-4">
                            <DateTime id="plannedDate" value = {data.plannedDate}  onChange={ (date) => this.handleDateChange(date._d , "plannedDate") }/>
                          </div>
                        </div>
                      </div>
                    </div>

                    {this.renderInput("locations","Locations","text","Enter Locations")}
                    {this.renderTextarea("narrative","Narrative","Enter narrative")}
                    {this.renderInput("field", "Field", "text", "Enter field")}
                    {this.renderInput("tags", "Tags", "text", "Enter Tags")}
                    {this.renderTextarea("note", "Note", "Enter note")}

                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label"htmlFor="status">Status
                      </label>
                      <div className="col-lg-8">
                        <select name="status" id="status" value={data.status  }
                          onChange={this.handleChange}
                          className="form-control">
                          <option value="">Select Status</option>
                          {this.statusoptions}
                        </select>
                      </div>
                      {errors.category && (
                        <div className="alert alert-danger">
                          {errors.status}
                        </div>
                      )}
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-8">
                        <button type="submit" disabled={this.validate()}className="btn btn-primary width-65">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </PanelBody>
              </Panel>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Kaizen);

import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Select from "react-select";
import options from "./options";
import "./style.css";

class Interview extends React.Component {
	render() {
		const targetHeight = 34;

		const customStyles = {
			control: (styles) => ({
				...styles,
				minHeight: "initial",
			}),

			option: (provided) => ({
				...provided,
			}),
			valueContainer: (base) => ({
				...base,
				padding: "0 8px",
			}),
			clearIndicator: (base) => ({
				...base,
				padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
			}),
			dropdownIndicator: (base) => ({
				...base,
				padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
			}),
		};

		const { handleChange, infoState, isEditable, handleMultiSelectChange, handleMultiChange } = this.props;
		// console.log("infoState", infoState);
		// console.log("options", options);

		return (
			<div className="main-container">
				<h3 className="mt-10">Interview</h3>
				<div className="panel-body">
					<fieldset>
						<legend className="legend-text">Lifestyle</legend>
						<div className="form-group">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>Social Relationship:</b>
									</label>
								</div>
								<div className="col-12 col-md-4">
									<Select
										isDisabled={!isEditable}
										styles={customStyles}
										options={options.socialRelationship}
										placeholder={"Select social relationship..."}
										value={
											infoState.socialRelationship && {
												value: infoState.socialRelationship,
												label: infoState.socialRelationship,
											}
										}
										onChange={(e) => handleChange("socialRelationship", e.value)}
									/>
								</div>
								<div className="col-5">&nbsp;</div>
							</div>
						</div>
						<div className="form-group">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>Habits:</b>
									</label>
								</div>
								<div className="col-12 col-md-4">
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.habits}
										placeholder={"Select habits..."}
										value={options.habits.filter((opt) => infoState.habits.includes(opt.value))}
										onChange={(e) => handleMultiChange("habits", e)}
									/>
								</div>
								<div className="col-5">&nbsp;</div>
							</div>
						</div>
						<div className="form-group">
							<div className="row">
								<div className="col-12 col-sm-3">
									<label>
										<b>Profession:</b>
									</label>
								</div>
								<div className="col-12 col-sm-9">
									<input
										disabled={!isEditable}
										type="text"
										className="form-control"
										name="profession"
										placeholder="Your profession..."
										value={infoState.profession}
										onChange={(e) => handleChange(e.target.name, e.target.value)}
									/>
								</div>
							</div>
						</div>
						<div className="form-group">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>Employment Status:</b>
									</label>
								</div>
								<div className="col-12 col-md-4">
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.employmentStatus}
										placeholder={"Select employment status..."}
										value={options.employmentStatus.filter((opt) =>
											infoState.employmentStatus.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("employmentStatus", e)}
									/>
								</div>
								<div className="col-5">&nbsp;</div>
							</div>
						</div>
						<div className="form-group">
							<div className="row">
								<div className="col-12 col-sm-3">
									<label>
										<b>Sport:</b>
									</label>
								</div>
								<div className="col-12 col-sm-9">
									<input
										disabled={!isEditable}
										type="text"
										className="form-control"
										name="sport"
										placeholder="sport of patient..."
										value={infoState.sport}
										onChange={(e) => handleChange(e.target.name, e.target.value)}
									/>
								</div>
							</div>
						</div>
						<div className="form-group">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>Sport Frequency:</b>
									</label>
								</div>
								<div className="col-12 col-md-4">
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.sportFrequency}
										placeholder={"Select employment status..."}
										value={options.sportFrequency.filter((opt) =>
											infoState.sportFrequency.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("sportFrequency", e)}
									/>
								</div>
								<div className="col-5">&nbsp;</div>
							</div>
						</div>
						<div className="form-group">
							<div className="row">
								<div className="col-12 col-sm-3">
									<label>
										<b>Hobby:</b>
									</label>
								</div>
								<div className="col-12 col-sm-9">
									<textarea
										disabled={!isEditable}
										rows="3"
										type="text"
										className="form-control"
										name="hobby"
										placeholder="Your hobbies..."
										value={infoState.hobby}
										onChange={(e) => handleChange(e.target.name, e.target.value)}
									/>
								</div>
							</div>
						</div>
					</fieldset>
				</div>

				<div className="panel-body">
					<fieldset>
						<legend className="legend-text">Inquiring for physical constitution</legend>
						<div className="row">
							<div className="col-12 col-sm-4">
								<div className="row">
									<div className="col-12 col-sm-3">
										<div className="form-group">
											<label>
												<b>Height :</b>
											</label>
										</div>
									</div>
									<div className="col-8 col-sm-4">
										<input
											disabled={!isEditable}
											type="number"
											className="form-control"
											name="height"
											placeholder="height"
											value={infoState.height}
											onChange={(e) => handleChange(e.target.name, e.target.value)}
										/>
									</div>
									<div className="col-4 col-sm-4">
										<Select
											isDisabled={!isEditable}
											styles={customStyles}
											options={[
												{ label: "cm", value: "cm" },
												{ label: "inch", value: "inch" },
											]}
											placeholder={"Select unit..."}
											value={{
												label: infoState.heightUnit,
												value: infoState.heightUnit,
											}}
											onChange={(e) => handleChange("heightUnit", e.value)}
										/>
									</div>
								</div>
							</div>
							<div className="col-12 col-sm-4">
								<div className="row">
									<div className="col-12 col-sm-3">
										<div className="form-group">
											<label>
												<b>Weight :</b>
											</label>
										</div>
									</div>
									<div className="col-8 col-sm-4">
										<input
											disabled={!isEditable}
											type="number"
											className="form-control"
											name="weight"
											placeholder="weight"
											value={infoState.weight}
											onChange={(e) => handleChange(e.target.name, e.target.value)}
										/>
									</div>
									<div className="col-4 col-sm-4">
										<Select
											isDisabled={!isEditable}
											styles={customStyles}
											options={[
												{ label: "kg", value: "kg" },
												{ label: "lbs", value: "lbs" },
											]}
											placeholder={"Select unit..."}
											value={{
												label: infoState.weightUnit,
												value: infoState.weightUnit,
											}}
											onChange={(e) => handleChange("weightUnit", e.value)}
										/>
									</div>
								</div>
							</div>
							<div className="col-12 col-sm-4">
								<div className="row">
									<div className="col-12 col-sm-3">
										<div className="form-group">
											<label>
												<b style={{ whiteSpace: "nowrap" }}>Temperature :</b>
											</label>
										</div>
									</div>
									<div className="col-8 col-sm-4">
										<input
											disabled={!isEditable}
											type="number"
											className="form-control"
											name="temperature"
											placeholder="temperature"
											value={infoState.temperature}
											onChange={(e) => handleChange(e.target.name, e.target.value)}
										/>
									</div>
									<div className="col-4 col-sm-5">
										{" "}
										<Select
											isDisabled={!isEditable}
											styles={customStyles}
											options={[
												{ label: "Celcius (°C)", value: "celsius" },
												{ label: "Fahrenheit (°F)", value: "fahrenheit" },
											]}
											placeholder={"Select unit..."}
											value={[
												{ label: "Celcius(°C)", value: "celsius" },
												{ label: "Fahrenheit(°F)", value: "fahrenheit" },
											].find((unit) => unit.value === infoState.temperatureUnit)}
											onChange={(e) => handleChange("temperatureUnit", e.value)}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="form-group mt-2">
							<div className="row">
								<div className="col-12 col-md-1">
									<label>
										<b>BMI :</b>
									</label>
								</div>
								<div className="col-12 col-md-3">
									<input
										disabled={!isEditable}
										type="number"
										className="form-control"
										name="bmi"
										placeholder="Enter BMI..."
										value={infoState.bmi}
										onChange={(e) => handleChange(e.target.name, e.target.value)}
									/>
								</div>
								<div className="col-12 col-md-2">
									<label>
										<b>Classification :</b>
									</label>
								</div>
								<div className="col-12 col-md-3">
									<input
										disabled={!isEditable}
										type="text"
										className="form-control"
										name="bmiClassification"
										placeholder="BMI classification..."
										value={infoState.bmiClassification}
										onChange={(e) => handleChange(e.target.name, e.target.value)}
									/>
								</div>
							</div>
						</div>

						<div className="form-group mt-4">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>1.&nbsp;Thermal Feeling :</b>
									</label>
								</div>
								<div className="col-12 col-md-4">
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.thermalFeeling}
										placeholder={"Select thermal feeling..."}
										value={options.thermalFeeling.filter((opt) =>
											infoState.thermalFeeling.includes(opt.value)
										)}
										onChange={(e) => handleMultiChange("thermalFeeling", e)}
									/>
								</div>
								<div className="col-5">&nbsp;</div>
							</div>
						</div>
						<div className="form-group">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>2.&nbsp;Perspiration :</b>
									</label>
								</div>
								<div className="col-12 col-md-4">
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.perspiration}
										placeholder={"Select perspiration..."}
										value={options.perspiration.filter((opt) => infoState.perspiration.includes(opt.value))}
										onChange={(e) => handleMultiChange("perspiration", e)}
									/>
								</div>
								<div className="col-5">&nbsp;</div>
							</div>
						</div>
						<div className="form-group">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>3.&nbsp;Appetite :</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.appetite}
										placeholder={"Select appetite..."}
										value={options.appetite.filter((opt) => infoState.appetite.includes(opt.value))}
										onChange={(e) => handleMultiChange("appetite", e)}
									/>
								</div>
								<div className="col-12 col-md-9">
									<label>
										<b>Note for appetite :</b>
									</label>
									<textarea
										disabled={!isEditable}
										rows="3"
										type="text"
										className="form-control"
										name="appetiteNote"
										placeholder="Your additional information for appetite..."
										value={infoState.appetiteNote}
										onChange={(e) => handleChange(e.target.name, e.target.value)}
									/>
								</div>
								<div className="col-5">&nbsp;</div>
							</div>
						</div>
						<div className="form-group">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>4.&nbsp;Vomiting :</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.vomiting}
										placeholder={"Select vomiting..."}
										value={options.vomiting.filter((opt) => infoState.vomiting.includes(opt.value))}
										onChange={(e) => handleMultiChange("vomiting", e)}
									/>
								</div>
								<div className="col-12 col-md-9">
									<label>
										<b>Note for vomiting :</b>
									</label>
									<textarea
										disabled={!isEditable}
										rows="3"
										type="text"
										className="form-control"
										name="vomitingNote"
										placeholder="Your additional information for vomiting..."
										value={infoState.vomitingNote}
										onChange={(e) => handleChange(e.target.name, e.target.value)}
									/>
								</div>
								<div className="col-5">&nbsp;</div>
							</div>
						</div>
						<div className="form-group">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>5.&nbsp;Diet :</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.diet}
										placeholder={"Select diet..."}
										value={options.diet.filter((opt) => infoState.diet.includes(opt.value))}
										onChange={(e) => handleMultiChange("diet", e)}
									/>
								</div>
								<div className="col-12 col-md-9">
									<label>
										<b>Note for diet :</b>
									</label>
									<textarea
										disabled={!isEditable}
										rows="3"
										type="text"
										className="form-control"
										name="dietNote"
										placeholder="Your additional information for diet..."
										value={infoState.dietNote}
										onChange={(e) => handleChange(e.target.name, e.target.value)}
									/>
								</div>
								<div className="col-5">&nbsp;</div>
							</div>
						</div>
						{[
							{
								label: "Taste",
								options: options.taste,
								variable: "taste",
								num: "6",
							},
							{
								label: "Thirst",
								options: options.thirst,
								variable: "thirst",
								num: "7",
							},
							{
								label: "Defecation/Stool",
								options: options.defecation,
								variable: "defecation",
								num: "8",
							},
							{
								label: "Urination",
								options: options.urination,
								variable: "urination",
								num: "9",
							},
							{
								label: "Urination-color",
								options: options.urinationColor,
								variable: "urinationColor",
								num: "10",
							},
							{
								label: "Sleep",
								options: options.sleep,
								variable: "sleep",
								num: "11",
							},
							{
								label: "Head",
								options: options.head,
								variable: "head",
								num: "12",
							},
							{
								label: "Eyes",
								options: options.eyes,
								variable: "eyes",
								num: "13",
							},
							{
								label: "Ear",
								options: options.ear,
								variable: "ear",
								num: "14",
							},
							{
								label: "Nose",
								options: options.nose,
								variable: "nose",
								num: "15",
							},
							{
								label: "Throat",
								options: options.throat,
								variable: "throat",
								num: "16",
							},
							{
								label: "Menstruation History",
								options: options.menstruation,
								variable: "menstruation",
								num: "17",
							},
							{
								label: "Leukorrhea",
								options: options.leukorrea,
								variable: "leukorrhea",
								num: "18",
							},
						].map((el) => (
							<div className="form-group mt-4" key={el.num}>
								<div className="row">
									<div className="col-12 col-md-3">
										<label>
											<b>
												{el.num}&#46;&nbsp;{el.label}&nbsp;:
											</b>
										</label>
									</div>
									<div className="col-12 col-md-4">
										<Select
											isDisabled={!isEditable}
											isMulti
											styles={customStyles}
											options={el.options}
											placeholder={`Select ${el.label.toLowerCase()}...`}
											// value={infoState[el.variable]}
											//onChange={(e) => handleMultiSelectChange(el.variable, e)}
											value={el.options.filter((opt) => infoState[el.variable].includes(opt.value))}
											onChange={(e) => handleMultiChange(el.variable, e)}
										/>
									</div>
									<div className="col-5">&nbsp;</div>
								</div>
							</div>
						))}
						<div className="form-group">
							<div className="row">
								<div className="col-12 col-sm-3">
									<label>
										<b>19&#46;&nbsp;Locality of Pain&nbsp;:</b>
									</label>
								</div>
								<div className="col-12 col-sm-9">
									<input
										disabled={!isEditable}
										type="text"
										className="form-control"
										name="localityOfPain"
										placeholder="Type in location of your pain..."
										value={infoState.localityOfPain}
										onChange={(e) => handleChange(e.target.name, e.target.value)}
									/>
								</div>
							</div>
						</div>
						<div className="form-group">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>20&#46;&nbsp;Nature of Pain&nbsp;:</b>
									</label>
								</div>
								<div className="col-12 col-md-4">
									<Select
										isDisabled={!isEditable}
										styles={customStyles}
										options={options.natureOfPain}
										placeholder={"Select nature of pain..."}
										value={
											infoState.natureOfPain && {
												value: infoState.natureOfPain,
												label: infoState.natureOfPain,
											}
										}
										onChange={(e) => handleChange("natureOfPain", e.value)}
									/>
								</div>
								<div className="col-5">&nbsp;</div>
							</div>
						</div>
						<div className="form-group">
							<div className="row">
								<div className="col-12 col-md-3">
									<label>
										<b>21&#46;&nbsp;Emotional Status&nbsp;:</b>
									</label>
									<Select
										isDisabled={!isEditable}
										isMulti
										styles={customStyles}
										options={options.emotionState}
										placeholder={"Select emotional status..."}
										value={options.emotionState.filter((opt) => infoState.emotionState.includes(opt.value))}
										onChange={(e) => handleMultiChange("emotionState", e)}
									/>
								</div>
								<div className="col-12 col-md-9">
									<label>
										<b>Note for Emotion :</b>
									</label>
									<textarea disabled={!isEditable} rows="3" type="text" className="form-control" name="emotionNote"
										placeholder="Type in additional information for emotions..." value={infoState.emotionNote}
										onChange={(e) => handleChange(e.target.name, e.target.value)}/>
								</div>
								<div className="col-5">&nbsp;</div>
							</div>
						</div>
						<div className="form-group">
							<div className="row">
								<div className="col-12 col-sm-3">
									<label>
										<b>22&#46;&nbsp;Mind&nbsp;:</b>
									</label>
								</div>
								<div className="col-12 col-sm-9">
									<textarea disabled={!isEditable} rows="6" className="form-control" name="mind"
										placeholder="State of mind of patient..." value={infoState.mind}
										onChange={(e) => handleChange(e.target.name, e.target.value)} />
								</div>
							</div>
						</div>
						<div className="form-group">
							<div className="row">
								<div className="col-12 col-sm-3">
									<label>
										<b>Note for interview :</b>
									</label>
								</div>
								<div className="col-12 col-sm-9">
									<textarea disabled={!isEditable} rows="6" className="form-control" name="interviewNote"
										placeholder="Type additional information of interviewer..."
										value={infoState.interviewNote}
										onChange={(e) => handleChange(e.target.name, e.target.value)}
									/>
								</div>
							</div>
						</div>
					</fieldset>
				</div>
			</div>
		);
	}
}

Interview.propTypes = {
	handleChange: PropTypes.func.isRequired,
	infoState: PropTypes.object.isRequired,
};

export default Interview;

import React from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
//import routes from './../../config/page-route.jsx';
import { PageSettings } from "./../../config/page-settings.js";
//import { Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configureStore from "./../../store/configureStore";
import { Provider } from "react-redux";

import ProtectedRoute from "./../../common/ProtectedRoute";
////////////////////////////////////////////////////////////////////
//import YourDrive from "./../../pages/drive/yourdrive.js";
import DashboardV2 from "./../../pages/dashboard/dashboardV2.js";
//import Dashboard-TCM from "./../../pages/dashboard/dashboard-TCM.js";
//import DashboardV3 from './../../pages/dashboard/dashboard-v3.js';
import Widgets from "./../../pages/widget/widget.js";
import UIGeneral from "./../../pages/ui/ui-general.js";
import UITypography from "./../../pages/ui/ui-typography.js";
import UITabsAccordion from "./../../pages/ui/ui-tabs-accordion.js";
import UIWidgetBoxes from "./../../pages/ui/ui-widget-boxes.js";
import UIMediaObject from "./../../pages/ui/ui-media-object.js";
import UIButtons from "./../../pages/ui/ui-buttons.js";
import UIIcons from "./../../pages/ui/ui-icons.js";
import UISimpleLineIcons from "./../../pages/ui/ui-simple-line-icons.js";
import UIIonicons from "./../../pages/ui/ui-ionicons.js";
import UILanguageBarIcon from "./../../pages/ui/ui-language-bar-icon.js";
import UISocialButtons from "./../../pages/ui/ui-social-buttons.js";
import Bootstrap4 from "./../../pages/bootstrap/bootstrap-4.js";
//import ChartJS from "./../../pages/chart/chart-js.js";
import ChartD3 from "./../../pages/chart/chart-d3.js";
import ChartApex from "./../../pages/chart/chart-apex.js";
import Map from "./../../pages/map/map.js";
import Gallery from "./../../pages/gallery/gallery.js";
import PageBlank from "./../../pages/option/page-blank.js";
import PageWithFooter from "./../../pages/option/page-with-footer.js";
import PageWithoutSidebar from "./../../pages/option/page-without-sidebar.js";
import PageWithRightSidebar from "./../../pages/option/page-with-right-sidebar.js";
import PageWithMinifiedSidebar from "./../../pages/option/page-with-minified-sidebar.js";
import PageWithTwoSidebar from "./../../pages/option/page-with-two-sidebar.js";
import PageFullHeight from "./../../pages/option/page-full-height.js";
import PageWithWideSidebar from "./../../pages/option/page-with-wide-sidebar.js";
import PageWithLightSidebar from "./../../pages/option/page-with-light-sidebar.js";
import PageWithMegaMenu from "./../../pages/option/page-with-mega-menu.js";
import PageWithTopMenu from "./../../pages/option/page-with-top-menu.js";
import PageWithBoxedLayout from "./../../pages/option/page-with-boxed-layout.js";
import PageWithMixedMenu from "./../../pages/option/page-with-mixed-menu.js";
import PageBoxedLayoutWithMixedMenu from "./../../pages/option/page-boxed-layout-with-mixed-menu.js";
import PageWithTransparentSidebar from "./../../pages/option/page-with-transparent-sidebar.js";
import PageWithSearchSidebar from "./../../pages/option/page-with-search-sidebar.js";
import Profile from "./../../pages/user/profile.js";
import ExtraCookieAcceptanceBanner from "./../../pages/extra/extra-cookie-acceptance-banner.js";
//import LoginV1 from './../../pages/user/login-v1.js';
import ExtraError from "./../../pages/extra/extra-error.js";
import LoginV2 from "../../pages/user/login.js";
import RegisterV3 from "./../../pages/register.js";
import User from "./../../pages/clinic/user";
import UserTable from "./../../pages/clinic/users.js";
import GridTicket from "./../../pages/ticket/grid-tickets.js";
import Ticket from "./../../pages/ticket/ticket.js";
import TicketsTable from "./../../pages/ticket/tickets.js";
import ticketProfile from "./../../pages/ticket/ticketprofile";
import ClinicSolo from "./../../pages/clinic/clinicsolo.js";
import ClinicSoloTable from "./../../pages/clinic/clinicsolos.js";
import Patient from "./../../pages/clinic/patient.js";
import PatientTable from "./../../pages/clinic/patients.js";
import Doctor from "./../../pages/clinic/doctor.js";
import DoctorTable from "./../../pages/clinic/doctors.js";
import Accountant from "./../../pages/clinic/accountant.js";
import AccountantTable from "./../../pages/clinic/accountants.js";
import Reception from "./../../pages/clinic/reception.js";
import ReceptionTable from "./../../pages/clinic/receptions.js";
//import Contacts from "./../../pages/clinic/contacts.js";
//import TCMTreatment from './../../pages/clinic/tcmtreatment.js';
//import TCMTreatmentTable from './../../pages/clinic/tcmtreatments.js';
import Task from "./../../pages/kanban/task.js";
import TaskTable from "./../../pages/kanban/tasks.js";
import Taskprofile from "./../../pages/kanban/taskprofile";

import Waste from "./../../pages/kaizen/wastetype.js";
import WastesTable from "./../../pages/kaizen/wastetypes.js";
import Kaizen from "./../../pages/kaizen/kaizen.js";
import KaizenTable from "./../../pages/kaizen/kaizens.js";
import Kaizenprofile from "./../../pages/kaizen/kaizenprofile";
import GridKaizen from "./../../pages/kaizen/gridkaizens.js";

import ListKanban from "./../../pages/kanban/listkanban.js";
import ListKanbansTable from "./../../pages/kanban/listkanbans.js";
import Kanban from "./../../pages/kanban/kanban.js";
import KanbanTable from "./../../pages/kanban/kanbans.js";
import Scrumboard from "./../../pages/kanban/scrumboard.js";
import KanbansDataTable from "./../../pages/kanban/kanbans.js";
import TimelineKanbanTasks from "../../pages/kanban/timelinetasks";
import TimelineTickets from "../../pages/ticket/timelinetickets";
import AddMedicalSession from "../../pages/clinic/medicalession/AddMedicalSession";

import CardTabs from '../../pages/kanban/card/CardTabs'

// import CardsTable from '../../pages/kanban/cards'

import Logout from "./../../common/logout";
import TCMMedicalFilesTableData from "./../../pages/clinic/TCMsession/medicalfiles";
import AddHomeopathySession from "../../pages/clinic/homeosession/AddHomeopathySession";
import MedicalFilesTableData from "../../pages/clinic/TCMsession/medicalfiles.js";
import AddAyurvedaSession from "../../pages/clinic/ayurvedasession/AddAyurvedaSession";
import AMedicalFilesTableData from "./../../pages/clinic/ayurvedasession/medicalfiles"; 




import AddTCMSession from "../../pages/clinic/TCMsession/AddTCMSession";
import UIModalNotification from "../../pages/clinic/TCMsession/suggestingtreatment.js";


//import MedicalFilesTableData from "./../../pages/clinic/medicalession/medicalfiles.js";

//import TimelineAppointments from "../../pages/calendar/timelineappointments";


//need to be reviewed
//import AddCoas from "../../pages/accounting/addCoas.js";
//import EditCoas from "../../pages/accounting/editCoa.js";

//import Kaibanzen from "./../../pages/kaizen/kaibanzen.js";
//need to be fixed
//import RootCause from "./../../pages/kaizen/rootcause.js";
//import RootCausesTable from "./../../pages/kaizen/rootcauses.js";
//import Action from "./../../pages/kaizen/action.js";
//import ActionsTable from "./../../pages/kaizen/actions.js";
//import TimelineKaizensTable from "./../../pages/kaizen/timelinekaizens.js";



// import HomeopathySession from "./../../pages/clinic/homeosession/HomeopathySession.js";


// import PermissionTab from "./../../pages/user/permissions.js";
// import UserRole from "./../../pages/user/userrole.js";
//import AgendaView from "../../pages/calendar/AgendaView";
//import Messenger from "../../pages/messenger/messenger";
//import Contacts from "../../pages/clinic/contacts";
// import Contacts from '../../pages/contacts/Contacts'
// import FileView from '../../pages/drive/FileViewer/fileviewer'
//import UserRoleTableData from "./../../pages/user/userroles";
//import Userrole_new from "../../pages/user/userrole_new";

//import ModulePermission from "../../pages/user/modulepermission";
//import ModulePermissionTable from "../../pages/user/modulepermissions";

//import AddTcmSession from "../../pages/clinic/tcmsession/AddTcmSession.js";


function setTitle(path, routeArray) {
	var pageTitle;
	for (var i = 0; i < routeArray.length; i++) {
		if (routeArray[i].path === path) {
			pageTitle = "TCMFiles | " + routeArray[i].title;
		}
	}
	document.title = pageTitle ? pageTitle : "TCMFiles | Users";
}

//const store = configureStore();

class Content extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	async componentDidMount() {
		//set page title dinamically
		//setTitle(this.props.history.location.pathname, routes);
		/*     try{
		const user = auth.getProfile();
	    //if(user){
		   const {data:currentUser} = await getUser(user._id);
		   this.setState({ user: this.mapToViewModel(currentUser) });
    	//}
	    }catch(ex){
		  console.log(ex);
    	} */
	}

	// componentWillMount() {
	//   //set page title dinamically
	//   this.props.history.listen(() => {
	// 		//setTitle(this.props.history.location.pathname, routes);
	//   });
	// }

	render() {
		const { user } = this.state;
		return (
			<PageSettings.Consumer>
				{({ pageContentFullWidth, pageContentClass, pageContentInverseMode }) => (
					<div
						className={
							"content " +
							(pageContentFullWidth ? "content-full-width " : "") +
							(pageContentInverseMode ? "content-inverse-mode " : "") +
							pageContentClass
						}
					>
						<React.Fragment>
							<ToastContainer />

							<Switch>
								<ProtectedRoute path="/dashboard/" title="Clinic Dashboard" component={DashboardV2} />
                               
								<Route path="/" exact>
									<Redirect to="/dashboard/" />
								</Route>

								<Route path="/user/login"
									title="Login"
									render={(props) => {
										if (this.state.user) return <Redirect to="/dashboard" />;
										return <LoginV2 {...props} />;
									}}
								/>
								<Route path="/logout" title="Logout" component={Logout} />
								<Route path="/register" title="Register" component={RegisterV3} />
								<ProtectedRoute path="/clinic/users/:id" title="User" component={User} />
								<ProtectedRoute path="/clinic/users" title="Users" component={UserTable} />

								<ProtectedRoute path="/ticket/tickets/:id" title="Ticket" component={Ticket} />
								<ProtectedRoute path="/ticket/tickets" title="Tickets" component={TicketsTable} />
								<Route path="/ticket/tickets" title="GridTicket" component={GridTicket} />
								<ProtectedRoute path="/ticket/ticketprofile/:id" title="ticketProfile" component={ticketProfile}/>
								<ProtectedRoute path="/ticket/grid-tickets" title="grid-tickets" component={GridTicket} />
															

								<ProtectedRoute path="/clinic/clinicsolos/:id" title="ClinicSolo" component={ClinicSolo} />
								<ProtectedRoute path="/clinic/clinicsolos" title="ClinicSolos" component={ClinicSoloTable} />
								<ProtectedRoute path="/clinic/patients/:id" title="Patient" component={Patient} />
								<ProtectedRoute path="/clinic/patients" title="Patients" component={PatientTable} />
								<ProtectedRoute path="/clinic/doctors/:id" title="Doctor" component={Doctor} />
								<ProtectedRoute path="/clinic/doctors" title="Doctors" component={DoctorTable} />
								<ProtectedRoute path="/clinic/accountants/:id" title="Accountant" component={Accountant} />
								<ProtectedRoute path="/clinic/accountants" title="Accountants" component={AccountantTable} />
								<ProtectedRoute path="/clinic/receptions/:id" title="Reception" component={Reception} />
								<ProtectedRoute path="/clinic/receptions" title="Receptions" component={ReceptionTable} />
								<Route path="/kanban/timelinetasks" title="Timeline Tasks" component={TimelineKanbanTasks} exact />
								<Route path="/ticket/timelinetickets" title="Timeline Tickets" component={TimelineTickets} exact />   
								<Route path="/testmymodal" title="testmymodal" component={UIModalNotification} />

								<ProtectedRoute path="/clinic/ayurvedasessions/medicalfiles" title="medical files" component={AMedicalFilesTableData} exact />		
								<ProtectedRoute path="/clinic/homeosessions/:id" title="HomeopathySession" component={AddHomeopathySession} />
								<ProtectedRoute path="/clinic/ayurvedasessions/:id" title="AyurvedaSession" component={AddAyurvedaSession} />																						
								<ProtectedRoute path="/clinic/tcmsession/medicalfiles" title="medical files" component={TCMMedicalFilesTableData} exact />								
								<ProtectedRoute path="/clinic/tcmsession/:id" title="TCMSession" component={AddTCMSession} />								
						{/* <ProtectedRoute path="/clinic/medicalfiles" title="medical files" component={MedicalFilesTableData} /> */}

								<Route path="/kanban/scrumboard/:id" title="Scrumboard" component={Scrumboard} />
								<Route path="/kanban/tasks/:id" title="Card" component={Task} />
								<ProtectedRoute path="/kanban/task/taskprofile/:id" title="Cards" component={Taskprofile} />
								<ProtectedRoute path="/kanban/tasks" title="Tasks" component={TaskTable} />				

								<ProtectedRoute path="/kanban/cards" title="Cards" component={CardTabs} />					

								{/* <ProtectedRoute path="/kanban/cards" title="Cards" component={CardsTable} />					 */}

								<Route path="/kanban/listkanbans/:id" title="Listkanban" component={ListKanban} />
								<ProtectedRoute path="/kanban/listkanbans" title="Listkanbans" component={ListKanbansTable} />
								<Route path="/kanban/kanbans/:id" title="Kanban" component={Kanban} />
								<ProtectedRoute path="/kanban/kanbans" title="Kanbans" component={KanbanTable} />
								<ProtectedRoute path="/kanban/allkanbans/:id" title="Kanbans" component={Scrumboard} />
								<ProtectedRoute path="/kanban/allkanbans" title="Kanbans" component={Scrumboard} />
								
								
                 {	/*<Route path="/kaizen/scrumboard/:id" title="Kaibanzen" component={Kaibanzen} />  */}
							{/*	 <Route path="/kaizen/kaizens" title="Kaizen" component={Kaizen} /> */}
								<ProtectedRoute path="/kaizen/kaizens/:id" title="Kaizen" component={Kaizen} />
								<ProtectedRoute path="/kaizen/kaizens" title="KaizenTable" component={KaizenTable} />
									
							{/*<ProtectedRoute path="/kaizen/edit/actions/:idAction" title="Cards" component={Exist} /> */}																	
							
								<ProtectedRoute path="/kaizen/wastetypes/:id" title="Wastes" component={Waste} />						
								<ProtectedRoute path="/kaizen/wastetypes" title="Wastes" component={WastesTable} />									
								<ProtectedRoute path="/kaizen/kaizenprofile/:id" title="Kaizenprofile" component={Kaizenprofile} />	
							
								{/* <ProtectedRoute path="/clinic/tcmsessions/:id" title="TCMSession" component={AddTcmSession} /> */}
								{/* <Route path="/user/permissions/:id" title="Permissions" component={UserRole} />
								<Route path="/user/permissions" title="Permissions" component={PermissionTab} />
								<Route path="/user/userroles/:id" title="Userrole" component={Userrole_new} />
                                <Route path="/user/userroles/new" title="Userrole" component={Userrole_new} />
                                <Route path="/user/userroles" title="Userroles" component={UserRoleTableData} />
								<Route path="/user/modulepermissions/:id" title="Permission" component={ModulePermission} />
								<Route path="/user/modulepermissions/new" title="Permission" component={ModulePermission} /> 
                                <Route path="/user/modulepermissions" title="Permission" component={ModulePermissionTable} />  */}

								{/* <Provider store={store}> */}
								<Route path="/user/profile" title="Extra Profile" component={Profile} />

								<Route title="404" component={ExtraError} />
								{/* </Provider> */}
							</Switch>
						</React.Fragment>
					</div>
				)}
			</PageSettings.Consumer>
		);
	}
}

export default withRouter(Content);